import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Select, Space, Switch, Row, Col, Card, Radio, RadioChangeEvent, Tag, Tooltip } from 'antd';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { AppContext } from '../../contexts/AppContext';
import SupplyDemandMatchingChart from '../../components/charts/supply-demand-matching.chart';
import SupplyDemandMatchingTable from '../../components/tables/supply-demand-matching.table';
import APIList from '../../http/ApiList';
import ChartGroup from '@meteor/frontend-core/dist/chart/chart-card/chart-group';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { ChartTableLayout } from '@meteor/frontend-core';
import { exportTableFromExcelTemp } from '../../utils/xlsxReader';
import dayjs from 'dayjs';

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const { initKpiData, getKpiData, snapshot, snapshotVersion } = useContext(AipcmctyContext);

  const { demandMhPercent, locale, menuCollapsed, scrollCount, setScrollCount } = useContext(AppContext);

  const [viewMode, setViewMode] = useState('chart-table');

  const { selectorTop } = useWindowSize({
    selector: '.chart-group-container',
    viewMode,
  });

  // const { t } = useTranslation();
  const [showProjects, setShowProjects] = useState(false);
  const [rank, setRank] = useState(5);
  const [chartGroupLoading, setChartGroupLoading] = useState(false);
  const [totalMh, setTotalMh] = useState([]);
  const [japanMh, setJapanMh] = useState([]);
  const [indiaMh, setIndiaMh] = useState([]);
  const [sumMh, setSumMh] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);
  const [year, setYear] = useState('2024');

  const [mhType, setMhType] = useState('imbalancedMh');
  const [tableData, setTableData] = useState({
    supplyTotal: [],
    mhEmployee: [],
    mhCp: [],
    mpEmployee: [],
    mpCp: [],
    jobMh: [],
    propMh: [],
    genMh: [],
    rdMh: [],
  });

  const [tgcs, setTgcs] = useState(['Toyo-J', 'Toyo-I']);
  const [divisions, setDivisions] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState([[], [], []]);
  const [exportLoading, setExportLoading] = useState(false);

  const initialKpiOrder = [1, 2, 3, 4];

  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );

  const kpiMapping = {
    1: (
      <SupplyDemandMatchingChart
        kpiCode="KOT004"
        data={getKpiData('KOT004').data}
        showProjects={showProjects}
        projectData={totalMh}
        height={380}
        loading={getKpiData('KOT004').loading}
        title={customChartGroupTitle(
          t('aipcmcty.page.others'),
          locale.locale === 'ja'
            ? `${t('aipcmcty.page.demandSupplyMatching')}(Toyo${t('aipcmcty.page.overall')})`
            : `Toyo${t('aipcmcty.page.overall')} ${t('aipcmcty.page.demandSupplyMatching')}`,
          '#808080'
        )}
        filter={filter}
        showTable={false}
      />
    ),
    2: (
      <SupplyDemandMatchingChart
        kpiCode="KOT001"
        data={getKpiData('KOT001').data}
        showProjects={showProjects}
        projectData={japanMh}
        height={380}
        loading={getKpiData('KOT001').loading}
        title={customChartGroupTitle(
          t('aipcmcty.page.others'),
          locale.locale === 'ja' ? `${t('aipcmcty.page.demandSupplyMatching')}(Japan)` : `Japan ${t('aipcmcty.page.demandSupplyMatching')}`,
          '#808080'
        )}
        filter={filter}
        showTable={false}
      />
    ),
    3: (
      <SupplyDemandMatchingChart
        kpiCode="KOT002"
        data={getKpiData('KOT002').data}
        showProjects={showProjects}
        projectData={indiaMh}
        height={380}
        loading={getKpiData('KOT002').loading}
        title={customChartGroupTitle(
          t('aipcmcty.page.others'),
          locale.locale === 'ja' ? `${t('aipcmcty.page.demandSupplyMatching')}(India)` : `India ${t('aipcmcty.page.demandSupplyMatching')}`,
          '#808080'
        )}
        filter={filter}
        showTable={false}
      />
    ),
    4: (
      <SupplyDemandMatchingChart
        kpiCode="KOT003"
        data={getKpiData('KOT003').data}
        showProjects={showProjects}
        projectData={sumMh}
        height={380}
        loading={getKpiData('KOT003').loading}
        title={customChartGroupTitle(
          t('aipcmcty.page.others'),
          locale.locale === 'ja'
            ? `${t('aipcmcty.page.demandSupplyMatching')}(Japan + India)`
            : `Japan + India ${t('aipcmcty.page.demandSupplyMatching')}`,
          '#808080'
        )}
        filter={filter}
        showTable={false}
      />
    ),
  };

  const [tgcOptions, setTgcOptions] = useState([]);

  useEffect(() => {
    APIList.getCmcOptions()
      .get({
        category: 'tgc',
        snapshot,
        snapshotVersion,
      })
      .then((tgcOpts) => {
        setTgcOptions(tgcOpts);
      });
  }, []);

  // extra filter
  const locationList = [
    { value: 'Toyo-J', label: 'Toyo-J' },
    { value: 'IKPT', label: 'IKPT' },
    { value: 'Toyo-C', label: 'Toyo-C' },
    { value: 'Toyo-I', label: 'Toyo-I' },
    { value: 'Toyo-K', label: 'Toyo-K' },
    { value: 'Toyo-M', label: 'Toyo-M' },
    { value: 'TPS', label: 'TPS' },
    { value: 'TSPI', label: 'TSPI' },
    { value: 'OFS', label: 'OFS' },
  ];

  const divisionList = [
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Procurement', label: 'Procurement' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Corporate', label: 'Corporate' },
    { value: 'Project', label: 'Project' },
  ];

  const disciplineList = [
    { value: 'PIT', label: 'PIT' },
    { value: 'PSE', label: 'PSE' },
    { value: 'HEX', label: 'HEX' },
    { value: 'ADT', label: 'ADT' },
    { value: 'APP', label: 'APP' },
    { value: 'PJM', label: 'PJM' },
    { value: 'MAT', label: 'MAT' },
    { value: 'CSA', label: 'CSA' },
    { value: 'PRS', label: 'PRS' },
    { value: 'GIR', label: 'GIR' },
    { value: 'LPA', label: 'LPA' },
    { value: 'SEM', label: 'SEM' },
    { value: 'STA', label: 'STA' },
    { value: 'C-MEC', label: 'C-MEC' },
    { value: 'FF', label: 'FF' },
    { value: 'LAB', label: 'LAB' },
    { value: 'NCE', label: 'NCE' },
    { value: 'CMP', label: 'CMP' },
    { value: 'ITM', label: 'ITM' },
    { value: 'FFP', label: 'FFP' },
    { value: 'LEG', label: 'LEG' },
    { value: 'C-INS', label: 'C-INS' },
    { value: 'C-INC', label: 'C-INC' },
    { value: 'DHSE', label: 'DHSE' },
    { value: 'PRO', label: 'PRO' },
    { value: 'ROT', label: 'ROT' },
    { value: 'MHE', label: 'MHE' },
    { value: 'ACC', label: 'ACC' },
    { value: 'COS', label: 'COS' },
    { value: 'HCD', label: 'HCD' },
    { value: 'TFA', label: 'TFA' },
    { value: 'CST', label: 'CST' },
    { value: 'SEC', label: 'SEC' },
    { value: 'QAM', label: 'QAM' },
    { value: 'ISP', label: 'ISP' },
    { value: 'PIP', label: 'PIP' },
    { value: 'EXP', label: 'EXP' },
    { value: 'HSE', label: 'HSE' },
    { value: 'FIN', label: 'FIN' },
    { value: 'BZD', label: 'BZD' },
    { value: 'ELE', label: 'ELE' },
    { value: 'NDC', label: 'NDC' },
    { value: 'C-ELE', label: 'C-ELE' },
    { value: 'ENG', label: 'ENG' },
    { value: 'TRS', label: 'TRS' },
    { value: 'C-PIP', label: 'C-PIP' },
    { value: 'SAL', label: 'SAL' },
    { value: 'PUR', label: 'PUR' },
    { value: 'MAR', label: 'MAR' },
    { value: 'RAD', label: 'RAD' },
    { value: 'INC', label: 'INC' },
    { value: 'COC', label: 'COC' },
    { value: 'FNC', label: 'FNC' },
    { value: 'EIT', label: 'EIT' },
    { value: 'DXT', label: 'DXT' },
    { value: 'GEN', label: 'GEN' },
    { value: 'COM', label: 'COM' },
    { value: 'PUE', label: 'PUE' },
    { value: 'STC', label: 'STC' },
    { value: 'C-PAT', label: 'C-PAT' },
    { value: 'BZM', label: 'BZM' },
    { value: 'C-CSA', label: 'C-CSA' },
  ];

  const roleList = [
    { value: 'CM', label: 'CM' },
    { value: 'M', label: 'M' },
    { value: 'PM', label: 'PM' },
    { value: 'L', label: 'L' },
    { value: 'QAM', label: 'QAM' },
    { value: 'OM', label: 'OM' },
    { value: 'FM', label: 'FM' },
    { value: 'ALAE', label: 'ALAE' },
    { value: 'FEM', label: 'FEM' },
    { value: 'PE', label: 'PE' },
    { value: '役員', label: '役員' },
    { value: 'QCM', label: 'QCM' },
    { value: 'AE', label: 'AE' },
    { value: 'S', label: 'S' },
    { value: 'LAE', label: 'LAE' },
  ];

  const onChange = (checked: boolean) => {
    if (checked) {
      setShowProjects(true);
    } else {
      setShowProjects(false);
    }
  };

  // Table settings
  const getDsMatchingTableData = (value) => {
    setTableLoading(true);
    const promise1 = APIList.getDsMatchingTableData().post({
      snapshot: snapshot,
      snapshotVersion: snapshotVersion,
      dsType: 'supply',
      year: value,
    });

    const promise2 = APIList.getDsMatchingTableData().post({
      snapshot: snapshot,
      snapshotVersion: snapshotVersion,
      dsType: 'demand',
      year: value,
    });

    Promise.all([promise1, promise2])
      .then((responses: any) => {
        const data1 = responses[0].tableDataMart;
        const data2 = responses[1].tableDataMart;
        if (data1 && data2) {
          const combinedData = {
            supplyTotal: data1.supplyTotal ?? [],
            mhEmployee: data1.mhEmployee ?? [],
            mhCp: data1.mhCp ?? [],
            mpEmployee: data1.mpEmployee,
            mpCp: data1.mpCp,
            jobMh: data2.JOB,
            propMh: data2.Proposal,
            genMh: data2.一般,
            rdMh: data2['R&D'],
          };
          setTableData(combinedData);
        }
        setTableLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    initKpiData([['KOT001'], ['KOT002'], ['KOT003'], ['KOT004']]);
    APIList.getDsMatchingChartData()
      .post({
        snapshot: snapshot,
        snapshotVersion: snapshotVersion,
        rank: rank,
      })
      .then((data: any) => {
        const { budgetProjects } = data;
        if (data) {
          setTotalMh(budgetProjects.total);
          setJapanMh(budgetProjects['Toyo-J']);
          setIndiaMh(budgetProjects['Toyo-I']);
          setSumMh(budgetProjects.sum);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    getDsMatchingTableData(year);
  }, [snapshot, snapshotVersion]);

  const handleMhTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    setMhType(value);
    // getDsMatchingTableData();
  };

  const handleYearChange = ({ target: { value } }: RadioChangeEvent) => {
    setYear(value);
    getDsMatchingTableData(value);
  };

  const handleTgcChange = (value: string[]) => {
    setTgcs(value);
  };

  const handleDivisionChange = (value: string[]) => {
    setDivisions(value);
    setFilter([value, disciplines, roles]);
  };

  const handleDisciplineChange = (value: string[]) => {
    setDisciplines(value);
    setFilter([divisions, value, roles]);
  };

  const handleRoleChange = (value: string[]) => {
    setRoles(value);
    setFilter([divisions, disciplines, value]);
  };

  const handleRankChange = (value: number) => {
    setChartGroupLoading(true);
    APIList.getDsMatchingChartData()
      .post({
        snapshot: snapshot,
        snapshotVersion: snapshotVersion,
        rank: value,
      })
      .then((data: any) => {
        const { budgetProjects } = data;
        if (data) {
          setTotalMh(budgetProjects.total);
          setJapanMh(budgetProjects['Toyo-J']);
          setIndiaMh(budgetProjects['Toyo-I']);
          setSumMh(budgetProjects.sum);
        }
        setChartGroupLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setChartGroupLoading(false);
      });
  };

  const getExportExcelData = () => {
    const years = ['2024', '2025', '2026'];
    return years.reduce((prev, y) => {
      prev[`data${y}`] = Promise.all([
        APIList.getDsMatchingTableData().post({
          snapshot: snapshot,
          snapshotVersion: snapshotVersion,
          dsType: 'supply',
          year: y,
        }),
        APIList.getDsMatchingTableData().post({
          snapshot: snapshot,
          snapshotVersion: snapshotVersion,
          dsType: 'demand',
          year: y,
        }),
      ]);
      return prev;
    }, {} as any);
  };

  const isValidNumber = (value: any, isDividend = false) =>
    isDividend ? ![undefined, null, '-', '0'].includes(value) : ![undefined, null, '-'].includes(value);

  const findLongest = (listArray) => {
    let longest = [];
    const others = [];
    listArray.forEach((l) => {
      if (l.length > longest.length) {
        if (longest.length) {
          others.push(longest);
        }
        longest = l;
      } else {
        others.push(l);
      }
    });
    return {
      longest,
      others,
    };
  };

  const supplyTotalMethod = (supply) => {
    const { mhEmployee, mhCp } = supply;
    const reg = /\d{4}-\d{2}/;
    const { longest, others } = findLongest([mhEmployee, mhCp]);
    return longest.reduce((prev, cur) => {
      const curTemplate = Object.keys(cur).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] = '-';
        } else {
          pr[k] = cur[k];
        }
        return pr;
      }, {});
      const otherItems = others.map(
        (os) =>
          os.find((m) => m.tgc === cur.tgc && m.division === cur.division && m.discipline === cur.discipline && m.role === cur.role) ??
          curTemplate
      );
      const total = Object.keys(cur).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] =
            Number(!['-', null, undefined].includes(cur[k]) ? cur[k] : 0) +
            otherItems.reduce((otherTotal, oItem) => otherTotal + Number(!['-', null, undefined].includes(oItem[k]) ? oItem[k] : 0), 0);
        }
        return pr;
      }, {});
      prev.push({
        type: '保有工数Total',
        tgc: cur.tgc,
        division: cur.division,
        discipline: cur.discipline,
        role: cur.role,
        ...total,
        numFmt: '#,##0',
      });
      return prev;
    }, []);
  };

  const demandTotalMethod = (demand) => {
    const { JOB: job, Proposal: proposal, 'R&D': rAndD } = demand;
    const normal = demand['一般'];
    const reg = /\d{4}-\d{2}/;
    const { longest, others } = findLongest([job, proposal, rAndD, normal]);
    return longest.reduce((prev, cur) => {
      const curTemplate = Object.keys(cur).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] = '-';
        } else {
          pr[k] = cur[k];
        }
        return pr;
      }, {});
      const otherItems = others.map(
        (os) =>
          os.find((m) => m.tgc === cur.tgc && m.division === cur.division && m.discipline === cur.discipline && m.role === cur.role) ??
          curTemplate
      );
      const total = Object.keys(cur).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] =
            Number(!['-', null, undefined].includes(cur[k]) ? cur[k] : 0) +
            otherItems.reduce((otherTotal, oItem) => otherTotal + Number(!['-', null, undefined].includes(oItem[k]) ? oItem[k] : 0), 0);
        }
        return pr;
      }, {});
      prev.push({
        type: '需要工数Total',
        tgc: cur.tgc,
        division: cur.division,
        discipline: cur.discipline,
        role: cur.role,
        ...total,
        numFmt: '#,##0',
      });
      return prev;
    }, []);
  };

  const balanceMethod = (supplyTotal, demandTotal) => {
    const reg = /\d{4}-\d{2}/;
    return supplyTotal.map((st) => {
      const curTemplate = Object.keys(st).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] = '-';
        } else {
          pr[k] = st[k];
        }
        return pr;
      }, {});
      const dt =
        demandTotal.find((d) => d.tgc === st.tgc && d.division === st.division && d.discipline === st.discipline && d.role === st.role) ??
        curTemplate;
      const typedData = {
        ...st,
        type: '過不足MH',
        numFmt: '#,##0',
      };
      return Object.keys(typedData).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] = Number(typedData[k]) - Number(dt[k]);
        } else {
          pr[k] = typedData[k];
        }
        return pr;
      }, {} as any);
    });
  };

  const workRatioTotalMethod = (supplyTotal, demandTotal) => {
    const reg = /\d{4}-\d{2}/;
    return supplyTotal.map((st) => {
      const curTemplate = Object.keys(st).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] = '-';
        } else {
          pr[k] = st[k];
        }
        return pr;
      }, {});
      const dt =
        demandTotal.find((d) => d.tgc === st.tgc && d.division === st.division && d.discipline === st.discipline && d.role === st.role) ??
        curTemplate;
      const typedData = {
        ...st,
        type: 'Work Ratio Total',
        numFmt: '0.0%',
      };
      return Object.keys(typedData).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] =
            !['-', 0, null, undefined].includes(typedData[k]) && ![undefined, null, '-'].includes(dt[k])
              ? Number(dt[k]) / Number(typedData[k])
              : '-';
        } else {
          pr[k] = typedData[k];
        }
        return pr;
      }, {} as any);
    });
  };

  const propsRatioMethod = (supplyTotal, propData, type: string) => {
    const reg = /\d{4}-\d{2}/;
    return supplyTotal.map((st) => {
      const curTemplate = Object.keys(st).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] = '-';
        } else {
          pr[k] = st[k];
        }
        return pr;
      }, {});
      const nItem =
        propData.find((p) => p.tgc === st.tgc && p.division === st.division && p.discipline === st.discipline && p.role === st.role) ??
        curTemplate;
      const typedData = {
        ...st,
        type,
        numFmt: '0.0%',
      };
      return Object.keys(typedData).reduce((pr, k) => {
        if (reg.test(k)) {
          pr[k] =
            !['-', 0, null, undefined].includes(typedData[k]) && ![undefined, null, '-'].includes(nItem[k])
              ? Number(nItem[k]) / Number(typedData[k])
              : '-';
        } else {
          pr[k] = typedData[k];
        }
        return pr;
      }, {});
    });
  };

  const handleExportExcel = async () => {
    setExportLoading(true);
    const { data2024, data2025, data2026 } = getExportExcelData();
    const [{ tableDataMart: supply2024 }, { tableDataMart: demand2024 }] = await data2024;
    const [{ tableDataMart: supply2025 }, { tableDataMart: demand2025 }] = await data2025;
    const [{ tableDataMart: supply2026 }, { tableDataMart: demand2026 }] = await data2026;
    const typeMap = new Map<string, string>([
      ['mhEmployee', '社員保有工数'],
      ['mhCp', 'CP保有工数'],
      ['mpEmployee', '社員数 (MP)'],
      ['mpCp', 'CP数 (MP)'],
      ['一般', '一般需要MH'],
      ['R&D', 'R&D需要MH'],
      ['Proposal', 'Proposal需要MH'],
      ['JOB', 'JOB需要MH'],
      ['JOB', 'JOB需要MH'],
    ]);
    const supplyAllFilteredData = Object.keys(supply2024).reduce((prev, k) => {
      prev[k] = [...supply2024[k], ...supply2025[k], ...supply2026[k]].filter(
        (s) =>
          (!tgcs.length || tgcs.includes(s.tgc)) &&
          (!disciplines.length || disciplines.includes(s.discipline)) &&
          (!divisions.length || divisions.includes(s.division)) &&
          (!roles.length || roles.includes(s.role))
      );
      return prev;
    }, {});
    const demandAllFilteredData = Object.keys(demand2024).reduce((prev, k) => {
      prev[k] = [...demand2024[k], ...demand2025[k], ...demand2026[k]].filter(
        (d) =>
          (!tgcs.length || tgcs.includes(d.tgc)) &&
          (!disciplines.length || disciplines.includes(d.discipline)) &&
          (!divisions.length || divisions.includes(d.division)) &&
          (!roles.length || roles.includes(d.role))
      );
      return prev;
    }, {});
    const supplyData = Object.entries(supplyAllFilteredData).reduce((prev, [k, vs]) => {
      prev[k] = (vs as any[]).reduce((pr, v) => {
        const existItem = pr.find(
          (p) => p.discipline === v.discipline && p.division === v.division && p.role === v.role && p.tgc === v.tgc
        );
        const isExist = isValidNumber(existItem?.[v.date]);
        if (isExist) {
          return pr;
        }
        const duplicateItems = (vs as any[]).filter(
          vv => vv.discipline === v.discipline
            && vv.division === v.division
            && vv.role === v.role
            && vv.tgc === v.tgc
            && vv.date === v.date
        );
        let sumData: string | number;
        if (duplicateItems.length > 1) {
          sumData = duplicateItems.reduce(
            (dpr, curD) => dpr + Number(isValidNumber(curD.supplyMh) ? curD.supplyMh : 0),
            0
          );
        } else if (isValidNumber(duplicateItems[0].supplyMh)) {
          sumData = duplicateItems[0].supplyMh;
        } else {
          sumData = '-';
        }
        if (existItem) {
          existItem[v.date] = sumData;
        } else {
          pr.push({
            discipline: v.discipline,
            division: v.division,
            role: v.role,
            tgc: v.tgc,
            [v.date]: sumData,
            numFmt: '#,##0',
          });
        }
        return pr;
      }, []);
      return prev;
    }, {});
    const demandData = Object.entries(demandAllFilteredData).reduce((prev, [k, vs]) => {
      prev[k] = (vs as any[]).reduce((pr, v) => {
        const existItem = pr.find(
          (p) => p.discipline === v.discipline && p.division === v.division && p.role === v.role && p.tgc === v.tgc
        );
        const isExist = isValidNumber(existItem?.[v.date]);
        if (isExist) {
          return pr;
        }
        const duplicateItems = (vs as any[]).filter(
          vv => vv.discipline === v.discipline
            && vv.division === v.division
            && vv.role === v.role
            && vv.tgc === v.tgc
            && vv.date === v.date
        );
        let sumData: string | number;
        if (duplicateItems.length > 1) {
          sumData = duplicateItems.reduce(
            (dpr, curD) => dpr + Number(isValidNumber(curD.demandMh) ? curD.demandMh : 0),
            0
          );
        } else if (isValidNumber(duplicateItems[0].demandMh)) {
          sumData = duplicateItems[0].demandMh;
        } else {
          sumData = '-';
        }
        if (existItem) {
          existItem[v.date] = sumData;
        } else {
          pr.push({
            discipline: v.discipline,
            division: v.division,
            role: v.role,
            tgc: v.tgc,
            [v.date]: sumData,
            numFmt: '#,##0',
          });
        }
        return pr;
      }, []);
      return prev;
    }, {});
    const supplyTotal = supplyTotalMethod(supplyData);
    const demandTotal = demandTotalMethod(demandData);
    const balance = balanceMethod(supplyTotal, demandTotal);
    const workRatio = workRatioTotalMethod(supplyTotal, demandTotal);
    const normalRatio = propsRatioMethod(supplyTotal, demandData['一般'], '一般比率');
    const rdRatio = propsRatioMethod(supplyTotal, demandData['R&D'], 'R&Ⅾ比率');
    const proposalRatio = propsRatioMethod(supplyTotal, (demandData as any).Proposal, 'Proposal比率');
    const jobRatio = propsRatioMethod(supplyTotal, (demandData as any).JOB, 'JOB比率');
    const combineData = [
      ...Object.keys(supplyData)
        .map((k) => supplyData[k].map((s) => ({ ...s, type: typeMap.get(k) })))
        .flat(),
      ...Object.keys(demandData)
        .map((k) => demandData[k].map((d) => ({ ...d, type: typeMap.get(k) })))
        .flat(),
      ...supplyTotal,
      ...demandTotal,
      ...balance,
      ...workRatio,
      ...normalRatio,
      ...rdRatio,
      ...proposalRatio,
      ...jobRatio,
    ];

    const filterData = [
      ['断面 Snapshot Version', 'Snapshot', snapshot],
      ['', 'Snapshot Version', snapshotVersion],
      ['フィルタ Head Filter', 'TGC', tgcs.join(', ')],
      ['', 'Division', divisions.join(', ')],
      ['', 'Discipline', disciplines.join(', ')],
      ['', 'Role', roles.join(', ')],
    ];
    await exportTableFromExcelTemp({
      fileName: `resource_setup_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`,
      templateFilePath: '/assets/resource_setup_export_template.xlsx',
      sheetData: [
        {
          sheet: '断面とフィルタ設定',
          dataType: 'aoa',
          data: filterData,
          options: {
            merge: [
              {
                start: [0, 0],
                end: [1, 0],
              },
              {
                start: [2, 0],
                end: [5, 0],
              },
            ],
          },
        },
        {
          sheet: 'リソースセットアップ',
          dataType: 'json',
          data: combineData,
          options: {
            templateMappingKeyRowIndex: 3,
            insertRowIndex: 3,
          },
        },
      ],
    });
    setExportLoading(false);
  };

  return (
    <div className="matching-result-page">
      <Row justify="space-between" className="operation-container">
        <Col>
          <Space>
            TGC:
            <Select
              allowClear
              showSearch
              mode="multiple"
              // maxTagCount={1}
              maxTagCount="responsive"
              maxTagTextLength={6}
              defaultValue={['Toyo-J', 'Toyo-I']}
              style={{ width: '180px' }}
              onChange={(value: any) => handleTgcChange(value)}
              optionFilterProp="label"
              options={tgcOptions}
            />
            Division:
            <Select
              allowClear
              showSearch
              mode="multiple"
              // maxTagCount={1}
              maxTagCount="responsive"
              maxTagTextLength={6}
              style={{ width: '180px' }}
              onChange={(value: any) => handleDivisionChange(value)}
              optionFilterProp="label"
              options={divisionList}
            />
            Discipline:
            <Select
              showSearch
              allowClear
              mode="multiple"
              // maxTagCount={1}
              maxTagCount="responsive"
              maxTagTextLength={6}
              style={{ width: '180px' }}
              onChange={(value: any) => handleDisciplineChange(value)}
              optionFilterProp="label"
              options={disciplineList}
            />
            Role:
            <Select
              showSearch
              allowClear
              mode="multiple"
              // maxTagCount={1}
              maxTagCount="responsive"
              maxTagTextLength={6}
              style={{ width: '160px' }}
              onChange={(value: any) => handleRoleChange(value)}
              optionFilterProp="label"
              options={roleList}
            />
          </Space>
        </Col>

        <Col>
          <Space>
            {t('aipcmcty.page.orderPlanJob')}
            <Switch onChange={onChange} />
            {t('aipcmcty.page.orderPlanProjectDemandMHTop')}
            <Select
              defaultValue={5}
              disabled={!showProjects}
              style={{ width: 60 }}
              onChange={handleRankChange}
              options={[
                {
                  value: 5,
                  label: 5,
                },
                {
                  value: 10,
                  label: 10,
                },
              ]}
            />
            <Button onClick={handleExportExcel} loading={exportLoading}>
              {t('aipcmcty.page.reportOutput')}
            </Button>
          </Space>
        </Col>
      </Row>
      <ChartTableLayout viewMode={viewMode}>
        <ChartTableLayout.Chart>
          <ChartGroup
            loading={chartGroupLoading}
            syncScroll
            sizeMode="small"
            menuCollapsed={menuCollapsed}
            scrollCount={scrollCount}
            setScrollCount={setScrollCount}
            selectorTop={selectorTop}
            height={342}
          >
            {initialKpiOrder.map((kpi) => (
              <ChartGroup.Item key={`chartTable-${kpi}`}>{kpiMapping[kpi]}</ChartGroup.Item>
            ))}
          </ChartGroup>
        </ChartTableLayout.Chart>
        <ChartTableLayout.Table>
          <Card>
            <div style={{ paddingBottom: 30, marginBottom: 20 }}>
              {/* <div style={{ marginLeft: 20, float: 'left' }}>
                  <Radio.Group defaultValue="Mh" buttonStyle="solid" value="Mh">
                    <Radio.Button value="Mh">MH</Radio.Button>
                    <Radio.Button value="Mp">MP</Radio.Button>
                  </Radio.Group>
                </div> */}
              <div style={{ marginRight: 20, float: 'right' }}>
                <Radio.Group defaultValue="2024" buttonStyle="solid" onChange={handleYearChange} value={year}>
                  <Radio.Button value="2024">2024</Radio.Button>
                  <Radio.Button value="2025">2025</Radio.Button>
                  <Radio.Button value="2026">2026</Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <SupplyDemandMatchingTable
              data={tableData}
              loading={tableLoading}
              year={year}
              redRange={demandMhPercent}
              mhType={mhType}
              tgcs={tgcs}
              filter={filter}
            />
          </Card>
        </ChartTableLayout.Table>
      </ChartTableLayout>
    </div>
  );
};

export default DashboardPage;
