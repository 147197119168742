/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Tag, Tooltip } from 'antd';
import { cloneDeep, groupBy, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import StrategyMatrixChart from '../../components/charts/strategy-matrix.chart';
import OrderAmountChart from '../../components/charts/order-amount.chart';
import TurnoverChart from '../../components/charts/turnover.chart';
import GrossProfitCompositionChart from '../../components/charts/gross-profit-composition.chart';
import GrossProfitMargin from '../../components/charts/gross-profit-margin.chart';
import GrossProfitPerMhChart from '../../components/charts/gross-profit-per-mh.chart';
import TimeValueChart from '../../components/charts/time-value.chart';
import WonPercentChart from '../../components/charts/won-percent.chart';
import CasesPercentageChart from '../../components/charts/cases-percentage.chart';
import HumanResourcesTurnoverChart from '../../components/charts/human-resources-turnover.chart';
import SupplyDemandChart from '../../components/charts/supply-demand.chart';
import APIList from '../../http/ApiList';
import { round } from '../../utils/commonUtil';
import ChartGroup from '@meteor/frontend-core/dist/chart/chart-card/chart-group';
import { TypicalKpiContext } from '../../contexts/typical-kpi.context';
import TypicalKpiHeader from '../../components/typical-kpi-header';
import TypicalKpiTable from '../../components/tables/typical-kpi.table';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ChartTableLayout, ECHART_EVENT } from '@meteor/frontend-core';
import { LAYOUT_VIEW_MODE } from '../../components/common/layout/layout-switcher';

const TypicalKpiPage: React.FC = () => {
  const { t } = useTranslation();
  const { color, fiscalQuarter, periodSwitch, locale, menuCollapsed, scrollCount, setScrollCount, sizeMode } = useContext(AppContext);

  const { kpiInfos, initKpiData, filterKpiData, getKpiData, kpiComputeRule, snapshot, snapshotVersion, orgKpiInfos } =
    useContext(AipcmctyContext);

  /** Headerデータ */
  const [kpiSwitch, setKpiSwitch] = useState(false);
  const [kpiTree, setKpiTree] = useState<string[]>([]);
  const [tgc, setTgc] = useState([]);

  /** Tool リスト */
  const [kpiTreeList, setKpiTreeList] = useState<any[]>([]);
  const [tgcList, setTgcList] = useState<any[]>([]);

  /** Chartデータ */
  // const [riskMapData, setRiskMapData] = useState<any>([]);

  /** Chart Loading */
  // const [riskMapLoading, setRiskMapLoading] = useState<any>(true);

  const [kpiMapping, setKpiMapping] = useState<any>();
  const [kpiOrder, setKpiOrder] = useState([]);
  const [kpiChartOnlyOrder, setKpiChartOnlyOrder] = useState([]);

  /** Tableデータ */
  const [tableResponseData, setTableResponseData] = useState<any>();
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  /** loading */
  const [chartLoading, setChartLoading] = useState(true);

  const [viewMode, setViewMode] = useState('chart-only');

  const { selectorTop } = useWindowSize({
    selector: '.chart-group-container',
    viewMode,
  });

  const [casesPercentageLayout] = useState({
    xLabels: [
      [t('aipcmcty.page.strategic')],
      [t('aipcmcty.page.continuity'), t('aipcmcty.page.futureDevelopment')],
      [
        t('aipcmcty.page.existing'),
        t('aipcmcty.page.existing'),
        t('aipcmcty.page.existing'),
        `${t('aipcmcty.page.new')}/${t('aipcmcty.page.futurePotential')}`,
      ],
    ],
    yLabels: [['顾客関係'], [t('aipcmcty.page.excellent'), t('aipcmcty.page.good'), t('aipcmcty.page.normal'), t('aipcmcty.page.general')]],
  });

  /**
   *   type
   *     未受注: 0
   *     受注済み:1
   */
  useEffect(() => {
    // Chartデータをセット
    getChartData();
    // Tableデータをセット
    // getTableData();

    setKpiOrder([5, 15, 23, 24, 27, 28, 7, 9, 10, 11, 12, 16, 17, 18, 22, 25, 29, 30, 31, 32, 33, 4, 21, 26]);
    setKpiChartOnlyOrder([
      [5, 7, 15, 9, 10, 11, 12, 16, 17, 18],
      [22, 23, 24, 25],
      [27, 28, 29, 30],
    ]);
    setTimeout(() => {
      setChartLoading(false);
    }, 1000);

    setViewMode('chart-only');
  }, [snapshot, snapshotVersion]);

  const [japanInvolved, setJapanInvolved] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // Chartデータをセット
      filterKpiData({ tgc, japanInvolved });
      // // Tableデータをセット
      // getTableData();
    }, 0);
  }, [tgc, snapshot, snapshotVersion, japanInvolved, orgKpiInfos]);

  useEffect(() => {
    getTableData();
  }, [tgc, snapshot, snapshotVersion]);

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_ITEM_SELECTED
      && e.chartTitle.value.key !== t('aipcmcty.page.orderAmount')
    ) {
      setViewMode(LAYOUT_VIEW_MODE.CHART_TABLE);
    }
  };

  useEffect(() => {
    setKpiMapping({
      4: (
        <StrategyMatrixChart
          kpiCode="KPJ001"
          data={getKpiData('KPJ001').data}
          loading={getKpiData('KPJ001').loading}
          layoutOptions={{
            xLabels: [
              [t('aipcmcty.page.grossProfitMargin')],
              [t('aipcmcty.page.lessThan8Percent'), '8%〜12%', t('aipcmcty.page.moreThan12Percent')],
            ],
            yLabels: [
              [{ label: t('aipcmcty.page.growth'), vertical: true, symmetry: locale.locale !== 'ja', initial: true }],
              [
                {
                  label: t('aipcmcty.page.new'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: t('aipcmcty.page.new'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: '',
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
              [
                {
                  label: `（${t('aipcmcty.page.innovation')}）`,
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: `（${t('aipcmcty.page.adjacentArea')}）`,
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: t('aipcmcty.page.existing'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
            ],
            gridTop: 35,
            xHeight: 65,
            chartTop: 25,
          }}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.valueCreation'), color.successColor)}
          ribbonText={t('aipcmcty.page.overall')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      5: (
        <OrderAmountChart
          kpiCode="KPJ002"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ002').data}
          loading={getKpiData('KPJ002').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.orderAmount'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
        />
      ),
      7: (
        <TurnoverChart
          kpiCode="KPJ003"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ003').data}
          loading={getKpiData('KPJ003').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.sales'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      8: (
        <TurnoverChart
          kpiCode="KPJ004"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ004').data}
          loading={getKpiData('KPJ004').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.expectedGrossProfitAmt'), color.successColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      9: (
        <GrossProfitCompositionChart
          kpiCode="KPJ005"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ005').data}
          loading={getKpiData('KPJ005').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.nonEPC')}/EPC)`
              : `${t('aipcmcty.page.nonEPC')}/EPC - ${t('aipcmcty.page.grossProfitComposition')}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      10: (
        <GrossProfitCompositionChart
          kpiCode="KPJ006"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ006').data}
          loading={getKpiData('KPJ006').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')})`
              : `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existingBusiness')} - ${t('aipcmcty.page.grossProfitComposition')}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      11: (
        <GrossProfitCompositionChart
          kpiCode="KPJ007"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ007').data}
          loading={getKpiData('KPJ007').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.grossProfitComposition')}(Japan/${t('aipcmcty.page.others')})`
              : `Japan/${t('aipcmcty.page.others')} - ${t('aipcmcty.page.grossProfitComposition')}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      12: (
        <GrossProfitCompositionChart
          kpiCode="KPJ008"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ008').data}
          loading={getKpiData('KPJ008').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.project'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.collaboration')}/${t('aipcmcty.page.own')}/${t(
                  'aipcmcty.page.JV'
                )})`
              : `${t('aipcmcty.page.collaboration')}/${t('aipcmcty.page.own')}/${t('aipcmcty.page.JV')} - ${t(
                  'aipcmcty.page.grossProfitComposition'
                )}`,
            color.successColor
          )}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      15: (
        <GrossProfitMargin
          kpiCode="KPJ011"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ011').data}
          loading={getKpiData('KPJ011').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.grossProfitAndRate'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      16: (
        <GrossProfitPerMhChart
          kpiCode="KPJ012"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ012').data}
          loading={getKpiData('KPJ012').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.grossProfitPerMH'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      17: (
        <TimeValueChart
          kpiCode="KPJ013"
          data={getKpiData('KPJ013').data}
          loading={getKpiData('KPJ013').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.NPVgrossProfitRate'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      18: (
        <WonPercentChart
          kpiCode="KPJ014"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KPJ014').data}
          loading={getKpiData('KPJ014').loading}
          title={customChartGroupTitle(t('aipcmcty.page.project'), 'Won％', color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      19: (
        <CasesPercentageChart
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          layoutOptions={casesPercentageLayout}
          title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.strategicProjectRatio'), color.successColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      21: (
        <StrategyMatrixChart
          kpiCode="KRS001"
          data={getKpiData('KRS001').data}
          loading={getKpiData('KRS001').loading}
          layoutOptions={{
            xLabels: [
              [t('aipcmcty.page.profitPerPersonAmount')],
              [t('aipcmcty.page.lessThan0Yen'), t('aipcmcty.page.0To150MillionYen'), t('aipcmcty.page.over150MillionYen')],
            ],
            yLabels: [
              [
                {
                  label: t('aipcmcty.page.growth'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
              [
                {
                  label: t('aipcmcty.page.new'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: t('aipcmcty.page.new'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: '',
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
              [
                {
                  label: `（${t('aipcmcty.page.innovation')}）`,
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: `（${t('aipcmcty.page.adjacentArea')}）`,
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
                {
                  label: t('aipcmcty.page.existing'),
                  vertical: true,
                  symmetry: locale.locale !== 'ja',
                  initial: true,
                },
              ],
            ],
            gridTop: 35,
            xHeight: 65,
            chartTop: 25,
          }}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.resourceCapability'), color.warningColor, {
            fontSize: 14,
          })}
          ribbonText={t('aipcmcty.page.overall')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      22: (
        <HumanResourcesTurnoverChart
          kpiCode="KRS002"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KRS002').data}
          loading={getKpiData('KRS002').loading}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.ESMHutilizationRate'), color.warningColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      23: (
        <HumanResourcesTurnoverChart
          kpiCode="KRS003"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KRS003').data}
          loading={getKpiData('KRS003').loading}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.demandSupplyGapMH'), color.warningColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      24: (
        <HumanResourcesTurnoverChart
          kpiCode="KRS004"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KRS004').data}
          loading={getKpiData('KRS004').loading}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.jobRate'), color.warningColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      25: (
        <HumanResourcesTurnoverChart
          kpiCode="KRS005"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KRS005').data}
          loading={getKpiData('KRS005').loading}
          title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.talentTurnoverRate'), color.warningColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      26: (
        <StrategyMatrixChart
          kpiCode="ValueProposition"
          data={getKpiData('ValueProposition').data}
          loading={getKpiData('ValueProposition').loading}
          title={
            <span
              key={t('aipcmcty.page.valueDelivery')}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 3,
              }}
            >
              <Tag color={color.successColor} style={{ margin: 0 }}>
                {t('aipcmcty.page.project')}
              </Tag>
              <Tag color={color.warningColor} style={{ margin: 0 }}>
                {t('aipcmcty.page.resource')}
              </Tag>
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 12,
                  marginLeft: 5,
                }}
              >
                {t('aipcmcty.page.valueDelivery')}
              </div>
            </span>
          }
          ribbonText={t('aipcmcty.page.overall')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      27: (
        <HumanResourcesTurnoverChart
          kpiCode="KDX002"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KDX002').data}
          loading={getKpiData('KDX002').loading}
          title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.DXapplicationRatio'), color.errorColor)}
          ribbonText={t('aipcmcty.page.amount')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      28: (
        <TurnoverChart
          kpiCode="KDX003"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KDX003').data}
          loading={getKpiData('KDX003').loading}
          title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.dxAllowanceFullPot'), color.errorColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      29: (
        <HumanResourcesTurnoverChart
          kpiCode="KDX004"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KDX004').data}
          loading={getKpiData('KDX004').loading}
          title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.DXallowanceDiscountRate'), color.errorColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      30: (
        <TurnoverChart
          kpiCode="KDX005"
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          data={getKpiData('KDX005').data}
          loading={getKpiData('KDX005').loading}
          title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.dxAllowanceAmount'), color.errorColor)}
          ribbonText={t('aipcmcty.page.quality')}
          ribbonColor={color.primaryColor}
          chartHandler={handleChartEvent}
        />
      ),
      31: (
        <SupplyDemandChart
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          kpiCode="KOT001"
          data={getKpiData('KOT001').data}
          loading={getKpiData('KOT001').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.others'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.demandSupplyMatching')}(Japan)`
              : `Japan - ${t('aipcmcty.page.demandSupplyMatching')}`,
            '#808080'
          )}
          chartHandler={handleChartEvent}
        />
      ),
      32: (
        <SupplyDemandChart
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          kpiCode="KOT002"
          data={getKpiData('KOT002').data}
          loading={getKpiData('KOT002').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.others'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.demandSupplyMatching')}(India)`
              : `India - ${t('aipcmcty.page.demandSupplyMatching')}`,
            '#808080'
          )}
          chartHandler={handleChartEvent}
        />
      ),
      33: (
        <SupplyDemandChart
          periodSwitch={periodSwitch}
          fiscalQuarter={fiscalQuarter}
          kpiCode="KOT003"
          data={getKpiData('KOT003').data}
          loading={getKpiData('KOT003').loading}
          title={customChartGroupTitle(
            t('aipcmcty.page.others'),
            locale.locale === 'ja'
              ? `${t('aipcmcty.page.demandSupplyMatching')}(Japan + India)`
              : `Japan + India - ${t('aipcmcty.page.demandSupplyMatching')}`,
            '#808080'
          )}
          chartHandler={handleChartEvent}
        />
      ),
    });
  }, [kpiInfos, fiscalQuarter, periodSwitch]);

  useEffect(() => {
    if (tableResponseData) {
      setTableLoading(true);
      setTimeout(() => {
        setTableLoading(false);
      }, 2000);
    }
  }, [periodSwitch]);

  useEffect(() => {
    if (tableResponseData) {
      const data = arrayToTree(processResponse(tableResponseData));
      setTableData(data);
    }
  }, [tableResponseData, kpiComputeRule]);

  const getChartData = () => {
    // setRiskMapLoading(true);

    initKpiData([
      [
        'KPJ002',
        'KPJ011',
        'KPJ003',
        'KPJ004',
        'KPJ005',
        'KPJ006',
        'KPJ007',
        'KPJ008',
        'KPJ012',
        'KPJ013',
        'KPJ014',
        'KPJ001',
        'KRS001',
        'ValueProposition',
      ],
      ['KRS002', 'KRS003', 'KRS004', 'KRS005'],
      ['KDX002'],
      ['KDX003', 'KDX004', 'KDX005'],
      ['KOT001'],
      ['KOT002'],
      ['KOT003'],
    ]);

    // APIList.getRiskMap()
    //   .get()
    //   .then((data: any[]) => {
    //     setRiskMapData(data);
    //     setRiskMapLoading(false);
    //   });
  };

  const getTableData = () => {
    setTableLoading(true);
    Promise.all(tgc.map((tgcValue) => APIList.getTypicalKpi().post({ snapshot, snapshotVersion, tgc: tgcValue }))).then((response) => {
      const data = {
        kpiResponse: [],
        goalResponse: [],
      };
      response.forEach((arr: any) => {
        data.kpiResponse = [...data.kpiResponse, ...arr.kpiResponse];
        data.goalResponse = [...data.goalResponse, ...arr.goalResponse];
      });
      setTableResponseData(data);
      setTableLoading(false);
    });
    // APIList.getTypicalKpi()
    //   .post({ snapshot: '2024-01-17', snapshotVersion: 'default' })
    //   .then((response: any) => {
    //     setTableResponseData(response);
    //     setTableLoading(false);
    //   });
  };

  const arrayToTree = (list) => {
    const cloneData = cloneDeep(list); // 深拷贝避免影响源数据
    return cloneData.filter((father) => {
      // 循环所有项，并添加children属性
      const branchArr = cloneData.filter((child) => father.tgcId === child.parentTgcId); // 筛选出此项的所有子元素
      branchArr.length > 0 ? (father.children = branchArr) : false; // 给父级添加一个children属性，并赋值
      // father.children = branchArr // 如果希望每个元素无论有没有children都添加children属性  可以直接赋值
      return !father.parentTgcId; // 返回pid为''的顶级节点
    });
  };

  const processResponse = ({ kpiResponse, goalResponse }) => {
    if (!kpiComputeRule) {
      return [];
    }
    const kpis = [
      'KPJ004',
      'KPJ002',
      'KPJ003',
      'KPJ006',
      'KPJ005',
      'KPJ011',
      'KPJ012',
      'TotalMH',
      'KRS002',
      'KPJ013',
      'KPJ014',
      'KRS002',
      'ResourceDemandMh',
      'ResourceSupplyMh',
      'KRS003',
      'KRS004',
      'KRS005',
      'KDX002',
      'KDX003',
      'KDX004',
      'KDX005',
      'GrossProfitAvg',
    ];
    const goals = ['KPJ002', 'KPJ003', 'KPJ004', 'KPJ012'];
    // KPI 計算
    const computeKpi = (kpiCode, resData, gbDate) => {
      Object.keys(gbDate).forEach((date) => {
        switch (kpiCode) {
          // 粗利額（案件の量）
          case 'KPJ004':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date]) / 100000000)}`;
            break;
          // 粗利額（案件の質）
          case 'GrossProfitAvg':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date]))}`;
            break;
          // 受注金額
          case 'KPJ002':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date]) / 100000000)}`;
            break;
          // 売上
          case 'KPJ003':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date]) / 100000000)}`;
            break;
          // 粗利構成比(新規事業/既存事業)
          case 'KPJ006':
            resData[`${kpiCode}${date}`] = (() => {
              const existedData = kpiComputeRule[kpiCode](
                gbDate[date].filter((element: any) => element.isExisted === t('aipcmcty.page.existing')),
                gbDate[date]
              );
              const notExistData = kpiComputeRule[kpiCode](
                gbDate[date].filter((element: any) => element.isExisted === t('aipcmcty.page.new')),
                gbDate[date]
              );
              if (existedData || notExistData) {
                return `${round(notExistData * 100)} | ${round(existedData * 100)}`;
              }
              return '-';
            })();
            break;
          // 粗利構成比(非EPC/EPC)
          case 'KPJ005':
            resData[`${kpiCode}${date}`] = (() => {
              const epcData = kpiComputeRule[kpiCode](
                gbDate[date].filter((element) => element.isEpc === 'EPC'),
                gbDate[date]
              );
              const notEpcData = kpiComputeRule[kpiCode](
                gbDate[date].filter((element) => element.isEpc === t('aipcmcty.page.nonEPC')),
                gbDate[date]
              );
              if (epcData || notEpcData) {
                return `${round(notEpcData * 100)} | ${round(epcData * 100)}`;
              }
              return '-';
            })();
            break;
          // NPV粗利率
          case 'KPJ013':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode].npvRate(gbDate[date]) * 100)}`;
            break;
          // 粗利率
          case 'KPJ011':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode].grossProfitRate(gbDate[date]) * 100)}`;
            break;
          // MHあたり粗利
          case 'KPJ012':
            resData[`${kpiCode}${date}`] = `${kpiComputeRule[kpiCode](gbDate[date])}`;
            break;
          // MHあたり粗利
          case 'TotalMH':
            resData[`${kpiCode}${date}`] = `${kpiComputeRule[kpiCode](gbDate[date])}`;
            break;
          // Won%
          case 'KPJ014':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode].wonRate(gbDate[date]) * 100)}`;
            break;
          // ESMH稼働率
          case 'KRS002':
            if (resData.parentTgcId) {
              resData[`${kpiCode}${date}`] = '-';
            } else {
              resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date]) * 100)}`;
            }
            break;
          // リソース需要MH
          case 'ResourceDemandMh':
            resData[`${kpiCode}${date}`] = `${kpiComputeRule[kpiCode](gbDate[date])}`;
            break;
          // リソース供給MH
          case 'ResourceSupplyMh':
            resData[`${kpiCode}${date}`] = `${kpiComputeRule[kpiCode](gbDate[date])}`;
            break;
          // 需給過不足MH
          case 'KRS003':
            if (resData.parentTgcId) {
              resData[`${kpiCode}${date}`] = '-';
            } else {
              resData[`${kpiCode}${date}`] = `${kpiComputeRule[kpiCode](gbDate[date])}`;
            }
            break;
          // Job率
          case 'KRS004':
            if (resData.parentTgcId) {
              resData[`${kpiCode}${date}`] = '-';
            } else {
              resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date]) * 100)}`;
            }
            break;
          // 人財回転率
          case 'KRS005':
            if (resData.parentTgcId) {
              resData[`${kpiCode}${date}`] = '-';
            } else {
              resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date], gbDate[date]) * 100)}`;
            }
            break;
          // DX適用比率
          case 'KDX002':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date].filter((item) => item.dxProjectFlag)) * 100)}`;
            break;
          // DX Allowanceフルポテ額
          case 'KDX003':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date], gbDate[date]) / 100000000)}`;
            break;
          // DX Allowance割引率
          case 'KDX004':
            resData[`${kpiCode}${date}`] = `${(() => {
              const data = kpiComputeRule[kpiCode](gbDate[date], gbDate[date]);
              if (data === null) {
                return '-';
              }
              return round(data * 100);
            })()}`;
            break;
          // DX Allowance積上げ額
          case 'KDX005':
            resData[`${kpiCode}${date}`] = `${round(kpiComputeRule[kpiCode](gbDate[date], gbDate[date]) / 100000000)}`;
            break;
          default:
        }
      });
    };
    const computeGoal = (kpiCode, resData, gbDate) => {
      Object.keys(gbDate).forEach((date) => {
        switch (kpiCode) {
          // 受注金額
          case 'KPJ002':
            resData[`${kpiCode}${date}-goal`] = `${round(kpiComputeRule[kpiCode](gbDate[date]) / 100000000)}`;
            break;
          default:
        }
      });
    };

    // KPIからのデータルール
    const kpiRules = kpis.map((kpi) => (resData) => {
      const { kpiData } = resData;
      // 四半期
      const gbYearQuarter = groupBy(kpiData, (data) => {
        if (data.fiscalQuarter) {
          return `${data.fiscalYear}-${data.fiscalQuarter}`;
        }
        return data.fiscalYear;
      });
      computeKpi(kpi, resData, gbYearQuarter);
      // 年
      const gbYear = groupBy(kpiData, 'fiscalYear');
      computeKpi(kpi, resData, gbYear);
    });

    const goalRules = goals.map((goal) => (resData) => {
      const { kpiData } = resData;
      // 年
      const gbYear = groupBy(kpiData, 'fiscalYear');
      computeGoal(goal, resData, gbYear);
    });

    // KPIからのデータ
    const kpiResData = [];
    kpiResponse.forEach((item) => {
      const kpiData = { ...item };
      kpiRules.forEach((rule) => {
        rule(kpiData);
      });
      delete kpiData.kpiData;
      const goalData = goalResponse.find((element) => element.key === item.key);
      if (goalData) {
        goalRules.forEach((rule) => {
          rule(goalData);
        });
        delete goalData.kpiData;
        kpiResData.push({ ...kpiData, ...goalData });
      } else {
        kpiResData.push({ ...kpiData });
      }
    });
    return kpiResData;
  };

  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );

  const chartContent = () => {
    if (viewMode === 'chart-only') {
      return (
        <>
          {kpiChartOnlyOrder.map((kpiArr, index) => (
            <ChartGroup
              key={kpiArr}
              viewMode={viewMode}
              loading={chartLoading}
              isScrollChart
              menuCollapsed={menuCollapsed}
              scrollCount={scrollCount}
              setScrollCount={setScrollCount}
              selectorTop={selectorTop}
              sizeMode={sizeMode}
            >
              {kpiArr.map((kpi) => (
                <ChartGroup.Item key={`cartOnly-${kpi}`}>{kpiMapping[kpi]}</ChartGroup.Item>
              ))}
            </ChartGroup>
          ))}
        </>
      );
    } else {
      return (
        <ChartGroup
          loading={false}
          menuCollapsed={menuCollapsed}
          scrollCount={scrollCount}
          setScrollCount={setScrollCount}
          selectorTop={selectorTop}
          sizeMode={sizeMode}
        >
          {kpiOrder.map((kpi) => (
            <ChartGroup.Item key={`chartTable-${kpi}`}>{kpiMapping[kpi]}</ChartGroup.Item>
          ))}
        </ChartGroup>
      );
    }
  };

  return (
    <>
      <div className="dashboard">
        <TypicalKpiContext.Provider
          value={{
            kpiSwitch,
            kpiTree,
            setKpiTree,
            setKpiSwitch,
            tgc,
            setTgc,
            setKpiTreeList,
            setTgcList,
            kpiTreeList,
            tgcList,
            viewMode,
            setViewMode,
            setJapanInvolved,
          }}
        >
          <TypicalKpiHeader snapshot={snapshot} snapshotVersion={snapshotVersion}>
            <ChartTableLayout viewMode={viewMode}>
              {kpiMapping ? (
                <ChartTableLayout.Chart>
                  {chartContent()}
                </ChartTableLayout.Chart>
              ) : (
                <></>
              )}
              <ChartTableLayout.Table>
                <TypicalKpiTable data={tableData} loading={tableLoading} />
              </ChartTableLayout.Table>
            </ChartTableLayout>
          </TypicalKpiHeader>
        </TypicalKpiContext.Provider>
      </div>
    </>
  );
};

export default TypicalKpiPage;
