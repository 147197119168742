import {
  useContext, useEffect, useRef, useState
} from 'react';
import Chat, { Bubble, useMessages } from '@chatui/core';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Divider,
  Input,
  Popconfirm,
  Spin, Tag, Tooltip, message
} from 'antd';
import { AppContext } from '../../contexts/AppContext';
import APIList from '../../http/ApiList';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { remove } from 'lodash';
import Highlighter from 'react-highlight-words';

type MemoProperties = {
  snapshot: string;
  snapshotVersion: string;
  projectId: string;
  projectName: string;
  collapsed: boolean;
  searchData: {
    memoKeyWord: string;
    creator: string[];
    createdAtStart: string;
    createdAtEnd: string;
    memoTag: string[];
  };
};

const MemoContainer: React.FC<MemoProperties> = (props) => {
  const { t } = useTranslation();
  const [memoLoading, setMemoLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const {
    snapshot,
    snapshotVersion,
    projectId,
    projectName,
    searchData,
    collapsed,
  } = props;
  const { authInfo, color } = useContext(AppContext);
  const [memoEditMsg, setMemoEditMsg] = useState<{
    id: string;
    text: string;
    memoTag: string;
    visible: boolean;
  }>();

  const highlight = {
    text: '#ffc069',
    background: '#ffc069',
  };

  const tagMap = {
    ISSUE: {
      color: 'green',
      highlight: highlight.background,
    },
    TODO: {
      color: 'red',
      highlight: highlight.background,
    },
    FYI: {
      color: 'blue',
      highlight: highlight.background,
    },
  };

  const getTag = (name) => {
    const defaultTag = {
      color: color.primaryColor,
    };
    const tag = tagMap[name];
    return tag ? tag : defaultTag;
  };

  const bubbleStyle = {
    padding: 6,
    marginLeft: 10,
    // fontSize: 'small',
    borderRadius: 6,
  };

  const iconStyle = {
    padding: 3,
    fontSize: 'small',
    color: '#555',
  };

  const composerRef = useRef(null);

  const { messages, prependMsgs, appendMsg, updateMsg, deleteMsg, resetList } =
    useMessages([]);

  const getProjectMemo = () => {
    setMemoLoading(true);
    APIList.getProjectMemo()
      .get({ projectId: projectId })
      .then((data: any) => {
        if (data) {
          const memoData = data.map((item) => ({
            _id: item.id,
            type: 'text',
            content: {
              text: item.memo,
            },
            memoTag: item.memoTag,
            user: {
              role:
                authInfo.user.attributes.sub === item.userId
                  ? 'User'
                  : 'Others',
              name:
                authInfo.user.attributes.sub === item.userId ? (
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: highlight.text,
                      padding: 0,
                    }}
                    searchWords={searchData.creator.map((userId) => {
                      if (userId === item.userId) {
                        return 'You';
                      }
                      return '';
                    })}
                    autoEscape
                    textToHighlight="You"
                  />
                ) : (
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: highlight.text,
                      padding: 0,
                    }}
                    searchWords={searchData.creator.map((userId) => {
                      if (userId === item.userId) {
                        return item.userName;
                      }
                      return '';
                    })}
                    autoEscape
                    textToHighlight={item.userName}
                  />
                ),
              snapshot: item.snapshot,
              snapshotVersion: item.snapshotVersion,
              updatedAt: new Date(item.updatedAt).toLocaleString('ja-JP', {
                hour12: false,
              }),
            },
            position:
              authInfo.user.attributes.sub === item.userId ? 'right' : 'left',
          }));
          prependMsgs(memoData);
        }
        setMemoLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setMemoLoading(false);
      });
  };

  useEffect(() => {
    composerRef.current.setText('');
    resetList([]);
    clearQuickReplies();
    if (projectId && !collapsed) getProjectMemo();
  }, [collapsed, projectId]);

  const handleSend = (type: string, val: string) => {
    setMemoLoading(true);
    const memoTags = quickReplies
      .filter((item) => item.isHighlight)
      .map((item) => item.name)
      .join(',');
    if (type === 'text' && val.trim()) {
      APIList.createProjectMemo()
        .post({
          snapshot: snapshot,
          snapshotVersion: snapshotVersion,
          projectId: projectId,
          memo: val,
          memoTag: memoTags,
        })
        .then((data: any) => {
          appendMsg({
            _id: data.id,
            type: 'text',
            content: {
              text: val,
            },
            memoTag: memoTags,
            user: {
              role: 'User',
              name: (
                <Highlighter
                  highlightStyle={{
                    backgroundColor: highlight.text,
                    padding: 0,
                  }}
                  searchWords={searchData.creator.map((userId) => {
                    if (userId === data.userId) {
                      return 'You';
                    }
                    return '';
                  })}
                  autoEscape
                  textToHighlight="You"
                />
              ),
              snapshot: data.snapshot,
              snapshotVersion: data.snapshotVersion,
              updatedAt: new Date(data.updatedAt).toLocaleString('ja-JP', {
                hour12: false,
              }),
            },
            position: 'right',
          } as any);
          message.success('Successfully created the memo');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setMemoLoading(false);
          clearQuickReplies();
        });
    }
  };

  const handleUpdateMsg = () => {
    const { id, text, memoTag } = memoEditMsg;
    APIList.updateProjectMemo()
      .put({
        id,
        snapshot: snapshot,
        snapshotVersion: snapshotVersion,
        projectId: projectId,
        memo: text,
        memoTag,
      })
      .then((data: any) => {
        updateMsg(id, {
          type: 'text',
          content: {
            text: text,
          },
          memoTag,
          user: {
            role: 'User',
            name: (
              <Highlighter
                highlightStyle={{
                  backgroundColor: highlight.text,
                  padding: 0,
                }}
                searchWords={searchData.creator.map((userId) => {
                  if (userId === data.userId) {
                    return 'You';
                  }
                  return '';
                })}
                autoEscape
                textToHighlight="You -- edited"
              />
            ),
            snapshot: data.snapshot,
            snapshotVersion: data.snapshotVersion,
            updatedAt: new Date(data.updatedAt).toLocaleString('ja-JP', {
              hour12: false,
            }),
          },
          position: 'right',
        } as any);
        message.success('Successfully updated the memo');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };

  const handleDeleteMsg = (msg) => {
    setMemoLoading(true);
    APIList.deleteProjectMemo()
      .del({ id: msg._id })
      .then(() => {
        deleteMsg(msg._id);
        message.success('Successfully deleted the memo');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setMemoLoading(false);
      });
  };

  const clearQuickReplies = () => {
    setQuickReplies(
      quickReplies.map((replied) => {
        return {
          name: replied.name,
          isHighlight: false,
        };
      })
    );
  };

  const renderMessageContent = (msg) => {
    const { _id, content, user, memoTag } = msg;

    const updatedAtKeyword = () => {
      if (searchData.createdAtStart && searchData.createdAtEnd) {
        if (
          dayjs(searchData.createdAtStart).isBefore(dayjs(user.updatedAt)) &&
          dayjs(searchData.createdAtEnd)
            .add(1, 'day')
            .isAfter(dayjs(user.updatedAt))
        ) {
          return user.updatedAt;
        }
      }
      if (searchData.createdAtStart) {
        if (dayjs(searchData.createdAtStart).isBefore(dayjs(user.updatedAt))) {
          return user.updatedAt;
        }
      }
      if (searchData.createdAtEnd) {
        if (
          dayjs(searchData.createdAtEnd)
            .add(1, 'day')
            .isAfter(dayjs(user.updatedAt))
        ) {
          return user.updatedAt;
        }
      }
    };

    const isUser = msg.user.role === 'User';

    const renderMessage = () => (
      <>
        <div style={isUser ? { display: 'flex', alignItems: 'center' } : {}}>
          {isUser && !(memoEditMsg?.visible && _id === memoEditMsg?.id) ? (
            <>
              <Popconfirm
                title={t('aipcmcty.page.memo.delConfirm')}
                onConfirm={() => handleDeleteMsg(msg)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined style={iconStyle} />
              </Popconfirm>

              <EditOutlined
                style={iconStyle}
                onClick={() => {
                  setMemoEditMsg({
                    id: _id,
                    text: content.text,
                    memoTag: msg.memoTag,
                    visible: !memoEditMsg?.visible,
                  });
                }}
              />
            </>
          ) : (
            <></>
          )}
          <Bubble
            style={bubbleStyle}
            content={
              <>
                {updateLoading && _id === memoEditMsg?.id ? (
                  <Spin />
                ) : (
                  <>
                    {memoEditMsg?.visible && _id === memoEditMsg?.id ? (
                      <>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>
                                {Object.keys(tagMap).map((tagName) => (
                                  <Tag
                                    color={
                                      memoEditMsg.memoTag
                                        .split(',')
                                        .includes(tagName)
                                        ? getTag(tagName).color
                                        : 'default'
                                    }
                                    style={{
                                      height: 18,
                                      lineHeight: '16px',
                                      borderRadius: 6,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      let memoTags = memoEditMsg.memoTag
                                        ? memoEditMsg.memoTag.split(',')
                                        : [];
                                      const isInclude =
                                        memoTags.includes(tagName);
                                      if (isInclude) {
                                        remove(
                                          memoTags,
                                          (tag) => tag === tagName
                                        );
                                      } else {
                                        memoTags.push(tagName);
                                      }
                                      memoEditMsg.memoTag;
                                      setMemoEditMsg({
                                        ...memoEditMsg,
                                        memoTag: memoTags.join(','),
                                      });
                                    }}
                                  >
                                    {tagName}
                                  </Tag>
                                ))}
                              </div>
                              <div>
                                <Tooltip title={t('aipcmcty.page.memo.cancel')}>
                                  <CloseOutlined
                                    style={{ ...iconStyle, marginLeft: 5 }}
                                    onClick={() => {
                                      setMemoEditMsg({
                                        ...memoEditMsg,
                                        visible: false,
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title={t('aipcmcty.page.memo.ok')}>
                                  <CheckOutlined
                                    style={{ ...iconStyle, marginLeft: 8 }}
                                    onClick={() => {
                                      setMemoEditMsg({
                                        ...memoEditMsg,
                                        visible: false,
                                      });
                                      setUpdateLoading(true);
                                      handleUpdateMsg();
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                            <Divider style={{ margin: '6px 0' }} />
                            <Input.TextArea
                              style={{ width: 250 }}
                              size="large"
                              value={
                                memoEditMsg?.id === _id ? memoEditMsg?.text : ''
                              }
                              onChange={(e) => {
                                setMemoEditMsg({
                                  ...memoEditMsg,
                                  text: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {memoTag && (
                          <>
                            {memoTag.split(',').map((tagName) => (
                              <Tag
                                color={
                                  searchData.memoTag.includes(tagName)
                                    ? getTag(tagName).highlight
                                    : getTag(tagName).color
                                }
                                style={{
                                  height: 18,
                                  lineHeight: '16px',
                                  borderRadius: 6,
                                }}
                              >
                                {tagName}
                              </Tag>
                            ))}
                            <Divider style={{ margin: '6px 0' }} />
                          </>
                        )}
                        <span>
                          <Highlighter
                            highlightStyle={{
                              backgroundColor: highlight.text,
                              padding: 0,
                            }}
                            searchWords={[searchData.memoKeyWord]}
                            autoEscape
                            textToHighlight={content.text}
                          />
                        </span>
                      </>
                    )}
                  </>
                )}
                <br />
                <p className="extra">
                  Snapshot: {user.snapshot}
                  <br />
                  Version: {user.snapshotVersion}
                  <br />
                  At:{' '}
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: highlight.text,
                      padding: 0,
                    }}
                    searchWords={[updatedAtKeyword()]}
                    autoEscape
                    textToHighlight={user.updatedAt}
                  />
                </p>
              </>
            }
          />
        </div>
      </>
    );

    return renderMessage();
  };

  const CustomNavbar = ({ title }) => (
    <div className="Navbar-main-cus" style={{ height: 54 }}>
      <Tooltip placement="bottom" title={title}>
        <h2 className="Navbar-title">{title}</h2>
      </Tooltip>
    </div>
  );

  const [quickReplies, setQuickReplies] = useState(
    Object.keys(tagMap).map((tag) => ({
      name: tag,
      isHighlight: false,
    }))
  );

  const handleQuickReplyClick = (item) => {
    setQuickReplies(
      quickReplies.map((replied) => {
        if (replied.name === item.name) {
          return {
            name: replied.name,
            isHighlight: !replied.isHighlight,
          };
        }
        return replied;
      })
    );
  };

  return (
    <Spin spinning={memoLoading}>
      <Chat
        locale="en-US"
        renderNavbar={() => (
          <CustomNavbar title={`${projectId}: ${projectName}`} {...props} />
        )}
        placeholder={t('aipcmcty.page.enterMemo')}
        messages={messages}
        renderMessageContent={renderMessageContent}
        onSend={handleSend}
        composerRef={composerRef}
        quickReplies={quickReplies}
        onQuickReplyClick={handleQuickReplyClick}
        renderQuickReplies={() => (
          <div style={{ margin: '10px 12px 0' }}>
            {quickReplies.map(({ name, isHighlight }) => (
              <Tag
                key={name}
                color={isHighlight ? getTag(name).color : 'default'}
                style={{
                  height: 18,
                  lineHeight: '16px',
                  borderRadius: 6,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleQuickReplyClick({ name, isHighlight });
                }}
              >
                {name}
              </Tag>
            ))}
          </div>
        )}
      />
    </Spin>
  );
};

export default MemoContainer;