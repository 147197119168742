import {
  Col,
  message,
  Popconfirm, Row, Select, Space, Table
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  useContext, useEffect, useMemo, useState
} from 'react';
import { RollbackOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { tableChangeMap } from '../simulation/project-setup';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import APIList from '../../http/ApiList';
import { RecordItem } from '../../contexts/provider/aipcmcty.provider.context';

const timeout = 360000;

const Record = () => {
  const { t } = useTranslation();

  const fieldMap = {
    priorityMatching: t('aipcmcty.page.priority'),
    budgetCategoryCustom: t('aipcmcty.page.budgetCategory'),
    orderAmount: t('aipcmcty.page.orderAmount'),
    grossMargin: t('aipcmcty.page.grossProfitRate'),
    projectStartDate: t('aipcmcty.page.startDate'),
    duration: t('aipcmcty.page.duration'),
    phase: t('aipcmcty.page.phase'),
    won: 'Won%',
    dxApplicable: t('aipcmcty.page.dxApplicable'),
    priority: t('aipcmcty.page.priorityLevel'),
    projectEndDate: t('aipcmcty.page.endDate'),
    grossProfit: t('aipcmcty.page.grossProfitAmt'),
    dx: t('aipcmcty.page.historyRecord.dx'),
    dxNot: t('aipcmcty.page.historyRecord.dxNot'),
    formJvFeedE: 'Form. JV Feed E',
    formJvFeedP: 'Form. JV Feed P',
    formJvFeedC: 'Form. JV Feed C',
    formJvFeedPjt: 'Form. JV Feed PJT',
    formJvEpcE: 'Form. JV EPC E',
    formJvEpcP: 'Form. JV EPC P',
    formJvEpcC: 'Form. JV EPC C',
    formJvEpcPjt: 'Form. JV EPC PJT',
    formTgcFeedE: 'Form. TGC Feed E',
    formTgcFeedP: 'Form. TGC Feed P',
    formTgcFeedC: 'Form. TGC Feed C',
    formTgcFeedPjt: 'Form. TGC Feed PJT',
    formTgcEpcE: 'Form. TGC Epc E',
    formTgcEpcP: 'Form. TGC Epc P',
    formTgcEpcC: 'Form. TGC Epc C',
    formTgcEpcPjt: 'Form. TGC Epc PJT',
    RESET: t('aipcmcty.page.historyRecord.reset'),
    FORMATION: t('aipcmcty.page.historyRecord.formation')
  };

  const { versionList } = useContext(AipcmctyContext);
  const { sOptions: snapshotOpts, vOptions: versionOpts } = useMemo(
    () => {
      const temp = groupBy(versionList, 'snapshot');
      const sOptions = Object.keys(temp).map(s => ({ label: s, value: s })).sort((a, b) => (a.value < b.value ? 1 : -1));
      const vOptions = Object.keys(temp).reduce((acc, curK) => {
        acc[curK] = temp[curK].map(
          v => ({ label: v.snapshotVersionName, value: v.snapshotVersion })
        );
        return acc;
      }, {});
      return { sOptions, vOptions };
    },
    [versionList]
  );

  const { selectorTop } = useWindowSize({
    selector: '.record-table',
  });

  const [recordList, setRecordList] = useState([]);
  const [options, setOptions] = useState({
    projectIds: [],
    snapshots: snapshotOpts,
    snapshotVersions: [],
    types: [
      { label: 'CHANGE', value: 'CHANGE' },
      { label: 'RESET', value: 'RESET' },
      { label: 'FORMATION', value: 'FORMATION' },
    ],
    updatedAt: []
  });
  const [filterState, setFilterState] = useState({
    projectIds: [],
    snapshot: null,
    snapshotVersions: [],
    types: [],
    updatedAt: []
  });
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    APIList.getOperationHistory().get().then(res => {
      setRecordList(res);
      const projectIds = res.reduce((acc, cur) => {
        if (!acc.includes(cur.projectId)) {
          acc.push(cur.projectId);
        }
        return acc;
      }, []);
      const updatedAt = res.reduce((acc, cur) => {
        const date = moment(cur.updatedAt).format('yyyy-MM-DD');
        if (!acc.includes(date)) {
          acc.push(date);
        }
        return acc;
      }, []);
      setOptions({
        ...options,
        projectIds: projectIds.map(p => ({ label: p, value: p })),
        updatedAt: updatedAt.map(u => ({ label: u, value: u }))
      });
      setDataLoading(false);
    });
  }, []);

  const tableData = useMemo(() => {
    const result = [];
    const {
      projectIds,
      snapshot,
      snapshotVersions,
      types,
      updatedAt
    } = filterState;
    const filteredData = recordList.filter(
      r => (projectIds.length === 0 || projectIds.includes(r.projectId))
        && (!snapshot || snapshot === r.snapshot)
        && (snapshotVersions.length === 0 || snapshotVersions.includes(r.snapshotVersion))
        && (types.length === 0 || types.includes(r.type))
        && (updatedAt.length === 0 || updatedAt.includes(moment(r.updatedAt).format('yyyy-MM-DD')))
    );
    const temp = groupBy(filteredData, item => item.operationId);
    Object.values(temp).forEach(v => {
      if (v[0].type !== 'CHANGE') {
        result.push({
          ...v[0],
          operationId: v[0].operationId,
          before: null,
          after: null,
          field: v[0].type,
          children: v.map((item, i) => ({ ...item, operationId: `${v[0].operationId}C${i}` })),
          canOperate: true
        });
      } else {
        result.push({
          ...v[0],
          canOperate: true
        });
      }
    });
    return result;
  }, [recordList, filterState]);

  const [expendRowKeys, setExpendRowKeys] = useState([]);

  const demandForecastAction = async (projectId, snapshot, snapshotVersion) => {
    try {
      const res = await APIList.demandForecastAction().post({ projectId, snapshotVersion, snapshot });
      const { sessionId } = res as any;
      const simulationPromise = new Promise<void>((resolve, reject) => {
        let timeoutTimer = setTimeout(async () => {
          if (timer) {
            clearInterval(timer);
            timer = null;
            reject();
          }
        }, timeout);
        let timer = setInterval(async () => {
          try {
            const r = await APIList.getSimulationStatus().get({ sessionId });
            if (r.status === 'forecastDone') {
              if (timer) {
                clearInterval(timer);
                timer = null;
              }
              if (timeoutTimer) {
                clearTimeout(timeoutTimer);
                timeoutTimer = null;
              }
              // eslint-disable-next-line no-console
              console.log('forecastDone');
              resolve();
            } else if (r.status === 'forecastError') {
              if (timer) {
                clearInterval(timer);
                timer = null;
              }
              if (timeoutTimer) {
                clearTimeout(timeoutTimer);
                timeoutTimer = null;
              }
              message.error('Forecast Failed.');
              // eslint-disable-next-line no-console
              console.log('forecastError');
              resolve();
            }
          } catch (e) {
            if (timer) {
              clearInterval(timer);
              timer = null;
            }
            if (timeoutTimer) {
              clearTimeout(timeoutTimer);
              timeoutTimer = null;
            }
            message.error('Forecast Failed.');
            // eslint-disable-next-line no-console
            console.log('forecastError');
            resolve();
          }
        }, 1000);
      });
      await simulationPromise;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      message.error('Connecting timeout.');
    }
  };

  const mappingValueType = (newV, oldV) => {
    const typeString = Object.prototype.toString.call(oldV);
    const type = typeString.match(/(\S+)]$/)?.[1];
    if (type === 'Number') {
      return Number(newV);
    }
    if (type === 'String') {
      return newV.toString();
    }
    if (type === 'Boolean') {
      return JSON.parse(newV);
    }
    return newV;
  };

  const handleRollback = async item => {
    const {
      projectUuid: id,
      snapshot,
      snapshotVersion,
      type,
      field,
      before,
      after,
      children
    } = item;
    const data = await APIList.getProjectItem().get({ id, snapshot, snapshotVersion });
    const restoredData = { ...data };
    const actions = type === 'CHANGE'
      ? { [field]: before }
      : children.reduce((acc, cur) => {
        acc[cur.field] = cur.before;
        return acc;
      }, {});
    Object.keys(actions).forEach(f => {
      restoredData[f] = mappingValueType(actions[f], data[f]);
    });
    if (type === 'CHANGE') {
      const method = tableChangeMap[field];
      if (method) {
        if (field === 'projectStartDate') {
          method(restoredData, moment(before).diff(moment(after), 'days'));
        } else if (field === 'phase') {
          const wonOpts = await APIList.getCmcOptions().get({
            category: 'wonCalcBackup',
            snapshot,
            snapshotVersion,
          });
          method(restoredData, wonOpts);
        } else {
          method(restoredData);
        }
      }
      await APIList.updateProjectCase().put({ ...restoredData });
      if (
        restoredData.demandForecastType?.includes('effimate')
        && field === 'dxApplicable'
      ) {
        await demandForecastAction(restoredData.projectId, snapshot, snapshotVersion);
      }
    }

    if (type === 'RESET') {
      await APIList.updateProjectCase().put({ ...restoredData });
    }

    if (type === 'FORMATION') {
      await APIList.demandForecast().put({ ...restoredData });
      await demandForecastAction(restoredData.projectId, snapshot, snapshotVersion);
    }
    message.success(t('aipcmcty.page.historyRecord.successMessage'));
  };

  const columns: ColumnType<RecordItem>[] = [
    {
      title: t('aipcmcty.page.historyRecord.projectId'),
      dataIndex: 'projectId',
      key: 'projectId',
      align: 'center',
      width: 180
    },
    {
      title: 'Snapshot',
      dataIndex: 'snapshot',
      key: 'snapshot',
      align: 'center',
      width: 120
    },
    {
      title: 'Version',
      dataIndex: 'snapshotVersion',
      key: 'snapshotVersion',
      align: 'center',
      render: (value) => versionList.find(v => v.snapshotVersion === value)?.snapshotVersionName
    },
    {
      title: t('aipcmcty.page.historyRecord.type'),
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 120
    },
    {
      title: t('aipcmcty.page.historyRecord.field'),
      dataIndex: 'field',
      key: 'field',
      align: 'center',
      width: 200,
      render: value => fieldMap[value] ?? value
    },
    {
      title: t('aipcmcty.page.historyRecord.before'),
      dataIndex: 'before',
      key: 'before',
      render: (value, item) => {
        const { field } = item;
        if (field === 'priorityMatching') {
          return value === 'true' ? fieldMap[field] : '-';
        }
        if (field === 'dxApplicable') {
          return value === 'true' ? fieldMap.dx : fieldMap.dxNot;
        }
        if (field.startsWith('formJv')) {
          return `${value}%`;
        }
        if (field === 'grossMargin') {
          return `${(Number(value) * 100).toFixed(1)}%`;
        }
        if (field.endsWith('Date')) {
          return moment(value).format('yyyy-MM-DD');
        }
        if (field === 'won') {
          return `${(Number(value) * 100).toFixed(1)}%`;
        }
        return value ?? '-';
      }
    },
    {
      title: t('aipcmcty.page.historyRecord.after'),
      dataIndex: 'after',
      key: 'after',
      render: (value, item) => {
        const { field } = item;
        if (field === 'priorityMatching') {
          return value === 'true' ? fieldMap[field] : '-';
        }
        if (field === 'dxApplicable') {
          return value === 'true' ? fieldMap.dx : fieldMap.dxNot;
        }
        if (field.startsWith('formJv')) {
          return `${value}%`;
        }
        if (field === 'grossMargin') {
          return `${(Number(value) * 100).toFixed(1)}%`;
        }
        if (field.endsWith('Date')) {
          return moment(value).format('yyyy-MM-DD');
        }
        if (field === 'won') {
          return `${(Number(value) * 100).toFixed(1)}%`;
        }
        return value ?? '-';
      }
    },
    {
      title: t('aipcmcty.page.historyRecord.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'center',
      width: 160,
      render: value => moment(value).format('yyyy-MM-DD HH:mm:ss')
    },
    {
      title: t('aipcmcty.page.historyRecord.action'),
      dataIndex: 'operation',
      key: 'operation',
      align: 'center',
      width: 90,
      render: (_, item) => {
        const { canOperate } = item;
        return canOperate
          ? (
            <Space>
              <Popconfirm
                title={t('aipcmcty.page.historyRecord.restoreAction')}
                placement="left"
                onConfirm={() => handleRollback(item)}
              >
                <RollbackOutlined />
              </Popconfirm>
            </Space>
          )
          : <></>;
      },
    },
  ];

  const handleFilterChange = (e: any, type: string) => {
    const newState = {
      ...filterState,
      [type]: e
    };
    if (type === 'snapshot') {
      setOptions({
        ...options,
        snapshotVersions: versionOpts[e]
      });
      newState.snapshotVersions = [];
    }
    setFilterState(newState);
  };

  return (
    <div className="operation-history-page">
      <Row className="operation-container">
        <Col>
          <Space>
            {t('aipcmcty.page.historyRecord.projectId')}
            :
            <Select
              style={{ width: 200 }}
              options={options.projectIds}
              value={filterState.projectIds}
              mode="multiple"
              maxTagCount="responsive"
              onChange={e => handleFilterChange(e, 'projectIds')}
            />
            Snapshot:
            <Select
              style={{ width: 150 }}
              options={options.snapshots}
              value={filterState.snapshot}
              onChange={e => handleFilterChange(e, 'snapshot')}
              allowClear
            />
            Version:
            <Select
              style={{ width: 200 }}
              options={options.snapshotVersions}
              value={filterState.snapshotVersions}
              mode="multiple"
              maxTagCount="responsive"
              onChange={e => handleFilterChange(e, 'snapshotVersions')}
            />
            {t('aipcmcty.page.historyRecord.type')}
            :
            <Select
              style={{ width: 200 }}
              options={options.types}
              value={filterState.types}
              mode="multiple"
              maxTagCount="responsive"
              onChange={e => handleFilterChange(e, 'types')}
            />
            {t('aipcmcty.page.historyRecord.updateAt')}
            :
            <Select
              style={{ width: 150 }}
              options={options.updatedAt}
              value={filterState.updatedAt}
              mode="multiple"
              maxTagCount="responsive"
              onChange={e => handleFilterChange(e, 'updatedAt')}
            />
          </Space>
        </Col>
      </Row>
      <Table
        style={{ marginTop: 5 }}
        className="record-table"
        size="small"
        columns={columns}
        dataSource={tableData}
        expandable={{
          onExpand(expended, record) {
            const { operationId } = record;
            if (expended) {
              setExpendRowKeys([...expendRowKeys, operationId]);
            } else {
              setExpendRowKeys(expendRowKeys.filter(k => k !== operationId));
            }
          },
          expandedRowKeys: expendRowKeys
        }}
        rowKey="operationId"
        loading={dataLoading}
        pagination={false}
        scroll={{ y: window.innerHeight - selectorTop - 60 }}
      />
    </div>
  );
};

export default Record;