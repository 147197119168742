/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react';
import { groupBy, sortBy, sumBy } from 'lodash';
import { Space, Divider, Radio, Tabs } from 'antd';
import MhPilesChart from './charts/mh-piles.chart';
import ProjectSetupDetailHeader from './project-setup-detail-header';
import ProjectSetupDetailTable from './tables/project-setup-detail.table';
import ProjectSetupSupplyHeader from './project-setup-supply-header';
import ProjectSetupSupplyTable from './tables/project-setup-supply.table';
import { AipcmctyContext } from '../contexts/aipcmcty.context';
import { ProjectSetupDetailContext } from '../contexts/project-setup-detail.context';
import APIList from '../http/ApiList';
import { useTranslation } from 'react-i18next';
import ProjectInformation from './project-information';

type ProjectSetupDetailProp = {
  project: any;
  // demandForecastType?: string;
};

const ProjectSetupDetail: React.FC<any> = (props: ProjectSetupDetailProp) => {
  const { project } = props;
  const { t } = useTranslation();
  const { snapshot, snapshotVersion } = useContext(AipcmctyContext);

  const [mhPilesChartData, setMhPilesChartData] = useState([]);
  const [demandTableData, setDemandTableData] = useState<any[]>();
  const [supplyTableData, setSupplyTableData] = useState<any[]>();
  const [mhPilesLoading, setMhPilesLoading] = useState(true);

  const [tgcList, setTgcList] = useState([]);
  const [feedOrEpcList, setFeedOrEpcList] = useState([]);

  const [demandTimeline, setDemandTimeline] = useState([]);
  const [supplyTimeline, setSupplyTimeline] = useState([]);

  const [formationList, setFormationList] = useState([]);
  const [japanList, setJapanList] = useState([]);
  const [indiaList, setIndiaList] = useState([]);

  const [searchDemandForm, setSearchDemandForm] = useState<any>();
  const [searchSupplyForm, setSearchSupplyForm] = useState<any>();

  const [formationData, setFormationData] = useState();
  const [demandData, setDemandData] = useState();

  const [tgcSwitch, setTgcSwitch] = useState<string>();
  const [feedOrEpcSwitch, setFeedOrEpcSwitch] = useState<string>();
  const [mhSwitch, setMhSwitch] = useState<string>('imbalancedMh');

  const defaultTgc = ['Toyo-I', 'Toyo-J'];
  const defaultFeedOrEpc = ['feed', 'epc'];

  const [defaultMh] = useState([
    {
      label: `${t('aipcmcty.page.excessDeficit')} MH`,
      value: 'imbalancedMh',
    },
    {
      label: `${t('aipcmcty.page.supply')} MH`,
      value: 'supplyMh',
    },
    {
      label: `${t('aipcmcty.page.demand')} MH`,
      value: 'demandMh',
    },
  ]);

  useEffect(() => {
    setMhPilesLoading(true);
    const projectId = project?.projectId;
    if (projectId) {
      APIList.getProjectFormation()
        .get({ projectId, snapshot, snapshotVersion })
        .then(({ formation, demand, matching }: any) => {
          setFormationData(formation);
          setDemandData(demand);

          // demand table
          const tableDataDemand = [];
          formation.forEach((formationItem) => {
            const demandFilterData = demand.filter(
              (item) =>
                item.division === formationItem.division &&
                item.discipline === formationItem.discipline &&
                item.category === formationItem.category &&
                item.stage === formationItem.stage &&
                item.role === formationItem.role
            );
            if (demandFilterData) {
              demandFilterData.forEach((demandItem) => {
                formationItem[`${demandItem.tgc}#${demandItem.year}-${demandItem.month}`] = demandItem.resourceDemandMh;
                formationItem[`id@${demandItem.tgc}#${demandItem.year}-${demandItem.month}`] = demandItem.id;
              });
            }
            tableDataDemand.push({ ...formationItem, key: `${formationItem.id}` });
          });

          const demandGb = groupBy(
            demand,
            (item) =>
              `${item.division || ''}#${item.discipline || ''}#${item.category || ''}#${item.stage || ''}#${item.role || ''}#${
                item.feedOrEpc || ''
              }#${item.segOfProject || ''}`
          );
          Object.keys(demandGb).forEach((gbKey) => {
            const demandProp = gbKey.split('#');
            const demandDivision = demandProp[0] || null;
            const demandDiscipline = demandProp[1] || null;
            const demandCategory = demandProp[2] || null;
            const demandStage = demandProp[3] || null;
            const demandRole = demandProp[4] || null;
            const demandFeedOrEpc = demandProp[5] || null;
            const demandSegOfProject = demandProp[6] || null;

            const formationFilterData = formation.find(
              (item) =>
                item.division === demandDivision &&
                item.discipline === demandDiscipline &&
                item.category === demandCategory &&
                item.stage === demandStage &&
                item.role === demandRole &&
                item.feedOrEpc === demandFeedOrEpc &&
                item.segOfProject === demandSegOfProject
            );
            if (!formationFilterData) {
              const demandProcessData = {
                id: gbKey,
              };
              demandGb[gbKey].forEach((demandItem) => {
                demandProcessData[`${demandItem.tgc}#${demandItem.year}-${demandItem.month}`] = demandItem.resourceDemandMh;
                demandProcessData[`id@${demandItem.tgc}#${demandItem.year}-${demandItem.month}`] = demandItem.id;
              });
              tableDataDemand.push({
                division: demandDivision,
                discipline: demandDiscipline,
                category: demandCategory,
                stage: demandStage,
                role: demandRole,
                feedOrEpc: demandFeedOrEpc,
                segOfProject: demandSegOfProject,
                ...demandProcessData,
              });
            }
          });
          setDemandTableData(tableDataDemand);

          // supply table
          const tableDataSupply = [];
          const matchingGb = groupBy(
            matching,
            (item) => `${item.tgc || ''}#${item.division || ''}#${item.discipline || ''}#${item.role || ''}#${item.dsMatched || ''}`
          );
          Object.keys(matchingGb).forEach((gbKey) => {
            const matchingProp = gbKey.split('#');
            const supplyTgc = matchingProp[0] || null;
            const supplyDivision = matchingProp[1] || null;
            const supplyDiscipline = matchingProp[2] || null;
            const supplyRole = matchingProp[3] || null;
            const supplyDsMatched = matchingProp[4] === 'true';
            const matchingProcessData = {
              tgc: supplyTgc,
              division: supplyDivision,
              discipline: supplyDiscipline,
              role: supplyRole,
              dsMatched: supplyDsMatched,
              demandMh: sumBy(matchingGb[gbKey], 'demandMh'),
              supplyMh: sumBy(matchingGb[gbKey], 'supplyMh'),
              imbalancedMh: sumBy(matchingGb[gbKey], 'imbalancedMh'),
            };
            matchingGb[gbKey].forEach((matchingItem) => {
              matchingProcessData[`demandMh#${matchingItem.year}-${matchingItem.month}`] = matchingItem.demandMh;
              matchingProcessData[`supplyMh#${matchingItem.year}-${matchingItem.month}`] = matchingItem.supplyMh;
              matchingProcessData[`imbalancedMh#${matchingItem.year}-${matchingItem.month}`] = matchingItem.imbalancedMh;
            });
            tableDataSupply.push(matchingProcessData);
          });
          setSupplyTableData(tableDataSupply);

          // table tgc
          const gbTgc = groupBy(
            demand.filter((item) => item.tgc),
            'tgc'
          );
          setTgcList(Object.keys(gbTgc));

          // table feedOrEpcList
          const gbFeedOrEpc = groupBy(
            demand.filter((item) => item.feedOrEpc),
            'feedOrEpc'
          );
          setFeedOrEpcList(Object.keys(gbFeedOrEpc));

          // table timeline
          const gbDemandDate = groupBy(demand, (item) => `${item.year}-${item.month}`);
          setDemandTimeline(sortBy(Object.keys(gbDemandDate)));
          const gbSupplyData = groupBy(matching, (item) => `${item.year}-${item.month}`);
          setSupplyTimeline(sortBy(Object.keys(gbSupplyData)));

          // chart
          setMhPilesChartData(demand);

          // loading
          setMhPilesLoading(false);
        });
    } else {
      setDemandTableData([]);
      setMhPilesChartData([]);
    }
  }, [project]);

  const [activeKey, setActiveKey] = useState('0');
  const tabChange = (key: string) => {
    setActiveKey(key);
  };

  const tabs = [
    {
      key: '0',
      label: '情報',
      forceRender: true,
      children: <ProjectInformation base=".cmcty-modal-body" offset={56} projectId={project?.projectId} />,
    },
    {
      key: '1',
      label: t('aipcmcty.page.demand'),
      forceRender: true,
      children: (
        <div>
          <ProjectSetupDetailHeader
            projectId={project?.projectId}
            demandForecastType={project?.demandForecastType}
            loading={mhPilesLoading}
          />
          <MhPilesChart data={mhPilesChartData} loading={mhPilesLoading} />
          <div style={{ padding: 12, float: 'right' }}>
            <Space split={<Divider type="vertical" />}>
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => {
                  setFeedOrEpcSwitch(e.target.value);
                }}
                value={feedOrEpcSwitch}
                size="small"
              >
                {defaultFeedOrEpc.map((feedOrEpc) => (
                  <Radio.Button
                    disabled={!feedOrEpcList.includes(feedOrEpc)}
                    key={feedOrEpc}
                    value={feedOrEpc}
                    style={{ width: 100, textAlign: 'center' }}
                  >
                    {feedOrEpc}
                  </Radio.Button>
                ))}
              </Radio.Group>
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => {
                  setTgcSwitch(e.target.value);
                }}
                value={tgcSwitch}
                size="small"
              >
                {defaultTgc.map((tag) => (
                  <Radio.Button disabled={!tgcList.includes(tag)} key={tag} value={tag} style={{ width: 100, textAlign: 'center' }}>
                    {tag}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Space>
          </div>
          <ProjectSetupDetailTable
            timeline={demandTimeline}
            tgcTags={tgcList}
            feedOrEpcTags={feedOrEpcList}
            data={demandTableData}
            loading={mhPilesLoading}
            demandForecastType={project?.demandForecastType}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: t('aipcmcty.page.supply'),
      forceRender: true,
      children: (
        <div>
          <ProjectSetupSupplyHeader
            projectId={project?.projectId}
            demandForecastType={project?.demandForecastType}
            loading={mhPilesLoading}
          />
          <div style={{ padding: 12, float: 'right' }}>
            <Space split={<Divider type="vertical" />}>
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => {
                  setMhSwitch(e.target.value);
                }}
                value={mhSwitch}
                size="small"
              >
                {defaultMh.map((tag) => (
                  <Radio.Button key={tag.value} value={tag.value} style={{ minWidth: 100, textAlign: 'center' }}>
                    {tag.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Space>
          </div>
          <ProjectSetupSupplyTable timeline={supplyTimeline} data={supplyTableData} loading={mhPilesLoading} />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="dashboard project-setup">
        <ProjectSetupDetailContext.Provider
          value={{
            formationList,
            japanList,
            indiaList,
            searchDemandForm,
            searchSupplyForm,
            setFormationList,
            setJapanList,
            setIndiaList,
            setSearchDemandForm,
            setSearchSupplyForm,
            demandTableData,
            supplyTableData,
            formationData,
            setFormationData,
            demandData,
            setDemandData,
            tgcSwitch,
            setTgcSwitch,
            feedOrEpcSwitch,
            setFeedOrEpcSwitch,
            mhSwitch,
            setMhSwitch,
            defaultMh,
          }}
        >
          <Tabs defaultActiveKey="1" type="card" activeKey={activeKey} onChange={tabChange} items={tabs} />
        </ProjectSetupDetailContext.Provider>
      </div>
    </>
  );
};

export default ProjectSetupDetail;
