import { useContext, useEffect, useRef, useState } from 'react';
import Chat, { Bubble, Card, CardTitle, useMessages } from '@chatui/core';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Divider, Input, Popconfirm, Spin, Tooltip, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { AppContext } from '../../contexts/AppContext';
import { ActionContext } from '../../contexts/action.context';
import { ACTION_ACTIVITY_TYPE } from '../../consts/action-const';
import APIList from '../../http/ApiList';

type MemoProperties = {
  collapsed: boolean;
};

const ActionActivityContainer: React.FC<MemoProperties> = (props) => {
  const { t } = useTranslation();
  const [memoLoading, setMemoLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const { collapsed } = props;
  const { authInfo } = useContext(AppContext);
  const {
    actionInfo: { actionId, actionName },
  } = useContext(ActionContext);
  const [memoEditMsg, setMemoEditMsg] = useState<{
    id: string;
    text: string;
    visible: boolean;
  }>();

  const bubbleStyle = {
    padding: 6,
    marginLeft: 10,
    borderRadius: 6,
  };

  const iconStyle = {
    padding: 3,
    fontSize: 'small',
    color: '#555',
  };

  const composerRef = useRef(null);

  const { messages, prependMsgs, appendMsg, updateMsg, deleteMsg, resetList } =
    useMessages([]);

  const getDate = (date) => {
    return new Date(date).toLocaleString('ja-JP', {
      hour12: false,
    });
  };

  const actionActivityTypeMap = {
    [ACTION_ACTIVITY_TYPE.COMMENT]: {
      title: t('aipcmcty.page.action.comments'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
    [ACTION_ACTIVITY_TYPE.STATUS]: {
      title: t('aipcmcty.page.action.status'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
    [ACTION_ACTIVITY_TYPE.PROGRESS]: {
      title: t('aipcmcty.page.action.progressRate'),
      format: (text) =>
        !isNil(text) ? `${text * 100}%` : t('aipcmcty.page.action.notSet'),
    },
    [ACTION_ACTIVITY_TYPE.ACTION_NAME]: {
      title: t('aipcmcty.page.action.actionName'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
    [ACTION_ACTIVITY_TYPE.ACTION_TYPE]: {
      title: t('aipcmcty.page.action.actionType'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
    [ACTION_ACTIVITY_TYPE.ACTION_DETAIL]: {
      title: t('aipcmcty.page.action.actionDetail'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
    [ACTION_ACTIVITY_TYPE.CREATED_AT]: {
      title: t('aipcmcty.page.action.startDate'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
    [ACTION_ACTIVITY_TYPE.PRIORITY]: {
      title: t('aipcmcty.page.action.priorityLevel'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
    [ACTION_ACTIVITY_TYPE.DEADLINE]: {
      title: t('aipcmcty.page.action.dueDate'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
    [ACTION_ACTIVITY_TYPE.ASSIGNEE]: {
      title: t('aipcmcty.page.action.assignee'),
      format: (text) => (text ? text : t('aipcmcty.page.action.notSet')),
    },
  };

  const getActionActivity = () => {
    setMemoLoading(true);
    APIList.getCmcActionActivities()
      .get({ actionId: actionId })
      .then((res: any) => {
        if (res) {
          const data = res.reduce((acc, cur) => {
            if (cur.activityType !== 1) {
              const findActivity = acc.find(
                (item) =>
                  item.activityType !== 1 &&
                  item.userName === cur.userName &&
                  item.updatedAt === cur.updatedAt
              );
              if (findActivity) {
                findActivity.activities = [...findActivity.activities, cur];
              } else {
                acc.push({
                  userName: cur.userName,
                  updatedAt: cur.updatedAt,
                  activities: [cur],
                });
              }
            } else {
              acc.push(cur);
            }
            return acc;
          }, []);
          const memoData = data.map((item) => ({
            _id: item.id,
            type: 'text',
            content: {
              text: item.comment,
              activities: item.activities,
              userName: item.userName,
              updatedAt: getDate(item.updatedAt),
            },
            user:
              item.activityType === 1
                ? {
                    role:
                      authInfo.user.attributes.sub === item.userId
                        ? 'User'
                        : 'Others',
                    name:
                      authInfo.user.attributes.sub === item.userId
                        ? 'You'
                        : item.userName,
                    updatedAt: getDate(item.updatedAt),
                  }
                : {
                    userName: item.userName,
                    updatedAt: getDate(item.updatedAt),
                  },
            position:
              authInfo.user.attributes.sub === item.userId ? 'right' : 'left',
          }));
          prependMsgs(memoData);
        }
        setMemoLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setMemoLoading(false);
      });
  };

  useEffect(() => {
    composerRef.current.setText('');
    resetList([]);
    if (actionId && !collapsed) getActionActivity();
  }, [collapsed, actionId]);

  const handleSend = (type: string, val: string) => {
    setMemoLoading(true);
    if (type === 'text' && val.trim()) {
      APIList.createCmcActionActivity()
        .post({
          actionId: actionId,
          comment: val,
          activityType: ACTION_ACTIVITY_TYPE.COMMENT,
        })
        .then((data: any) => {
          appendMsg({
            _id: data.id,
            type: 'text',
            content: {
              text: val,
            },
            user: {
              role: 'User',
              name: 'You',
              updatedAt: getDate(data.updatedAt),
            },
            position: 'right',
          } as any);
          message.success('Successfully created the comment');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setMemoLoading(false);
        });
    }
  };

  const handleUpdateMsg = () => {
    const { id, text } = memoEditMsg;
    APIList.updateCmcActionActivity()
      .put({
        id,
        actionId: actionId,
        comment: text,
        activityType: ACTION_ACTIVITY_TYPE.COMMENT,
      })
      .then((data: any) => {
        updateMsg(id, {
          type: 'text',
          content: {
            text: text,
          },
          user: {
            role: 'User',
            name: 'You -- edited',
            updatedAt: getDate(data.updatedAt),
          },
          position: 'right',
        } as any);
        message.success('Successfully updated the comment');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };

  const handleDeleteMsg = (msg) => {
    setMemoLoading(true);
    APIList.deleteCmcActionActivity()
      .del({ id: msg._id })
      .then(() => {
        deleteMsg(msg._id);
        message.success('Successfully deleted the comment');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setMemoLoading(false);
      });
  };

  const renderMessageContent = (msg) => {
    const { _id, content, user } = msg;

    const isUser = user.role === 'User';

    const renderMessage = () => (
      <>
        {content.activities && content.activities.length > 0 ? (
          <>
            <Card className="action-card">
              <CardTitle
                className="action-card-title"
                title={t('aipcmcty.page.action.updateMessage')
                  .replace('{userName}', content.userName)
                  .replace('{updatedAt}', content.updatedAt)}
                subtitle={content.activities.map((activity) => (
                  <>
                    {`・${
                      actionActivityTypeMap[activity.activityType].title
                    }: ${actionActivityTypeMap[activity.activityType].format(
                      activity.operationBefore
                    )} ⇒ ${actionActivityTypeMap[activity.activityType].format(
                      activity.operationAfter
                    )}`}
                    <br />
                  </>
                ))}
              ></CardTitle>
            </Card>
          </>
        ) : (
          <div style={isUser ? { display: 'flex', alignItems: 'center' } : {}}>
            {isUser && !(memoEditMsg?.visible && _id === memoEditMsg?.id) ? (
              <>
                <Popconfirm
                  title={t('aipcmcty.page.action.delConfirm')}
                  onConfirm={() => handleDeleteMsg(msg)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined style={iconStyle} />
                </Popconfirm>

                <EditOutlined
                  style={iconStyle}
                  onClick={() => {
                    setMemoEditMsg({
                      id: _id,
                      text: content.text,
                      visible: !memoEditMsg?.visible,
                    });
                  }}
                />
              </>
            ) : (
              <></>
            )}
            <Bubble
              style={bubbleStyle}
              content={
                <>
                  {updateLoading && _id === memoEditMsg?.id ? (
                    <Spin />
                  ) : (
                    <>
                      {memoEditMsg?.visible && _id === memoEditMsg?.id ? (
                        <>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <div>
                                  <Tooltip
                                    title={t('aipcmcty.page.memo.cancel')}
                                  >
                                    <CloseOutlined
                                      style={{ ...iconStyle, marginLeft: 5 }}
                                      onClick={() => {
                                        setMemoEditMsg({
                                          ...memoEditMsg,
                                          visible: false,
                                        });
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip title={t('aipcmcty.page.memo.ok')}>
                                    <CheckOutlined
                                      style={{ ...iconStyle, marginLeft: 8 }}
                                      onClick={() => {
                                        setMemoEditMsg({
                                          ...memoEditMsg,
                                          visible: false,
                                        });
                                        setUpdateLoading(true);
                                        handleUpdateMsg();
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                              <Divider style={{ margin: '6px 0' }} />
                              <Input.TextArea
                                style={{ width: 250 }}
                                size="large"
                                value={
                                  memoEditMsg?.id === _id
                                    ? memoEditMsg?.text
                                    : ''
                                }
                                onChange={(e) => {
                                  setMemoEditMsg({
                                    ...memoEditMsg,
                                    text: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <span>{content.text}</span>
                        </>
                      )}
                    </>
                  )}
                  <br />
                  <p className="extra">At: {user.updatedAt}</p>
                </>
              }
            />
          </div>
        )}
      </>
    );

    return renderMessage();
  };

  const CustomNavbar = ({ title }) => (
    <div className="Navbar-main-cus" style={{ height: 54 }}>
      <Tooltip placement="bottom" title={title}>
        <h2 className="Navbar-title">{title}</h2>
      </Tooltip>
    </div>
  );

  return (
    <Spin spinning={memoLoading}>
      <Chat
        locale="en-US"
        renderNavbar={() => (
          <CustomNavbar title={`#${actionId}: ${actionName}`} />
        )}
        placeholder={'コメントを入力してください'}
        messages={messages}
        renderMessageContent={renderMessageContent}
        onSend={handleSend}
        composerRef={composerRef}
      />
    </Spin>
  );
};

export default ActionActivityContainer;
