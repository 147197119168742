import { BarsOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Tabs,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { isNil, orderBy, sortBy } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import DrawerContainer, { DrawerProperties } from '../../components/widget/drawer-container';
import { ACTION_PRIORITY, OPERATION } from '../../consts/action-const';
import APIList from '../../http/ApiList';
import { ActionContext } from '../../contexts/action.context';
import ActionTable from '../../components/tables/action.table';
import ActionFilterContainer from '../../components/widget/action-filter-container';
import ActionActivityContainer from '../../components/widget/action-activity-container';

// export const OptLangMap = {
//   'システムの同期/関連データの修正':
//     'Synchronization/Correction of system-related data',
//   'DX運用案件・運用DX施策の変更・承認':
//     'Change/Approval of DX operation case and operation DX measures',
//   '受注カテゴリ計画の更新・承認': 'Update/Approval of order category plan',
//   'フォーメーション詳細の変更・承認': 'Change/Approval of formation details',
//   その他: 'Other',
//   未着手: 'Not started',
//   着手中: 'In progress',
//   レビュー待ち: 'Review pending',
//   完了: 'Completed',
//   緊急: 'Urgent',
//   高: 'High',
//   中: 'Medium',
//   低: 'Low',
// };

const ActionPage: React.FC = () => {
  // App
  const { setMenuCollapsed } = useContext(AppContext);
  // locales
  const { t } = useTranslation();
  // filter
  const [filterCollapsed, setFilterCollapsed] = useState(true);
  const [filtersData, setFiltersData] = useState({});
  // action activity
  const [actionCollapsed, setActionCollapsed] = useState(true);
  const [actionInfo, setActionInfo] = useState({
    actionId: null,
    actionName: '',
  });
  // table
  const [tableDataOrg, setTableDataOrg] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  // drawer
  const drawerOpts: Omit<DrawerProperties, 'collapsed' | 'children'> = {
    maxWidth: 340,
    minWidth: 0,
  };
  // model
  const [actionCreateVisible, setActionCreateVisible] = useState(false);
  const [operation, setOperation] = useState<string>();
  // form
  const [form] = Form.useForm();
  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const [optData, setOptData] = useState({
    actionType: [],
    status: [],
    priority: [],
    createdBy: [],
    assignee: [],
    progress: [],
  });

  const showActionOperationModel = (op: string, record?: any) => {
    setActionCreateVisible(true);
    setOperation(op);
    setActionCollapsed(true);
    form.resetFields();
    if (op === OPERATION.MODIFY && record) {
      setTimeout(() => {
        form.setFieldsValue({
          ...record,
          startDate: record.startDate ? moment(record.startDate) : null,
          deadline: record.deadline ? moment(record.deadline) : null,
        });
      }, 100);
    }
  };

  const actionCancelHandler = () => {
    setActionCreateVisible(false);
  };

  const actionOperationHandler = (values) => {
    setBtnLoading(true);
    const params = Object.keys(values).reduce((acc, cur) => {
      if (isNil(values[cur])) {
        acc[cur] = null;
      } else {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});
    if (operation === OPERATION.ADD) {
      actionCreate(params);
    } else {
      actionUpdate(params);
    }
  };

  const actionCreate = (values) => {
    if (values.assigneeId) {
      values.assigneeName = optData.assignee.find(
        (item) => item.value === values.assigneeId
      )?.label;
    }
    APIList.createCmcAction()
      .post({
        ...values,
      })
      .then(() => {
        actionCancelHandler();
        getActionData();
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const actionUpdate = async (values) => {
    if (values.assigneeId) {
      values.assigneeName = optData.assignee.find(
        (item) => item.value === values.assigneeId
      )?.label;
    } else {
      values.assigneeName = null;
    }
    await APIList.updateCmcAction()
      .put({ ...values })
      .then(() => {
        actionCancelHandler();
        getActionData();
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const actionDeleteHandler = (actionId) => {
    setTableLoading(true);
    APIList.deleteCmcAction()
      .del({ actionId })
      .then(() => {
        getActionData();
      });
  };

  const initOptData = () => {
    const actionTypeValues = [
      'システムの同期/関連データの修正',
      'DX運用案件・運用DX施策の変更・承認',
      '受注カテゴリ計画の更新・承認',
      'フォーメーション詳細の変更・承認',
      'その他',
    ];
    const statusValues = ['未着手', '着手中', 'レビュー待ち', '完了'];
    const priorityValues = [
      ACTION_PRIORITY.URGENT,
      ACTION_PRIORITY.HIGH,
      ACTION_PRIORITY.MIDDLE,
      ACTION_PRIORITY.LOW,
    ];
    const progressValue = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
    setOptData({
      actionType: actionTypeValues.map((value) => ({
        key: value,
        value,
        label: value,
      })),
      status: statusValues.map((value) => ({
        key: value,
        value,
        label: value,
      })),
      priority: priorityValues.map((value) => ({
        key: value,
        value,
        label: value,
      })),
      createdBy: [
        // {
        //   value: 'c19093f5-7d31-4f17-ad43-51fadedb5397',
        //   label: 'chunyuan.wang',
        // },
        // {
        //   value: 'b737f072-003d-492b-af45-4105d9a6dc46',
        //   label: 'MoShixin',
        // },
      ],
      assignee: [
        // {
        //   value: 'c19093f5-7d31-4f17-ad43-51fadedb5397',
        //   label: 'chunyuan.wang',
        // },
        // {
        //   value: 'b737f072-003d-492b-af45-4105d9a6dc46',
        //   label: 'MoShixin',
        // },
      ],
      progress: progressValue.map((value) => ({
        value,
        label: `${value * 100}%`,
      })),
    });
  };

  const getActionData = () => {
    setTableLoading(true);
    const prioritySort = [
      ACTION_PRIORITY.URGENT,
      ACTION_PRIORITY.HIGH,
      ACTION_PRIORITY.MIDDLE,
      ACTION_PRIORITY.LOW,
    ];
    APIList.getCmcActions()
      .get()
      .then((res) => {
        setTableDataOrg(
          res.map((item) => ({
            ...item,
            priorityOrder: item.priority
              ? prioritySort.indexOf(item.priority)
              : res.length,
          }))
        );
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const filterMethods = {
    search: (item, v = '') =>
      !v || item.actionName?.includes(v) || `${item.actionId}`.includes(v),
    projectId: (item, v = '') => !v || item.projectId?.includes(v),
    priority: (item, v) => !v?.length || v.includes(item.priority),
    actionType: (item, v) => !v?.length || v.includes(item.actionType),
    status: (item, v) => !v?.length || v.includes(item.status),
    createdBy: (item, v) => !v?.length || v.includes(item.createdBy),
    assigneeId: (item, v) => !v?.length || v.includes(item.assigneeId),
    startDate: (item, v) => {
      if (!v) {
        return true;
      }
      return (
        moment(item.startDate).format('YYYY-MM-DD') ===
        moment(v).format('YYYY-MM-DD')
      );
    },
    deadline: (item, v) => {
      if (!v) {
        return true;
      }
      return (
        moment(item.deadline).format('YYYY-MM-DD') ===
        moment(v).format('YYYY-MM-DD')
      );
    },
    updatedAt: (item, v) => {
      if (!v) {
        return true;
      }
      return (
        moment(item.updatedAt).format('YYYY-MM-DD') ===
        moment(v).format('YYYY-MM-DD')
      );
    },
    progress: (item, v) => !v?.length || v.includes(item.progress),
  };

  useEffect(() => {
    initOptData();
    getActionData();
  }, []);

  useEffect(() => {
    const filteredTableData = Object.keys(filterMethods).reduce(
      (tData, cur) => {
        const method = filterMethods[cur];
        return method
          ? tData.filter((t) => method(t, filtersData[cur]))
          : tData;
      },
      [...tableDataOrg]
    );

    const sortedTableData = orderBy(
      filteredTableData,
      ['priorityOrder', 'deadline'],
      ['asc', 'desc']
    );

    setTableData(sortedTableData);
  }, [tableDataOrg, filtersData]);

  return (
    <div className="action dashboard" style={{ height: `calc(100vh - 85px)` }}>
      <ActionContext.Provider
        value={{
          actionCollapsed,
          setActionCollapsed,
          filterCollapsed,
          setFilterCollapsed,
          filtersData,
          setFiltersData,
          tableDataOrg,
          setTableDataOrg,
          actionInfo,
          setActionInfo,
          optData,
          showActionOperationModel,
          actionDeleteHandler,
          actionUpdate,
          setTableLoading,
        }}
      >
        <div
          style={{
            marginRight: filterCollapsed && actionCollapsed ? 0 : 340,
          }}
        >
          <div className="select-group-container flex-container">
            <div
              style={{
                display: 'flex',
                padding: '8px 20px',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div style={{ display: 'flex' }}></div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 20,
                  }}
                >
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        showActionOperationModel(OPERATION.ADD);
                      }}
                    >
                      {t('aipcmcty.page.action.actionLogin')}
                    </Button>
                    <Button
                      onClick={() => {
                        setMenuCollapsed(filterCollapsed);
                        setActionCollapsed(true);
                        setFilterCollapsed(!filterCollapsed);
                      }}
                    >
                      <BarsOutlined />
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
          <ActionTable loading={tableLoading} data={tableData} />
        </div>
        <DrawerContainer {...drawerOpts} collapsed={filterCollapsed}>
          <ActionFilterContainer />
        </DrawerContainer>
        <DrawerContainer
          {...drawerOpts}
          collapsed={actionCollapsed}
          closeBtnFunc={() => {
            setMenuCollapsed(false);
            setActionCollapsed(true);
          }}
        >
          <ActionActivityContainer collapsed={actionCollapsed} />
        </DrawerContainer>
        <Modal
          title={t('aipcmcty.page.action.actionLogin')}
          width={600}
          open={actionCreateVisible}
          onCancel={() => {
            actionCancelHandler();
          }}
          destroyOnClose
          maskClosable={false}
          footer={null}
        >
          <Form
            className="plan-form"
            form={form}
            {...formLayout}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={(values) => {
              actionOperationHandler(values);
            }}
          >
            {operation === OPERATION.MODIFY ? (
              <Form.Item name="actionId" hidden rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            ) : (
              <></>
            )}

            <Form.Item
              label={t('aipcmcty.page.action.actionName')}
              name="actionName"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('aipcmcty.page.action.actionType')}
              name="actionType"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select options={optData.actionType} />
            </Form.Item>
            <Form.Item
              label={t('aipcmcty.page.action.actionDetail')}
              name="actionDetail"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Form.Item
                  label={t('aipcmcty.page.action.status')}
                  name="status"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select options={optData.status} />
                </Form.Item>
                <Form.Item
                  label={t('aipcmcty.page.action.priorityLevel')}
                  name="priority"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select options={optData.priority} />
                </Form.Item>
                <Form.Item
                  label={t('aipcmcty.page.action.assignee')}
                  name="assigneeId"
                >
                  <Select options={optData.assignee} allowClear />
                </Form.Item>
              </div>
              <div style={{ flex: 1 }}>
                <Form.Item
                  label={t('aipcmcty.page.action.startDate')}
                  name="startDate"
                >
                  <DatePicker allowClear />
                </Form.Item>
                <Form.Item
                  label={t('aipcmcty.page.action.dueDate')}
                  name="deadline"
                >
                  <DatePicker allowClear />
                </Form.Item>
                <Form.Item
                  label={t('aipcmcty.page.action.progressRate')}
                  name="progress"
                >
                  <Select options={optData.progress} allowClear />
                </Form.Item>
              </div>
            </div>
            <Form.Item {...tailLayout}>
              <Button
                htmlType="button"
                onClick={() => {
                  actionCancelHandler();
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                style={{ marginLeft: 30 }}
                type="primary"
                htmlType="submit"
                loading={btnLoading}
              >
                {t('common.submit')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </ActionContext.Provider>
    </div>
  );
};

export default ActionPage;
