import { useState, useEffect, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';

type WindowSize = {
  width: number;
  height: number;
};

type WindowSizeProp = {
  selector?: string;
  footer?: number;
  viewMode?: string;
  watcherSelector?: string[];
};

/**
 * これHookを使用して、下記の変量を取得できる
 * selectorHeight4Table：selectorはTableのscrollたら、scrollのheightを取得
 * selectorTop：selectorからwindowのtopまでの距離
 * windowSize：windowのinnerWidthとinnerHeight
 * @param props
 * @returns
 */
export const useWindowSize = (props: WindowSizeProp) => {
  const {
    selector = '.cmcty-table-body',
    footer = 20,
    viewMode = 'chart-table',
    watcherSelector,
  } = props;

  const { sizeMode } = useContext(AppContext);
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [selectorTop, setSelectorTop] = useState<number>(575);
  const [selectorHeight4Table, setSelectorHeight4Table] = useState(415);

  const updateSize = () => {
    if (selector) {
      const element = document.querySelector(selector);
      if (element) {
        const rect = element.getBoundingClientRect();
        const top = rect.top + window.scrollY;
        const height = window.innerHeight - top - footer;
        setSelectorTop(top);
        setSelectorHeight4Table(height);
      }
    }
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    setTimeout(() => {
      updateSize();
    }, 300);
    let resizeObserver;
    if (watcherSelector && watcherSelector.length > 0) {
      resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            // Firefox implements `contentBoxSize` as a single content rect, rather than an array
            updateSize();
          }
        }
        console.log('Size changed');
      });
      watcherSelector.forEach((element) => {
        if (document.querySelector(element)) {
          resizeObserver.observe(document.querySelector(element));
        }
      });
    }
    return () => {
      window.removeEventListener('resize', updateSize);
      if (resizeObserver && watcherSelector && watcherSelector.length > 0) {
        watcherSelector.forEach((element) => {
          if (document.querySelector(element)) {
            resizeObserver.unobserve(document.querySelector(element));
          }
        });
      }
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updateSize();
    }, 100);
  }, [sizeMode, viewMode]);

  return { windowSize, selectorTop, selectorHeight4Table };
};