import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Radio, RadioChangeEvent, Row, Select, Space, Table } from 'antd';
import { groupBy } from 'lodash';
import APIList from '../../http/ApiList';
import { ChartGroup, ChartTableLayout } from '@meteor/frontend-core';
import InvestmentReturnChart from '../../components/charts/investment-return.chart';
import { AppContext } from '../../contexts/AppContext';
import { useWindowSize } from '../../hooks/useWindowSize';
import useVersion from '../../hooks/useVersion';
import { useTranslation } from 'react-i18next';

type Option = {
  label: ReactNode;
  value: any;
  key?: any;
  [key: string]: any;
};

type InvestmentReturnItem = {
  /** 财务年度 */
  fiscalYear: string;
  /** DX効果（Allow.+課金）_Plan❶当初計画 */
  dxAPlan01: number;
  /** DX効果（Allow.+課金）_Plan❷期初計画 */
  dxAPlan02: number;
  /** DX効果（Allow.+課金）_最新計画 */
  dxAPlanLatest: number;
  /** DX効果（Allow.+課金）_最新計画 プロポーサル前 */
  dxAPlanLatestPreSale: number;
  /** DX効果（Allow.+課金）_最新計画 プロポーサル済み */
  dxAPlanLatestProposal: number;
  /** DX効果（Allow.+課金）_最新計画 受注済み */
  dxAPlanLatestExecuted: number;
  /** DX効果（Allow.+課金）_見込み+実績 */
  dxAForecastActualSum: number;
  /** DX効果（Allow.+課金）_見込み */
  dxAForecastSum: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル前 */
  dxAForecastPreSale: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル済み */
  dxAForecastProposal: number;
  /** DX効果（Allow.+課金）_見込み 受注済み */
  dxAForecastExecution: number;
  /** DX効果（Allow.+課金）_実績 */
  dxAActual: number;
  /** DX効果（Allow.+課金）_見込み+実績（コンテ込み） */
  dxAForecastActualSumCont: number;
  /** DX効果（Allow.+課金）_見込み（コンテ込み） */
  dxAForecastSumCont: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル前（コンテ込み） */
  dxAForecastPreSaleCont: number;
  /** DX効果（Allow.+課金）_見込み プロポーサル済み（コンテ込み） */
  dxAForecastProposalCont: number;
  /** DX効果（Allow.+課金）_見込み 受注済み（コンテ込み） */
  dxAForecastExecutionCont: number;
  /** DX効果（Allow.+課金）_実績（コンテ込み） */
  dxAActualCont: number;
  /** DX投資額_Plan❶当初計画 */
  dxIPlan01: number;
  /** DX投資額_最新計画 */
  dxIPlanLatest: number;
  /** DX投資額_見込み+実績 */
  dxIForecastActualSum: number;
  /** Cumulative_Plan❶当初計画 */
  cumPlan01: number;
  /** Cumulative_Plan❷期初計画 */
  cumPlan02: number;
  /** Cumulative_最新計画 */
  cumPlanLatest: number;
  /** Cumulative_見込み+実績 */
  cumForecastActualSum: number;
  /** Cumulative_見込み+実績（コンテ込み） */
  cumForecastActualSumCont: number;
};

export type TransformToFiscalYear = {
  [key in keyof Omit<InvestmentReturnItem, 'fiscalYear'>]: {
    [P in InvestmentReturnItem['fiscalYear'] as `FY${number}`]: InvestmentReturnItem[key];
  };
};

const InvestmentReturnPage: React.FC = () => {
  const { t } = useTranslation();
  const { menuCollapsed, scrollCount, setScrollCount } = useContext(AppContext);
  // chart container
  const { selectorTop } = useWindowSize({
    selector: '.chart-section',
  });

  const { selectorHeight4Table } = useWindowSize({
    selector: '.cmcty-card-body',
    viewMode: 'chart-table',
  });

  const { orgAllSnapshots, snapshot, snapshotVersion } = useVersion(false);
  const orgVersions = orgAllSnapshots
    .filter((o) => o.tag === 0)
    .map((o) => ({
      key: o.id,
      label: `${o.snapshot} ${o.snapshotVersionName}`,
      value: `${o.snapshot} ${o.snapshotVersion}`,
    }));
  const defaultOrgVersion = orgVersions[0];
  const [filterOpts, setFilterOpts] = useState({
    tgcOpts: [],
    orgVersions: orgVersions,
  });
  const [filter, setFilter] = useState({
    tgc: [],
    orgVersion: defaultOrgVersion.value,
  });
  const handleFilterChange = (e, key: keyof typeof filter) => {
    setFilter((prev) => ({ ...prev, [key]: e }));
  };

  const [tableCategory, setTableCategory] = useState(TABLE_CATEGORY.DX_EFFECT);
  const [tableData, setTableData] = useState({
    tableSource: [],
    tableColumns: [],
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [transformedData, setTransformedData] = useState<TransformToFiscalYear | null>(null);
  useEffect(() => {
    initializePage();
  }, [snapshot, snapshotVersion]);

  const handleSearch = async () => {
    setTableLoading(true);
    await getChartAndTableData(filter.tgc, ...(filter.orgVersion.split(' ') as [string, string]));
    setTableLoading(false);
  };

  const initializePage = async () => {
    try {
      setTableLoading(true);
      const selectedTgc = await getTgcOptions();
      const [orgSnapshot, orgVersion] = filter.orgVersion.split(' ');
      await getChartAndTableData(selectedTgc, orgSnapshot, orgVersion);
    } finally {
      setTableLoading(false);
    }
  };

  const getTgcOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'tgc',
      snapshot,
      snapshotVersion,
    })) as Option[];
    const tgcOpts = res
      .filter((r) => r.attribute1 === 'subsidiary')
      .map((r) => ({
        key: r.value,
        label: r.value,
        value: r.value,
      }));
    setFilterOpts((prev) => ({
      ...prev,
      tgcOpts,
    }));
    const selectedTgc = tgcOpts.map((r) => r.value);
    setFilter((prev) => ({ ...prev, tgc: selectedTgc }));
    return selectedTgc;
  };

  const getChartAndTableData = async (tgc: string[], orgSnapshot, orgVersion) => {
    const res = (await APIList.getInvestsAndRetrieves().get({
      curSnapshot: snapshot,
      curVersion: snapshotVersion,
      orgSnapshot,
      orgVersion,
      tgc,
    })) as InvestmentReturnItem[];
    const groupByFY = groupBy(res, 'fiscalYear');
    const data = Object.keys(groupByFY).reduce((prev, cur) => {
      Object.keys(groupByFY[cur][0]).forEach((k) => {
        if (k === 'fiscalYear') {
          return;
        }
        if (prev[k]) {
          prev[k][cur] = Number(groupByFY[cur][0][k]);
        } else {
          prev[k] = {
            [cur]: Number(groupByFY[cur][0][k]),
          };
        }
      });
      return prev;
    }, {} as TransformToFiscalYear);
    setTransformedData(data);
    initDXEffectTableData(data);
  };

  const initDXEffectTableData = (data: TransformToFiscalYear) => {
    if (!data) {
      return;
    }
    const columnYears = Object.keys(data.dxAPlan01);
    const columnTemplate = [
      {
        title: t('aipcmcty.page.investAndRepay.breakDown'),
        key: 'node',
        dataIndex: 'node',
        width: 300,
        className: 'text-left',
        fixed: 'left',
      },
      ...columnYears.map((year) => ({
        title: year,
        key: year,
        dataIndex: year,
        className: 'text-right',
        render: (val) => Number((val / 1000000).toFixed(2)).toLocaleString(),
      })),
    ];

    const dataTemplate = [
      {
        key: 'dxAPlan01',
        node: t('aipcmcty.page.investAndRepay.plan01'),
        ...data.dxAPlan01,
      },
      {
        key: 'dxAPlan02',
        node: t('aipcmcty.page.investAndRepay.plan02'),
        ...data.dxAPlan02,
      },
      {
        key: 'dxAPlanLatest',
        node: t('aipcmcty.page.investAndRepay.planLatest'),
        ...data.dxAPlanLatest,
        children: [
          {
            key: 'dxAPlanLatestPreSale',
            node: t('aipcmcty.page.investAndRepay.preSale'),
            ...data.dxAPlanLatestPreSale,
          },
          {
            key: 'dxAPlanLatestProposal',
            node: t('aipcmcty.page.investAndRepay.proposal'),
            ...data.dxAPlanLatestProposal,
          },
          {
            key: 'dxAPlanLatestExecuted',
            node: t('aipcmcty.page.investAndRepay.execution'),
            ...data.dxAPlanLatestExecuted,
          },
        ],
      },
      {
        key: 'dxAForecastActualSum',
        node: t('aipcmcty.page.investAndRepay.forecastAndActual'),
        ...data.dxAForecastActualSum,
        children: [
          {
            key: 'dxAForecastSum',
            node: t('aipcmcty.page.investAndRepay.forecast'),
            ...data.dxAForecastSum,
            children: [
              {
                key: 'dxAForecastPreSale',
                node: t('aipcmcty.page.investAndRepay.preSale'),
                ...data.dxAForecastPreSale,
              },
              {
                key: 'dxAForecastProposal',
                node: t('aipcmcty.page.investAndRepay.proposal'),
                ...data.dxAForecastProposal,
              },
              {
                key: 'dxAForecastExecution',
                node: t('aipcmcty.page.investAndRepay.execution'),
                ...data.dxAForecastExecution,
              },
            ],
          },
          {
            key: 'dxAActual',
            node: t('aipcmcty.page.investAndRepay.actual'),
            ...data.dxAActual,
          },
        ],
      },
      {
        key: 'dxAForecastActualSumCont',
        node: `${t('aipcmcty.page.investAndRepay.forecastAndActual')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        ...data.dxAForecastActualSumCont,
        children: [
          {
            key: 'dxAForecastSumCont',
            node: t('aipcmcty.page.investAndRepay.forecast'),
            ...data.dxAForecastSumCont,
            children: [
              {
                key: 'dxAForecastPreSaleCont',
                node: t('aipcmcty.page.investAndRepay.preSale'),
                ...data.dxAForecastPreSaleCont,
              },
              {
                key: 'dxAForecastProposalCont',
                node: t('aipcmcty.page.investAndRepay.proposal'),
                ...data.dxAForecastProposalCont,
              },
              {
                key: 'dxAForecastExecutionCont',
                node: t('aipcmcty.page.investAndRepay.execution'),
                ...data.dxAForecastExecutionCont,
              },
            ],
          },
          {
            key: 'dxAActualCont',
            node: t('aipcmcty.page.investAndRepay.actual'),
            ...data.dxAActualCont,
          },
        ],
      },
    ];
    setTableData({
      tableColumns: columnTemplate,
      tableSource: dataTemplate,
    });
  };

  const initDXInvestmentTableData = (data: TransformToFiscalYear) => {
    if (!data) {
      return;
    }
    const columnYears = Object.keys(data.dxIPlan01);
    const columnTemplate = [
      {
        title: t('aipcmcty.page.investAndRepay.breakDown'),
        key: 'node',
        dataIndex: 'node',
        width: 300,
        className: 'text-left',
        fixed: 'left',
      },
      ...columnYears.map((year) => ({
        title: year,
        key: year,
        dataIndex: year,
        className: 'text-right',
        render: (val) => Number((val / 1000000).toFixed(2)).toLocaleString(),
      })),
    ];

    const dataTemplate = [
      {
        key: 'dxAPlan01',
        node: t('aipcmcty.page.investAndRepay.plan01'),
        ...data.dxIPlan01,
      },
      {
        key: 'dxAPlanLatest',
        node: t('aipcmcty.page.investAndRepay.planLatest'),
        ...data.dxIPlanLatest,
      },
      {
        key: 'dxAForecastActualSum',
        node: t('aipcmcty.page.investAndRepay.forecastAndActual'),
        ...data.dxIForecastActualSum,
      },
    ];
    setTableData({
      tableColumns: columnTemplate,
      tableSource: dataTemplate,
    });
  };

  const initCumulativeTableData = (data: TransformToFiscalYear) => {
    if (!data) {
      return;
    }
    const columnYears = Object.keys(data.cumPlan01);
    const columnTemplate = [
      {
        title: t('aipcmcty.page.investAndRepay.breakDown'),
        key: 'node',
        dataIndex: 'node',
        width: 300,
        className: 'text-left',
        fixed: 'left',
      },
      ...columnYears.map((year) => ({
        title: year,
        key: year,
        dataIndex: year,
        className: 'text-right',
        render: (val) => Number((val / 1000000).toFixed(2)).toLocaleString(),
      })),
    ];

    const dataTemplate = [
      {
        key: 'dxAPlan01',
        node: t('aipcmcty.page.investAndRepay.plan01'),
        ...data.cumPlan01,
      },
      {
        key: 'dxAPlan02',
        node: t('aipcmcty.page.investAndRepay.plan02'),
        ...data.cumPlan02,
      },
      {
        key: 'dxAPlanLatest',
        node: t('aipcmcty.page.investAndRepay.planLatest'),
        ...data.cumPlanLatest,
      },
      {
        key: 'dxAForecastActualSum',
        node: t('aipcmcty.page.investAndRepay.forecastAndActual'),
        ...data.cumForecastActualSum,
      },
      {
        key: 'dxAForecastActualSumCont',
        node: `${t('aipcmcty.page.investAndRepay.forecastAndActual')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        ...data.cumForecastActualSumCont,
      },
    ];
    setTableData({
      tableColumns: columnTemplate,
      tableSource: dataTemplate,
    });
  };

  const changeTabHandler = (e: RadioChangeEvent) => {
    const checkValue = e.target.value;
    setTableCategory(checkValue);
    switch (checkValue) {
      case TABLE_CATEGORY.DX_EFFECT:
        initDXEffectTableData(transformedData);
        break;
      case TABLE_CATEGORY.DX_INVESTMENT:
        initDXInvestmentTableData(transformedData);
        break;
      case TABLE_CATEGORY.CUMULATIVE:
        initCumulativeTableData(transformedData);
        break;
    }
  };

  return (
    <>
      {/* <Row justify="space-between" className="operation-container" style={{ backgroundColor: 'white', padding: '0 16px', marginBottom: 2 }}>
        <Col style={{ padding: '5px 0' }}>
          <Space>
            ORG:
            <Select
              style={{ width: 240 }}
              options={filterOpts.orgVersions}
              value={filter.orgVersion}
              onChange={(e) => handleFilterChange(e, 'orgVersion')}
            />
            TGC:
            <Select
              mode="multiple"
              style={{ width: 180 }}
              options={filterOpts.tgcOpts}
              value={filter.tgc}
              maxTagCount="responsive"
              onChange={(e) => handleFilterChange(e, 'tgc')}
            />
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Space>
        </Col>
      </Row> */}
      <ChartTableLayout viewMode={'chart-table'}>
        <ChartTableLayout.Chart>
          <ChartGroup
            defaultItemCount={1}
            defaultHeightCount={2}
            loading={false}
            syncScroll
            height={340}
            viewMode={'chart-table'}
            isScrollChart
            menuCollapsed={menuCollapsed}
            scrollCount={scrollCount}
            setScrollCount={setScrollCount}
            selectorTop={selectorTop}
            sizeMode={'small'}
          >
            <ChartGroup.Item>
              <InvestmentReturnChart data={transformedData} title="" loading={tableLoading} />
            </ChartGroup.Item>
          </ChartGroup>
        </ChartTableLayout.Chart>
        <ChartTableLayout.Table>
          <Card style={{ height: selectorHeight4Table }}>
            <div style={{ paddingBottom: 30, marginBottom: 20 }}>
              <div style={{ marginRight: 20, float: 'right' }}>
                <span style={{ marginRight: 15 }}>{t('aipcmcty.page.investAndRepay.unit')}</span>
                <Radio.Group defaultValue="2024" buttonStyle="solid" onChange={changeTabHandler} value={tableCategory}>
                  <Radio.Button value={TABLE_CATEGORY.DX_EFFECT}>{t('aipcmcty.page.investAndRepay.dxEffect')}</Radio.Button>
                  <Radio.Button value={TABLE_CATEGORY.DX_INVESTMENT}>{t('aipcmcty.page.investAndRepay.dxInvest')}</Radio.Button>
                  <Radio.Button value={TABLE_CATEGORY.CUMULATIVE}>{t('aipcmcty.page.investAndRepay.cum')}</Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <Table
              expandable={{
                defaultExpandedRowKeys: [
                  'dxAPlanLatest',
                  'dxAForecastActualSum',
                  'dxAForecastSum',
                  'dxAForecastActualSumCont',
                  'dxAForecastSumCont',
                ]
              }}
              style={{ background: '#FFF' }}
              scroll={{ x: 1920 - 550 - 100 - 240, y: selectorHeight4Table - 24 * 2 - 50 - 40 }}
              pagination={false}
              columns={tableData.tableColumns}
              dataSource={tableData.tableSource}
              loading={tableLoading}
              className="scroll-table"
              size={'small'}
              bordered
            />
          </Card>
        </ChartTableLayout.Table>
      </ChartTableLayout>
    </>
  );
};

enum TABLE_CATEGORY {
  DX_EFFECT,
  DX_INVESTMENT,
  CUMULATIVE,
}

export default InvestmentReturnPage;
