import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { round } from '../../utils/commonUtil';
import { TableChart } from '@meteor/frontend-core';
import { TransformToFiscalYear } from '../../pages/demo/investment-return';

type ChartProps = {
  title: string;
  data: TransformToFiscalYear;
  loading: boolean;
};

const InvestmentReturnChart: React.FC<ChartProps> = (props) => {
  const { data, title, loading } = props;

  const { t } = useTranslation();

  const [options, setOptions] = useState<any>({
    title: {
      value: title,
      styles: {
        fontSize: 18,
        paddingLeft: 0,
      },
    },
    chartOptions: {
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        appendToBody: true,
        confine: true,
        trigger: 'axis',
        formatter: (params) => {
          return params
            .map((element, index) => {
              const [dataIndex, data, showData = []] = element.data;
              return `
              <div style="text-align: left;">
              ${index === 0 ? `<span style="font-size: 16px;font-weight: bold;">${element.name}</span>` : ''}
              <p style="margin: 0">${element.marker} ${element.seriesName}: ${round(data / 100000000)}</p>
              ${showData
                .map(({ label, value, children = [] }) => {
                  return `<p style="margin: 0 30px">
                  ${label}: ${round(value / 100000000)}
                </p>
                ${
                  children &&
                  children
                    .map(({ label, value }) => {
                      return `<p style="margin: 0 45px">
                  ${label}: ${round(value / 100000000)}
                </p>`;
                    })
                    .join('')
                }
              `;
                })
                .join('')}
            </div >
            `;
            })
            .join('');
        },
      },
      legend: {
        orient: 'vertical',
        top: 0,
        left: 0,
        textStyle: {
          fontSize: 10,
        },
        itemGap: 5,
        itemWidth: 14,
        // type: 'scroll',
        formatter: (name) => {
          return `${name}`;
        },
      },
      grid: {
        left: 260,
        right: 60,
        bottom: 20,
        top: 40,
      },
      yAxis: [
        {
          type: 'value',
          name: (() => {
            return t('aipcmc.charts.hundredMillion');
          })(),
          axisLine: {
            show: true,
            onZero: true,
          },
          axisLabel: {
            fontSize: 12,
            formatter: (value) => {
              return value / 100000000;
            },
          },
          axisTick: {},
          splitLine: {
            show: false,
          },
          nameTextStyle: {
            fontSize: 12,
          },
          nameGap: 5,
          // alignTicks: true,
        },
        {
          type: 'value',
          name: (() => {
            return t('aipcmc.charts.hundredMillion');
          })(),
          axisLine: {
            show: true,
            onZero: true,
          },
          axisLabel: {
            fontSize: 12,
            formatter: (value) => {
              return value / 100000000;
            },
          },
          axisTick: {},
          splitLine: {
            show: false,
          },
          nameTextStyle: {
            fontSize: 12,
          },
          nameGap: 5,
          // alignTicks: true,
        },
      ],
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: 12,
        },
        axisLine: {
          show: true,
        },
        // alignTicks: true,
      },
      series: [],
    },
    height: (window.innerHeight - 100) * 0.4,
  });

  /**
   * chartとtableは再計算必要があります
   */
  useEffect(() => {
    initChartData();
  }, [data]);

  /**
   * 初期化Chartデータ
   */
  const initChartData = () => {
    if (!data) {
      return;
    }
    const xAxisData = Object.keys(data.dxAPlan01);
    options.chartOptions.xAxis.data = xAxisData;
    const bar1Data = Object.values(data.dxAPlan01).map((p, i) => [i, p]);
    const bar2Data = Object.values(data.dxAPlan02).map((p, i) => [i, p]);
    const bar3Data = Object.entries(data.dxAPlanLatest).map(([k, v], i) => [
      i,
      v,
      [
        { label: t('aipcmcty.page.investAndRepay.preSale'), value: data.dxAPlanLatestPreSale[k] },
        { label: t('aipcmcty.page.investAndRepay.proposal'), value: data.dxAPlanLatestProposal[k] },
        { label: t('aipcmcty.page.investAndRepay.execution'), value: data.dxAPlanLatestExecuted[k] },
      ],
    ]);
    const bar4_1Data = Object.entries(data.dxAForecastSum).map(([k, v], i) => [
      i,
      v,
      [
        { label: t('aipcmcty.page.investAndRepay.preSale'), value: data.dxAForecastPreSale[k] },
        { label: t('aipcmcty.page.investAndRepay.proposal'), value: data.dxAForecastProposal[k] },
        { label: t('aipcmcty.page.investAndRepay.execution'), value: data.dxAForecastExecution[k] },
      ],
    ]);
    const bar4_2Data = Object.entries(data.dxAActual).map(([k, v], i) => [
      i,
      v,
      [
        { label: t('aipcmcty.page.investAndRepay.execution'), value: v }
      ]
    ]);
    const bar5_1Data = Object.entries(data.dxAForecastSumCont).map(([k, v], i) => [
      i,
      v,
      [
        { label: t('aipcmcty.page.investAndRepay.preSale'), value: data.dxAForecastPreSaleCont[k] },
        { label: t('aipcmcty.page.investAndRepay.proposal'), value: data.dxAForecastProposalCont[k] },
        { label: t('aipcmcty.page.investAndRepay.execution'), value: data.dxAForecastExecutionCont[k] },
      ],
    ]);
    const bar5_2Data = Object.entries(data.dxAActualCont).map(([k, v], i) => [
      i,
      v,
      [
        { label: t('aipcmcty.page.investAndRepay.execution'), value: v }
      ]
    ]);
    const bar1mData = Object.values(data.dxIPlan01).map((p, i) => [i, p]);
    const bar3mData = Object.values(data.dxIPlanLatest).map((p, i) => [i, p]);
    const bar4mData = Object.values(data.dxIForecastActualSum).map((p, i) => [i, p]);
    const line1Data = Object.values(data.cumPlan01).map((p, i) => [i, p]);
    const line2Data = Object.values(data.cumPlan02).map((p, i) => [i, p]);
    const line3Data = Object.values(data.cumPlanLatest).map((p, i) => [i, p]);
    const line4Data = Object.values(data.cumForecastActualSum).map((p, i) => [i, p]);
    const line5Data = Object.values(data.cumForecastActualSumCont).map((p, i) => [i, p]);
    options.chartOptions.series = [
      {
        name: `${t('aipcmcty.page.investAndRepay.dxEffectCharge')}_${t('aipcmcty.page.investAndRepay.plan01')}`,
        type: 'bar',
        stack: 'bar1',
        // itemStyle: {
        //   color: '#195D87'
        // },
        data: bar1Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxEffectCharge')}_${t('aipcmcty.page.investAndRepay.plan02')}`,
        type: 'bar',
        stack: 'bar2',
        // itemStyle: {
        //   color: '#ED7D31'
        // },
        data: bar2Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxEffectCharge')}_${t('aipcmcty.page.investAndRepay.planLatest')}`,
        type: 'bar',
        stack: 'bar3',
        // itemStyle: {
        //   color: '#A5A5A5'
        // },
        data: bar3Data,
      },
      {
        name: t('aipcmcty.page.investAndRepay.forecast'),
        type: 'bar',
        stack: 'bar4',
        // itemStyle: {
        //   color: '#FFC000'
        // },
        data: bar4_1Data,
      },
      {
        name: t('aipcmcty.page.investAndRepay.actual'),
        type: 'bar',
        stack: 'bar4',
        // itemStyle: {
        //   color: '#BF9000'
        // },
        data: bar4_2Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.forecast')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        type: 'bar',
        stack: 'bar5',
        // itemStyle: {
        //   color: '#9FD9F6'
        // },
        data: bar5_1Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.actual')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        type: 'bar',
        stack: 'bar5',
        // itemStyle: {
        //   color: '#5B9BD5'
        // },
        data: bar5_2Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxInvestAmount')}_${t('aipcmcty.page.investAndRepay.plan01')}`,
        type: 'bar',
        stack: 'bar1',
        // itemStyle: {
        //   color: '#195D87'
        // },
        data: bar1mData,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxInvestAmount')}_${t('aipcmcty.page.investAndRepay.planLatest')}`,
        type: 'bar',
        stack: 'bar3',
        // itemStyle: {
        //   color: '#A5A5A5'
        // },
        data: bar3mData,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.dxInvestAmount')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}`,
        type: 'bar',
        stack: 'bar4',
        // itemStyle: {
        //   color: '#FFC000'
        // },
        data: bar4mData,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.plan01')}`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#195D87'
        // },
        data: line1Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.plan02')}`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#ED7D31'
        // },
        data: line2Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.planLatest')}`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#A5A5A5'
        // },
        data: line3Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#FFC000'
        // },
        data: line4Data,
      },
      {
        name: `${t('aipcmcty.page.investAndRepay.cum')}_${t('aipcmcty.page.investAndRepay.forecastAndActual')}(${t('aipcmcty.page.investAndRepay.contingency')})`,
        type: 'line',
        yAxisIndex: 1,
        // itemStyle: {
        //   color: '#4472C4'
        // },
        data: line5Data,
      },
    ];
    setOptions(cloneDeep(options));
  };

  return (
    <TableChart
      tooltips={[]}
      title={options.title}
      showTable={false}
      chartOptions={options.chartOptions}
      height={options.height}
      loading={loading}
    />
  );
};

export default InvestmentReturnChart;
