import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ceil, cloneDeep, maxBy, remove } from 'lodash';
import Ribbon from '../widget/ribbon';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { AppContext } from '../../contexts/AppContext';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { Switch } from 'antd';

type ChartProps = {
  kpiCode?: string;
  data?: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  tableOptions?: TableOptions;
  showTable?: boolean;
  layoutOptions?: any;
  chartHandler?: (e: any) => any;
};

const StrategyMatrixChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode,
    data,
    height,
    loading,
    title,
    ribbonText,
    ribbonColor,
    ribbonTop,
    showTable = false,
    tableOptions,
    layoutOptions,
    titleLoading,
    coverLoading,
    chartHandler
  } = props;

  const { color, sizeMode } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);
  const { t } = useTranslation();
  const offset = 10;

  const [switchesGroup, setSwitchesGroup] = useState<string[]>(['dxApplicable', 'isEpc']);

  const valuePropositionLegend: any = (switchesGroup) => (
    <div className="strategy-matrix-legend" style={{ height: 20, fontSize: 12 }}>
      {switchesGroup.length > 0 ? (
        <>
          {(() => {
            if (switchesGroup.includes('dxApplicable') && switchesGroup.includes('isEpc')) {
              return (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div style={{ marginTop: 5, marginRight: 0 }}>
                      <div>
                        <span style={{ background: '#1A4F99' }} />
                        <span>{`${t('aipcmcty.page.dxApplicable')}（EPC）`}</span>
                      </div>
                      <div>
                        <span style={{ background: '#3299D9' }} />
                        <span>{`${t('aipcmcty.page.dxApplicable')}（${t('aipcmcty.page.nonEPC')}）`}</span>
                      </div>
                    </div>
                    <div style={{ marginTop: 5, marginRight: 0 }}>
                      <div>
                        <span style={{ background: '#9FD9F6' }} />
                        <span>{`${t('aipcmcty.page.dxNonApplicable')}（EPC）`}</span>
                      </div>
                      <div>
                        <span style={{ background: '#093B5E' }} />
                        <span>{`${t('aipcmcty.page.dxNonApplicable')}（${t('aipcmcty.page.nonEPC')}）`}</span>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
            if (switchesGroup.includes('dxApplicable')) {
              return (
                <>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 5,
                      marginRight: 0,
                      alignItems: 'flex-start',
                    }}
                  >
                    <div>
                      <span style={{ background: '#1A4F99' }} />
                      <span>{t('aipcmcty.page.dxApplicable')}</span>
                    </div>
                    <div>
                      <span style={{ background: '#3299D9' }} />
                      <span>{t('aipcmcty.page.dxNonApplicable')}</span>
                    </div>
                  </div>
                </>
              );
            }
            if (switchesGroup.includes('isEpc')) {
              return (
                <>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 5,
                      marginRight: 0,
                      alignItems: 'flex-start',
                    }}
                  >
                    <div>
                      <span style={{ background: '#1A4F99' }} />
                      <span>EPC</span>
                    </div>
                    <div>
                      <span style={{ background: '#3299D9' }} />
                      <span>{t('aipcmcty.page.nonEPC')}</span>
                    </div>
                  </div>
                </>
              );
            }
            return <></>;
          })()}
        </>
      ) : (
        <></>
      )}
    </div>
  );

  const list = [
    {
      label: `${t('aipcmcty.page.dxApplicable')}/${t('aipcmcty.page.dxNonApplicable')}`,
      key: t('aipcmcty.page.dxApplicable'),
      mark: 'dxApplicable',
      onChange: (checked, switchesGroup) => {
        if (checked) {
          switchesGroup.push('dxApplicable');
          setSwitchesGroup(cloneDeep(switchesGroup));
        } else {
          remove(switchesGroup, (item) => item === 'dxApplicable');
          setSwitchesGroup(cloneDeep(switchesGroup));
        }
      },
    },
    {
      label: `EPC/${t('aipcmcty.page.nonEPC')}`,
      key: `EPC/${t('aipcmcty.page.nonEPC')}`,
      mark: 'isEpc',
      onChange: (checked, switchesGroup) => {
        if (checked) {
          switchesGroup.push('isEpc');
          setSwitchesGroup(cloneDeep(switchesGroup));
        } else {
          remove(switchesGroup, (item) => item === 'isEpc');
          setSwitchesGroup(cloneDeep(switchesGroup));
        }
      },
    },
  ];

  const select = {
    type: 'switches',
    node: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginRight: 5,
        }}
      >
        <div style={{ flex: 1 }}>{valuePropositionLegend(switchesGroup)}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: 120,
            fontSize: 12,
          }}
        >
          {list.map((item, index) => (
            <div
              key={item.key}
              style={{
                marginTop: 5,
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}
            >
              <label style={{ whiteSpace: 'nowrap' }}>{item.label}</label>
              <Switch
                style={{ marginLeft: 5 }}
                checked={switchesGroup.includes(item.mark)}
                onChange={(checked) => {
                  if (item.onChange) {
                    item.onChange(checked, switchesGroup);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
    ),
  };

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: kpiCode === 'ValueProposition' ? 35 : 10,
      gridLeft: kpiCode === 'ValueProposition' ? 50 : 0,
      gridRight: kpiCode === 'ValueProposition' ? 80 : 20,
      gridBottom: kpiCode === 'ValueProposition' ? 40 : 0,
    },
    big: {
      ...sizeMapBase.big,
      gridTop: kpiCode === 'ValueProposition' ? 35 : 20,
      gridLeft: kpiCode === 'ValueProposition' ? 50 : 0,
      gridRight: kpiCode === 'ValueProposition' ? 80 : 20,
      gridBottom: kpiCode === 'ValueProposition' ? 25 : 0,
    },
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.orderAmount'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        appendToBody: true,
        className: 'overflow-all',
        hideDelay: 0,
        trigger: 'item',
        position: (point, params, dom, rect, size) =>
          point[0] < size.contentSize[0] / 2
            ? [point[0] + offset, point[1] + offset]
            : [point[0] - size.contentSize[0] - offset, point[1] + offset],
        formatter: (params) => {
          const {
            name,
            label,
            value,
            orderAmount,
            demandMh,
            dxDiscounted,
            grossMargin,
            operatingProfitPerMp,
            dxDiscountRate,
            budgetCategoryCustom,
            ordersPerCapita,
            grossProfit,
            dxApplicable,
            isEpc,
          } = params.data;
          if (!name) {
            return <></>;
          }
          switch (kpiCode) {
            case 'KPJ001':
              return `<div style="text-align: left;">
                ${t('aipcmcty.page.projectCaseId')}: ${name}<br/> 
                <span style="display: inline-block; width: 300px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                  ${t('aipcmcty.page.projectCaseName')}: ${label}
                </span><br/>
                ${t('aipcmcty.page.budgetCategory')}: ${budgetCategoryCustom}<br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.orderAmount')}: ${round(orderAmount / 100000000) || 0}${t(
                'aipcmcty.page.billion'
              )}（${t('aipcmcty.page.size')}）</span><br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.grossProfitRate')}: ${round(grossMargin * 100)}%（${t(
                'aipcmcty.page.xAxis'
              )}）</span><br/>
                ${t('aipcmcty.page.demand')}MH: ${round(demandMh || 0)}<br/>
                ${t('aipcmcty.page.profitPerPerson')}: ${round(operatingProfitPerMp)}<br/>
                ${t('aipcmcty.page.orderAmountPerPerson')}: ${round(ordersPerCapita)}<br/>
                ${t('aipcmcty.page.dxAllowanceAmount')}: ${round(dxDiscounted || 0)}<br/>
                ${t('aipcmcty.page.DXallowanceDiscountRate')}: ${round(dxDiscountRate * 100)}%<br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.growth')}: ${(() => {
                const growth = value[1];
                if (growth < 4) {
                  return t('aipcmcty.page.existing');
                }
                if (growth < 8) {
                  return `${t('aipcmcty.page.new')}（${t('aipcmcty.page.adjacentArea')}）`;
                }
                if (growth < 12) {
                  return `${t('aipcmcty.page.new')}（${t('aipcmcty.page.innovation')}）`;
                }
                return '';
              })()}（${t('aipcmcty.page.yAxis')}）</span><br/>
                </div>`;
            case 'KRS001':
              return `<div style="text-align: left;">
                ${t('aipcmcty.page.projectCaseId')}: ${name}<br/> 
                <span style="display: inline-block; width: 300px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                  ${t('aipcmcty.page.projectCaseName')}: ${label}
                </span><br/>
                ${t('aipcmcty.page.budgetCategory')}: ${budgetCategoryCustom}<br/>
                ${t('aipcmcty.page.orderAmount')}: ${round(orderAmount / 100000000) || 0}${t('aipcmcty.page.billion')}<br/>
                ${t('aipcmcty.page.grossProfitRate')}: ${round(grossMargin * 100)}%<br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.demand')}MH: ${round(demandMh || 0)}（${t(
                'aipcmcty.page.size'
              )}）</span><br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.profitPerPerson')}: ${round(operatingProfitPerMp)}（${t(
                'aipcmcty.page.xAxis'
              )}）</span><br/>
                ${t('aipcmcty.page.orderAmountPerPerson')}: ${round(ordersPerCapita)}<br/>
                ${t('aipcmcty.page.dxAllowanceAmount')}: ${round(dxDiscounted || 0)}<br/>
                ${t('aipcmcty.page.DXallowanceDiscountRate')}: ${round(dxDiscountRate * 100)}%<br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.growth')}: ${(() => {
                const growth = value[1];
                if (growth < 4) {
                  return t('aipcmcty.page.existing');
                }
                if (growth < 8) {
                  return `${t('aipcmcty.page.new')}（${t('aipcmcty.page.adjacentArea')}）`;
                }
                if (growth < 12) {
                  return `${t('aipcmcty.page.new')}（${t('aipcmcty.page.innovation')}）`;
                }
                return '';
              })()}（${t('aipcmcty.page.yAxis')}）</span><br/>
                </div>`;
            case 'KDX001':
              return `<div style="text-align: left;">
                ${t('aipcmcty.page.projectCaseId')}: ${name}<br/> 
                <span style="display: inline-block; width: 300px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                  ${t('aipcmcty.page.projectCaseName')}: ${label}
                </span><br/>
                ${t('aipcmcty.page.budgetCategory')}: ${budgetCategoryCustom}<br/>
                ${t('aipcmcty.page.orderAmount')}: ${round(orderAmount / 100000000) || 0}${t('aipcmcty.page.billion')}<br/>
                ${t('aipcmcty.page.grossProfitRate')}: ${round(grossMargin * 100)}%<br/>
                ${t('aipcmcty.page.demand')}MH: ${round(demandMh || 0)}<br/>
                ${t('aipcmcty.page.profitPerPerson')}: ${round(operatingProfitPerMp)}<br/>
                ${t('aipcmcty.page.orderAmountPerPerson')}: ${round(ordersPerCapita)}<br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.dxAllowanceAmount')}: ${round(dxDiscounted || 0)}（${t(
                'aipcmcty.page.size'
              )}）</span><br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.DXallowanceDiscountRate')}: ${round(dxDiscountRate * 100)}%（${t(
                'aipcmcty.page.xAxis'
              )}）</span><br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.growth')}: ${(() => {
                const growth = value[1];
                if (growth < 4) {
                  return t('aipcmcty.page.existing');
                }
                if (growth < 8) {
                  return `${t('aipcmcty.page.new')}（${t('aipcmcty.page.adjacentArea')}）`;
                }
                if (growth < 12) {
                  return `${t('aipcmcty.page.new')}（${t('aipcmcty.page.innovation')}）`;
                }
                return '';
              })()}（${t('aipcmcty.page.yAxis')}）</span>
                </div>`;
            case 'ValueProposition':
              return `<div style="text-align: left;">
                ${t('aipcmcty.page.projectCaseId')}: ${name}<br/> 
                <span style="display: inline-block; width: 300px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                  ${t('aipcmcty.page.projectCaseName')}: ${label}
                </span><br/>
                ${t('aipcmcty.page.budgetCategory')}: ${budgetCategoryCustom}<br/>
                ${t('aipcmcty.page.orderAmount')}: ${round(orderAmount / 100000000) || 0}${t('aipcmcty.page.billion')}<br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.grossProfitRate')}: ${round(grossMargin * 100)}%（${t(
                'aipcmcty.page.yAxis'
              )}）</span><br/>
                ${t('aipcmcty.page.demand')}MH: ${round(demandMh || 0)}<br/>
                ${t('aipcmcty.page.profitPerPerson')}: ${round(operatingProfitPerMp)}<br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.orderAmountPerPerson')}: ${round(ordersPerCapita)}（${t(
                'aipcmcty.page.xAxis'
              )}）</span><br/>
                ${t('aipcmcty.page.dxAllowanceAmount')}: ${round(dxDiscounted || 0)}<br/>
                ${t('aipcmcty.page.DXallowanceDiscountRate')}: ${round(dxDiscountRate * 100)}%<br/>
                <span style="font-weight: bolder">${t('aipcmcty.page.grossProfitAmt')}: ${round(grossProfit || 0)}（${t(
                'aipcmcty.page.size'
              )}）</span><br/>
                ${dxApplicable ? t('aipcmcty.page.dxApplicable') : t('aipcmcty.page.dxNonApplicable')}<br/>
                ${isEpc ? 'EPC' : t('aipcmcty.page.nonEPC')}
                </div>`;
            default:
          }
          return '';
        },
        // position: ['10%', '15%'],
        // position: 'top',
      },
      legend: {
        top: 5,
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        data: [],
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      yAxis: {
        show: false,
        type: 'value',
      },
      xAxis: {
        show: false,
        type: 'value',
        scale: true,
        inverse: kpiCode === 'KDX001',
      },
      series: [],
    },
    height: 220,
  });

  useEffect(() => {
    initData();
  }, [data]);

  useEffect(() => {
    initChartData();
  }, [switchesGroup]);

  const initData = () => {
    initChartData();
  };

  const initChartData = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    const seriesDataList = [];
    const maxValue: any = maxBy(mart, 'value');
    mart.forEach(
      ({
        projectId,
        projectName,
        value,
        productivity,
        growth,
        orderAmount,
        demandMh,
        dxDiscounted,
        grossMargin,
        operatingProfitPerMp,
        dxDiscountRate,
        budgetCategoryCustom,
        ordersPerCapita,
        grossProfit,
        dxApplicable,
        isEpc,
      }) => {
        seriesDataList.push({
          name: projectId,
          label: projectName,
          value: [productivity, growth],
          orderAmount,
          demandMh,
          dxDiscounted,
          grossMargin,
          operatingProfitPerMp,
          dxDiscountRate: dxDiscountRate || 0,
          budgetCategoryCustom,
          ordersPerCapita,
          grossProfit,
          dxApplicable,
          isEpc,
          symbolSize: (() => {
            const size = value / ((maxValue?.value || 0) / 40);
            if (size < 5) {
              return 5;
            }
            return size;
          })(),
          itemStyle: {
            color: (() => {
              if (kpiCode === 'ValueProposition') {
                if (switchesGroup.length > 0) {
                  if (switchesGroup.includes('dxApplicable') && switchesGroup.includes('isEpc')) {
                    if (dxApplicable && isEpc === 'EPC') {
                      return '#1A4F99';
                    }
                    if (dxApplicable && isEpc === '非EPC') {
                      return '#3299D9';
                    }
                    if (!dxApplicable && isEpc === 'EPC') {
                      return '#9FD9F6';
                    }
                    if (!dxApplicable && isEpc === '非EPC') {
                      return '#093B5E';
                    }
                  }
                  if (switchesGroup.includes('dxApplicable')) {
                    if (dxApplicable) {
                      return '#1A4F99';
                    }
                    if (!dxApplicable) {
                      return '#3299D9';
                    }
                  }
                  if (switchesGroup.includes('isEpc')) {
                    if (isEpc === 'EPC') {
                      return '#1A4F99';
                    }
                    if (isEpc === '非EPC') {
                      return '#3299D9';
                    }
                  }
                } else {
                  return '#1A4F99';
                }
              } else {
                return budgetCategoryCustom === 'Budget' ? '#3299D9' : '#093B5E';
              }
            })(),
          },
        });
      }
    );
    const sortedData = seriesDataList.sort((a, b) => b.symbolSize - a.symbolSize);
    const sortedSeries = sortedData.map((item, index) => ({
      symbol: 'circle',
      type: 'scatter',
      data: [item],
      emphasis: {
        scale: true,
        focus: 'self',
        z: 999,
      },
      itemStyle: {
        color: color.primaryColor,
      },
      z: 9 + index,
    }));
    options.chartOptions.series = sortedSeries;
    if (kpiCode === 'KPJ001') {
      createLineBlock(0.04, 0, 0.16, 12);
    }
    if (kpiCode === 'KRS001') {
      createLineBlock(-1500000, 0, 3000000, 12);
    }
    if (kpiCode === 'KDX001') {
      createLineBlockInverse(0, 0, 1, 12);
    }
    if (kpiCode === 'ValueProposition') {
      options.chartOptions.xAxis = {
        type: 'value',
        max: 1000000000,
        min: 10000000,
        name: `${t('aipcmcty.page.perPerson')}\n${t('aipcmcty.page.orderAmt')}（${t('aipcmcty.page.billion')}）`,
        nameTextStyle: {
          width: 40,
          overflow: 'break',
        },
        axisLabel: {
          formatter: (value) => (value / 100000000).toLocaleString(),
        },
      };
      options.chartOptions.yAxis = {
        type: 'value',
        max: 0.75,
        name: t('aipcmcty.page.grossProfitRate'),
        axisLabel: {
          formatter: (value) => `${value * 100}%`,
        },
      };
      options.chartOptions.series.push({
        name: 'markLine',
        type: 'scatter',
        zlevel: 9 + sortedData.length,
        z: 9 + sortedData.length,
        markLine: {
          symbol: ['none', 'none'],
          label: {
            show: true,
            formatter: () => t('aipcmcty.page.breakEvenThreshold'),
            color: '#ff6666',
            distance: 5,
          },
          lineStyle: {
            color: '#ff6666',
            width: 2,
          },
          data: [{ xAxis: 46000000 }],
          animation: false,
        },
      });
    }
    setOptions(cloneDeep(options));
  };

  const createLineBlock = (xMin, yMin, xMax, yMax) => {
    const pX0 = xMin;
    const pY0 = yMin;
    const pX1 = (xMax - xMin) / 3 + xMin;
    const pY1 = (yMax - yMin) / 3 + yMin;
    const pX2 = ((xMax - xMin) / 3) * 2 + xMin;
    const pY2 = ((yMax - yMin) / 3) * 2 + yMin;
    const pX3 = ceil(xMax, 2);
    const pY3 = ((yMax - yMin) / 3) * 3 + yMin;
    const pY4 = ceil(yMax, 2);

    const block1 = {
      type: 'line',
      data: [
        [pX0, pY0],
        [pX1, pY0],
        [pX1, pY1],
        [pX0, pY1],
        [pX0, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: 'gray',
        width: 1,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: '#E79894',
        opacity: 1,
      },
      animation: false,
      z: 3,
    };
    const block2 = {
      type: 'line',
      data: [
        [pX1, pY0],
        [pX2, pY0],
        [pX2, pY1],
        [pX1, pY1],
        [pX1, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#aaa',
        width: 1,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: '#E79894',
        opacity: 1,
      },
      animation: false,
      z: 2,
    };
    const block3 = {
      type: 'line',
      data: [
        [pX2, pY0],
        [pX3, pY0],
        [pX3, pY1],
        [pX2, pY1],
        [pX2, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: 'lightgray',
        width: 1,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: '#FCE490',
        opacity: 1,
      },
      animation: false,
      z: 1,
    };
    const block4 = {
      type: 'line',
      data: [
        [pX0, pY1],
        [pX1, pY1],
        [pX1, pY2],
        [pX0, pY2],
        [pX0, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#E79894',
      },
      animation: false,
      z: 1,
    };
    const block5 = {
      type: 'line',
      data: [
        [pX1, pY1],
        [pX2, pY1],
        [pX2, pY2],
        [pX1, pY2],
        [pX1, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#FCE490',
      },
      animation: false,
      z: 1,
    };
    const block6 = {
      type: 'line',
      data: [
        [pX2, pY1],
        [pX3, pY1],
        [pX3, pY2],
        [pX2, pY2],
        [pX2, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#9DBF9D',
      },
      animation: false,
      z: 1,
    };
    const block7 = {
      type: 'line',
      data: [
        [pX0, pY2],
        [pX1, pY2],
        [pX1, pY3],
        [pX0, pY3],
        [pX0, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#FCE490',
      },
      animation: false,
      z: 1,
    };
    const block8 = {
      type: 'line',
      data: [
        [pX1, pY2],
        [pX2, pY2],
        [pX2, pY3],
        [pX1, pY3],
        [pX1, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#9DBF9D',
      },
      animation: false,
      z: 1,
    };
    const block9 = {
      type: 'line',
      data: [
        [pX2, pY2],
        [pX3, pY2],
        [pX3, pY3],
        [pX2, pY3],
        [pX2, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#9DBF9D',
      },
      animation: false,
      z: 1,
    };

    options.chartOptions.xAxis.min = pX0 * 0.99;
    options.chartOptions.xAxis.max = pX3 * 1.01;
    options.chartOptions.yAxis.min = pY0 * 0.99;
    options.chartOptions.yAxis.max = pY4 * 1.01;
    const series = [...options.chartOptions.series, block1, block2, block3, block4, block5, block6, block7, block8, block9];
    options.chartOptions.series = series;
  };

  const createLineBlockInverse = (xMin, yMin, xMax, yMax) => {
    const pX0 = xMin;
    const pY0 = yMin;
    const pX1 = (xMax - xMin) / 3 + xMin;
    const pY1 = (yMax - yMin) / 3 + yMin;
    const pX2 = ((xMax - xMin) / 3) * 2 + xMin;
    const pY2 = ((yMax - yMin) / 3) * 2 + yMin;
    const pX3 = xMax;
    const pY3 = ((yMax - yMin) / 3) * 3 + yMin;
    const pY4 = yMax;

    const block1 = {
      type: 'line',
      data: [
        [pX0, pY0],
        [pX1, pY0],
        [pX1, pY1],
        [pX0, pY1],
        [pX0, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: 'gray',
        width: 1,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: '#FCE490',
        opacity: 1,
      },
      animation: false,
      z: 3,
    };
    const block2 = {
      type: 'line',
      data: [
        [pX1, pY0],
        [pX2, pY0],
        [pX2, pY1],
        [pX1, pY1],
        [pX1, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#aaa',
        width: 1,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: '#E79894',
        opacity: 1,
      },
      animation: false,
      z: 2,
    };
    const block3 = {
      type: 'line',
      data: [
        [pX2, pY0],
        [pX3, pY0],
        [pX3, pY1],
        [pX2, pY1],
        [pX2, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: 'lightgray',
        width: 1,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        color: '#E79894',
        opacity: 1,
      },
      animation: false,
      z: 1,
    };
    const block4 = {
      type: 'line',
      data: [
        [pX0, pY1],
        [pX1, pY1],
        [pX1, pY2],
        [pX0, pY2],
        [pX0, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#9DBF9D',
      },
      animation: false,
      z: 1,
    };
    const block5 = {
      type: 'line',
      data: [
        [pX1, pY1],
        [pX2, pY1],
        [pX2, pY2],
        [pX1, pY2],
        [pX1, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#FCE490',
      },
      animation: false,
      z: 1,
    };
    const block6 = {
      type: 'line',
      data: [
        [pX2, pY1],
        [pX3, pY1],
        [pX3, pY2],
        [pX2, pY2],
        [pX2, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#E79894',
      },
      animation: false,
      z: 1,
    };
    const block7 = {
      type: 'line',
      data: [
        [pX0, pY2],
        [pX1, pY2],
        [pX1, pY3],
        [pX0, pY3],
        [pX0, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#9DBF9D',
      },
      animation: false,
      z: 1,
    };
    const block8 = {
      type: 'line',
      data: [
        [pX1, pY2],
        [pX2, pY2],
        [pX2, pY3],
        [pX1, pY3],
        [pX1, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#9DBF9D',
      },
      animation: false,
      z: 1,
    };
    const block9 = {
      type: 'line',
      data: [
        [pX2, pY2],
        [pX3, pY2],
        [pX3, pY3],
        [pX2, pY3],
        [pX2, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        opacity: 0,
        color: '#E3E3E3 ',
        width: 2,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: '#FCE490',
      },
      animation: false,
      z: 1,
    };
    options.chartOptions.xAxis.min = pX0;
    options.chartOptions.xAxis.max = pX3;
    options.chartOptions.yAxis.min = pY0;
    options.chartOptions.yAxis.max = pY4;
    const series = [...options.chartOptions.series, block1, block2, block3, block4, block5, block6, block7, block8, block9];
    options.chartOptions.series = series;
  };

  const handleChartEvent = e => {
    if (e && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        setSwitchesGroup(['dxApplicable', 'isEpc']);
        initData();
      }
    }
    if (chartHandler) {
      chartHandler(e);
    }
  }

  const strategyMatrixLegend: any = (
    <div
      className="strategy-matrix-legend"
      style={{
        position: 'relative',
        left: '-70px',
        width: 'calc(100% + 70px)',
      }}
    >
      <div>
        <span style={{ background: '#3299D9' }} />
        <span>Budget</span>
      </div>
      <div>
        <span style={{ background: '#093B5E' }} />
        <span>Awarded</span>
      </div>
    </div>
  );

  return (
    <>
      {ribbonText ? (
        <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantity')} top={ribbonTop} color={ribbonColor || color.primaryColor} />
      ) : (
        <></>
      )}
      <TableChart
        select={kpiCode === 'ValueProposition' ? select : null}
        showTable={showTable}
        chartOptions={options.chartOptions}
        title={
          title
            ? {
                value: title,
                styles: {
                  fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : options.title
        }
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        // isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        layoutOptions={
          layoutOptions
            ? {
                ...layoutOptions,
                size: {
                  xAxis: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
                  yAxis: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
                },
              }
            : undefined
        }
        strategyMatrixLegend={strategyMatrixLegend}
        // switchesGroup={switchesGroup}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default StrategyMatrixChart;
