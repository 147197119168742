/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';

type RibbonProp = {
  text: string;
  color?: string;
  top?: number;
}

const Ribbon: React.FC<any> = (props: RibbonProp) => {
  const { text, color, top = 15 } = props;
  const context: any = useContext(AppContext);

  return (
    <>
      <div className={context.sizeMode === 'big' ? 'big-view ribbon' : 'small-view ribbon'} style={{ top }}>
        <span style={{ background: color || context.color.primaryColor, color: '#fff' }}>{text}</span>
      </div>
    </>
  );
};

export default Ribbon;
