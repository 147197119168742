/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable radix */
/* eslint-disable react/require-default-props */
import { InputNumber } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { cloneDeep, isFinite } from 'lodash';
import VirtualTable from '../widget/virtual-table';
import { ProjectSetupDetailContext } from '../../contexts/project-setup-detail.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import { round } from '../../utils/commonUtil';

type ProjectSetupDetailTableData = {
  tgcTags: string[];
  feedOrEpcTags: string[];
  timeline: string[];
  data: any;
  loading: boolean;
  demandForecastType: string;
};

const ProjectSetupDetailTable: React.FC<ProjectSetupDetailTableData> = (props) => {
  const { tgcTags, feedOrEpcTags, timeline, data, loading, demandForecastType } = props;

  const {
    formationList,
    indiaList,
    searchDemandForm,
    demandData,
    setDemandData,
    tgcSwitch,
    setTgcSwitch,
    feedOrEpcSwitch,
    setFeedOrEpcSwitch,
  } = useContext(ProjectSetupDetailContext);

  const { selectorTop } = useWindowSize({
    selector: '.cmcty-modal-body .cmcty-table-body',
  });

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const instance = useRef(null);

  useEffect(() => {
    setTableColumns();
  }, [tgcSwitch, formationList, indiaList, tableData, demandForecastType]);

  useEffect(() => {
    if (!searchDemandForm) {
      return;
    }
    const { division, discipline, category, stage, roles } = searchDemandForm;

    const filterMap = {
      division: (item) => (division.length > 0 ? division.includes(item.division) : true),
      discipline: (item) => (discipline.length > 0 ? discipline.includes(item.discipline) : true),
      category: (item) => (category.length > 0 ? category.includes(item.category) : true),
      stage: (item) => (stage.length > 0 ? stage.includes(item.stage) : true),
      roles: (item) => (roles.length > 0 ? roles.includes(item.role) : true),
      feedOrEpcSwitch: (item) => (feedOrEpcSwitch ? item.feedOrEpc === feedOrEpcSwitch : true),
    };

    const filterData = data.filter((item) => Object.keys(filterMap).every((key) => filterMap[key](item)));
    setTableData(filterData);
  }, [data, searchDemandForm, feedOrEpcSwitch]);

  useEffect(() => {
    if (tgcTags && tgcTags.length > 0) {
      setTgcSwitch(tgcTags[0]);
    }
  }, [tgcTags]);

  useEffect(() => {
    if (feedOrEpcTags && feedOrEpcTags.length > 0) {
      setFeedOrEpcSwitch(feedOrEpcTags[0]);
    } else {
      setFeedOrEpcSwitch('');
    }
  }, [feedOrEpcTags]);

  const renderNumber = (text, record, key, disabled) => (
    <InputNumber
      disabled={disabled}
      min={0}
      style={{ width: 80 }}
      value={round(text)}
      onChange={(value) => {
        tableData.forEach((item) => {
          if (item.key === record.key) {
            item[key] = round(value);
          }
        });
        setTableData(cloneDeep(tableData));

        // process demandData
        const id = record[`id@${key}`];
        const tgc = key.split('#')[0];
        const date = key.split('#')[1];
        const year = date.split('-')[0];
        const month = date.split('-')[1];
        const findDemandData = demandData.find((item) => id && item.id === id);
        if (findDemandData) {
          findDemandData.resourceDemandMh = round(value);
        } else {
          demandData.push({
            snapshot: record.snapshot,
            snapshotVersion: record.snapshotVersion,
            projectId: record.projectId,
            tgc: tgc,
            division: record.division,
            discipline: record.discipline,
            category: record.category,
            stage: record.stage,
            role: record.role,
            jobType: 'execution',
            year,
            month,
            resourceDemandMh: round(value),
          });
        }

        setDemandData(cloneDeep(demandData));
      }}
    />
  );

  const setTableColumns = () => {
    const baseColumns = [
      {
        title: 'Demand',
        dataIndex: 'Demand',
        key: 'Demand',
        children: [
          {
            title: 'Division',
            dataIndex: 'division',
            key: 'division',
            width: 120,
            ellipsis: true,
            render: (text) => text || '-',
            // fixed: 'left'
          },
          {
            title: 'Discipline',
            dataIndex: 'discipline',
            key: 'discipline',
            width: 80,
            ellipsis: true,
            render: (text) => text || '-',
            // fixed: 'left'
          },
          {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: 160,
            ellipsis: true,
            render: (text) => text || '-',
            // fixed: 'left'
          },
          {
            title: 'Stage',
            dataIndex: 'stage',
            key: 'stage',
            width: 180,
            ellipsis: true,
            render: (text) => text || '-',
            // fixed: 'left'
          },
          {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: 60,
            ellipsis: true,
            render: (text) => text || '-',
            // fixed: 'left'
          },
        ],
      },
      {
        title: 'Formation',
        dataIndex: 'formation',
        key: 'formation',
        children: [
          {
            title: 'JV',
            dataIndex: 'jvRatio',
            key: 'jvRatio',
            width: 110,
            className: 'text-right',
            render: (text, record) => (isFinite(text) ? round(text * 100) : '-'),
            // render: (text, record) => (
            // <InputNumber
            //   disabled={!demandForecastType?.includes('effimate')}
            //   min={0} max={100} value={round(text * 100)} onChange={(value) => {
            //     tableData.forEach((item) => {
            //       if (item.key === record.key) {
            //         item.jvRatio = round(value / 100);
            //       }
            //     });
            //     setTableData(cloneDeep(tableData));

            //     // process formationData
            //     const findFormationData = formationData.find((item) => item.id === record.id);
            //     findFormationData.jvRatio = round(value / 100);
            //     setFormationData(cloneDeep(formationData));
            //   }}
            // />
            // )
          },
          {
            title: 'Toyo-J',
            dataIndex: 'japanRatio',
            key: 'japanRatio',
            width: 110,
            className: 'text-right',
            render: (text, record) => (isFinite(text) ? round(text * 100) : '-'),
            // fixed: 'left',
            // render: (text, record) => (
            // <InputNumber
            //   disabled={!demandForecastType?.includes('effimate')}
            //   min={0} max={100} value={round(text * 100)} onChange={(value) => {
            //     tableData.forEach((item) => {
            //       if (item.key === record.key) {
            //         item.japanRatio = round(value / 100);
            //         item.indiaRatio = round(1 - value / 100);
            //       }
            //     });
            //     setTableData(cloneDeep(tableData));

            //     // process formationData
            //     const findFormationData = formationData.find((item) => item.id === record.id);
            //     findFormationData.japanRatio = round(value / 100);
            //     findFormationData.indiaRatio = round(1 - value / 100);
            //     setFormationData(cloneDeep(formationData));
            //   }}
            // />
            // )
          },
          {
            title: 'Toyo-I',
            dataIndex: 'indiaRatio',
            key: 'indiaRatio',
            width: 110,
            className: 'text-right',
            render: (text, record) => (isFinite(text) ? round(text * 100) : '-'),
            // fixed: 'left',
            // render: (text, record) => (
            // <InputNumber
            //   disabled={!demandForecastType?.includes('effimate')}
            //   min={0} max={100} value={round(text * 100)} onChange={(value) => {
            //     tableData.forEach((item) => {
            //       if (item.key === record.key) {
            //         item.indiaRatio = round(value / 100);
            //         item.japanRatio = round(1 - value / 100);
            //       }
            //     });
            //     setTableData(cloneDeep(tableData));

            //     // process formationData
            //     const findFormationData = formationData.find((item) => item.id === record.id);
            //     findFormationData.indiaRatio = round(value / 100);
            //     findFormationData.japanRatio = round(1 - value / 100);
            //     setFormationData(cloneDeep(formationData));
            //   }}
            // />
            // )
          },
        ],
      },
      {
        title: () => (
          <>
            {/* <Space split={<Divider type="vertical" />}>
            <Radio.Group
              onChange={(e) => {
                setFeedOrEpcSwitch(e.target.value);
              }}
              value={feedOrEpcSwitch}
              size="small"
              // style={{ position: 'absolute', left: 100, top: 7 }}
            >
              {feedOrEpcTags.map((tag) => <Radio.Button key={tag} value={tag} style={{ width: 100 }}>{tag}</Radio.Button>)}
            </Radio.Group>
            <Radio.Group
              onChange={(e) => {
                setTgcSwitch(e.target.value);
              }}
              value={tgcSwitch}
              size="small"
              // style={{ position: 'absolute', left: 220, top: 7 }}
            >
              {tgcTags.map((tag) => <Radio.Button key={tag} value={tag} style={{ width: 100 }}>{tag}</Radio.Button>)}
            </Radio.Group>
          </Space> */}
          </>
        ),
        dataIndex: 'Japan',
        key: 'Japan',
        className: 'formation-tag-title',
        children: [],
      },
    ];

    baseColumns[baseColumns.length - 1].children = timeline.map((item, index) => ({
      title: item,
      dataIndex: `${tgcSwitch}#${item}`,
      key: `${tgcSwitch}#${item}`,
      width: 90,
      className: 'text-right',
      render: (text, record) => (isFinite(text) ? round(text) : '-'),
      // render: (text, record) => renderNumber(text, record, `${tgcSwitch}#${item}`, !demandForecastType?.includes('effimate')),
    }));
    setColumns(baseColumns);
  };

  return (
    <>
      <div className="table-container" style={{ marginTop: 5 }}>
        <VirtualTable
          size="small"
          loading={loading}
          columns={columns}
          dataSource={tableData}
          scroll={{ y: document.body.clientHeight - selectorTop - 60 }}
        />
        {/* <Table
          // className="pagination-style-initial"
          ref={instance}
          scroll={{ y: window.innerHeight - selectorTop - 60 }}
          pagination={{ position: ['bottomRight'], pageSize: 6, showSizeChanger: false }}
          columns={columns}
          dataSource={tableData}
          size="small"
          loading={loading}
        /> */}
      </div>
    </>
  );
};

export default ProjectSetupDetailTable;
