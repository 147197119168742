import { Button, Col, InputNumber, Row, Select, Space, Switch, Table, TablePaginationConfig, Tabs, Tooltip } from 'antd';
import { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { BarsOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { orderBy, sortBy } from 'lodash';
import DrawerContainer, { DrawerProperties } from '../../components/widget/drawer-container';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { AppContext } from '../../contexts/AppContext';
import APIList from '../../http/ApiList';
import { convertToJSON } from '../../utils/commonUtil';
import CustomerScrollBar from '../../components/widget/customer-scrollbar';
import PFMatrixChart from '../../components/charts/pf-matrix.chart';
import { useWindowSize } from '../../hooks/useWindowSize';
import useVersion from '../../hooks/useVersion';

const filterColSpanMap = {
  label: 10,
  item: 14,
};

const drawerOpts: Omit<DrawerProperties, 'collapsed' | 'children'> = {
  maxWidth: 340,
  minWidth: 0,
};

const PortfolioMonitor = () => {
  const { selectorHeight4Table } = useWindowSize({
    selector: '.portfolio-table .cmcty-table-body',
    viewMode: 'chart-table',
  });
  const { t } = useTranslation();
  const { setMenuCollapsed } = useContext(AppContext);

  const [pageInit, setPageInit] = useState(false);
  const { snapshot, snapshotVersion, compareVersion, snapshotList, versionOptCollection, setCompareVersion } = useVersion(pageInit);

  const tarTableRef = useRef<any>();
  const curTableRef = useRef<any>();
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 200,
  });

  const [snapshotValue, setSnapshotValue] = useState(compareVersion.snapshot);
  const [versionValue, setVersionValue] = useState(compareVersion.snapshotVersion);

  const [loading, setLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState({
    [PORTFOLIO_TYPE.CURRENT]: [],
    [PORTFOLIO_TYPE.TARGET]: [],
  });
  const [dataProvider, setDataProvider] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const curChartRef = useRef<any>();
  const tarChartRef = useRef<any>();
  const [state, dispatch] = useReducer(
    (s, action) => {
      const { curChartRef: cChartRef, tarChartRef: tChartRef } = s;
      const { type, event } = action;
      const curInstance = cChartRef.current?.getEchartsInstance();
      const tarInstance = tChartRef.current?.getEchartsInstance();
      let finalDataIndex = -1;
      let finalSeriesIndex = -1;
      switch (type) {
        case 'curFocusAction':
          if (tarInstance) {
            const curProjectId = event.value[3];
            tarInstance.getOption().series.forEach((seriesInfo, seriesIndex) => {
              seriesInfo.data.forEach((item, dataIndex) => {
                const tarProjectId = item[3];
                if (curProjectId === tarProjectId) {
                  finalSeriesIndex = seriesIndex;
                  finalDataIndex = dataIndex;
                }
              });
            });
            if (finalSeriesIndex > -1 && finalDataIndex > -1) {
              tarInstance.dispatchAction({
                type: 'showTip',
                seriesIndex: finalSeriesIndex,
                dataIndex: finalDataIndex,
              });
              tarInstance.dispatchAction({
                type: 'highlight',
                seriesIndex: finalSeriesIndex,
                dataIndex: finalDataIndex,
              });
            }
          }
          return s;
        case 'tarFocusAction':
          if (curInstance) {
            const tarProjectId = event.value[3];
            curInstance.getOption().series.forEach((seriesInfo, seriesIndex) => {
              seriesInfo.data.forEach((item, dataIndex) => {
                const curProjectId = item[3];
                if (tarProjectId === curProjectId) {
                  finalSeriesIndex = seriesIndex;
                  finalDataIndex = dataIndex;
                }
              });
            });
            if (finalSeriesIndex > -1 && finalDataIndex > -1) {
              curInstance.dispatchAction({
                type: 'showTip',
                seriesIndex: finalSeriesIndex,
                dataIndex: finalDataIndex,
              });
              curInstance.dispatchAction({
                type: 'highlight',
                seriesIndex: finalSeriesIndex,
                dataIndex: finalDataIndex,
              });
            }
          }
          return s;
        case 'clearTip':
          curInstance?.dispatchAction({
            type: 'hideTip',
          });
          tarInstance?.dispatchAction({
            type: 'hideTip',
          });
          curInstance?.dispatchAction({
            type: 'downplay',
          });
          tarInstance?.dispatchAction({
            type: 'downplay',
          });
          return s;
      }
    },
    {
      curChartRef,
      tarChartRef,
    }
  );

  const [options, setOptions] = useState({
    accountingYears: [],
    budgets: [],
    types: [],
    dxApplicable: [],
  });

  const [filter, setFilter] = useState({
    accountingYears: [],
    budgets: [],
    types: [],
    dx: false,
    dxApplicable: [],
    grossProfitMin: 0,
    grossProfitMax: 2500000000,
    mhGrossMin: 0,
    mhGrossMax: 30000,
    pgPerMhTop: null,
  });

  const handleFilterChange = (e, type: string) => {
    setFilter((pFilter) => ({ ...pFilter, [type]: e }));
  };

  const handleVersionChange = (key: string, value: any) => {
    if (key === 'snapshotVersion') {
      setVersionValue(value);
    }
    if (key === 'snapshot') {
      setSnapshotValue(value);
      setVersionValue('default');
    }
  };

  const getTableChartData = async (sn: string, sv: string, compSn: string, compSv: string) => {
    const response = await APIList.getPortfolio().post({
      dxOn: filter.dx,
      curSnapshot: sn,
      curVersion: sv,
      tarSnapshot: compSn,
      tarVersion: compSv,
    });
    const data = convertToJSON(response).filter((d) => d.currentIsEpc || d.targetIsEpc);
    const optionAYears = Array.from(new Set([...data.map((d) => d.currentAccountingYear), ...data.map((d) => d.targetAccountingYear)]))
      .sort((a, b) => (a < b ? -1 : 1))
      .map((v) => ({ label: v, key: v, value: v }))
      .filter((o) => !!o.value);
    const optionBudgets = Array.from(
      new Set([...data.map((d) => d.currentBudgetCategoryCustom), ...data.map((d) => d.targetBudgetCategoryCustom)]),
      (v) => ({ label: v, key: v, value: v })
    ).filter((o) => !!o.value);
    const optionTypes = Array.from(new Set([...data.map((d) => d.currentIsEpc), ...data.map((d) => d.targetIsEpc)]), (v) => ({
      label: v,
      key: v,
      value: v,
    })).filter((o) => !!o.value);
    const optionDXApplicable = Array.from(
      new Set([...data.map((d) => d.currentDxScopeStatus), ...data.map((d) => d.targetDxScopeStatus)]),
      (v) => ({ label: v, key: v, value: v })
    ).filter((o) => !!o.value);
    const categoryOrder = ['Awarded', 'Budget', 'IF', 'Others'];
    const newOptions = {
      ...options,
      accountingYears: optionAYears,
      budgets: sortBy(optionBudgets, (item) => categoryOrder.indexOf(item.value)),
      types: optionTypes,
      dxApplicable: optionDXApplicable,
    };
    setOptions(newOptions);

    initTableColumns();
    setDataProvider(data);
  };

  const initData = async () => {
    setLoading(true);
    await getTableChartData(snapshot, snapshotVersion, compareVersion.snapshot, compareVersion.snapshotVersion);
    setLoading(false);
    setPageInit(true);
  };

  useEffect(() => {
    if (snapshot && snapshotVersion && compareVersion.snapshot && compareVersion.snapshotVersion) {
      initData();
    }
  }, [compareVersion]);

  useEffect(() => {
    setSnapshotValue(compareVersion.snapshot);
    setVersionValue(compareVersion.snapshotVersion);
  }, [compareVersion]);

  useEffect(() => {
    const topProjectIds = orderBy(
      dataProvider.filter((item) => item.currentDemandMh),
      ['currentDemandMh'],
      ['desc']
    )
      .slice(0, filter.pgPerMhTop)
      .map((item) => item.currentProjectId);

    const preData = dataProvider.filter((d) => {
      const { accountingYears, budgets, types, dxApplicable } = filter;
      return (
        (!accountingYears.length ||
          accountingYears.includes(d.currentAccountingYear) ||
          accountingYears.includes(d.targetAccountingYear)) &&
        (!budgets.length || budgets.includes(d.currentBudgetCategoryCustom) || budgets.includes(d.targetBudgetCategoryCustom)) &&
        (!types.length || types.includes(d.currentIsEpc) || types.includes(d.targetIsEpc)) &&
        (!dxApplicable.length || dxApplicable.includes(d.currentDxScopeStatus) || dxApplicable.includes(d.targetDxScopeStatus))
      );
    });
    setFilteredData(filter.pgPerMhTop ? preData.filter((item) => topProjectIds.includes(item.currentProjectId)) : preData);
  }, [dataProvider, filter]);

  const handleCompare = () => {
    setCompareVersion({
      snapshot: snapshotValue,
      snapshotVersion: versionValue,
    });
  };

  const initTableColumns = () => {
    const columnsOptMap = {
      default: {
        render: (value, _) => value || '-',
      },
      projectId: {
        width: 90,
        fixed: 'left',
        align: 'left',
        className: 'padding_0_8',
        render: (value) => {
          if (!value) {
            return '-';
          }
          if (value.split(',').length > 1) {
            return value.split(',').map((s) => <div key={s}>{s}</div>);
          }
          return <div style={{ padding: '8px 0' }}>{value}</div>;
        },
      },
      budgetCategoryCustom: {
        width: 110,
      },
      isEpc: {
        width: 80,
      },
      accountingYear: {
        width: 80,
      },
      projectName: {
        fixed: 'left',
        render: (value, _) => (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={value || '-'} placement="topLeft">
            <span
              style={{
                width: '100%',
                display: 'inline-block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {value || '-'}
            </span>
          </Tooltip>
        ),
        width: 200,
        align: 'left',
      },
      dxScopeStatus: {
        align: 'left',
        width: 100,
      },
      grossProfit: {
        render: (value, record) => {
          if ([null, undefined, '', '0'].includes(value)) {
            return '-';
          }
          if (value === 'inf') {
            return value;
          }
          return Number(value.toFixed(0)).toLocaleString();
        },
        align: 'right',
        width: 140,
        // sorter: (a, b) => a.grossProfit - b.grossProfit
      },
      demandMh: {
        render: (value, record) => {
          if ([null, undefined, '', '0'].includes(value)) {
            return '-';
          }
          if (value === 'inf') {
            return value;
          }
          return Number(value.toFixed(0)).toLocaleString();
        },
        align: 'right',
        width: 140,
        // sorter: (a, b) => a.demandMh - b.demandMh
      },
      pgPerMh: {
        render: (value, record) => {
          if ([null, undefined, '', '0'].includes(value)) {
            return '-';
          }
          if (value === 'inf') {
            return value;
          }
          return Number(value.toFixed(0)).toLocaleString();
        },
        align: 'right',
        width: 140,
        // sorter: (a, b) => a.mhGross - b.mhGross
      },
      grossProfitAddedBase: {
        render: (value, record) => {
          if ([null, undefined, '', '0'].includes(value)) {
            return '-';
          }
          if (value === 'inf') {
            return value;
          }
          return Number(value.toFixed(0)).toLocaleString();
        },
        align: 'right',
        width: 140,
        // sorter: (a, b) => a.mhGross - b.mhGross
      },
      demandMhReducedBase: {
        render: (value, record) => {
          if ([null, undefined, '', '0'].includes(value)) {
            return '-';
          }
          if (value === 'inf') {
            return value;
          }
          return Number(value.toFixed(0)).toLocaleString();
        },
        align: 'right',
        width: 140,
        // sorter: (a, b) => a.mhGross - b.mhGross
      },
      gpRatio: {
        render: (value, _) => (![null, undefined, '', '0'].includes(value) ? `${(value * 100).toFixed(1)}%` : '-'),
        align: 'right',
        width: 120,
        // sorter: (a, b) => a.grossRatio - b.grossRatio
      },
      mhRatio: {
        render: (value, _) => (![null, undefined, '', '0'].includes(value) ? `${(value * 100).toFixed(1)}%` : '-'),
        align: 'right',
        width: 100,
        // sorter: (a, b) => a.mhRatio - b.mhRatio
      },
    };

    const getColumns = (key, mapKey, title, width?) => {
      const map = columnsOptMap[mapKey] ?? columnsOptMap.default;
      return {
        key,
        dataIndex: key,
        title,
        fixed: map.fixed,
        className: `${map.className || ''} align-${map.align ?? 'center'}`,
        width: width || map.width,
        render: map.render || columnsOptMap.default.render,
        sorter: map.sorter,
      };
    };

    setTableColumns({
      [PORTFOLIO_TYPE.CURRENT]: [
        getColumns('currentProjectId', 'projectId', t('aipcmcty.page.portfolio.projectId')),
        getColumns('currentProjectName', 'projectName', t('aipcmcty.page.portfolio.projectName')),
        getColumns('currentBudgetCategoryCustom', 'budgetCategoryCustom', t('aipcmcty.page.portfolio.budgetCate')),
        getColumns('currentIsEpc', 'isEpc', t('aipcmcty.page.portfolio.isEpc')),
        getColumns('currentAccountingYear', 'accountingYear', t('aipcmcty.page.portfolio.year')),
        getColumns('currentDxScopeStatus', 'dxScopeStatus', t('aipcmcty.page.portfolio.dxScopeStatus')),
        getColumns('currentGrossProfit', 'grossProfit', t('aipcmcty.page.portfolio.grossProfit')),
        getColumns('currentDemandMh', 'demandMh', t('aipcmcty.page.portfolio.demandMh')),
        getColumns('currentPgPerMh', 'pgPerMh', t('aipcmcty.page.portfolio.pgPerMh')),
        getColumns('currentGrossProfitAddedBase', 'grossProfitAddedBase', t('aipcmcty.page.portfolio.grossProfitAddedBase')),
        getColumns('currentDemandMhReducedBase', 'demandMhReducedBase', t('aipcmcty.page.portfolio.demandMhReducedBase')),
        getColumns('currentGpRatio', 'gpRatio', t('aipcmcty.page.portfolio.gpRatio')),
        getColumns('currentMhRatio', 'mhRatio', t('aipcmcty.page.portfolio.mhRatio')),
      ],
      [PORTFOLIO_TYPE.TARGET]: [
        getColumns('targetProjectId', 'projectId', t('aipcmcty.page.portfolio.projectId')),
        getColumns('targetProjectName', 'projectName', t('aipcmcty.page.portfolio.projectName')),
        getColumns('targetBudgetCategoryCustom', 'budgetCategoryCustom', t('aipcmcty.page.portfolio.budgetCate')),
        getColumns('targetIsEpc', 'isEpc', t('aipcmcty.page.portfolio.isEpc')),
        getColumns('targetAccountingYear', 'accountingYear', t('aipcmcty.page.portfolio.year')),
        getColumns('targetDxScopeStatus', 'dxScopeStatus', t('aipcmcty.page.portfolio.dxScopeStatus')),
        getColumns('targetGrossProfit', 'grossProfit', t('aipcmcty.page.portfolio.grossProfit')),
        getColumns('targetDemandMh', 'demandMh', t('aipcmcty.page.portfolio.demandMh')),
        getColumns('targetPgPerMh', 'pgPerMh', t('aipcmcty.page.portfolio.pgPerMh')),
        getColumns('targetGrossProfitAddedBase', 'grossProfitAddedBase', t('aipcmcty.page.portfolio.grossProfitAddedBase')),
        getColumns('targetDemandMhReducedBase', 'demandMhReducedBase', t('aipcmcty.page.portfolio.demandMhReducedBase')),
        getColumns('targetGpRatio', 'gpRatio', t('aipcmcty.page.portfolio.gpRatio')),
        getColumns('targetMhRatio', 'mhRatio', t('aipcmcty.page.portfolio.mhRatio')),
      ],
    });
  };

  /** Filter Modal UI Start */
  const [filterCollapsed, setFilterCollapsed] = useState(true);
  const handleFilterBtnClick = () => {
    setMenuCollapsed(filterCollapsed);
    setFilterCollapsed(!filterCollapsed);
  };

  const tabs = [
    {
      key: '1',
      label: t('aipcmcty.page.refinement'),
      children: (
        <CustomerScrollBar style={{ height: 'calc(100% - 16px)', padding: '0 10px' }}>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>{t('aipcmcty.page.portfolio.accountingYear')}:</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <Select
                maxTagCount={1}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                options={options.accountingYears}
                value={filter.accountingYears}
                onChange={(e) => handleFilterChange(e, 'accountingYears')}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>{t('aipcmcty.page.portfolio.classification')}:</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <Select
                maxTagCount={1}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                options={options.types}
                value={filter.types}
                onChange={(e) => handleFilterChange(e, 'types')}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>{t('aipcmcty.page.portfolio.budgetCategory')}:</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <Select
                maxTagCount={1}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                options={options.budgets}
                value={filter.budgets}
                onChange={(e) => handleFilterChange(e, 'budgets')}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>{t('aipcmcty.page.portfolio.dxAvailable')}:</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <Select
                maxTagCount={1}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                options={options.dxApplicable}
                value={filter.dxApplicable}
                onChange={(e) => handleFilterChange(e, 'dxApplicable')}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>{t('aipcmcty.page.portfolio.orderGPMin')}:</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <InputNumber
                placeholder={t('aipcmcty.page.portfolio.min')}
                style={{ width: '100%' }}
                value={filter.grossProfitMin / 100000000}
                onChange={(e) => handleFilterChange(e * 100000000, 'grossProfitMin')}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>{t('aipcmcty.page.portfolio.orderGPMax')}:</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <InputNumber
                placeholder={t('aipcmcty.page.portfolio.max')}
                style={{ width: '100%' }}
                value={filter.grossProfitMax / 100000000}
                onChange={(e) => handleFilterChange(e * 100000000, 'grossProfitMax')}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>{t('aipcmcty.page.portfolio.mhGPMin')}:</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder={t('aipcmcty.page.portfolio.min')}
                value={filter.mhGrossMin / 10000}
                onChange={(e) => handleFilterChange(e * 10000, 'mhGrossMin')}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>{t('aipcmcty.page.portfolio.mhGPMax')}:</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <InputNumber
                style={{ width: '100%' }}
                placeholder={t('aipcmcty.page.portfolio.max')}
                value={filter.mhGrossMax / 10000}
                onChange={(e) => handleFilterChange(e * 10000, 'mhGrossMax')}
              />
            </Col>
          </Row>
          <Row align="middle" style={{ marginBottom: 16 }}>
            <Col span={filterColSpanMap.label}>
              <span>MH TOP :</span>
            </Col>
            <Col span={filterColSpanMap.item}>
              <Select
                allowClear
                style={{ width: '100%' }}
                className="text-left"
                options={[
                  {
                    key: '5',
                    label: 5,
                    value: 5,
                  },
                  {
                    key: '10',
                    label: 10,
                    value: 10,
                  },
                  {
                    key: '15',
                    label: 15,
                    value: 15,
                  },
                ]}
                value={filter.pgPerMhTop}
                onChange={(e) => handleFilterChange(e, 'pgPerMhTop')}
              />
            </Col>
          </Row>
        </CustomerScrollBar>
      ),
    },
  ];

  useEffect(() => {
    if (curTableRef.current.nativeElement && tarTableRef.current.nativeElement) {
      const curScrollHandler = (e) => {
        tarTableRef.current.nativeElement.querySelector('.cmcty-table-body').scrollTop = e.target.scrollTop;
      };
      const tarScrollHandler = (e) => {
        curTableRef.current.nativeElement.querySelector('.cmcty-table-body').scrollTop = e.target.scrollTop;
      };
      curTableRef.current.nativeElement.querySelector('.cmcty-table-body').removeEventListener('scroll', curScrollHandler);
      tarTableRef.current.nativeElement.querySelector('.cmcty-table-body').removeEventListener('scroll', tarScrollHandler);
      curTableRef.current.nativeElement.querySelector('.cmcty-table-body').addEventListener('scroll', curScrollHandler);
      tarTableRef.current.nativeElement.querySelector('.cmcty-table-body').addEventListener('scroll', tarScrollHandler);
    }
  }, [curTableRef, tarTableRef]);

  return (
    <div className="portfolio-monitor">
      <div
        style={{
          marginRight: filterCollapsed ? 0 : drawerOpts.maxWidth - 14,
          overflow: 'hidden',
        }}
      >
        <Row className="operation-container" justify="space-between" style={{ backgroundColor: 'white', padding: '0 16px' }}>
          <Col style={{ padding: '5px 0' }}>
            <Space>
              DX:
              <div style={{ width: 60 }}>
                <Switch checkedChildren="ON " unCheckedChildren="OFF" onChange={(e) => handleFilterChange(e, 'dx')} />
              </div>
              {t('aipcmcty.page.comparisonResource.targetSnapshot')}
              :
              <Select
                style={{ width: 150 }}
                options={snapshotList}
                value={snapshotValue}
                onChange={(e) => handleVersionChange('snapshot', e)}
              />
              {t('aipcmcty.page.comparisonResource.targetVersion')}
              :
              <Select
                style={{ width: 150 }}
                options={versionOptCollection[snapshotValue]}
                value={versionValue}
                onChange={(e) => handleVersionChange('snapshotVersion', e)}
              />
              <Button type="primary" onClick={handleCompare}>
                {t('aipcmcty.page.comparisonResource.compare')}
              </Button>
            </Space>
          </Col>
          <Col style={{ padding: '5px 0' }}>
            <Space>
              <Button onClick={handleFilterBtnClick}>
                <BarsOutlined />
              </Button>
            </Space>
          </Col>
        </Row>
        <Row justify="space-between" gutter={4} style={{ marginTop: 4 }}>
          <Col span={12}>
            <div
              className="chart"
              style={{
                padding: 6,
                backgroundColor: 'white',
                minHeight: 450,
              }}
            >
              <PFMatrixChart
                operation={[state, dispatch]}
                chartInfo={{
                  title: t('aipcmcty.page.portfolio.targetProject'),
                  xName: t('aipcmcty.page.portfolio.xAxisName'),
                  yName: t('aipcmcty.page.portfolio.yAxisName'),
                }}
                loading={loading}
                graphHeight={450}
                data={filteredData}
                useDX={filter.dx}
                filter={filter}
                type={PORTFOLIO_TYPE.TARGET}
              />
            </div>
            <div className="table">
              <Table
                ref={tarTableRef}
                loading={loading}
                style={{ marginTop: 4, backgroundColor: 'white' }}
                columns={tableColumns[PORTFOLIO_TYPE.TARGET]}
                dataSource={filteredData}
                scroll={{ y: selectorHeight4Table - 60 }}
                className="portfolio-table"
                rowKey={(record) => record.targetPorjectId || record.currentProjectId}
                size="small"
                pagination={{
                  current: pagination.current,
                  pageSizeOptions: [200, 500],
                  pageSize: pagination.pageSize,
                  size: 'small',
                  onChange: (page, pageSize) => {
                    setPagination({
                      current: page,
                      pageSize,
                    });
                  },
                }}
              />
            </div>
          </Col>
          <Col span={12}>
            <div
              className="chart"
              style={{
                padding: 6,
                backgroundColor: 'white',
                minHeight: 450,
              }}
            >
              <PFMatrixChart
                operation={[state, dispatch]}
                chartInfo={{
                  title: t('aipcmcty.page.portfolio.currentProject'),
                  xName: t('aipcmcty.page.portfolio.xAxisName'),
                  yName: t('aipcmcty.page.portfolio.yAxisName'),
                }}
                loading={loading}
                graphHeight={450}
                data={filteredData}
                useDX={filter.dx}
                filter={filter}
                type={PORTFOLIO_TYPE.CURRENT}
              />
            </div>
            <div className="table">
              <Table
                ref={curTableRef}
                loading={loading}
                style={{ marginTop: 4, backgroundColor: 'white' }}
                columns={tableColumns[PORTFOLIO_TYPE.CURRENT]}
                dataSource={filteredData}
                scroll={{ y: selectorHeight4Table - 60 }}
                className="portfolio-table"
                rowKey={(record) => record.currentProjectId || record.targetPorjectId}
                size="small"
                pagination={{
                  current: pagination.current,
                  pageSizeOptions: [200, 500],
                  pageSize: pagination.pageSize,
                  size: 'small',
                  onChange: (page, pageSize) => {
                    setPagination({
                      current: page,
                      pageSize,
                    });
                  },
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <DrawerContainer {...drawerOpts} collapsed={filterCollapsed}>
        {!filterCollapsed && <Tabs type="card" size="small" style={{ height: '100%' }} items={tabs} />}
      </DrawerContainer>
    </div>
  );
};

export const enum PORTFOLIO_TYPE {
  CURRENT = 'current',
  TARGET = 'target',
}

export default PortfolioMonitor;
