/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable radix */
/* eslint-disable react/require-default-props */
import { Button, Table, Tooltip } from 'antd';
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { TypicalKpiContext } from '../../contexts/typical-kpi.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type TableCardData = {
  loading: boolean;
  data: any;
};

const TypicalKpiTable: React.FC<TableCardData> = (props) => {
  const {
    data, loading
  } = props;
  const { t } = useTranslation();
  const { defaultFiscalQuarter, fiscalQuarter } = useContext(AppContext);
  const {
    kpiTree, tgc, viewMode, setViewMode
  } = useContext(TypicalKpiContext);

  const navigate = useNavigate();

  const { selectorHeight4Table } = useWindowSize({
    viewMode
  });

  const ONLY_YEAR_KPI = ['KPJ013', 'KPJ014'];

  const [columns, setColumns] = useState([]);
  const [showColumns, setShowColumns] = useState([]);
  const [showData, setShowData] = useState([]);

  const instance = useRef(null);

  useEffect(() => {
    if (instance.current) {
      const tbody = instance.current.nativeElement;
      tbody.onmouseleave = () => {
        setTimeout(() => {
          const cells = tbody.querySelectorAll('.cmcty-table-cell');
          for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            cell.classList.remove('cmcty-table-cell-row-hover');
          }
        }, 10);
      };
    }
  }, [instance.current]);

  useEffect(() => {
    setTableColumns();
  }, [fiscalQuarter]);

  useEffect(() => {
    if (columns && columns.length > 0) {
      const deepColumns = ([...columns]
        .filter(column => (kpiTree ? kpiTree.find(kpi => kpi === column.dataIndex) : false))
        .map(column => ({
          ...column,
          children: column.children ? column.children.filter(col => (fiscalQuarter ? (ONLY_YEAR_KPI.includes(column.dataIndex.split('-')[column.dataIndex.split('-').length - 1]) ? defaultFiscalQuarter : fiscalQuarter).find(per => per === col.title) : false)) : []
        })));
      deepColumns.unshift(columns[0]);
      setShowColumns(deepColumns);
    } else {
      setShowColumns([]);
    }
  }, [columns, kpiTree, fiscalQuarter]);

  const renderLink = (text, record, index, key) => {
    if (!text) {
      return '-';
    }
    const goal = record[`${key}-goal`];
    if (goal) {
      return (
        <Tooltip title={`${t('aipcmcty.page.target')}:${goal}`}>
          <Button
            style={{ padding: 0 }}
            type="link" onClick={() => {
              navigate('/aipcmcty/simulation/project-setup');
            }}
          >
            <span style={{ color: '#373737' }}>{text}</span>
          </Button>
        </Tooltip>
      );
    }
    return (
      <Button
        style={{ padding: 0 }}
        type="link" onClick={() => {
          navigate('/aipcmcty/simulation/project-setup');
        }}
      >
        <span style={{ color: '#373737' }}>{text}</span>
      </Button>
    );
  };

  const setTableColumns = () => {
    const getColumnChildren = (prefix) => {
      let date = fiscalQuarter;
      if (ONLY_YEAR_KPI.includes(prefix)) {
        date = defaultFiscalQuarter;
      }
      return date.sort().map((title) => ({
        title,
        dataIndex: `${prefix}${title}`,
        key: `${prefix}${title}`,
        width: 90,
        render: (text, record, index) => {
          let parsedText = text;
          if (prefix === 'KPJ012') {
            parsedText = parsedText ? Number(parsedText).toFixed(0) : parsedText;
          }
          if (prefix === 'KPJ013' || prefix === 'KPJ011') {
            parsedText = parsedText ? Number(parsedText).toFixed(1) : parsedText;
          }
          return renderLink(parsedText, record, index, `${prefix}${title}`);
        },
        onCell: (record, rowIndex) => ({
          onMouseOver: (event: any) => {
            const { currentTarget } = event;
            const columnIndex = Array.prototype.indexOf.call(currentTarget.parentNode.children, currentTarget);
            const tableBody = currentTarget.parentNode.parentNode;
            setTimeout(() => {
              for (let i = 1; i < tableBody.children.length; i++) {
                const childTr = tableBody.children[i];
                if (i - 1 === rowIndex) {
                  for (let j = 0; j < childTr.children.length; j++) {
                    childTr.children[j].classList.add('cmcty-table-cell-row-hover');
                  }
                } else {
                  for (let j = 0; j < childTr.children.length; j++) {
                    childTr.children[j].classList.remove('cmcty-table-cell-row-hover');
                  }
                }
                if (childTr && childTr.children[columnIndex]) {
                  const cell = childTr.children[columnIndex];
                  cell.classList.add('cmcty-table-cell-row-hover');
                }
              }
            });
          },
        })
      }));
    };
    setColumns([{
      title: 'TGC',
      dataIndex: 'tgc',
      key: 'tgc',
      fixed: 'left',
      width: 120,
      onCell: (record, rowIndex) => ({
        onMouseOver: (event: any) => {
          const { currentTarget } = event;
          const tableBody = currentTarget.parentNode.parentNode;
          setTimeout(() => {
            for (let i = 1; i < tableBody.children.length; i++) {
              const childTr = tableBody.children[i];
              if (i - 1 === rowIndex) {
                for (let j = 0; j < childTr.children.length; j++) {
                  childTr.children[j].classList.add('cmcty-table-cell-row-hover');
                }
              } else {
                for (let j = 0; j < childTr.children.length; j++) {
                  childTr.children[j].classList.remove('cmcty-table-cell-row-hover');
                }
              }
            }
          });
        },
      })
    }, {
      title: `${t('aipcmcty.page.grossProfitAmt')}（${t('aipcmcty.page.billion')}）`,
      dataIndex: '1-2-KPJ004',
      key: '1-2-KPJ004',
      children: getColumnChildren('KPJ004')
    }, {
      title: 'Won％（%）',
      dataIndex: '1-3-KPJ014',
      key: '1-3-KPJ014',
      children: getColumnChildren('KPJ014')
    }, {
      title: `${t('aipcmcty.page.orderAmount')}（${t('aipcmcty.page.billion')}）`,
      dataIndex: '1-KPJ002',
      key: '1-KPJ002',
      children: getColumnChildren('KPJ002')
    }, {
      title: `${t('aipcmcty.page.sales')}（${t('aipcmcty.page.billion')}）`,
      dataIndex: '1-KPJ003',
      key: '1-KPJ003',
      children: getColumnChildren('KPJ003')
    }, {
      title: `${t('aipcmcty.page.newVsExistingRatio')}（%）`,
      dataIndex: '1-KPJ006',
      key: '1-KPJ006',
      children: getColumnChildren('KPJ006')
    }, {
      title: `${t('aipcmcty.page.nonEPC')}/EPC比率（%）`,
      dataIndex: '1-KPJ005',
      key: '1-KPJ005',
      children: getColumnChildren('KPJ005')
    }, {
      title: t('aipcmcty.page.grossProfitAmt'),
      dataIndex: '2-GrossProfitAvg',
      key: '2-GrossProfitAvg',
      children: getColumnChildren('GrossProfitAvg')
    }, {
      title: `${t('aipcmcty.page.NPVgrossProfitRate')}（%）`,
      dataIndex: '2-KPJ013',
      key: '2-KPJ013',
      children: getColumnChildren('KPJ013')
    }, {
      title: 'Won%（%）',
      dataIndex: '2-KPJ014',
      key: '2-KPJ014',
      children: getColumnChildren('KPJ014')
    }, {
      title: `${t('aipcmcty.page.grossProfitRate')}（%）`,
      dataIndex: '2-KPJ011',
      key: '2-KPJ011',
      children: getColumnChildren('KPJ011')
    }, {
      title: t('aipcmcty.page.grossProfitAmt'),
      dataIndex: '2-3-GrossProfitAvg',
      key: '2-3-GrossProfitAvg',
      children: getColumnChildren('GrossProfitAvg')
    }, {
      title: t('aipcmcty.page.grossProfitPerMH'),
      dataIndex: '2-KPJ012',
      key: '2-KPJ012',
      children: getColumnChildren('KPJ012')
    }, {
      title: t('aipcmcty.page.grossProfitAmt'),
      dataIndex: '2-4-GrossProfitAvg',
      key: '2-4-GrossProfitAvg',
      children: getColumnChildren('GrossProfitAvg')
    }, {
      title: t('aipcmcty.page.totalManHours'),
      dataIndex: '2-TotalMH',
      key: '2-TotalMH',
      children: getColumnChildren('TotalMH')
    }, {
      title: `${t('aipcmcty.page.ESMHutilizationRate')}（%）`,
      dataIndex: '3-KRS002',
      key: '3-KRS002',
      children: getColumnChildren('KRS002')
    }, {
      title: t('aipcmcty.page.resourceDemandMH'),
      dataIndex: '3-ResourceDemandMh',
      key: '3-ResourceDemandMh',
      children: getColumnChildren('ResourceDemandMh')
    }, {
      title: t('aipcmcty.page.resourceSupplyMH'),
      dataIndex: '3-ResourceSupplyMh',
      key: '3-ResourceSupplyMh',
      children: getColumnChildren('ResourceSupplyMh')
    }, {
      title: t('aipcmcty.page.demandSupplyGapMH'),
      dataIndex: '3-KRS003',
      key: '3-KRS003',
      children: getColumnChildren('KRS003')
    }, {
      title: `${t('aipcmcty.page.jobRate')}（%）`,
      dataIndex: '4-KRS004',
      key: '4-KRS004',
      children: getColumnChildren('KRS004')
    }, {
      title: `${t('aipcmcty.page.talentTurnoverRate')}（%）`,
      dataIndex: '4-KRS005',
      key: '4-KRS005',
      children: getColumnChildren('KRS005')
    }, {
      title: t('aipcmcty.page.grossProfitPerMH'),
      dataIndex: '4-1-KPJ012',
      key: '4-1-KPJ012',
      children: getColumnChildren('KPJ012')
    },
    {
      title: `${t('aipcmcty.page.DXapplicationRatio')}（%）`,
      dataIndex: '5-KDX002',
      key: '5-KDX002',
      children: getColumnChildren('KDX002')
    },
    {
      title: t('aipcmcty.page.grossProfitPerMH'),
      dataIndex: '6-KPJ012',
      key: '6-KPJ012',
      children: getColumnChildren('KPJ012')
    },
    {
      title: `${t('aipcmcty.page.DXallowanceFullPotentialAmount')}（${t('aipcmcty.page.billion')}）`,
      dataIndex: '6-1-KDX003',
      key: '6-1-KDX003',
      children: getColumnChildren('KDX003')
    },
    {
      title: `${t('aipcmcty.page.DXallowanceDiscountRate')}（%）`,
      dataIndex: '6-2-KDX004',
      key: '6-2-KDX004',
      children: getColumnChildren('KDX004')
    },
    {
      title: `${t('aipcmcty.page.DXallowanceAccumulatedAmount')}（${t('aipcmcty.page.billion')}）`,
      dataIndex: '6-3-KDX005',
      key: '6-3-KDX005',
      children: getColumnChildren('KDX005')
    },
    ]);
  };

  /**
 * Table高度Map
 */
  const tableHeightMap = {
    'chart-table': selectorHeight4Table,
    'table-only': selectorHeight4Table,
    'chart-only': 0,
  };

  useEffect(() => {
    setShowData(data.filter(item => tgc.includes(item.tgc)));
  }, [data, tgc]);

  // useEffect(() => {
  //   console.log(showColumns);
  // }, [showColumns]);

  // useEffect(() => {
  //   console.log(showData);
  // }, [showData]);

  return (
    <>
      <div className="table-container custom-split-line typical-kpi-table" style={{ marginTop: 5 }}>
        <Table
          loading={loading}
          bordered
          ref={instance}
          scroll={{ y: tableHeightMap[viewMode] }}
          pagination={false}
          columns={showColumns}
          dataSource={showData}
          size="small"
        />
      </div>
    </>
  );
};

export default TypicalKpiTable;