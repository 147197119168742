// chart title: Buffer Suitability Evaluation
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, groupBy, sumBy } from 'lodash';
import { Tooltip } from 'antd';
import Ribbon from '../widget/ribbon';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  tableOptions?: TableOptions
  showTable?: boolean;
  fiscalQuarter?: string[];
  periodSwitch?: boolean;
  chartHandler?: (e: any) => any;
};

const SupplyDemandChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode, data, height, loading, title, ribbonText, ribbonColor, showTable = true, tableOptions, fiscalQuarter, periodSwitch = false,
    titleLoading = false, coverLoading, chartHandler
  } = props;

  const {
    color, sizeMode
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const current = `${date.getFullYear()}\n${currentMonth < 10 ? `0${currentMonth}` : currentMonth}`;
  const months: string[] = [];
  for (let year = 2022; year <= 2026; year++) {
    for (let month = 1; month <= 12; month++) {
      months.push(`${year}\n${month < 10 ? `0${month}` : month}`);
    }
  }

  const chartRef = useRef(null);
  const modalChartRef = useRef(null);

  const [divisionSelectValue, setDivisionSelectValue] = useState(['All', 'All']);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 80,
      gridBottom: 10,
    },
    big: {
      ...sizeMapBase.big,
    },
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.orderAmount'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        // appendToBody: true,
        // className: 'overflow-all',
        // confine: true,
        hideDelay: 0,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        },
        formatter: (params) => {
          const displayDate = params[0].axisValue.replace(/\n/g, '/');
          let tooltips = `<div><table style="width: 180px"><tr><td>${displayDate}</td></tr>`;
          params.reverse();

          params.forEach((element, index) => {
            const tooltipBody = `<td>${element.marker}${element.seriesName}:</td> <td style="text-align: right; font-weight:bold; padding-right: 5px">${element.value.toLocaleString()}</td>`;
            tooltips += `<tr>${tooltipBody}</tr>`;
          });

          tooltips += '</table></div>';
          return tooltips;
        },
      },
      legend: {
        type: 'scroll',
        textStyle: {
          fontSize: getSizeByMap('small', sizeMap, 'legend'),
        },
        data: [],
        itemGap: 3,
        selected: {},
        width: 230,
        left: 0,
        pageButtonItemGap: 0,
        top: 2,
      },
      grid: {
        left: Number(getSizeByMap(sizeMode, sizeMap, 'gridLeft')) - 35,
        right: Number(getSizeByMap(sizeMode, sizeMap, 'gridRight')),
        bottom: sizeMode === 'big' ? Number(getSizeByMap(sizeMode, sizeMap, 'gridBottom')) - 15
          : Number(getSizeByMap(sizeMode, sizeMap, 'gridBottom')) + 35,
        top: Number(getSizeByMap(sizeMode, sizeMap, 'gridTop')) - 45,
        containLabel: true
      },
      toolbox: {
        feature: {
          // dataZoom: {
          //   yAxisIndex: 'none'
          // },
          // restore: {},
          // saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          fontSize: getSizeByMap('small', sizeMap, 'xAxis'),
        },
      },
      yAxis: {
        type: 'value',
        min: 0,
        axisLabel: {
          fontSize: getSizeByMap('small', sizeMap, 'yAxis'),
        },
        nameTextStyle: {
          fontSize: getSizeByMap('small', sizeMap, 'yAxis'),
        },
      },
      dataZoom: [
        {
          type: 'inside',
          startValue: months.indexOf(current),
        },
        {
          type: 'slider',
          textStyle: {
            fontSize: 11,
          },
          bottom: 30,
          height: 18,
        },
      ],
      series: []
    },
    height: 220,
  });

  useEffect(() => {
    initData();
  }, [data, divisionSelectValue, fiscalQuarter, kpiComputeRule]);

  const initData = () => {
    initChartData();
    initTableColumns();
    initTableData();
  };

  const initChartData = () => {
    if (!data) {
      return;
    }
    const { jobTypeData, supplyData } = data;
    const gbDivision = groupBy(jobTypeData, 'division');
    const gbDiscipline = groupBy(jobTypeData, 'discipline');

    const customOrder = ['Engineering', 'Procurement', 'Construction', 'Corporate', 'Project'];

    const sortedDivisions = Object.keys(gbDivision).sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));

    setDivisionSelectOptions([
      {
        value: 'All',
        label: 'All Div.',
        key: 'All',
        children: [{
          value: 'All',
          label: 'All Dis.',
          key: 'All'
        },
        ...Object.keys(gbDiscipline).map(discipline => ({
          value: discipline,
          label: discipline,
          key: discipline,
        }))]
      },
      ...sortedDivisions.map(division => ({
        value: division,
        label: division === 'Project' ? 'PJT' : `${division.substring(0, 3)}.`,
        key: division,
        children: [{
          value: 'All',
          label: 'All Dis.',
          key: 'All',
        },
        ...Object.entries(groupBy(jobTypeData.filter(item => item.division === division), 'discipline'))
          .map(([value]) => ({
            value,
            label: value,
            key: value
          })),
        ]
      }))
    ]);
    // level2粒度：Division
    const jobTypeFilterDataLv2 = divisionSelectValue[0] === 'All' ? jobTypeData : jobTypeData.filter(item => item.division === divisionSelectValue[0]);
    const supplyFilterDataLv2 = divisionSelectValue[0] === 'All' ? supplyData : supplyData.filter(item => item.division === divisionSelectValue[0]);

    // level3粒度：Discipline
    const jobTypeFilterDataLv3 = divisionSelectValue[1] === 'All' ? jobTypeFilterDataLv2 : jobTypeFilterDataLv2.filter(item => item.discipline === divisionSelectValue[1]);
    const supplyFilterDataLv3 = divisionSelectValue[1] === 'All' ? supplyFilterDataLv2 : supplyFilterDataLv2.filter(item => item.discipline === divisionSelectValue[1]);

    const gbDateSupply = groupBy(supplyFilterDataLv3, (item) => `${item.year}\n${item.month}`);

    // // yearsを取得
    // const years = Object.keys(gbDate).sort((a: any, b: any) => a - b);

    // chart optionのデータを組み立て
    options.chartOptions.xAxis.data = months;
    const series = [];
    const legend = [];
    const gbJT = groupBy(jobTypeFilterDataLv3, 'jobType');
    Object.keys(gbJT).forEach((jobType) => {
      const gbJTDate = groupBy(gbJT[jobType], (item) => `${item.year}\n${item.month}`);
      series.push({
        name: jobType,
        type: 'line',
        stack: 'Total',
        areaStyle: {},
        showSymbol: false,
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        data: months.map(key => Math.ceil(kpiComputeRule[kpiCode](gbJTDate[key] || []))),
      });
      legend.push(jobType);
    });

    series.push({
      name: 'Total MH',
      type: 'line',
      lineStyle: {
        color: color.errorColor,
        width: 2,
      },
      showSymbol: false,
      step: 'middle',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      itemStyle: {
        color: color.errorColor,
      },
      emphasis: {
        focus: 'series',
      },
      data: Object.keys(gbDateSupply).map(key => Math.ceil(kpiComputeRule.ResourceSupplyMh(gbDateSupply[key])))
    });
    legend.push('Total MH');

    const selected: any = {};
    legend.forEach((type) => {
      selected[type] = true;
    });
    options.chartOptions.legend = {
      textStyle: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
      },
      data: legend,
      selected: selected,
      type: 'scroll',
      itemGap: 3,
      width: 230,
      left: 0,
      pageButtonItemGap: 0,
      top: 2,
    };
    options.chartOptions.series = series;
    setOptions(cloneDeep(options));
  };

  /**
   * 初期化table column
   */
  const initTableColumns = () => {
    if (!data) {
      return;
    }
    const { jobTypeData } = data;
    const map = groupBy(jobTypeData, 'year');
    const years = Object.keys(map).sort((a: any, b: any) => a - b);
    const baseColumns = [{
      title: '',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      width: sizeMode === 'big' ? 50 : 40
    }];
    const columns: any[] = years.map(year => ({
      title: <Tooltip title={year}>{year}</Tooltip>,
      dataIndex: year,
      key: year,
      className: 'text-right',
      width: sizeMode === 'big' ? 100 : 80,
    }));
    setTableColumns(baseColumns.concat(columns));
  };

  /**
     * 初期化tableデータ
     */
  const initTableData = () => {
    if (!data) {
      return;
    }
    const { jobTypeData } = data;
    // level2粒度：Division
    const jobTypeFilterDataLv2 = divisionSelectValue[0] === 'All' ? jobTypeData : jobTypeData.filter(item => item.division === divisionSelectValue[0]);
    // level3粒度：Discipline
    const jobTypeFilterDataLv3 = divisionSelectValue[1] === 'All' ? jobTypeFilterDataLv2 : jobTypeFilterDataLv2.filter(item => item.discipline === divisionSelectValue[1]);
    // map
    const map = groupBy(jobTypeFilterDataLv3, 'year');

    const prepareData: any[] = [{
      key: 'awarded',
      type: t('aipcmcty.page.owned'),
    }, {
      key: 'budget',
      type: t('aipcmcty.page.plan'),
    }];

    const setPrepareData = (per) => {
      prepareData[0].key = 'awarded';
      prepareData[0].type = t('aipcmcty.page.owned');
      prepareData[0][per] = `${Math.ceil((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.budgetCategoryCustom === 'Awarded'), (map[per] || [])))).toLocaleString()}`;
      prepareData[1].key = 'budget';
      prepareData[1].type = t('aipcmcty.page.plan');
      prepareData[1][per] = `${Math.ceil((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.budgetCategoryCustom === 'Budget'), (map[per] || [])))).toLocaleString()}`;
    };

    Object.keys(map).forEach((per) => {
      setPrepareData(per);
    });
    setTableData(prepareData);
  };

  const [divisionSelectOptions, setDivisionSelectOptions] = useState([]);

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        setDivisionSelectValue(['All', 'All']);
        initData();
      }
    }
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_LEGEND_SELECTED
    ) {
      const { event, chartTitle, chartInstance } = e;
      if (title.key === chartTitle.value.key) {
        const { jobTypeData, supplyData } = data;

        // level2粒度：Division
        const jobTypeFilterDataLv2 = divisionSelectValue[0] === 'All' ? jobTypeData : jobTypeData.filter(item => item.division === divisionSelectValue[0]);
        const supplyFilterDataLv2 = divisionSelectValue[0] === 'All' ? supplyData : supplyData.filter(item => item.division === divisionSelectValue[0]);

        // level3粒度：Discipline
        const jobTypeFilterDataLv3 = divisionSelectValue[1] === 'All' ? jobTypeFilterDataLv2 : supplyFilterDataLv2.filter(item => item.discipline === divisionSelectValue[1]);
        const supplyFilterDataLv3 = divisionSelectValue[1] === 'All' ? supplyFilterDataLv2 : supplyFilterDataLv2.filter(item => item.discipline === divisionSelectValue[1]);

        const gbDate = groupBy(jobTypeFilterDataLv3.filter(item => !event.selected[item.jobType]), (item) => `${item.year}\n${item.month}`);
        // const gbDateSupply = groupBy(supplyFilterDataLv3.filter(item => !event.selected[item.jobType]), (item) => `${item.year}\n${item.month}`);

        const totalGbDateSupply = groupBy(supplyFilterDataLv3, (item) => `${item.year}\n${item.month}`);

        const instance = chartInstance.current.getEchartsInstance();
        const option = instance.getOption();
        const lineSeries = option.series.find(item => item.name === 'Total MH');
        const targetData = Object.keys(totalGbDateSupply).map(key => (Math.ceil(sumBy(totalGbDateSupply[key], 'resourceSupplyMh') || 0)));
        if (Object.keys(gbDate).length > 0) {
          lineSeries.data = months.map((key, index) => (targetData[index] || 0) - Math.ceil(sumBy(gbDate[key], 'supplyMh')));
        } else {
          lineSeries.data = targetData;
        }

        setOptions(cloneDeep({
          ...options,
          chartOptions: option
        }));
      }
    }

    if (chartHandler) {
      chartHandler(e);
    }
  };

  return (
    <>
      {ribbonText ? <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantity')} color={ribbonColor || color.primaryColor} /> : <></>}
      <TableChart
        showTable={showTable}
        chartRef={chartRef}
        tableColumns={showTable ? tableColumns : []}
        tableData={tableData}
        modalChartRef={modalChartRef}
        chartOptions={options.chartOptions}
        title={title ? {
          value: title,
          styles: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
            paddingLeft: sizeMode === 'big' ? 15 : 0,
          },
        } : options.title}
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        select={{
          type: 'cascader',
          value: divisionSelectValue,
          list: divisionSelectOptions,
          styles: {
            position: 'absolute',
            right: 4,
            zIndex: 1,
            paddingTop: 3,
          },
          notMinus: true,
          onChange: (value) => {
            setDivisionSelectValue(value);
          }
        }}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default SupplyDemandChart;
