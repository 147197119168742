import { cloneDeep, isNumber } from "lodash";
import { readExcelFile, readSheetResponse, readBufferWithStyles } from "../utils/xlsxReader";

const MOCK_FILE_PATH = '/assets/data-excel/demo-data.xlsx';

const mockList = {
  // DEMO
  '/api/demo/chart': async (req, res) => {
    const data = await readExcelFile(MOCK_FILE_PATH);
    const keyLine = req.queryStringParameters.keyLine;
    const chartData = [];
    Object.keys(data).forEach((key) => {
      if (key.includes(`${req.queryStringParameters.version}-G`)) {
        chartData.push({
          title: data[key][0][0],
          type: key.split('-')[2],
          data: readSheetResponse(data[key], keyLine),
          yName: data[key][0][1] ?? '',
          xName: data[key][0][2] ?? '',
        });
      }
    });
    return {
      status: 200,
      data: chartData,
    };
  },
  '/api/demo/table': async (req, res) => {
    let dataWithStyles = null;
    let tableType = '';
    let data = null;
    dataWithStyles = await readBufferWithStyles(MOCK_FILE_PATH);
    Object.keys(dataWithStyles).forEach((key) => {
      if (key.includes(`${req.queryStringParameters.version}-${req.queryStringParameters.table || 'T1'}`)) {
        data = dataWithStyles[key];
        tableType = key.split('-')[2];
      }
    });
    let tableColumns: any = [];
    let tableSource: any = [];
    let tableTitleLine = 1;
    if (data) {
      let tableF = formatTableFun(data, tableType, isNumber(req.queryStringParameters.fixCount) ? req.queryStringParameters.fixCount : 1);
      tableColumns = tableF.tableColumns;
      tableSource = tableF.tableSource;
      tableTitleLine = tableF.titleRowCount + 1;
    }
    return {
      status: 200,
      data: { tableColumns, tableSource, tableTitleLine },
    };
  },
} as any;

//functions for format table column and data Begin
const formatTableFun = (tableData, tableType = 'b', fixCount) => {
  let keys = [];
  // let tableData = [];
  let titleLastRow = 0;
  var kannjiReg = /[\u3040-\u30FF\u31F0-\u31FF\uFF00-\uFFEF\u4E00-\u9FAF]/g;
  var wAZReg = /[A-Z]/g;
  var wazReg = /[a-z]/g;
  var blankReg = /[\f\n\r\t\v\u0020\u00a0\u1680\u180e\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]/g;
  var characterReg = /[\%\\\/]/g;
  var punctuationReg = /[\.\-]/g;
  var numberReg = /[0-9]/g;
  var dotReg = /[,，、]/g;

  const BASE_CHARTER_WIDTH = 2.55;
  const SCREEN_WIDTH = 1920;
  const TITLE_AREA_COLOR = '806000';

  const columnWidthList = new Set();
  let tableWidth = 0;

  const tableCustomFunForA = {
    setFixedColumn: (tableColumnObj) => {
      let firstItemList = tableData[0];
      tableColumnObj.push({
        title: firstItemList[keys[0]].value ?? '',
        dataIndex: keys[0],
        width: setColumnWidth(keys[0], 0),
        key: keys[0],
        fixed: 'left',
        render: itemRenderFunction(),
      });
      return tableColumnObj;
    },

    setColumnWidth: (key, keyIndex) => {
      let tmpData = cloneDeep(tableData);
      let maxLength = 0;
      let tmpLength = 0;
      if (key === keys[0]) {
        tmpData.splice(0, titleLastRow);
        for (let j = 0; j < tmpData.length; j++) {
          for (let i = 0; i < fixCount; i++) {
            if (tmpData[j][keys[i]]?.value) {
              tmpLength = formatValueToLength(tmpData[j][keys[i]].value);
              tmpLength = tmpLength + i * 12;
              if (tmpLength > maxLength) {
                maxLength = tmpLength;
              }
            }
          }
        }
      } else {
        for (let i = 0; i < tmpData.length; i++) {
          if (!tmpData[i][key]?.value || (keyIndex + 1 < keys.length && !tmpData[i][keys[keyIndex + 1]]?.value)) {
            continue;
          } else {
            tmpLength = formatValueToLength(tmpData[i][key].value);
            if (tmpLength > maxLength) {
              maxLength = tmpLength;
            }
          }
        }
      }
      let columnWidth = BASE_CHARTER_WIDTH * maxLength;
      if (keyIndex >= fixCount) {
        columnWidthList.add(columnWidth);
      }
      tableWidth += columnWidth;
      return columnWidth;
    },
    formatData: () => {
      let dataList = [];
      let tmpData = cloneDeep(tableData);
      tmpData.splice(0, titleLastRow + 1);
      for (let i = 0; i < tmpData.length; i++) {
        let obj = {};
        obj['key'] = i;
        for (let j = 0; j < keys.length; j++) {
          let cellValue = { value: '', bgColor: '' };
          cellValue.value = tmpData[i][keys[j]]?.value ?? '';
          cellValue.bgColor = tmpData[i][keys[j]]?.bgColor ?? '';
          obj[keys[j] + ''] = cellValue;
        }
        dataList.push(obj);
      }
      dataList = setDataToTree(dataList);
      return dataList;
    },
  };

  const getKeys = (rowObj) => {
    let keys = Object.keys(rowObj);
    return keys.sort((a: any, b: any) => {
      if (a.length === b.length && a > b) {
        return 1;
      } else if (a.length !== b.length && a.length < b.length) {
        return -1;
      } else if (a.length !== b.length && a.length > b.length) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  const getTitleArea = (tableDataObj) => {
    let i = 0;
    while (i < tableDataObj.length - 1) {
      if (tableDataObj[i]['A'].bgColor === TITLE_AREA_COLOR) {
        i++;
        continue;
      }
      break;
    }
    return i - 1;
  };

  let finishKeyIndex;
  let setColumnProperties = (item, key, keyIndex) => {
    item.key = key;
    item.width = setColumnWidth(key, keyIndex);
    item.dataIndex = key;
    item.className = 'text-right';
    item.render = itemRenderFunction();
    finishKeyIndex = keyIndex;
    return item;
  };
  let setItemChildren = (nextItem, key, keyIndex, currentRow) => {
    if (nextItem.value) {
      let childrenCount = 0;
      for (let i = keyIndex + 1; i < keys.length; i++) {
        if (!tableData[currentRow][keys[i]]?.value) {
          childrenCount++;
        } else {
          break;
        }
      }
      let item = {} as any;
      let childrenList = [];
      item.title = nextItem.value;
      if (currentRow === titleLastRow) {
        return setColumnProperties(item, key, keyIndex);
      } else {
        for (let j = 0; j <= childrenCount; j++) {
          for (let i = currentRow + 1; i <= titleLastRow; i++) {
            if (keyIndex + j <= finishKeyIndex) {
              continue;
            }
            if (tableData[i][keys[keyIndex + j]]?.value) {
              childrenList.push(setItemChildren(tableData[i][keys[keyIndex + j]], keys[keyIndex + j], keyIndex + j, i));
              break;
            } else {
              continue;
            }
          }
        }
        if (childrenList.length > 0) {
          item.key = key;
          item.children = childrenList;
        } else {
          setColumnProperties(item, key, keyIndex);
        }
        return item;
      }
    } else {
      return {};
    }
  };

  let checkRowSpan = (tmpData, rowIndex, key, keyIndex) => {
    let rSpan = -1;
    if (rowIndex + 1 !== tmpData.length && !tmpData[rowIndex + 1][key]?.value) {
      rSpan = 1;
      for (let i = rowIndex; i < tmpData.length; i++) {
        if (i + 1 !== tmpData.length && !tmpData[i + 1][key]?.value) {
          for (let j = 0; j < keyIndex; j++) {
            if (tmpData[i + 1][keys[j]]?.value) {
              return rSpan;
            }
          }
          rSpan++;
        } else {
          break;
        }
      }
    }
    return rSpan;
  };

  let checkCellSpan = (tmpData, record, rowIndex, key, keyIndex) => {
    let cSpan = 1;
    let rSpan = -1;
    for (let i = keyIndex; i < fixCount; i++) {
      if (i + 1 !== fixCount && record[keys[i + 1]].value === '') {
        cSpan++;
      } else {
        break;
      }
    }
    rSpan = checkRowSpan(tmpData, rowIndex, key, keyIndex);
    if (rSpan === -1) {
      return { colSpan: cSpan };
    } else {
      return { colSpan: cSpan, rowSpan: rSpan };
    }
  };

  let setColumnCell = (key, keyIndex) => {
    let tmpData = cloneDeep(tableData);
    tmpData.splice(0, titleLastRow + 1);
    return (record, rowIndex) => {
      if (record[key].value !== '') {
        if (keyIndex + 1 !== fixCount && record[keys[keyIndex + 1]].value === '') {
          return checkCellSpan(tmpData, record, rowIndex, key, keyIndex);
        }
        if (rowIndex + 1 !== tmpData.length && !tmpData[rowIndex + 1][key]?.value) {
          let rSpan = 1;
          rSpan = checkRowSpan(tmpData, rowIndex, key, keyIndex);
          return { rowSpan: rSpan };
        }
        return { colSpan: 1 };
      } else {
        for (let i = 0; i < keyIndex; i++) {
          if (record[keys[i]].value !== '') {
            return { colSpan: 0 };
          } else {
            return { rowSpan: 0 };
          }
        }
        return { rowSpan: 0 };
      }
    };
  };

  let setFixedColumn = (tableColumnObj) => {
    let firstItemList = tableData[0];
    for (let i = 0; i < fixCount; i++) {
      tableColumnObj.push({
        title: firstItemList[keys[i]].value ?? '',
        dataIndex: keys[i],
        width: setColumnWidth(keys[i], 0),
        fixed: 'left',
        key: keys[i],
        onCell: setColumnCell(keys[i], i),
        render: itemRenderFunction(),
      });
    }
    return tableColumnObj;
  };

  let setActionFixedColumn;

  let formatTableColumn = () => {
    let tableColumnObj = [];
    // set left-top blank area
    while (fixCount < keys.length) {
      if (!tableData[0][keys[fixCount]].value) {
        fixCount++;
      } else {
        break;
      }
    }
    setFixedColumn(tableColumnObj);
    finishKeyIndex = fixCount - 1;
    for (let i = fixCount; i < keys.length; i++) {
      let nextItem = setItemChildren(tableData[0][keys[i]], keys[i], i, 0);
      if (nextItem.title === 'Action') {
        nextItem.hidden = true;
      }
      if (nextItem.key) {
        tableColumnObj.push(nextItem);
      } else {
        continue;
      }
    }
    setActionFixedColumn && setActionFixedColumn(tableColumnObj);
    return tableColumnObj;
  };

  let itemRenderFunction = () => {
    return (val, record, index) => {
      if (!val) {
        return '';
      }
      return val.bgColor ? (
        <div
          style={{
            background: '#' + val.bgColor,
            // animation: "bg-blink 1s linear forwards",
          }}
        >
          {val.value}
        </div>
      ) : (
        val.value
      );
    };
  };

  const formatValueToLength = (valueStr): number => {
    let text = valueStr;
    text = text.replaceAll(',', '.');
    text = text.replaceAll(numberReg, '5,');
    text = text.replaceAll(punctuationReg, '2,');
    text = text.replaceAll(characterReg, '4,');
    text = text.replaceAll(blankReg, '2,');
    text = text.replaceAll(wazReg, '5,');
    text = text.replaceAll(wAZReg, '7,');
    text = text.replaceAll(kannjiReg, '10,');
    const widthList = text.split(',');
    return widthList.reduce((a, b) => {
      if (isNaN(Number(b))) {
        return Number(a) + 6;
      } else {
        return Number(a) + Number(b);
      }
    }, 0) as number;
  };

  let setColumnWidth = (key, keyIndex) => {
    let tmpData = cloneDeep(tableData);
    let maxLength = 0;
    let tmpLength = 0;

    for (let i = 0; i < tmpData.length; i++) {
      if (!tmpData[i][key]?.value || (keyIndex + 1 < keys.length && !tmpData[i][keys[keyIndex + 1]]?.value)) {
        continue;
      } else {
        tmpLength = formatValueToLength(tmpData[i][key].value);
        if (tmpLength > maxLength) {
          maxLength = tmpLength;
        }
      }
    }

    let columnWidth = BASE_CHARTER_WIDTH * maxLength;
    if (keyIndex >= fixCount) {
      columnWidthList.add(columnWidth);
    }
    tableWidth += columnWidth;
    return columnWidth;
  };

  let formatData = () => {
    let dataList = [];
    let tmpData = cloneDeep(tableData);
    tmpData.splice(0, titleLastRow + 1);
    for (let i = 0; i < tmpData.length; i++) {
      let obj = {};
      obj['key'] = i;
      for (let j = 0; j < keys.length; j++) {
        let cellValue = { value: '', bgColor: '' };
        cellValue.value = tmpData[i][keys[j]]?.value ?? '';
        cellValue.bgColor = tmpData[i][keys[j]]?.bgColor ?? '';
        obj[keys[j] + ''] = cellValue;
      }
      dataList.push(obj);
    }
    return dataList;
  };

  const setDataToTree = (dataObj) => {
    let newDataObj = [];
    let parentLevelLastIndexList = [];
    for (let i = 0; i < fixCount; i++) {
      parentLevelLastIndexList.push(0);
    }
    dataObj[0].key = 'A00';
    newDataObj.push(dataObj[0]);
    for (let i = 1; i < dataObj.length; i++) {
      let tempDataObj = dataObj[i];
      for (let j = 0; j < fixCount; j++) {
        if (tempDataObj[keys[j]].value !== '' && j === 0) {
          dataObj[i].key = 'A' + i + j;
          newDataObj.push(dataObj[i]);
          parentLevelLastIndexList[0] = newDataObj.length - 1;
          break;
        } else if (tempDataObj[keys[j]].value !== '' && j !== 0) {
          let g = 1;
          newDataObj[parentLevelLastIndexList[0]]['children'] = newDataObj[parentLevelLastIndexList[0]]['children'] ?? [];
          let tempObj = newDataObj[parentLevelLastIndexList[0]]['children'];
          while (g < j) {
            tempObj[parentLevelLastIndexList[g]]['children'] = tempObj[parentLevelLastIndexList[g]]['children'] ?? [];
            tempObj = tempObj[parentLevelLastIndexList[g]]['children'];
            g++;
          }

          tempDataObj.key = 'A' + i + j;
          tempDataObj[keys[0]].value = tempDataObj[keys[j]].value;
          tempDataObj[keys[j]].value = '';
          tempObj.push(tempDataObj);
          parentLevelLastIndexList[g] = tempObj.length - 1;
          while (g < parentLevelLastIndexList.length - 1) {
            parentLevelLastIndexList[++g] = 0;
          }
          break;
        }
      }
    }
    return newDataObj;
  };

  const deleteProperties = (itemObj, propertyName) => {
    delete itemObj[propertyName];
  };

  const loopColumnChildrenAndDeleteProperties = (parentItem) => {
    let item = parentItem;
    if (item.children && parentItem.children.length > 0) {
      for (let i = 0; i < item.children.length; i++) {
        loopColumnChildrenAndDeleteProperties(item.children[i]);
      }
    } else {
      deleteProperties(item, 'width');
    }
  };

  const loopColumnChildrenAndResetWidth = (parentItem) => {
    let item = parentItem;
    if (item.children && parentItem.children.length > 0) {
      for (let i = 0; i < item.children.length; i++) {
        loopColumnChildrenAndResetWidth(item.children[i]);
      }
    } else {
      let newWidthList = setWidthToSame();
      let newIndex = newWidthList.findIndex((nWidth) => nWidth.oldWidth === item.width);
      if (newIndex !== -1) {
        item.width = newWidthList[newIndex].newWidth;
      }
    }
  };

  let resetColumnWidth = (tableColumnsO) => {
    const tableColumnsN = cloneDeep(tableColumnsO);
    if (SCREEN_WIDTH > tableWidth) {
      for (let i = 0; i < tableColumnsN.length; i++) {
        // if(tableColumnsN[i].fixed) {
        //   continue;
        // } else {
        loopColumnChildrenAndDeleteProperties(tableColumnsN[i]);
        // }
      }
    } else {
      for (let i = 0; i < tableColumnsN.length; i++) {
        if (tableColumnsN[i].fixed) {
          continue;
        } else {
          loopColumnChildrenAndResetWidth(tableColumnsN[i]);
        }
      }
    }
    return tableColumnsN;
  };

  let setWidthToSame = () => {
    const widthArray = Array.from(columnWidthList) as number[];
    const arrayLastIndex = widthArray.length - 1;
    const curseIndexList = [];
    widthArray.sort((a, b) => (a > b ? 1 : -1));
    for (let i = 0; i < widthArray.length - 1; i++) {
      for (let j = arrayLastIndex; j > 0; j--) {
        if (widthArray[i] > widthArray[j] - 30) {
          curseIndexList.push(j);
          i = j;
          break;
        }
      }
    }
    const newWidthObjList = widthArray.reduce((a, b, index) => {
      for (let i = 0; i < curseIndexList.length; i++) {
        if (index < curseIndexList[i]) {
          a.push({
            oldWidth: b,
            newWidth: widthArray[curseIndexList[i]],
          });
        }
      }
      return a;
    }, []);
    return newWidthObjList;
  };

  keys = getKeys(tableData[0]);
  titleLastRow = getTitleArea(tableData);
  let tableColumns: any = [];
  let tableSource: any = [];
  // set table layout function by table type begin
  switch (tableType) {
    case 'a':
      setFixedColumn = tableCustomFunForA.setFixedColumn;
      setColumnWidth = tableCustomFunForA.setColumnWidth;
      formatData = tableCustomFunForA.formatData;
      break;
    default:
      break;
  }
  // set table layout function by table type end

  tableColumns = formatTableColumn();
  tableColumns = resetColumnWidth(tableColumns);
  tableSource = formatData();

  return {
    tableColumns: tableColumns,
    tableSource: tableSource,
    titleRowCount: titleLastRow,
  };
};
export default mockList;
