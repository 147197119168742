import React, { useContext, useEffect, useState } from 'react';
import { isFinite } from 'lodash';
import VirtualTable from '../widget/virtual-table';
import { ProjectSetupDetailContext } from '../../contexts/project-setup-detail.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import { AppContext } from '../../contexts/AppContext';
import { round } from '../../utils/commonUtil';
import { useTranslation } from 'react-i18next';

type ProjectSetupSupplyTableData = {
  timeline: string[];
  data: any;
  loading: boolean;
};

const ProjectSetupSupplyTable: React.FC<ProjectSetupSupplyTableData> = (props) => {
  const { timeline, data, loading } = props;
  const { t } = useTranslation();
  const { searchSupplyForm, mhSwitch, defaultMh } = useContext(ProjectSetupDetailContext);

  const { color } = useContext(AppContext);

  const { selectorTop } = useWindowSize({
    selector: '.cmcty-modal-body .cmcty-table-body',
  });

  const [columns, setColumns] = useState([]);

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableColumns();
  }, [tableData, timeline, mhSwitch]);

  useEffect(() => {
    if (!searchSupplyForm) {
      return;
    }
    const { division, discipline, roles } = searchSupplyForm;

    const filterData = data.filter(
      (item) => division.includes(item.division) && discipline.includes(item.discipline) && roles.includes(item.role)
    );
    setTableData(filterData.sort((a, b) => b.imbalancedMh - a.imbalancedMh));
  }, [data, searchSupplyForm]);

  const setTableColumns = () => {
    const baseColumns = [
      {
        title: 'Supply',
        dataIndex: 'Supply',
        key: 'Supply',
        children: [
          {
            title: 'Division',
            dataIndex: 'division',
            key: 'division',
            width: 150,
            ellipsis: true,
            render: (text) => text || '-',
            // fixed: 'left'
          },
          {
            title: 'Discipline',
            dataIndex: 'discipline',
            key: 'discipline',
            width: 120,
            ellipsis: true,
            render: (text) => text || '-',
            // fixed: 'left'
          },
          {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: 80,
            ellipsis: true,
            render: (text) => text || '-',
            // fixed: 'left'
          },
          {
            title: `${t('aipcmcty.page.excessDeficit')}MH`,
            dataIndex: 'imbalancedMh',
            key: 'imbalancedMh',
            width: 120,
            className: 'text-right',
            render: (text) => (isFinite(text) ? round(text) : '-'),
            sorter: (a, b) => Number(a.imbalancedMh) - Number(b.imbalancedMh),
            defaultSortOrder: 'descend',
          },
          {
            title: `${t('aipcmcty.page.supply')}MH`,
            dataIndex: 'supplyMh',
            key: 'supplyMh',
            width: 90,
            className: 'text-right',
            render: (text) => (isFinite(text) ? round(text) : '-'),
          },
          {
            title: `${t('aipcmcty.page.demand')}MH`,
            dataIndex: 'demandMh',
            key: 'demandMh',
            width: 90,
            className: 'text-right whiteSpaceNowrap',
            render: (text) => (isFinite(text) ? round(text) : '-'),
          },
          {
            title: t('aipcmcty.page.matching'),
            dataIndex: 'dsMatched',
            key: 'dsMatched',
            width: 110,
            className: 'text-right',
            render: (text) =>
              text ? <span style={{ color: color.successColor }}>〇</span> : <span style={{ color: color.errorColor }}>✕</span>,
          },
        ],
      },
      {
        title: () => <></>,
        dataIndex: 'Japan',
        key: 'Japan',
        className: 'formation-tag-title',
        children: [],
      },
    ];

    baseColumns[baseColumns.length - 1].children = timeline.map((item) => ({
      title: item,
      dataIndex: `${mhSwitch}#${item}`,
      key: `${mhSwitch}#${item}`,
      width: 90,
      className: 'text-right',
      render: (text) => (isFinite(text) ? round(text) : '-'),
    }));

    setColumns(baseColumns);
  };

  return (
    <>
      <div className="table-container" style={{ marginTop: 5 }}>
        <VirtualTable
          size="small"
          loading={loading}
          columns={columns}
          dataSource={tableData}
          scroll={{ y: document.body.clientHeight - selectorTop - 60 + 190 }}
        />
      </div>
    </>
  );
};

export default ProjectSetupSupplyTable;
