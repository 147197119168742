/* eslint-disable no-template-curly-in-string */
import {
  FileExclamationOutlined,
  FormOutlined,
  RollbackOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Empty,
  InputNumber,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { memo, useContext, useState } from 'react';
import { debounce, isArray, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ProjectSetupContext } from '../../contexts/project-setup.context';
import { AppContext } from '../../contexts/AppContext';
import { round } from '../../utils/commonUtil';
import { localeMapping } from '../../consts/locale.mapping';
import APIList from '../../http/ApiList';
import { exportTableFromExcelTemp } from '../../utils/xlsxReader';

export type ProjectAttrsType = {
  // 会計年度
  accountingYear: string;
  // 役務範囲
  scope: string;
  // 新規/既存
  isExisted: string;
  // 非EPC/EPC
  isEpc: string;
  // 事業区分
  businessType: string;
  // 商品区分
  goodsType: string;
  // 戦略性
  strategic: string;
  // 顧客関係
  customerRelationship: string;
};

export type ProjectKPIType = {
  key: string;
  // Mhあたり粗利
  grossProfitPerMh: number;
  // NPV粗利率
  npvGrossMargin: string;
  // 総業務量
  totalWorkload: number;
  // 想定生産性
  expectedProductivity: string;
  // リソース需要Mh
  resourceDemandMh: number;
  // リソース供給Mh
  resourceSupplyMh: number;
  // 需給過不足Mh
  imbalancedMh: number;
  // DX Allowanceフルポテ
  dxFullPotential: number;
  // DX Allowance割引率
  dxDiscountRate: number;
  // DX Allowance積上額
  dxDiscounted: number;
  won: number;
};

/* @noflow */
export interface ProjectSetupTableItem {
  key: string;
  // 案件 UUID
  id: string;
  // 案件ID
  projectId: string;
  // 案件名
  projectName: string;
  // TGC
  tgc: string;
  // 予算カテゴリ
  budgetCategory: string;
  budgetCategoryDefault: string;
  budgetCategoryCustom: string;
  // 最優先
  priorityMatching: boolean;
  // 優先度
  priorityDefault: number;
  // 受注金額
  orderAmount: number;
  // 受注金額_default
  orderAmountDefault: number;
  orderAccumulation: number;
  // 粗利率
  grossMargin: number;
  grossMarginDefault: number;
  grossProfit: number;
  grossProfitDefault: number;
  profitAccumulation: number;
  // 需要Mh
  demandMh: number;
  // Won%
  won: number;
  // フェーズ / ステージ
  phase: string;
  phaseDefault: string;
  // 確度
  certainty: string;
  // 開始予定日
  projectStartDate: Date;
  projectStartDateDefault: Date;
  // 終了予定日
  projectEndDate: Date;
  projectEndDateDefault: Date;
  // 期間
  duration: number;
  durationDefault: number;
  // Feed開始予定日
  feedStartDate: Date;
  feedStartDateDefault: Date;
  // Feed終了予定日
  feedEndDate: Date;
  feedEndDateDefault: Date;
  // Feed期間
  feedDuration: number;
  feedDurationDefault: number;
  // DX適用
  dxAvailable: boolean;
  dxApplicable: boolean;
  formJvFeedE: number;
  formJvFeedP: number;
  formJvFeedC: number;
  formJvFeedPjt: number;
  formJvEpcE: number;
  formJvEpcP: number;
  formJvEpcC: number;
  formJvEpcPjt: number;
  formTgcFeedE: string;
  formTgcFeedP: string;
  formTgcFeedC: string;
  formTgcFeedPjt: string;
  formTgcEpcE: string;
  formTgcEpcP: string;
  formTgcEpcC: string;
  formTgcEpcPjt: string;
  // アクション / アラート
  action: string[];
  // 会計年度
  accountingYear: string;
  // 役務範囲
  scope: string;
  // 新規/既存
  isExisted: string;
  // 非EPC/EPC
  isEpc: string;
  // 事業区分
  businessType: string;
  // 商品区分
  goodsType: string;
  // 戦略性
  strategic: string;
  // 顧客関係
  customerRelationship: string;
  // Mhあたり粗利
  grossProfitPerMh: number;
  // NPV粗利率
  npvGrossMargin: number;
  // 総業務量
  totalWorkload: number;
  // 想定生産性
  expectedProductivity: string;
  // リソース需要Mh
  resourceDemandMh: number;
  // リソース供給Mh
  resourceSupplyMh: number;
  // 需給過不足Mh
  imbalancedMh: number;
  // DX Allowanceフルポテ
  dxFullPotential: number;
  // DX Allowance割引率
  dxDiscountRate: number;
  // DX Allowance積上額
  dxDiscounted: number;
  demandForecastType: string;
  constructionLocation: string;
  subCount: number;
  memoCount: number;
  isChildren: boolean;
  children?: ProjectSetupTableItem[];
}

const ProjectSetupTable: React.FC<{
  loading: boolean;
  data: ProjectSetupTableItem[];
  viewMode?: string;
  columnFilter?: {
    update: string[];
    query: string[];
    hidden: string[];
  };
  onPageSizeChange: (pageSize: number) => void;
  onSortChange: () => void;
  onExpandChange: (projectId: string, expanded: boolean) => void;
  pageSize: number;
  needDisable: boolean;
  expandedRows: string[];
}> = (props) => {
  // Common Config
  const {
    data,
    viewMode,
    columnFilter,
    loading,
    onPageSizeChange,
    onSortChange,
    pageSize,
    needDisable,
    onExpandChange,
    expandedRows,
  } = props;

  const { snapshot, snapshotVersion } = useContext(AipcmctyContext);
  const { demandMhPercent, certainty, locale } = useContext(AppContext);
  const {
    setIsModalOpen,
    setMemoCollapsed,
    setMemoInfo,
    setMenuCollapsed,
    setFilterCollapsed,
    optData,
    setFormationModalOpen,
    setCurTableItem,
    handleTableChange,
    setCurModalItem,
    handleTableReset,
    isUseCaseOne = false,
    calcFeedActive,
    setCachedFormation,
    filtersData,
    accountingPerspective,
    accountingPerspectiveOpt,
    imbalancedMhOpt,
    priorityMatchingOpt,
    searchData,
    filterMethods,
  } = useContext(ProjectSetupContext);

  const { selectorHeight4Table } = useWindowSize({
    selector: '.project-table .cmcty-table-tbody',
    viewMode,
    watcherSelector: ['.operation-container'],
  });

  const { t } = useTranslation();

  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleMemoClick = (item: ProjectSetupTableItem) => {
    setMenuCollapsed(true);
    setFilterCollapsed(true);
    setMemoCollapsed(false);
    setMemoInfo({ projectId: item.projectId, projectName: item.projectName });
  };

  const fixed = (val: string | number, length = 0) => Number(Number(val).toFixed(length));

  // Table Config
  const columns: ColumnType<ProjectSetupTableItem>[] = [
    {
      key: 'expand',
      width: 30,
      fixed: 'left',
    },
    {
      title: t('aipcmcty.page.priority'),
      dataIndex: 'priorityMatching',
      key: 'priorityMatching',
      width: 60,
      className: 'small-column',
      fixed: 'left',
      sorter: (a, b) => Number(a.priorityMatching) - Number(b.priorityMatching),
      render: (val, item) => (!item.isChildren ? (
        <Switch
          disabled={!needDisable}
          checked={val}
          onChange={(e) => handleTableChange('priorityMatching', e, item.id)}
        />
      ) : null),
    },
    {
      title: t('aipcmcty.page.projectCaseId'),
      dataIndex: 'projectId',
      key: 'projectId',
      width: 80,
      fixed: 'left',
      className: 'align-right small-column',
      sorter: (a, b) => a.projectId.localeCompare(b.projectId),
      render: (val) => {
        const ids = val ? val.split(',') : '-';
        return ids.map((id) => (
          <div key={id} style={{ lineHeight: '16px' }}>
            {id}
          </div>
        ));
      },
    },
    {
      title: t('aipcmcty.page.projectCaseName'),
      dataIndex: 'projectName',
      key: 'projectName',
      fixed: 'left',
      width: 180,
      className: 'align-left small-column',
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      render(val, item) {
        const attrSource = {
          accountingYear: item.accountingYear,
          scope: item.scope,
          isExisted: item.isExisted,
          isEpc: item.isEpc,
          businessType: item.businessType,
          goodsType: item.goodsType,
          strategic: item.strategic,
          customerRelationship: item.customerRelationship,
        };
        const grossProfitPerMh = !!Number(item.grossProfitPerMh) && Number(item.grossProfitPerMh) !== 0
          ? Number(item.grossProfitPerMh).toFixed(2)
          : item.grossProfitPerMh;
        const kpiSource = {
          grossProfitPerMh: grossProfitPerMh,
          npvGrossMargin: `${round(item.npvGrossMargin * 100).toFixed(1)}%`,
          totalWorkload: item.totalWorkload,
          expectedProductivity: item.expectedProductivity,
          resourceDemandMh: round(item.resourceDemandMh),
          resourceSupplyMh: round(item.resourceSupplyMh),
          imbalancedMh: round(item.imbalancedMh),
          dxFullPotential: item.dxFullPotential,
          dxDiscountRate: item.dxDiscountRate,
          dxDiscounted: item.dxDiscounted,
          won: item.won,
        };
        const content = (
          <Space direction="vertical">
            <Table
              title={() => t('aipcmcty.page.projectAttribute')}
              columns={projectAttrCols}
              dataSource={[attrSource]}
              pagination={false}
            />
            <Table
              title={() => t('aipcmcty.page.projectKpi')}
              columns={projectKPICols as any}
              dataSource={[kpiSource]}
              pagination={false}
            />
          </Space>
        );
        return (
          <Row align="middle" style={{ width: '100%' }}>
            {item.isChildren ? (
              <>
                <Col span={24}>
                  <Tooltip
                    mouseLeaveDelay={0}
                    mouseEnterDelay={0.8}
                    title={val}
                    placement="topLeft"
                  >
                    <div className="project-name ellipsis-text">
                      <span>{val}</span>
                    </div>
                  </Tooltip>
                </Col>
              </>
            ) : (
              <>
                <Col span={22}>
                  <Tooltip
                    mouseLeaveDelay={0}
                    mouseEnterDelay={0.8}
                    title={val}
                    placement="topLeft"
                  >
                    <Button
                      className="project-name ellipsis-text"
                      type="link"
                      onClick={() => {
                        setIsModalOpen(true);
                        setCurModalItem({ ...item });
                      }}
                    >
                      {val}
                    </Button>
                  </Tooltip>
                </Col>
                <Col span={2}>
                  <Popover
                    content={content}
                    trigger="hover"
                    placement="rightBottom"
                  >
                    <FileExclamationOutlined />
                  </Popover>
                </Col>
              </>
            )}
          </Row>
        );
      },
    },
    {
      title: t('aipcmcty.page.year'),
      dataIndex: 'accountingYear',
      key: 'accountingYear',
      width: 60,
      fixed: 'left',
      className: 'align-left small-column',
      sorter: (a, b) => a.accountingYear.localeCompare(b.accountingYear),
      render: (val) => val ?? '-',
    },
    {
      title: 'TGC',
      dataIndex: 'tgc',
      key: 'tgc',
      width: 70,
      fixed: 'left',
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aTgc = a.tgc ?? '';
        const bTgc = b.tgc ?? '';
        return aTgc.localeCompare(bTgc);
      },
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.budgetCategory'),
      dataIndex: 'budgetCategoryCustom',
      key: 'budgetCategoryCustom',
      fixed: 'left',
      width: 120,
      className: 'small-column',
      sorter: (a, b) => a.budgetCategory.localeCompare(b.budgetCategory),
      render(val, item) {
        const curOrder = optData?.budgetCategory.find(
          (p) => p.value === (val ?? item.budgetCategory)
        )?.order;
        const defaultOrder = optData?.budgetCategory.find(
          (p) => p.value === item.budgetCategoryDefault
        ).order;
        let classNames = 'project-select left';
        if (!needDisable || item.isChildren) {
          classNames += ' table-item-disable';
        }
        if (curOrder > defaultOrder) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-select greater';
        }
        if (curOrder < defaultOrder) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-select less';
        }
        const content = (
          <>
            <span>{`SFDC: ${item.budgetCategoryDefault}`}</span>
            <br />
            <span>{`CMC: ${item.budgetCategory}`}</span>
          </>
        );
        return (
          <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={content}>
            <Select
              disabled={!needDisable || item.isChildren}
              className={classNames}
              value={val ?? item.budgetCategory}
              options={optData?.budgetCategory}
              onChange={(e) => {
                handleTableChange('budgetCategoryCustom', e, item.id);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: `${t('aipcmcty.page.constructionSite')}`,
      dataIndex: 'constructionLocation',
      key: 'constructionLocation',
      width: 90,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aLoc = a.constructionLocation ?? '';
        const bLoc = b.constructionLocation ?? '';
        return aLoc.localeCompare(bLoc);
      },
      render: (val) => (
        <Tooltip
          mouseLeaveDelay={0}
          mouseEnterDelay={0.8}
          title={val ?? '-'}
          placement="topLeft"
        >
          <div
            style={{
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {val ?? '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('aipcmcty.page.business'),
      dataIndex: 'businessType',
      key: 'businessType',
      width: 60,
      className: 'align-left small-column',
      sorter: (a, b) => {
        const aLoc = a.constructionLocation ?? '';
        const bLoc = b.constructionLocation ?? '';
        return aLoc.localeCompare(bLoc);
      },
      render: (val) => val || '-',
    },
    {
      title: `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`,
      dataIndex: 'isExisted',
      key: 'isExisted',
      width: 100,
      className: 'align-left small-column',
      sorter: (a, b) => a.isExisted.localeCompare(b.isExisted),
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.customerRelationship'),
      dataIndex: 'customerRelationship',
      key: 'customerRelationship',
      width: 100,
      className: 'align-left small-column',
      sorter: (a, b) => a.customerRelationship.localeCompare(b.customerRelationship),
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.orderAmount'),
      dataIndex: 'orderAmount',
      key: 'orderAmount',
      width: 150,
      className: 'align-right small-column',
      sorter: (a, b) => a.orderAmount - b.orderAmount,
      render(val, item) {
        const toolTip = (
          <>
            <span>
              {`SFDC: ${fixed(
                item.orderAmountDefault
              ).toLocaleString()}`}
            </span>
            <br />
            <span>{`Won%: ${round(val * item.won).toLocaleString()}`}</span>
          </>
        );
        let classNames = 'project-number';
        if (!needDisable || item.isChildren) {
          classNames += ' table-item-disable';
        }
        if (val < item.orderAmountDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.orderAmountDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={toolTip}
            trigger="hover"
            placement="top"
          >
            <div>
              <InputNumber
                disabled={!needDisable || item.isChildren}
                className={classNames}
                value={val}
                controls={false}
                formatter={(value) => fixed(value).toLocaleString()}
                parser={(value) => value!.replace(/(,*)/g, '')}
                onChange={debounce(
                  (e) => handleTableChange('orderAmount', e, item.id),
                  800
                )}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.grossProfitRate'),
      dataIndex: 'grossMargin',
      key: 'grossMargin',
      width: 90,
      className: 'small-column',
      sorter: (a, b) => a.grossMargin - b.grossMargin,
      render(val, item) {
        let classNames = 'project-number';
        if (!needDisable || item.isChildren) {
          classNames += ' table-item-disable';
        }
        if (val < item.grossMarginDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.grossMarginDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${round(item.grossMarginDefault * 100).toFixed(1)}%`}
          >
            <div>
              <InputNumber
                disabled={!needDisable || item.isChildren}
                className={classNames}
                value={val}
                controls={false}
                min={0}
                max={100}
                formatter={(value) => `${round(value * 100).toFixed(1)}%`}
                parser={(value) => Number(value.replace('%', '')) / 100}
                onChange={debounce(
                  (e) => handleTableChange('grossMargin', e, item.id),
                  800
                )}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.grossProfitAmt'),
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => a.grossProfit - b.grossProfit,
      render: (val, item) => {
        let classNames = 'project-number';
        if (val < item.grossProfitDefault) {
          classNames += ' sfdc-number less';
        }
        if (val > item.grossProfitDefault) {
          classNames += ' sfdc-number greater';
        }
        const toolTip = (
          <>
            <span>
              {`SFDC: ${fixed(
                item.grossProfitDefault
              ).toLocaleString()}`}
            </span>
            <br />
            <span>{`Won%: ${round(val * item.won).toLocaleString()}`}</span>
          </>
        );
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={toolTip}
            trigger="hover"
            placement="top"
          >
            <span className={classNames}>{fixed(val).toLocaleString()}</span>
          </Tooltip>
        );
      },
    },
    {
      title: `${t('aipcmcty.page.grossProfit')}/MH`,
      dataIndex: 'grossProfitPerMh',
      key: 'grossProfitPerMh',
      width: 90,
      className: 'small-column',
      sorter: (a, b) => a.grossProfitPerMh - b.grossProfitPerMh,
      render: (val) => (val ? fixed(val).toLocaleString() : val || '-'),
    },
    {
      title: t('aipcmcty.page.serviceScope'),
      dataIndex: 'scope',
      key: 'scope',
      className: 'align-left small-column',
      width: 180,
      sorter: (a, b) => a.scope.localeCompare(b.scope),
      render: (val) => (
        <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val}>
          <div
            style={{
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {val}
          </div>
        </Tooltip>
      ),
    },
    {
      title: `${t('aipcmcty.page.demand')}MH`,
      dataIndex: 'demandMh',
      key: 'demandMh',
      className: 'align-right small-column',
      width: 100,
      sorter: (a, b) => a.demandMh - b.demandMh,
      render(val, item) {
        const toolTip = (
          <>
            <span>{`Won%: ${fixed(val * item.won).toLocaleString()}`}</span>
          </>
        );
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={toolTip}
            trigger="hover"
            placement="top"
          >
            <div>{val ? Number(val).toLocaleString() : val || '-'}</div>
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.startDate'),
      dataIndex: 'projectStartDate',
      key: 'projectStartDate',
      width: 130,
      className: 'small-column',
      sorter: (a, b) => {
        if (moment(a.projectStartDate).isBefore(b.projectStartDate)) {
          return -1;
        }
        if (moment(a.projectStartDate).isAfter(b.projectStartDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        const curMoment = moment(val);
        const defaultMoment = moment(item.projectStartDateDefault);
        let classNames = '';
        if (!needDisable || item.isChildren) {
          classNames += ' table-item-disable';
        }
        if (curMoment.isBefore(defaultMoment)) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-date less';
        }
        if (curMoment.isAfter(defaultMoment)) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-date greater';
        }
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}
          >
            <DatePicker
              disabled={!needDisable || item.isChildren}
              className={classNames}
              allowClear={false}
              defaultValue={moment(new Date())}
              value={curMoment}
              format="YYYY-MM-DD"
              onChange={debounce(
                (date) => handleTableChange('projectStartDate', date, item.id),
                800
              )}
            />
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.endDate'),
      dataIndex: 'projectEndDate',
      key: 'projectEndDate',
      width: 110,
      className: 'small-column',
      sorter: (a, b) => {
        if (moment(a.projectEndDate).isBefore(b.projectEndDate)) {
          return -1;
        }
        if (moment(a.projectEndDate).isAfter(b.projectEndDate)) {
          return 1;
        }
        return 0;
      },
      render(val, item) {
        // const curMoment = moment(val);
        const defaultMoment = moment(item.projectEndDateDefault);
        // let classNames = '';
        // if (curMoment.isBefore(defaultMoment)) {
        //   classNames += ' sfdc-date less';
        // }
        // if (curMoment.isAfter(defaultMoment)) {
        //   classNames += ' sfdc-date greater';
        // }
        // return (
        //   <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}>
        //     <DatePicker
        //       className={classNames}
        //       allowClear={false}
        //       defaultValue={moment(new Date())}
        //       value={curMoment}
        //       format="YYYY-MM-DD"
        //       onChange={debounce(date => handleTableChange('projectEndDate', date, item.id), 800)}
        //     />
        //   </Tooltip>
        // );
        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${defaultMoment.format('YYYY-MM-DD')}`}
          >
            {val || '-'}
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.duration'),
      dataIndex: 'duration',
      key: 'duration',
      width: 70,
      className: 'small-column',
      sorter: (a, b) => a.duration - b.duration,
      render(val, item) {
        let classNames = 'project-number';
        if (!needDisable || item.isChildren) {
          classNames += ' table-item-disable';
        }
        if (val < item.durationDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number less';
        }
        if (val > item.durationDefault) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-number greater';
        }

        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${item.durationDefault}`}
          >
            <div>
              <InputNumber
                disabled={!needDisable || item.isChildren}
                className={classNames}
                value={val}
                controls={false}
                min={1}
                onChange={debounce(
                  (e) => handleTableChange('duration', e, item.id),
                  800
                )}
              />
            </div>
          </Tooltip>
        );
        // return (
        //   <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${item.durationDefault}`}>
        //     {val || '-'}
        //   </Tooltip>
        // );
      },
    },
    // {
    //   title: 'FEED開始日',
    //   dataIndex: 'feedStartDate',
    //   key: 'feedStartDate',
    //   width: 150,
    //   align: 'left',
    //   sorter: (a, b) => {
    //     if (!!a.feedStartDate && !!b.feedStartDate) {
    //       if (moment(a.feedStartDate).isBefore(b.feedStartDate)) {
    //         return -1;
    //       }
    //       if (moment(a.feedStartDate).isAfter(b.feedStartDate)) {
    //         return 1;
    //       }
    //     } else {
    //       const aV = a.feedStartDate ?? '';
    //       const bV = b.feedStartDate ?? '';
    //       if (aV > bV) {
    //         return 1;
    //       }
    //       if (aV < bV) {
    //         return -1;
    //       }
    //     }
    //     return 0;
    //   },
    //   render(val, item) {
    //     const curMoment = val ? moment(val) : null;
    //     const defaultMoment = item.feedStartDateDefault ? moment(item.feedStartDateDefault) : null;
    //     let classNames = '';
    //     if (curMoment?.isBefore(defaultMoment)) {
    //       classNames += ' sfdc-date less';
    //     }
    //     if (curMoment?.isAfter(defaultMoment)) {
    //       classNames += ' sfdc-date greater';
    //     }
    //     const isFeedActive = item.demandForecastType?.includes('effimate') && calcFeedActive(item);
    //     return isFeedActive
    //       ? (
    //         <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment?.format('YYYY-MM-DD') ?? null}`}>
    //           <DatePicker
    //             disabled={!isFeedActive}
    //             className={classNames}
    //             allowClear={false}
    //             defaultValue={moment(new Date())}
    //             value={curMoment}
    //             format="YYYY-MM-DD"
    //             onChange={debounce(date => handleTableChange('feedStartDate', date, item.id), 800)}
    //           />
    //         </Tooltip>
    //       )
    //       : (
    //         <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment?.format('YYYY-MM-DD') ?? null}`}>
    //           { val ?? '-' }
    //         </Tooltip>
    //       );
    //   }
    // },
    // {
    //   title: 'FEED終了日',
    //   dataIndex: 'feedEndDate',
    //   key: 'feedEndDate',
    //   width: 150,
    //   align: 'left',
    //   sorter: (a, b) => {
    //     if (!!a.feedStartDate && !!b.feedStartDate) {
    //       if (moment(a.feedStartDate).isBefore(b.feedStartDate)) {
    //         return -1;
    //       }
    //       if (moment(a.feedStartDate).isAfter(b.feedStartDate)) {
    //         return 1;
    //       }
    //     } else {
    //       const aV = a.feedStartDate ?? '';
    //       const bV = b.feedStartDate ?? '';
    //       if (aV > bV) {
    //         return 1;
    //       }
    //       if (aV < bV) {
    //         return -1;
    //       }
    //     }
    //     return 0;
    //   },
    //   render(val, item) {
    //     const curMoment = val ? moment(val) : null;
    //     const defaultMoment = item.feedEndDateDefault ? moment(item.feedEndDateDefault) : null;
    //     let classNames = '';
    //     if (curMoment?.isBefore(defaultMoment)) {
    //       classNames += ' sfdc-date less';
    //     }
    //     if (curMoment?.isAfter(defaultMoment)) {
    //       classNames += ' sfdc-date greater';
    //     }
    //     const isFeedActive = item.demandForecastType?.includes('effimate') && calcFeedActive(item);
    //     return isFeedActive
    //       ? (
    //         <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment?.format('YYYY-MM-DD') ?? null}`}>
    //           <DatePicker
    //             disabled={!isFeedActive}
    //             className={classNames}
    //             allowClear={false}
    //             defaultValue={moment(new Date())}
    //             value={curMoment}
    //             format="YYYY-MM-DD"
    //             onChange={debounce(date => handleTableChange('feedEndDate', date, item.id), 800)}
    //           />
    //         </Tooltip>
    //       )
    //       : (
    //         <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${defaultMoment?.format('YYYY-MM-DD') ?? null}`}>
    //           { val ?? '-' }
    //         </Tooltip>
    //       );
    //   }
    // },
    // {
    //   title: 'FEED期間',
    //   dataIndex: 'feedDuration',
    //   key: 'feedDuration',
    //   width: 100,
    //   sorter: (a, b) => a.feedDuration - b.feedDuration,
    //   render(val, item) {
    //     let classNames = 'project-number';
    //     if (val < item.feedDurationDefault) {
    //       classNames += ' sfdc-number less';
    //     }
    //     if (val > item.feedDurationDefault) {
    //       classNames += ' sfdc-number greater';
    //     }
    //     const isFeedActive = item.demandForecastType?.includes('effimate') && calcFeedActive(item);
    //     return isFeedActive
    //       ? (
    //         <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${item.feedDurationDefault}`}>
    //           <InputNumber
    //             disabled={!isFeedActive}
    //             className={classNames}
    //             value={val}
    //             controls={false}
    //             onChange={debounce(e => handleTableChange('feedDuration', e, item.id), 800)}
    //           />
    //         </Tooltip>
    //       )
    //       : (
    //         <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={`SFDC: ${item.feedDurationDefault}`}>
    //           {val === 0 ? '-' : val}
    //         </Tooltip>
    //       );
    //   }
    // },
    {
      title: 'Won%',
      dataIndex: 'won',
      key: 'won',
      width: 80,
      className: 'small-column',
      sorter: (a, b) => a.won - b.won,
      render: (val) => `${round(val * 100)}%`,
    },
    {
      title: t('aipcmcty.page.phase'),
      dataIndex: 'phase',
      key: 'phase',
      width: 150,
      className: 'small-column',
      sorter: (a, b) => a.phase.localeCompare(b.phase),
      render(val, item) {
        const curOrder = optData?.phase.find((p) => p.value === val)?.order;
        const defaultOrder = optData?.phase.find(
          (p) => p.value === item.phaseDefault
        )?.order;
        let classNames = 'project-select left';
        if (!needDisable || item.isChildren) {
          classNames += ' table-item-disable';
        }
        if (curOrder > defaultOrder) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-select greater';
        }
        if (curOrder < defaultOrder) {
          classNames = classNames.replace(' table-item-disable', '');
          classNames += ' sfdc-select less';
        }

        return (
          <Tooltip
            mouseLeaveDelay={0}
            mouseEnterDelay={0.8}
            title={`SFDC: ${item.phaseDefault}`}
          >
            <Select
              disabled={!needDisable || item.isChildren}
              className={classNames}
              value={val}
              options={optData?.phase}
              onChange={(e) => {
                handleTableChange('phase', e, item.id);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: t('aipcmcty.page.accuracy'),
      dataIndex: 'certainty',
      key: 'certainty',
      width: 100,
      className: 'align-left small-column',
      sorter: (a, b) => a.phase.localeCompare(b.certainty),
      render: (val) => (
        <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0.8} title={val}>
          <div
            style={{
              width: '100%',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {val || '-'}
          </div>
        </Tooltip>
      ),
    },
    {
      title: t('aipcmcty.page.priorityLevel'),
      dataIndex: 'priorityDefault',
      key: 'priorityDefault',
      fixed: 'right',
      width: 80,
      // sorter: (a, b) => a.priorityDefault - b.priorityDefault,
      className: 'align-right small-column',
    },
    {
      title: t('aipcmcty.page.DXallowanceDiscountRate'),
      dataIndex: 'dxDiscountRate',
      key: 'dxDiscountRate',
      width: 180,
      className: 'small-column',
      sorter: (a, b) => a.dxDiscountRate - b.dxDiscountRate,
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.dxAllowanceFullPot'),
      dataIndex: 'dxFullPotential',
      key: 'dxFullPotential',
      width: 180,
      className: 'small-column',
      sorter: (a, b) => a.dxFullPotential - b.dxFullPotential,
      render: (val, item) => {
        if (val) {
          if (certainty === 1) {
            return round(val * item.won);
          }
          return val;
        }
        return '-';
      },
    },
    {
      title: t('aipcmcty.page.accumulatedOrderAmount'),
      dataIndex: 'orderAccumulation',
      key: 'orderAccumulation',
      fixed: 'right',
      width: 150,
      className: 'small-column',
      render: (val, item) => (item.isChildren ? '-' : fixed(val).toLocaleString()),
    },
    {
      title: t('aipcmcty.page.accumulatedGrossProfit'),
      dataIndex: 'profitAccumulation',
      key: 'profitAccumulation',
      fixed: 'right',
      width: 130,
      className: 'small-column',
      render: (val, item) => (item.isChildren ? '-' : fixed(val).toLocaleString()),
    },
    {
      title: t('aipcmcty.page.dxApplicable'),
      dataIndex: 'dxApplicable',
      key: 'dxApplicable',
      fixed: 'right',
      width: 80,
      className: 'small-column',
      sorter: (a, b) => Number(a.dxApplicable) - Number(b.dxApplicable),
      render: (val, item) => (!item.isChildren ? (
        <Switch
          disabled={!needDisable || !item.dxAvailable}
          checked={val}
          onChange={(e) => handleTableChange('dxApplicable', e, item.id)}
        />
      ) : null),
    },
    {
      key: 'memo',
      fixed: 'right',
      align: 'right',
      width: 70,
      className: 'small-column',
      onCell: () => ({
        style: {
          paddingRight: 8,
        },
      }),
      render(_, item) {
        if (item.isChildren) {
          return null;
        }
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {item.demandForecastType?.includes('effimate') && (
              <UserSwitchOutlined
                onClick={() => {
                  setFormationModalOpen(true);
                  setCurTableItem({ ...item });
                  const formation: { [key: `form${string}`]: any } = {};
                  Object.keys(item).forEach(curK => {
                    if (curK.startsWith('form')) {
                      formation[curK] = item[curK];
                    }
                  });
                  setCachedFormation(formation);
                }}
              />
            )}
            <Popconfirm
              placement="topRight"
              title={t('aipcmcty.page.resetProjectDefault').replace(
                '${item.projectId}',
                item.projectId
              )}
              onConfirm={() => handleTableReset(item.id)}
              okText={t('aipcmcty.page.yes')}
              cancelText={t('aipcmcty.page.cancel')}
              disabled={!needDisable}
            >
              <RollbackOutlined
                style={{
                  marginLeft: 6,
                  color: !needDisable ? '#aaa' : 'inherit',
                }}
              />
            </Popconfirm>
            <div onClick={() => handleMemoClick(item)} style={{ cursor: 'pointer' }}>
              <Badge dot={item.memoCount > 0} size="small">
                <FormOutlined style={{ marginLeft: 6 }} />
              </Badge>
            </div>
          </div>
        );
      },
    },
  ];

  if (isUseCaseOne) {
    columns.find((c) => c.dataIndex === 'orderAmount').sortOrder = 'descend';
  }

  const copiedColumns = columns
    .filter((c) => !columnFilter.hidden.includes(c.dataIndex as string))
    .reverse();
  const filterFixedColumns = [];
  const filterUpdateColumns = [];
  const filterQueryColumns = [];
  for (let i = copiedColumns.length - 1; i > 0; i--) {
    if (copiedColumns[i].fixed === 'left') {
      filterFixedColumns.push(copiedColumns[i]);
      copiedColumns.splice(i, 1);
    }
  }
  columnFilter.update.forEach((u) => {
    const colIndex = copiedColumns.findIndex((c) => c.dataIndex === u);
    if (colIndex !== -1) {
      filterUpdateColumns.push(copiedColumns[colIndex]);
      copiedColumns.splice(colIndex, 1);
    }
  });
  columnFilter.query.forEach((q) => {
    const colIndex = copiedColumns.findIndex((c) => c.dataIndex === q);
    if (colIndex !== -1) {
      filterQueryColumns.push(copiedColumns[colIndex]);
      copiedColumns.splice(colIndex, 1);
    }
  });
  const filterCommonColumns = copiedColumns.reverse();

  /**
   * Table高度Map
   */
  const tableHeightMap = {
    'chart-table': selectorHeight4Table - 40,
    'table-only': selectorHeight4Table,
    'chart-only': 0,
  };

  // Popover Table Config
  const projectAttrCols: ColumnsType<ProjectAttrsType> = [
    {
      title: t('aipcmcty.page.fiscalYear'),
      dataIndex: 'accountingYear',
      key: 'accountingYear',
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.serviceScope'),
      dataIndex: 'scope',
      key: 'scope',
      render: (val) => val ?? '-',
    },
    {
      title: `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`,
      dataIndex: 'isExisted',
      key: 'isExisted',
      render: (val) => val ?? '-',
    },
    {
      title: `${t('aipcmcty.page.nonEPC')}/EPC`,
      dataIndex: 'isEpc',
      key: 'isEpc',
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.businessType'),
      dataIndex: 'businessType',
      key: 'businessType',
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.productType'),
      dataIndex: 'goodsType',
      key: 'goodsType',
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.strategic'),
      dataIndex: 'strategic',
      key: 'strategic',
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.customerRelationship'),
      dataIndex: 'customerRelationship',
      key: 'customerRelationship',
      render: (val) => val ?? '-',
    },
  ];
  const projectKPICols: ColumnsType<ProjectKPIType> = [
    {
      title: t('aipcmcty.page.grossProfitPerMH'),
      dataIndex: 'grossProfitPerMh',
      key: 'grossProfitPerMh',
      align: 'right',
      render: (val) => (val ? Number(Number(val).toFixed(0)).toLocaleString() : '-'),
    },
    {
      title: t('aipcmcty.page.NPVgrossProfitRate'),
      dataIndex: 'npvGrossMargin',
      key: 'npvGrossMargin',
      align: 'right',
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.totalWorkVolume'),
      dataIndex: 'totalWorkload',
      key: 'totalWorkload',
      align: 'right',
      render: (val) => (val ? Number(val).toLocaleString() : '-'),
    },
    {
      title: t('aipcmcty.page.assumedProductivity'),
      dataIndex: 'expectedProductivity',
      key: 'expectedProductivity',
      align: 'right',
      render: (val) => (val ? Number(val).toLocaleString() : '-'),
    },
    {
      title: t('aipcmcty.page.resourceDemandMH'),
      dataIndex: 'resourceDemandMh',
      key: 'resourceDemandMh',
      align: 'right',
      render: (val) => (val ? Number(val).toLocaleString() : '-'),
    },
    {
      title: t('aipcmcty.page.resourceSupplyMH'),
      dataIndex: 'resourceSupplyMh',
      key: 'resourceSupplyMh',
      align: 'right',
      render: (val) => (val ? Number(val).toLocaleString() : '-'),
    },
    {
      title: t('aipcmcty.page.demandSupplyGapMH'),
      dataIndex: 'imbalancedMh',
      key: 'imbalancedMh',
      align: 'right',
      render: (val) => (val ? Number(val).toLocaleString() : '-'),
    },
    {
      title: t('aipcmcty.page.dxAllowanceFullPot'),
      dataIndex: 'dxFullPotential',
      key: 'dxFullPotential',
      align: 'right',
      render: (val, item) => {
        if (val) {
          if (certainty === 1) {
            return Math.round(val * item.won);
          }
          return val ? Number(val).toLocaleString() : '-';
        }
        return '-';
      },
    },
    {
      title: t('aipcmcty.page.DXallowanceDiscountRate'),
      dataIndex: 'dxDiscountRate',
      key: 'dxDiscountRate',
      align: 'right',
      render: (val) => val ?? '-',
    },
    {
      title: t('aipcmcty.page.dxAllowanceAmount'),
      dataIndex: 'dxDiscounted',
      key: 'dxDiscounted',
      align: 'right',
      render: (val, item) => {
        if (val) {
          if (certainty === 1) {
            return Math.round(val * item.won);
          }
          return val ? Number(val).toLocaleString() : '-';
        }
        return '-';
      },
    },
  ];

  const scrollX = [
    ...filterFixedColumns,
    ...filterUpdateColumns,
    ...filterQueryColumns,
    ...filterCommonColumns,
  ].reduce((prev: number, curCol) => prev + curCol.width, 0);

  const excelJSONDataSetup = () => ({
    projectList: data.map(d => ({
      ...d,
      grossProfitPerMh: Number(d.grossProfitPerMh ?? 0)
    })),
  });

  const excelAoaDataSetup = () => {
    const filterList: any[] = [];
    const certaintyOpt = [
      {
        value: 0,
        label: t('aipcmcty.page.wonPercentageNotConsidered'),
      },
      {
        value: 1,
        label: t('aipcmcty.page.wonPercentageConsidered'),
      },
    ];
    const dxApplicableOpt = optData?.dxApplicable.map((item) => ({
      ...item,
      label: locale.locale === 'ja' ? item.label : localeMapping[item.label],
    }));
    const getValue = (value, SP = ', ') => {
      const EMPTY = '-';
      if (isArray(value)) {
        if (
          value.length === 0
          || value.every((item) => isNil(item) || item === '')
        ) {
          return EMPTY;
        }
        return value.join(SP);
      }
      if (isNil(value) || value === '') {
        return EMPTY;
      }

      return value;
    };
    filterList.push(['Snapshot Version', 'snapshot', getValue(snapshot)]);
    filterList.push(['', 'snapshot version', getValue(snapshotVersion)]);
    filterList.push([
      t('aipcmcty.page.calculationSettings'),
      t('aipcmcty.page.budgetRevenueCalculation'),
      getValue(certaintyOpt.find((item) => item.value === certainty)?.label),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.supplyDemandGapAllowed'),
      getValue(demandMhPercent),
    ]);
    filterList.push([
      'Head Filter',
      t('aipcmcty.page.accountingPerspective'),
      getValue(
        accountingPerspectiveOpt.find(
          (item) => accountingPerspective === item.value
        )?.label
      ),
    ]);
    filterList.push(['', 'TGC', getValue(filtersData.tgc)]);
    filterList.push([
      '',
      t('aipcmcty.page.fiscalYear'),
      getValue(filtersData.accountingYear),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.useCase'),
      getValue(
        optData?.alertOptions.find((item) => item.value === filtersData.useCase)
          ?.text
      ),
    ]);
    filterList.push([
      'Sider Filter',
      `${t('aipcmcty.page.projectCaseName')} / ${t(
        'aipcmcty.page.projectCaseId'
      )}`,
      getValue(filtersData.search),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.serviceScope'),
      getValue(filtersData.scope),
    ]);
    filterList.push([
      '',
      `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`,
      getValue(
        locale.locale === 'ja'
          ? filtersData.isExisted
          : localeMapping[filtersData.isExisted]
      ),
    ]);
    filterList.push([
      '',
      `${t('aipcmcty.page.nonEPC')}/${t('aipcmcty.page.EPC')}`,
      getValue(filtersData.isEpc),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.businessType'),
      getValue(
        filtersData.businessType.map((businessType) => (locale.locale === 'ja'
          ? businessType
          : localeMapping[businessType]))
      ),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.productType'),
      getValue(
        filtersData.goodsType.map((goodsType) => (locale.locale === 'ja' ? goodsType : localeMapping[goodsType]))
      ),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.priority'),
      getValue(
        priorityMatchingOpt.find(
          (item) => item.value === filtersData.priorityMatching
        )?.label
      ),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.priorityLevel'),
      getValue(filtersData.priorityDefault, ' ～ '),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.budgetCategory'),
      getValue(filtersData.budgetCategoryCustom),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.orderAmount'),
      getValue(filtersData.orderAmount),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.grossProfitRate'),
      getValue(filtersData.grossMargin, ' ～ '),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.demandSupplyFilterMH'),
      getValue(
        imbalancedMhOpt.find((item) => item.value === filtersData.imbalancedMh)
          ?.label
      ),
    ]);
    filterList.push(['', 'Won%', getValue(filtersData.won, ' ～ ')]);
    filterList.push([
      '',
      t('aipcmcty.page.phase'),
      getValue(
        filtersData.phase.map((phase) => (locale.locale === 'ja' ? phase : localeMapping[phase]))
      ),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.accuracy'),
      getValue(
        filtersData.certainty.map((certainty) => (locale.locale === 'ja' ? certainty : localeMapping[certainty]))
      ),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.startDate'),
      getValue(
        filtersData.projectStartDate
          ? moment(filtersData.projectStartDate).format('YYYY-MM-DD')
          : null
      ),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.endDate'),
      getValue(
        filtersData.projectEndDate
          ? moment(filtersData.projectEndDate).format('YYYY-MM-DD')
          : null
      ),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.dxApplication'),
      getValue(
        dxApplicableOpt.find((item) => item.value === filtersData.dxApplicable)
          ?.label
      ),
    ]);
    filterList.push([
      'Memo Filter',
      t('aipcmcty.page.memo.keyWord'),
      getValue(searchData.memoKeyWord),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.memo.author'),
      getValue(
        optData.memoCreators
          .filter((item) => searchData.creator.includes(item.value))
          .map((item) => item.label)
      ),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.memo.creationPeriod'),
      getValue([searchData.createdAtStart, searchData.createdAtEnd], ' ～ '),
    ]);
    filterList.push([
      '',
      t('aipcmcty.page.memo.tag'),
      getValue(searchData.memoTag),
    ]);

    return {
      filterList,
    };
  };

  const excelAwaitList = async () => {
    setDownloadLoading(true);
    const { memoList, subProjectList } = await APIList.getExportProjectSetup()
      .get({
        ...searchData,
        snapshot,
        snapshotVersion,
      })
      .finally(() => {
        setDownloadLoading(false);
      });
    const mappedSubList = subProjectList.filter(sb => data.some(d => sb.projectId === d.projectId)).map(s => ({
      ...s,
      grossProfitPerMh: Number(s.grossProfitPerMh ?? 0)
    }));
    const mList = memoList.map((memo) => {
      const item = {
        ...memo,
        createdAt: new Date(memo.createdAt).toLocaleString('ja-JP', {
          hour12: false,
        }),
        updatedAt: new Date(memo.updatedAt).toLocaleString('ja-JP', {
          hour12: false,
        }),
      };
      return item;
    }).filter(m => data.some(d => m.projectId === d.projectId));
    return {
      memoList: mList,
      subProjectList: mappedSubList,
    };
  };

  return (
    <div className="table-container" style={{ position: 'relative' }}>
      <Table
        loading={loading}
        className="project-table"
        style={{ marginTop: 5, paddingBottom: 40, backgroundColor: '#fff' }}
        columns={[
          ...filterFixedColumns,
          ...filterUpdateColumns,
          ...filterQueryColumns,
          ...filterCommonColumns,
        ]}
        expandable={{
          onExpand(expanded, record) {
            const { projectId } = record;
            onExpandChange(projectId, expanded);
          },
          expandedRowKeys: expandedRows,
        }}
        dataSource={data}
        scroll={{ x: scrollX, y: tableHeightMap[viewMode] }}
        rowKey="projectId"
        size="small"
        pagination={{
          position: ['bottomRight'],
          pageSize: pageSize,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        rowClassName={(record) => {
          const addBgKeys = ['Toyo-J', 'Toyo-I'];
          const {
            imbalancedMh, budgetCategoryCustom, tgc, resourceDemandMh
          } = record;
          if (record.isChildren) {
            return 'light-dark-gray';
          }
          if (record.budgetCategoryCustom === 'Exclusion') {
            return 'light-gray';
          }
          if (
            addBgKeys.includes(tgc)
            && imbalancedMh > (resourceDemandMh * demandMhPercent) / 100
          ) {
            return 'light-red';
          }
          if (
            addBgKeys.includes(tgc)
            && ['Awarded', 'Budget'].includes(budgetCategoryCustom)
            && imbalancedMh < (resourceDemandMh * demandMhPercent) / 100
          ) {
            return 'light-green';
          }
          return '';
        }}
        locale={{
          emptyText: (
            <Empty
              style={{ height: selectorHeight4Table - 105 }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('aipcmcty.page.noData')}
            />
          ),
        }}
        onChange={(pagination, f, s, extra) => {
          onPageSizeChange(pagination.pageSize);
          if (extra.action === 'sort') {
            onSortChange();
          }
        }}
      />

      <Button
        type="primary"
        disabled={loading}
        loading={downloadLoading}
        style={{
          position: 'absolute', left: 4, zIndex: 100, bottom: 6
        }}
        onClick={async () => {
          // todo 把数据组装到一起，header尽量用实际显示的语言文字
          const { projectList } = excelJSONDataSetup();
          const { filterList } = excelAoaDataSetup();
          const { memoList, subProjectList } = await excelAwaitList();
          exportTableFromExcelTemp({
            fileName: `project_list_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`,
            templateFilePath: '/assets/project_list.xlsx',
            sheetData: [
              {
                sheet: '案件一覧',
                dataType: 'json',
                data: projectList,
                options: {
                  templateMappingKeyRowIndex: 2
                }
              },
              {
                sheet: 'サブ案件一覧',
                dataType: 'json',
                data: subProjectList,
                options: {
                  templateMappingKeyRowIndex: 2
                }
              },
              {
                sheet: 'メモ一覧',
                dataType: 'json',
                data: memoList,
                options: {
                  templateMappingKeyRowIndex: 2
                }
              },
              {
                sheet: 'フィルタ設定',
                dataType: 'aoa',
                data: filterList,
                options: {
                  merge: [
                    {
                      start: [0, 0],
                      end: [1, 0]
                    },
                    {
                      start: [2, 0],
                      end: [3, 0]
                    },
                    {
                      start: [4, 0],
                      end: [7, 0]
                    },
                    {
                      start: [8, 0],
                      end: [25, 0]
                    },
                    {
                      start: [26, 0],
                      end: [29, 0]
                    },
                  ]
                }
              },
            ]
          });
        }}
      >
        Export to Excel
      </Button>
    </div>
  );
};

export default ProjectSetupTable;
