import React, { useContext, useEffect, useState } from 'react';
import { Select, Switch } from 'antd';
import { groupBy } from 'lodash';
import { AppContext } from '../contexts/AppContext';
import { SupplyValidationContext } from '../contexts/supply-validation.context';
import LayoutSwitcher from './common/layout/layout-switcher';
import { useTranslation } from 'react-i18next';

type TypicalKpiHeaderProp = {
  children: React.ReactNode;
};

const SupplyValidationHeader: React.FC<any> = (props: TypicalKpiHeaderProp) => {
  const { t } = useTranslation();
  const { children } = props;
  const {
    viewMode, setViewMode, projectData, setProjectGroupData
  } = useContext(SupplyValidationContext);
  const [category, setCategory] = useState<string>();
  const [budgetCategory, setBudgetCategory] = useState([]);
  const [amountCategory, setAmountCategory] = useState([]);
  const [accountingYear, setAccountingYear] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [budgetCategoryList, setBudgetCategoryList] = useState([]);
  const [amountCategoryList, setAmountCategoryList] = useState([]);
  const [accountingYearList, setAccountingYearList] = useState([]);

  useEffect(() => {
    const categories = [
      {
        label: `${t('aipcmcty.page.constructionSite')}`,
        value: 'buildingSite',
        key: 'buildingSite',
      },
      {
        label: `${t('aipcmcty.page.product')}`,
        value: 'goods',
        key: 'goods',
      },
      {
        label: `${t('aipcmcty.page.nonEPC')}/EPC`,
        value: 'epc',
        key: 'epc',
      },
      {
        label: `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')}`,
        value: 'isNew',
        key: 'isNew',
      },
    ];
    const budgetCategories = [
      {
        label: 'Awarded',
        value: 'awarded',
        key: 'awarded',
      },
      {
        label: 'Budget',
        value: 'budget',
        key: 'budget',
      },
      {
        label: 'If',
        value: 'if',
        key: 'if',
      },
      {
        label: 'Others',
        value: 'others',
        key: 'others',
      },
    ];
    const amountCategories = [
      {
        label: t('aipcmcty.page.over60Billion'),
        value: 'gt600',
        key: 'gt600',
      },
      {
        label: t('aipcmcty.page.between50to600Billion'),
        value: '50_600',
        key: '50_600',
      },
      {
        label: t('aipcmcty.page.below50Billion'),
        value: 'lt50',
        key: 'lt50',
      },
    ];

    const accountingYears = [
      {
        label: 'FY21',
        value: 'FY21',
        key: 'FY21',
      },
      {
        label: 'FY22',
        value: 'FY22',
        key: 'FY22',
      },
      {
        label: 'FY23',
        value: 'FY23',
        key: 'FY23',
      },
      {
        label: 'FY24',
        value: 'FY24',
        key: 'FY24',
      },
      {
        label: 'FY25',
        value: 'FY25',
        key: 'FY25',
      },
      {
        label: 'FY26',
        value: 'FY26',
        key: 'FY26',
      },
    ];

    setCategory('buildingSite');
    setBudgetCategory(['awarded', 'budget']);
    setAmountCategory(['gt600', '50_600', 'lt50']);
    setAccountingYear(['FY24']);

    setAccountingYearList(accountingYears);
    setCategoryList(categories);
    setBudgetCategoryList(budgetCategories);
    setAmountCategoryList(amountCategories);
  }, []);

  useEffect(() => {
    if (projectData) {
      const filtered = projectData.filter((item) => {
        const unit = 100000000;
        let amount = false;
        if (amountCategory.includes('gt600')) {
          amount = amount || item.orderAmount >= 600 * unit;
        }
        if (amountCategory.includes('50_600')) {
          amount = amount
            || (item.orderAmount < 600 * unit && item.orderAmount >= 50 * unit);
        }
        if (amountCategory.includes('lt50')) {
          amount = amount || item.orderAmount < 50 * unit;
        }
        return (
          ((item.budgetCategoryCustom
            && budgetCategory.includes(item.budgetCategoryCustom.toLowerCase()))
            || budgetCategory.length === 0)
          && ((item.accountingYear
            && accountingYear.includes(item.accountingYear))
            || accountingYear.length === 0)
          && item.orderAmount
          && amount
        );
      });
      let group;
      const grouped = [];
      switch (category) {
        case 'buildingSite':
          group = groupBy(filtered, 'constructionLocation');
          break;
        case 'goods':
          group = groupBy(filtered, 'goodsType');
          break;
        case 'epc':
          group = groupBy(filtered, 'isEpc');
          break;
        case 'isNew':
          group = groupBy(filtered, 'isExisted');
          break;
        default:
          break;
      }
      let others = null;
      if (group) {
        const keys = Object.keys(group);
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          const data = group[key];
          if (!key || key === 'null') {
            others = {
              name: t('aipcmcty.page.others'),
              data: data,
            };
          } else {
            grouped.push({
              name: key,
              data: data,
            });
          }
        }
      }
      if (others) {
        grouped.push(others);
      }
      setProjectGroupData({ groupedBy: category, data: grouped });
    }
  }, [category, budgetCategory, amountCategory, accountingYear, projectData]);

  return (
    <>
      <div className="select-group-container flex-container">
        <div
          style={{
            display: 'flex',
            padding: '8px 0',
            justifyContent: 'space-between',
            width: 'calc(100% - 150px)',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}
            >
              <span>
                {`${t('aipcmcty.page.classification')}: `}
              </span>
              <div style={{ marginLeft: 8 }}>
                <Select
                  value={category}
                  className="select-height-32"
                  style={{ width: 220 }}
                  maxTagCount={1}
                  maxTagTextLength={6}
                  onChange={(value) => {
                    setCategory(value);
                  }}
                  options={categoryList}
                />
              </div>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}
            >
              <span>
                {`${t('aipcmcty.page.budgetCategory')}: `}
              </span>
              <div style={{ marginLeft: 8 }}>
                <Select
                  mode="multiple"
                  value={budgetCategory}
                  className="select-height-32"
                  style={{ width: 180 }}
                  maxTagCount={1}
                  maxTagTextLength={6}
                  onChange={(value) => {
                    setBudgetCategory(value);
                  }}
                  options={budgetCategoryList}
                />
              </div>
            </div>

            <div
              style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}
            >
              <span>{`${t('aipcmcty.page.fiscalYear')}: `}</span>
              <div style={{ marginLeft: 8 }}>
                <Select
                  style={{ width: 180 }}
                  mode="multiple"
                  maxTagCount={1}
                  allowClear
                  options={accountingYearList}
                  value={accountingYear}
                  onChange={(value) => {
                    setAccountingYear(value);
                  }}
                />
              </div>
            </div>

            <div
              style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}
            >
              <span>{`${t('aipcmcty.page.orderAmount')}: `}</span>
              <div style={{ marginLeft: 8 }}>
                <Select
                  value={amountCategory}
                  mode="multiple"
                  className="select-height-32"
                  style={{ width: 220 }}
                  maxTagCount={1}
                  maxTagTextLength={6}
                  onChange={(value) => {
                    setAmountCategory(value);
                  }}
                  options={amountCategoryList}
                />
              </div>
            </div>
          </div>
        </div>
        <LayoutSwitcher viewMode={viewMode} setViewMode={setViewMode} />
      </div>
      {React.Children.map(children, (child: React.ReactElement) => React.cloneElement(child, { viewMode, category }))}
    </>
  );
};

export default SupplyValidationHeader;
