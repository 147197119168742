export const localeMapping = {
  案件入れ替え: 'Replace Project',
  案件質向上: 'PJT. Quality Up',
  リソース調整: 'Adjust Resource',
  '600億超え': 'Over 60 Billion',
  '50〜600億円': '5 ~ 60 Billion',
  '50億円未満': 'Under 5 Billion',
  all: 'All',
  中国: 'China',
  日本: 'Japan',
  インドネシア: 'Indonesia',
  台湾: 'Taiwan',
  韓国: 'South Korea',
  タイ: 'Thailand',
  インド: 'India',
  ロシア: 'Russia',
  アメリカ: 'United States',
  ボリビア: 'Bolivia',
  パキスタン: 'Pakistan',
  マレーシア: 'Malaysia',
  ナイジェリア: 'Nigeria',
  オーストラリア: 'Australia',
  ドイツ: 'Germany',
  チリ: 'Chile',
  クウェート: 'Kuwait',
  シンガポール: 'Singapore',
  ブラジル: 'Brazil',
  イギリス: 'United Kingdom',
  ガイアナ: 'Guyana',
  サウジアラビア: 'Saudi Arabia',
  'トリニダ-ド・トバゴ': 'Trinidad and Tobago',
  ポーランド: 'Poland',
  カザフスタン: 'Kazakhstan',
  アラブ首長国連邦: 'United Arab Emirates',
  オマーン: 'Oman',
  メキシコ: 'Mexico',
  ジョージア: 'Georgia',
  ポルトガル: 'Portugal',
  トルクメニスタン: 'Turkmenistan',
  バングラデシュ: 'Bangladesh',
  スリナム: 'Suriname',
  ウズベキスタン: 'Uzbekistan',
  カナダ: 'Canada',
  フィリピン: 'Philippines',
  ベルギー: 'Belgium',
  イラク: 'Iraq',
  ベトナム: 'Vietnam',
  アンゴラ: 'Angola',
  新規: 'New',
  既存: 'Existing',
  EPC: 'EPC',
  非EPC: 'Non-EPC',
  協業: 'Collab.',
  独自: 'Own',
  JV等: 'JV',
  需給均衡: 'in Balance',
  需給不足: 'Not in Balance',
  投資方針決定: 'Investment Decision Making',
  プロポーザル: 'Proposal',
  FID手続き中: 'Under FID Procedure',
  案件受注: 'Order Received',
  初期計画段階: 'Initial Planning Stage',
  投資検討中: 'Investment Study',
  ネゴシエーション: 'Negotiation',
  Feed実施: 'Feed Conduct',
  'Limited Bid': 'Limited Bid',
  'Open Bid': 'Open Bid',
  '随契+SingleFeed RollOver': 'Private Contract+SingleFeed RollOver',
  なし: 'Yes',
  あり: 'No',
  医薬: 'Medicine Treatment',
};
