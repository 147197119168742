// chart title: Buffer Suitability Evaluation
import React, {
  useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import Ribbon from '../widget/ribbon';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';


type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  // fiscalQuarter?: string[];
  tableOptions?: TableOptions;
  showTable?: boolean;
  // periodSwitch?: boolean;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  splitNumber: number;
};

const CompareChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode,
    data,
    height,
    title,
    ribbonText,
    ribbonColor,
    ribbonTop,
    showTable = true,
    tableOptions,
    loading,
    titleLoading,
    coverLoading,
    splitNumber
  } = props;

  const { color, sizeMode } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      splitNumber: 3,
    },
  };

  const [options, setOptions] = useState({
    title: {
      value: 'test',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        itemGap: 5,
        itemWidth: 14,
        data: data.legends
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: ['2022', '2023', '2024', '2025', '2026'],
          axisLabel: {
            interval: 0,
            fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: (() => t('aipcmc.charts.hundredMillion'))(),
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => value / 100000000,
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
          nameGap: 5,
          splitNumber,
        }
      ],
      series: [
        {
          name: data.legends[0],
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series'
          },
          data: data.reality,
          barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        },
        {
          name: data.legends[1],
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: data.prediction,
          barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        }
      ]
    },
    height: 220
  });

  useEffect(() => {
    setOptions(cloneDeep(options));
  }, [data]);

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        setOptions(cloneDeep(options));
      }
    }
  };

  return (
    <>
      {ribbonText ? (
        <Ribbon
          text={ribbonText || t('aipcmcty.page.projectQuantity')}
          top={ribbonTop}
          color={ribbonColor || color.primaryColor}
        />
      ) : (
        <></>
      )}
      <TableChart
        showTable={showTable}
        tableColumns={[]}
        tableData={[]}
        chartOptions={options.chartOptions}
        title={
          title
            ? {
              value: title,
              styles: {
                fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                paddingLeft: sizeMode === 'big' ? 15 : 0,
              },
            }
            : options.title
        }
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default CompareChart;
