import { cloneDeep } from 'lodash';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiffResponse } from '../../pages/comparison/project';
import { AppContext } from '../../contexts/AppContext';
import { TableChart } from '@meteor/frontend-core';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';

type DiffChartProps = {
  data: DiffResponse;
  loading: boolean;
  graphLoading: boolean;
  graphHeight?: number;
  title?: string | ReactNode;
};

const PhaseDiffChart = (props: DiffChartProps) => {
  const { data, loading, graphLoading, graphHeight = 340, title } = props;

  const { sizeMode } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);

  const { t } = useTranslation();

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
      xAxis: 10,
      yAxis: 10,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const mapPhaseType = (phaseStr: string) => {
    switch (true) {
      case ['プロポーザル'].includes(phaseStr):
        return 'Proposal';
      case ['ネゴシエーション'].includes(phaseStr):
        return 'Negotiation';
      case ['案件受注'].includes(phaseStr):
        return 'Awarded';
      case ['初期計画段階', '投資検討中', '投資方針決定', 'FID手続き中'].includes(phaseStr):
      default:
        return 'Pre-Sales';
    }
  };

  const [phaseGraphOptions, setPhaseGraphOptions] = useState({
    title: {
      value: t('aipcmcty.page.comparison.grossProfitDiffTitle'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
        formatter(p) {
          switch (p.dataType) {
            case 'node':
              return `
                <div>
                  <div>${t('aipcmcty.page.comparison.projectId')}: ${p.name}</div>
                  <div>${t('aipcmcty.page.comparison.phase')}: ${p.value[0]}</div>
                  <div>${t('aipcmcty.page.comparison.certainty')}: ${p.value[1]}</div>
                </div>
              `;
            case 'edge':
              return `${p.seriesName}: ${t('aipcmcty.page.comparison.targetCyd')} → ${t('aipcmcty.page.comparison.currentCyd')}`;
            default:
              return '';
          }
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      legend: {
        type: 'scroll',
        width: '300px',
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
        data: [
          t('aipcmcty.page.comparison.preSales'),
          t('aipcmcty.page.comparison.proposal'),
          t('aipcmcty.page.comparison.negotiation'),
          t('aipcmcty.page.comparison.awarded'),
        ],
      },
      yAxis: {
        type: 'value',
        position: 'left',
        axisLine: {
          show: true,
        },
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          formatter: (value) => `${value}%`,
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        min: 0,
        max: 100,
        splitNumber: 4,
        interval: 25,
      },
      series: [],
    },
    height: 220,
  });

  const updatePhaseGraphOptions = () => {
    const phaseGraphData = data.phaseUp.slice(0, 10).map((cur) => {
      const { projectId, phaseCurrent, phaseTarget, certaintyIndexCurrent, certaintyIndexTarget } = cur;
      const curName = `${cur.projectId} ${t('aipcmcty.page.comparison.currentCyd')}`;
      const tarName = `${cur.projectId} ${t('aipcmcty.page.comparison.targetCyd')}`;
      const curVal = [mapPhaseType(phaseCurrent), certaintyIndexCurrent * 100];
      const tarVal = [mapPhaseType(phaseTarget), certaintyIndexTarget * 100];
      return {
        name: projectId,
        type: 'graph',
        layout: 'none',
        coordinateSystem: 'cartesian2d',
        symbolSize: 10,
        edgeSymbol: ['circle', 'arrow'],
        edgeSymbolSize: [0, 6],
        data: [
          {
            name: curName,
            value: curVal,
          },
          {
            name: tarName,
            value: tarVal,
          },
        ],
        links: [
          {
            source: tarName,
            target: curName,
          },
        ],
        catagories: [projectId],
        emphasis: {
          focus: 'series',
          lineStyle: {
            width: 4,
          },
        },
      };
    });

    const mergedPhaseOptions = cloneDeep(phaseGraphOptions);
    mergedPhaseOptions.chartOptions.series = phaseGraphData;
    setPhaseGraphOptions(mergedPhaseOptions);
  };

  useEffect(() => {
    updatePhaseGraphOptions();
  }, [data]);

  return (
    <>
      <TableChart
        showTable={false}
        chartOptions={phaseGraphOptions.chartOptions}
        title={
          title
            ? {
                value: title,
                styles: {
                  fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : phaseGraphOptions.title
        }
        height={graphHeight}
        loading={loading}
        titleLoading={!loading && graphLoading}
      />
    </>
  );
};

export default PhaseDiffChart;
