import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

const usePageLeaveConfirm = (
  isValueChanged: boolean,
  canLeave: boolean,
  setCanLeaveFunc: (canLeave: boolean) => void,
  clientConfirmCallback: () => boolean,
  closeMessage?: string,
) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isValueChanged && currentLocation.pathname !== nextLocation.pathname
  );

  // set canLeave to true when it has already been false
  useEffect(() => {
    if (!canLeave) {
      setCanLeaveFunc(true);
    }
  }, [canLeave]);

  if (blocker.state === 'blocked') {
    const confirmRes = clientConfirmCallback();
    if (confirmRes) {
      setCanLeaveFunc(true);
      blocker?.proceed();
    } else {
      setCanLeaveFunc(false);
      blocker?.reset();
    }
  }

  useEffect(() => {
    if (!isValueChanged) {
      return () => {};
    }
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      const confirmationMessage = closeMessage ?? 'Are you sure leaving this page without save?';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isValueChanged]);
};

export default usePageLeaveConfirm;
