import {
  AimOutlined, CheckCircleOutlined, DeleteOutlined, SaveOutlined, ScheduleOutlined, StopOutlined
} from '@ant-design/icons';
import {
  Select, Button, Modal, Input, Space, Checkbox, Table, Tooltip, Tag, message, Popconfirm, Spin, Switch,
  Row,
  Col
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';
import useStorage from '../hooks/useStorage';
import { AppContext } from '../contexts/AppContext';
import APIList from '../http/ApiList';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isNumber, minBy } from 'lodash';

type SaveModalData = {
  snapshotVersionN: string;
  comment: string;
  isMaster: boolean;
  tag: number
};

type VersionManagement = {
  id: string;
  snapshot: string;
  snapshotVersion: string;
  comment: string;
  updatedAt: Date;
  isDeleted: boolean;
  isMaster: boolean;
  isEdit: boolean;
  isPublic: boolean;
  copyFrom: string;
  createdBy: string;
};

const VersionManager: React.FC<any> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setItem } = useStorage();
  const {
    // authInfo: { user: { username, attributes: { email } } },
    authInfo: { user },
    setAipcmctyVersion, aipcmctyVersion, cmcSelectedVersion, setCmcSelectedVersion, versionList, setVersionList, color
  } = useContext(AppContext);
  const {
    snapshot, snapshotVersion, snapshotVersionName, userId
  } = JSON.parse(aipcmctyVersion);
  const {
    snapshot: selectedSnapshot,
    snapshotVersion: selectedVersion
  } = cmcSelectedVersion;

  const initialSaveVerData = useRef({
    snapshotVersionN: '',
    comment: '',
    isMaster: false,
    tag: null
  });

  const [saveVerModal, setSaveVerModel] = useState(false);
  const [saveConfirmLoading, setSaveConfirmLoading] = useState(false);
  const [saveVerData, setSaveVerData] = useState<SaveModalData>(initialSaveVerData.current);

  const [manageVerModal, setManageVerModal] = useState(false);
  const [manageTableLoading, setManageTableLoading] = useState(false);

  const getVersionList = async () => {
    const res = (await APIList.getVersions().get()) as any[];
    res.forEach(r => {
      r.originM = r.isMaster;
      r.isShared = r.isPublic && !r.isEdit;
    });
    setVersionList(res);
    if (!snapshot && !snapshotVersion) {
      const masterItem = res.sort((a, b) => {
        if (b.snapshot > a.snapshot) {
          return 1;
        }
        if (b.snapshot < a.snapshot) {
          return -1;
        }
        return 0;
      }).find(r => r.isMaster);
      setCmcSelectedVersion({
        snapshot: masterItem.snapshot,
        snapshotVersion: masterItem.snapshotVersion,
        snapshotVersionName: masterItem.snapshotVersionName,
        userId: masterItem.createdBy,
        isShared: masterItem.isShared
      });
    } else {
      const selectedItem = res.find(r => r.snapshot === snapshot && r.snapshotVersion === snapshotVersion);
      setCmcSelectedVersion({
        snapshot: snapshot,
        snapshotVersion: snapshotVersion,
        snapshotVersionName: snapshotVersionName,
        userId: userId,
        isShared: selectedItem?.isShared ?? false
      });
    }
    setManageTableLoading(false);
  };

  useEffect(() => {
    getVersionList();
  }, [aipcmctyVersion]);

  const genVersionBySnapshot = (list: { snapshot: string }[]) => list.reduce((acc, cur) => {
    if (acc[cur.snapshot]) {
      acc[cur.snapshot].push(cur);
    } else {
      acc[cur.snapshot] = [cur];
    }
    return acc;
  }, {} as { [key: string]: any });

  const updateSaveVerData = (key: keyof SaveModalData, value: SaveModalData[keyof SaveModalData]) => {
    setSaveVerData({
      ...saveVerData,
      [key]: value
    });
  };

  const handleSaveVerData = async () => {
    const { snapshotVersionN } = saveVerData;
    const reg = /^\w{1,24}$/g;
    if (!reg.test(snapshotVersionN)) {
      message.error(t('aipcmcty.page.versionFormatError'));
      return;
    }
    setSaveConfirmLoading(true);
    APIList.createSnapshotVersions().post({
      ...saveVerData,
      snapshot: selectedSnapshot,
      // snapshotVersionの名
      snapshotVersion: selectedVersion,
      userName: user?.username,
      createdBy: user?.attributes?.email
    }).then(({ status, data }: any) => {
      if (status === 'EXIST') {
        message.error(t('aipcmcty.page.versionExists'));
        setSaveConfirmLoading(false);
        return;
      }
      if (status === 'ERROR') {
        message.error(t('aipcmcty.page.createVersionFailure').replace('${saveVerData.snapshotVersionN}', saveVerData.snapshotVersionN));
        setSaveConfirmLoading(false);
        return;
      }
      navigate('/aipcmcty/dashboard/overall', { replace: true });
      setTimeout(() => {
        message.success(t('aipcmcty.page.createVersionSuccess').replace('${saveVerData.snapshotVersionN}', saveVerData.snapshotVersionN));
        setSaveConfirmLoading(false);
        setSaveVerModel(false);
        setSaveVerData(initialSaveVerData.current);
        setItem(
          'cmcVersion',
          JSON.stringify({
            snapshot: selectedSnapshot,
            snapshotVersion: data.snapshotVersion,
            snapshotVersionName: saveVerData.snapshotVersionN,
            userId: user?.attributes?.email,
            isShared: false
          }),
          'local'
        );
        setAipcmctyVersion(JSON.stringify({
          snapshot: selectedSnapshot,
          snapshotVersion: data.snapshotVersion,
          snapshotVersionName: saveVerData.snapshotVersionN,
          userId: user?.attributes?.email,
          isShared: false
        }));
      }, 2500);
    }).catch(() => {
      message.error(t('aipcmcty.page.createVersionFailure').replace('${saveVerData.snapshotVersionN}', saveVerData.snapshotVersionN));
      setSaveConfirmLoading(false);
    });
  };

  const handleOperations = (id: string, operation: string) => {
    const newVerList = [...versionList];
    const operationItem = newVerList.find(l => l.id === id);
    const msgMap = {
      master: {
        success: t('aipcmcty.page.updateMasterSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.updateMasterFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      },
      delete: {
        success: t('aipcmcty.page.deleteVersionSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.deleteVersionFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      },
      public: {
        success: t('aipcmcty.page.updatePublicSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.updatePublicFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      },
      edit: {
        success: t('aipcmcty.page.updateEditSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.updateEditFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      },
      share: {
        success: t('aipcmcty.page.updateSharingSuccess').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName),
        error: t('aipcmcty.page.updateSharingFailure').replace('${operationItem.snapshotVersionName}', operationItem.snapshotVersionName)
      }
    };
    switch (operation) {
      case 'master':
        newVerList.forEach(l => { l.isMaster = false; });
        operationItem.isMaster = true;
        break;
      case 'delete':
        operationItem.isDeleted = true;
        break;
      case 'public':
        operationItem.isPublic = !operationItem.isPublic;
        break;
      case 'edit':
        operationItem.isEdit = !operationItem.isEdit;
        break;
      case 'share':
        operationItem.isShared = !operationItem.isShared;
        operationItem.isPublic = operationItem.isShared;
        operationItem.isEdit = !operationItem.isShared;
        break;
      default:
        return;
    }
    setManageTableLoading(true);
    APIList.updateVersions().put({ ...operationItem }).then(async res => {
      if (res === 'SUCCESS') {
        await getVersionList();
        message.success(msgMap[operation].success);
      } else {
        message.error(msgMap[operation].error);
      }
    });
  };

  const columns: ColumnsType<VersionManagement> = [
    {
      title: 'Version Name',
      dataIndex: 'snapshotVersionName',
      key: 'snapshotVersionName',
      render: (val, { isMaster, snapshotVersion: version, snapshot: s }) => (
        <Space>
          {val}
          {selectedSnapshot === s && selectedVersion === version && <Tag color="blue">In Use</Tag>}
          {isMaster && <Tag color="red">Master</Tag>}
        </Space>
      )
    },
    {
      title: 'Origin Version',
      dataIndex: 'copyFrom',
      key: 'copyFrom',
      render: (_, item) => versionList.find(e => e.snapshotVersion === item.copyFrom)?.snapshotVersionName
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    {
      title: 'Author',
      dataIndex: 'userName',
      key: 'userName',
      width: 150
    },
    {
      title: 'Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      render: val => moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: `${t('aipcmcty.page.shared')}/${t('aipcmcty.page.nonShared')}`,
      dataIndex: 'isShared',
      key: 'isShared',
      width: 100,
      render: (
        val,
        { id, createdBy }
      ) => (
        <Switch
          disabled={createdBy !== user?.attributes?.email}
          checkedChildren={t('aipcmcty.page.shared')}
          unCheckedChildren={t('aipcmcty.page.nonShared')}
          checked={val}
          onChange={() => handleOperations(id, 'share')}
        />
      )
    },
    {
      title: 'Operations',
      width: 100,
      render: ({
        id, isMaster, snapshotVersion: version, createdBy, isPublic
      }) => {
        const showDelete = !isMaster && selectedVersion !== version && user?.attributes?.email === createdBy;
        return (
          <Space>
            {
              !isMaster && isPublic && (
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure to set as Master?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleOperations(id, 'master')}
                >
                  <Tooltip title="Set as Master">
                    <AimOutlined />
                  </Tooltip>
                </Popconfirm>
              )
            }
            {
              showDelete && (
                <Popconfirm
                  placement="topLeft"
                  title="Are you sure to delete?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => handleOperations(id, 'delete')}
                >
                  <Tooltip title="Delete">
                    <DeleteOutlined />
                  </Tooltip>
                </Popconfirm>
              )
            }
          </Space>
        );
      }
    },
  ];

  const versionOpts = useMemo(() => (genVersionBySnapshot(versionList)[selectedSnapshot] ?? []).reduce(
    (acc, cur) => {
      let groupNum = 3;
      const iconContent = cur.isShared
        ? <CheckCircleOutlined style={{ marginRight: 6, color: color.successColor }} />
        : <StopOutlined style={{ marginRight: 6, color: color.errorColor }} />;
      if (cur.createdBy === 'system') {
        groupNum = 1;
      } else if (cur.createdBy === user?.attributes?.email) {
        groupNum = 2;
      }
      if (cur.isMaster && acc[0].options.length === 0) {
        acc[0].options.push({
          label: (
            <span title={cur.snapshotVersionName}>
              {iconContent}
              {cur.snapshotVersionName}
            </span>
          ),
          key: 'master',
          value: cur.snapshotVersion,
          tag: cur.tag
        });
      }
      acc[groupNum].options.push({
        label: (
          <span title={cur.snapshotVersionName}>
            {iconContent}
            {cur.snapshotVersionName}
          </span>
        ),
        key: cur.snapshotVersion,
        value: cur.snapshotVersion,
        tag: cur.tag
      });
      return acc;
    },
    [
      {
        label: 'Master',
        options: []
      },
      {
        label: 'System',
        options: []
      },
      {
        label: 'My Versions',
        options: []
      },
      {
        label: 'Others',
        options: []
      }
    ]
  ), [versionList, selectedSnapshot, user?.attributes?.email]);

  const tableData = versionList
    .filter(v => v.snapshot === selectedSnapshot)
    .reduce((acc, cur) => {
      if (cur.createdBy === 'system') {
        acc.system.push(cur);
      } else if (cur.createdBy === user?.attributes?.email) {
        acc.mine.push(cur);
      } else {
        acc.others.push(cur);
      }
      return acc;
    }, { system: [], mine: [], others: [] });

  const getTag = (tag) => {
    const styles: any = {
      height: 17,
      lineHeight: '15px',
      fontSize: 10,
      width: 18,
      padding: 0,
      textAlign: 'center',
      marginLeft: 2
    }
    switch (tag) {
      case 0: return <Tag color='blue' style={styles}>原</Tag>;
      case 1: return <Tag color='volcano' style={styles}>修</Tag>;
      default: return <></>
    }
  }

  return (
    <>
      <div style={{ width: '100%', padding: '12px' }}>
        <div style={{ paddingBottom: '6px' }}>
          <div>Snapshot</div>
          <Select
            style={{ width: '100%' }}
            value={selectedSnapshot}
            onChange={e => {
              const versionMap = genVersionBySnapshot(versionList);
              const isExist = versionMap[e].find(v => v.snapshot === snapshot && v.snapshotVersion === snapshotVersion);
              const masterItem = versionMap[e].find(v => v.isMaster);
              let defaultSelected = versionMap[e].find(v => v.snapshotVersionName === 'default');
              if (isExist?.snapshotVersion) {
                defaultSelected = isExist;
              } else if (masterItem?.snapshotVersion) {
                defaultSelected = masterItem;
              }
              const versionInfo = {
                snapshot: defaultSelected.snapshot,
                snapshotVersion: defaultSelected.snapshotVersion,
                snapshotVersionName: defaultSelected.snapshotVersionName,
                userId: defaultSelected.createdBy,
                isShared: defaultSelected.isShared
              };
              setCmcSelectedVersion(versionInfo);
              setItem(
                'cmcVersion',
                JSON.stringify(versionInfo),
                'local'
              );
              setAipcmctyVersion(JSON.stringify(versionInfo));
            }}
          >
            {
              Object.keys(genVersionBySnapshot(versionList))
                .sort((a, b) => {
                  if (b > a) {
                    return 1;
                  }
                  if (b < a) {
                    return -1;
                  }
                  return 0;
                })
                .map(o => (
                  <Select.Option style={{ display: 'flex' }} key={o} label={o} value={o}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="ellipsis">{o}</div> {getTag(minBy<any>(genVersionBySnapshot(versionList)[o], 'tag')?.tag)}
                    </div>
                  </Select.Option>)
                )
            }
          </Select>
        </div>
        <div style={{ paddingBottom: '12px' }}>
          <div>Version</div>
          <div style={{ display: 'flex' }}>
            <Select
              style={{ width: '100%' }}
              value={selectedVersion}
              onChange={e => {
                const item = versionList.find(v => v.snapshotVersion === e && v.snapshot === selectedSnapshot);
                const versionInfo = {
                  snapshot: item.snapshot,
                  snapshotVersion: item.snapshotVersion,
                  snapshotVersionName: item.snapshotVersionName,
                  userId: item.createdBy,
                  isShared: item.isShared
                };
                setCmcSelectedVersion(versionInfo);
                setItem(
                  'cmcVersion',
                  JSON.stringify(versionInfo),
                  'local'
                );
                setAipcmctyVersion(JSON.stringify(versionInfo));
              }}
            >
              {
                versionOpts.map(
                  optGroup => (
                    <Select.OptGroup {...optGroup} key={optGroup.label}>
                      {optGroup.options.map(
                        (opt) => <Select.Option {...opt}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="ellipsis">{opt.label}</div>
                            {getTag(opt.tag)}
                          </div>
                        </Select.Option>
                      )}
                    </Select.OptGroup>
                  )
                )
              }
            </Select>
          </div>
        </div>
        <Button
          className="version-button"
          disabled={
            Object.keys(genVersionBySnapshot(versionList))
              .sort((a, b) => {
                if (b > a) {
                  return 1;
                }
                if (b < a) {
                  return -1;
                }
                return 0;
              })[0] !== selectedSnapshot
            || !versionList.find(v => v.snapshotVersion === selectedVersion && v.snapshot === selectedSnapshot)?.isShared
          }
          icon={<SaveOutlined />}
          style={{ width: '100%', marginBottom: '12px'}}
          onClick={() => setSaveVerModel(true)}
        >
          Save as New Version
        </Button>
        <Button
          className="version-button"
          icon={<ScheduleOutlined />}
          style={{ width: '100%', marginBottom: '12px' }}
          onClick={() => {
            setManageVerModal(true);
            setManageTableLoading(true);
            getVersionList();
          }}
        >
          Manage Versions
        </Button>
      </div>
      <Modal
        title="Save as a new version"
        open={saveVerModal}
        closable={false}
        maskClosable={false}
        okText={t('aipcmcty.page.confirm')}
        cancelText={t('aipcmcty.page.cancel')}
        confirmLoading={saveConfirmLoading}
        onOk={handleSaveVerData}
        onCancel={() => {
          setSaveVerData(initialSaveVerData.current);
          setSaveVerModel(false);
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <h4 style={{ margin: 0 }}>
            Name of New Version
            <br />
            <span style={{ color: color.errorColor, fontSize: 12, fontWeight: 'normal' }}>{t('aipcmcty.page.versionFormatError')}</span>
          </h4>
          <Input
            placeholder="Name of New Version" value={saveVerData.snapshotVersionN}
            onChange={e => updateSaveVerData('snapshotVersionN', e.target.value)}
          />
          <h4 style={{ margin: 0 }}>Comment</h4>
          <TextArea
            rows={4} value={saveVerData.comment}
            onChange={e => updateSaveVerData('comment', e.target.value)}
          />
          <Checkbox
            checked={saveVerData.isMaster}
            onChange={e => updateSaveVerData('isMaster', e.target.checked)}
          >
            Set as Master
          </Checkbox>
          <Row>
            <Col>
              <Checkbox checked={saveVerData.tag === 0} onChange={e => updateSaveVerData('tag', e.target.checked ? 0 : null)}>原受注計画</Checkbox>
              <Checkbox checked={saveVerData.tag === 1} onChange={e => updateSaveVerData('tag', e.target.checked ? 1 : null)}>修正受注計画</Checkbox>
            </Col>
          </Row>
        </Space>
      </Modal>
      <Modal
        title="Manage Versions"
        width="85%"
        maskClosable={false}
        open={manageVerModal}
        className="manage-modal"
        onCancel={() => setManageVerModal(false)}
        footer={null}

      >
        <Table
          columns={columns}
          dataSource={tableData.system.sort((a, b) => (b.isMaster - a.isMaster))}
          pagination={false}
          rowKey="id"
          scroll={{ y: 120 }}
          size="small"
          showHeader
          title={() => <h3 style={{ marginBottom: 0 }}>System</h3>}
        />
        <Table
          columns={columns}
          dataSource={tableData.mine.sort((a, b) => (b.isMaster - a.isMaster))}
          pagination={false}
          rowKey="id"
          scroll={{ y: 120 }}
          size="small"
          showHeader
          title={() => <h3 style={{ marginBottom: 0 }}>My Versions</h3>}
        />
        <Table
          columns={columns}
          dataSource={tableData.others.sort((a, b) => (b.isMaster - a.isMaster))}
          pagination={false}
          rowKey="id"
          scroll={{ y: 120 }}
          size="small"
          showHeader
          title={() => <h3 style={{ marginBottom: 0 }}>Others</h3>}
        />
        {
          manageTableLoading
            ? (
              <div style={{
                position: 'absolute',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                margin: 'auto',
                backgroundColor: 'rgba(255, 255, 255, 0.5)'
              }}
              >
                <Spin />
              </div>
            )
            : null
        }

      </Modal>
    </>
  );
};

export default VersionManager;
