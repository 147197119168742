const tgc = ['Toyo-J', 'IKPT', 'Toyo-C', 'Toyo-I', 'Toyo-K', 'Toyo-M', 'TPS', 'TSPI', 'OFS'];

const division = ['Corporate', 'Project', 'Construction', 'Engineering', 'Procurement'];

const discipline = [
  'PIT',
  'PSE',
  'HEX',
  'ADT',
  'APP',
  'PJM',
  'MAT',
  'CSA',
  'PRS',
  'GIR',
  'LPA',
  'SEM',
  'STA',
  'C-MEC',
  'FF',
  'LAB',
  'NCE',
  'CMP',
  'ITM',
  'FFP',
  'LEG',
  'C-INS',
  'C-INC',
  'DHSE',
  'PRO',
  'ROT',
  'MHE',
  'ACC',
  'COS',
  'HCD',
  'TFA',
  'CST',
  'SEC',
  'QAM',
  'ISP',
  'PIP',
  'EXP',
  'HSE',
  'FIN',
  'BZD',
  'ELE',
  'NDC',
  'C-ELE',
  'ENG',
  'TRS',
  'C-PIP',
  'SAL',
  'PUR',
  'MAR',
  'RAD',
  'INC',
  'COC',
  'FNC',
  'EIT',
  'DXT',
  'GEN',
  'COM',
  'PUE',
  'STC',
  'C-PAT',
  'BZM',
  'C-CSA',
];

const role = [
  'FEM',
  'QCM',
  'APM',
  'LAE',
  'PD',
  '役員',
  'PCM',
  'PM',
  'L',
  'QAM',
  'PE',
  'AE',
  'M',
  'PPM',
  'FM',
  'OM',
  'ALAE',
  'PEM',
  'S',
  'CM',
];

const divisionOps = division.map((v) => ({
  key: v,
  label: v,
  value: v,
}));

const disciplineOps = discipline.map((v) => ({
  key: v,
  label: v,
  value: v,
}));

const roleOps = role.map((v) => ({
  key: v,
  label: v,
  value: v,
}));

const tgcOps = tgc.map((v) => ({
  key: v,
  label: v,
  value: v,
}));

export { divisionOps, disciplineOps, roleOps, tgcOps };
