// chart title: Buffer Suitability Evaluation
import React, {
  ReactNode, useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, groupBy, sum } from 'lodash';
import { Tooltip } from 'antd';
import Ribbon from '../widget/ribbon';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions;
  periodSwitch?: boolean;
  showTable?: boolean;
  barWidth?: number;
  chartHandler?: (e: any) => any;
};

const GrossProfitMargin: React.FC<ChartProps> = (props) => {
  const {
    kpiCode,
    data,
    height,
    loading,
    title,
    ribbonText,
    ribbonColor,
    ribbonTop,
    fiscalQuarter,
    tableOptions,
    periodSwitch = false,
    showTable = true,
    barWidth,
    titleLoading,
    coverLoading,
    chartHandler
  } = props;

  const {
    defaultFiscalQuarter, color, sizeMode
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule, } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 45,
      gridRight: 30,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridBottom: 30,
      splitNumber: 3,
    },
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.grossProfitAndRate'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          if (params && params.length > 0) {
            let tooltip = '<div style="text-align: left;">';
            for (let index = 0; index < params.length; index++) {
              const element = params[index];
              if (element.value > 0 || element.seriesName !== 'Target') {
                if (element.seriesType === 'line') {
                  tooltip += `${element.marker}${element.seriesName}: ${round(
                    element.value * 100
                  )}% <br/>`;
                } else if (element.value) {
                  tooltip += `${element.marker}${element.seriesName}: ${round(
                    element.value / 100000000
                  )} <br/>`;
                }
              }
            }
            tooltip += '</div>';
            return tooltip;
          }
          return null;
        },
      },
      legend: {
        top: 0,
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        data: [],
        type: 'scroll',
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: t('aipcmc.charts.hundredMillion'),
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => value / 100000000,
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
          nameGap: 5,
          splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
        },
        {
          type: 'value',
          position: 'right',
          name: '%',
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => `${value * 100}`,
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
        },
      ],
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      series: [],
    },
    height: 220,
  });

  /**
   * データまた四半期switchの変化がありましたら、
   * chartとtableは再計算必要があります
   */
  useEffect(() => {
    initData();
  }, [data, fiscalQuarter, kpiComputeRule]);

  /**
   * 初期化
   */
  const initData = () => {
    initChartData();
    initTableColumns();
    initTableData();
  };

  /**
   * 初期化Chartデータ
   */
  const initChartData = () => {
    if (!data) {
      return;
    }
    // barのwidthを計算
    const width = (window.innerWidth - 300) / (sizeMode === 'small' ? 4 : 2);
    const preContainer = barWidth || width / (fiscalQuarter?.length || 5); // x count
    sizeMap.big.barWidth = preContainer * 0.35;
    sizeMap.big.targetWidth = sizeMap.big.barWidth * 1.5;
    sizeMap.small.barWidth = preContainer * 0.35;
    sizeMap.small.targetWidth = sizeMap.small.barWidth * 1.5;

    // 粗利額, 粗利率
    const { mart, goal } = data;

    // 粗利額未受注と受注済みのデータを取得
    const filterGrossProfitData = mart.filter((item) => (fiscalQuarter || defaultFiscalQuarter).find(
      (per) => per
          === (periodSwitch
            ? `${item.fiscalYear}-${item.fiscalQuarter}`
            : item.fiscalYear)
    ));
    const gbDate = groupBy(
      filterGrossProfitData,
      periodSwitch
        ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}`
        : 'fiscalYear'
    );

    const accountingYearDate = groupBy(mart.filter((item)=> item.accountingYear !== 'all'), 'accountingYear');
    const sortedAccountingYear = Object.keys(accountingYearDate).sort((a, b) => a.localeCompare(b));
    const notOrdered = {};

    sortedAccountingYear.forEach((key) => {
      notOrdered[key] = [];
      Object.keys(gbDate).forEach((date) => {
        const notOrderedData = gbDate[date].filter((item) => item.orderState === t('aipcmcty.page.unreceived'));
        const accountingYears = groupBy(notOrderedData, 'accountingYear');
        if (accountingYears[key]) {
          notOrdered[key].push(kpiComputeRule[kpiCode].grossProfit(accountingYears[key]));
        } else {
          notOrdered[key].push(0);
        }
      });
    });

    const orderedGrossProfitData = Object.keys(gbDate).map((date) => kpiComputeRule[kpiCode].grossProfit(
      gbDate[date].filter((item) => item.orderState === '受注')
    ));

    // yearsを取得
    const dates = Object.keys(gbDate).sort((a: any, b: any) => a - b);

    // 粗利率未受注と受注済みのデータを取得
    const notOrderedGrossProfitRateData = Object.keys(gbDate).map((date) => kpiComputeRule[kpiCode].grossProfitRate(
      gbDate[date].filter((item) => item.orderState === t('aipcmcty.page.unreceived'))
    ));
    const orderedGrossProfitRateData = dates.map((date) => kpiComputeRule[kpiCode].grossProfitRate(
      gbDate[date].filter((item) => item.orderState === '受注')
    ));

    options.chartOptions.xAxis.data = dates;

    // chart optionのデータを組み立て
    // bar: 粗利額
    options.chartOptions.series = [];

    options.chartOptions.legend.data = [
      t('aipcmcty.page.receivedGrossProfit'),
      ...sortedAccountingYear.filter((item)=> sum(notOrdered[item]) > 0).map((item)=> `未・${item}`),
      t('aipcmcty.page.receivedGrossProfitRate'),
      t('aipcmcty.page.unreceivedGrossProfitRate'),
    ];
    options.chartOptions.series.push({
      name: t('aipcmcty.page.receivedGrossProfit'),
      type: 'bar',
      stack: 'plan',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      barGap: '-125%',
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: orderedGrossProfitData,
      z: 10,
    });

    sortedAccountingYear.forEach((item)=> {
      options.chartOptions.series.push({
        name: `未・${item}`,
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: notOrdered[item],
        z: 10,
      });
    });

    // line: 粗利率
    options.chartOptions.series.push({
      name: t('aipcmcty.page.receivedGrossProfitRate'),
      type: 'line',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        formatter: (params) => `${params.value * 100}%`,
      },
      emphasis: {
        focus: 'series',
      },
      lineStyle: {
        shadowColor: '#fff',
        shadowBlur: 2,
      },
      data: orderedGrossProfitRateData,
      yAxisIndex: 1,
      z: 20
    });

    options.chartOptions.series.push({
      name: t('aipcmcty.page.unreceivedGrossProfitRate'),
      type: 'line',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        formatter: (params) => `${params.value * 100}%`,
      },
      emphasis: {
        focus: 'series',
      },
      lineStyle: {
        shadowColor: '#fff',
        shadowBlur: 2,
      },
      data: notOrderedGrossProfitRateData,
      yAxisIndex: 1,
      z: 20
    });

    // targetを組み立て
    const greenList = [];
    const yellowList = [];
    const redList = [];

    const goalFilterData = goal.filter((item) => (fiscalQuarter ? item.fiscalYear : true));
    const goalGbDate = groupBy(goalFilterData, 'fiscalYear');

    for (let j = 0; j < dates.length; j++) {
      const date = dates[j];
      const sumAmount = kpiComputeRule[kpiCode].grossProfit(gbDate[date]);
      const sumTarget = kpiComputeRule[kpiCode].grossProfit(goalGbDate[date]);

      greenList.push(sumAmount / sumTarget >= 1 ? sumTarget : 0);
      redList.push(sumAmount / sumTarget <= 0.9 ? sumTarget : 0);
      yellowList.push(
        sumAmount / sumTarget > 0.9 && sumAmount / sumTarget < 1 ? sumTarget : 0
      );
    }

    options.chartOptions.series.push({
      name: 'Target',
      type: 'bar',
      stack: 'goal',
      data: greenList,
      itemStyle: {
        color: '#9bca63',
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
    });
    options.chartOptions.series.push({
      name: 'Target',
      type: 'bar',
      stack: 'goal',
      data: yellowList,
      itemStyle: {
        color: '#faad14',
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
    });
    options.chartOptions.series.push({
      name: 'Target',
      type: 'bar',
      stack: 'goal',
      data: redList,
      itemStyle: {
        color: color.errorColor,
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
    });

    setOptions(cloneDeep(options));
  };

  /**
   * 初期化table column
   */
  const initTableColumns = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    const map = groupBy(
      mart.filter((item) => (fiscalQuarter || defaultFiscalQuarter).find(
        (per) => per
            === (periodSwitch
              ? `${item.fiscalYear}-${item.fiscalQuarter}`
              : item.fiscalYear)
      )),
      periodSwitch
        ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}`
        : 'fiscalYear'
    );
    const years = Object.keys(map).sort((a: any, b: any) => a - b);
    const baseColumns = [
      {
        title: '',
        dataIndex: 'type',
        key: 'type',
        fixed: 'left',
        width: sizeMode === 'big' ? 50 : 40,
      },
    ];
    const columns: any[] = years.map((fiscalYear) => ({
      title: <Tooltip title={fiscalYear}>{fiscalYear}</Tooltip>,
      dataIndex: fiscalYear,
      key: fiscalYear,
      className: 'text-right',
      width: sizeMode === 'big' ? 100 : 50,
      render: val => {
        const isNumber = typeof val === 'number';
        if (isNumber) {
          return val.toLocaleString();
        }
        return val;
      }
    }));
    setTableColumns(baseColumns.concat(columns));
  };

  /**
   * 初期化tableデータ
   */
  const initTableData = () => {
    if (!data) {
      return;
    }
    const { mart, goal } = data;
    const map = groupBy(
      mart,
      periodSwitch
        ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}`
        : 'fiscalYear'
    );
    const goalMap = groupBy(goal, 'fiscalYear');

    const getValue = (info: any[]) => round(kpiComputeRule[kpiCode].grossProfit(info) / 100000000);
    const getTarget = (info: any[]) => round(kpiComputeRule[kpiCode].grossProfit(info) / 100000000);
    const getGoal = (amountInfo: any[], goalInfo: any[]) => {
      if (getTarget(goalInfo)) {
        return `${round((getValue(amountInfo) / getTarget(goalInfo)) * 100).toFixed(0)}%`;
      }
      return '-';
    };
    // `${getTarget(goalInfo) ? round(getValue(amountInfo) / getTarget(goalInfo)) * 100 : 0}%`;

    const prepareData = [
      {
        key: 'value',
        type: `${t('aipcmcty.page.value')}(${t('aipcmcty.page.billion')})`,
      },
      {
        key: 'goal',
        type: t('aipcmcty.page.achievementRate'),
      },
    ];

    if (fiscalQuarter) {
      fiscalQuarter.forEach((per) => {
        prepareData[0][per] = getValue(map[per]);
        prepareData[1][per] = getGoal(map[per], goalMap[per]);
      });
    } else {
      Object.keys(map).forEach((per) => {
        prepareData[0][per] = getValue(map[per]);
        prepareData[1][per] = getGoal(map[per], goalMap[per]);
      });
    }
    setTableData(prepareData);
  };

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        initData();
      }
    }
    if (chartHandler) {
      chartHandler(e);
    }
  };

  return (
    <>
      {ribbonText ? (
        <Ribbon
          text={ribbonText || t('aipcmcty.page.projectQuantityAndQuality')}
          top={ribbonTop}
          color={ribbonColor || color.errorColor}
        />
      ) : (
        <></>
      )}
      <TableChart
        showTable={showTable}
        tableColumns={tableColumns}
        tableData={tableData}
        chartOptions={options.chartOptions}
        title={
          title
            ? {
              value: title,
              styles: {
                fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                paddingLeft: sizeMode === 'big' ? 15 : 0,
              },
            }
            : options.title
        }
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default GrossProfitMargin;
