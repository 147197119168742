// chart title: Buffer Suitability Evaluation
import React, {
  ReactNode, useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, groupBy } from 'lodash';
import { Tooltip } from 'antd';
import Ribbon from '../widget/ribbon';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  title?: string | ReactNode;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions;
  showTable?: boolean;
  periodSwitch?: boolean;
  loading?: boolean;
  barWidth?: number;
  selectedProjects: any[];
  unselectBudgets: any[];
  isDxMode: boolean;
  consolidated: 0 | 1;
};

const TurnoverResource: React.FC<ChartProps> = (props) => {
  const {
    kpiCode,
    data,
    height,
    title,
    ribbonText,
    ribbonColor,
    ribbonTop,
    fiscalQuarter,
    showTable = true,
    tableOptions,
    periodSwitch = false,
    loading,
    barWidth,
    selectedProjects = [],
    unselectBudgets = [],
    isDxMode,
    consolidated
  } = props;

  const {
    defaultFiscalQuarter, color, sizeMode
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  // const [selectedYear, setSelectedYear] = useState(null);
  const [mode, setMode] = useState(0);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 20,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const selectedProjectIdsIF = selectedProjects.filter(s => s.budgetCategoryCustom.endsWith('IF')).map(s => s.projectId);
  const selectedProjectIdsOthers = selectedProjects.filter(s => s.budgetCategoryCustom.endsWith('Others')).map(s => s.projectId);
  const unselectedIds = unselectBudgets.map(u => u.projectId);

  // const profitLegend = [t('aipcmcty.page.receivedOrders'), t('aipcmcty.page.unreceived'), `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.receivedOrders')})`, `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.unreceived')})`];

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.orderAmount'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params: any[]) => {
          if (params && params.length > 0) {
            params.reverse();
            let tooltip = '';
            for (let index = 0; index < params.length; index++) {
              const element = params[index];
              if ((element.seriesName === `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.receivedOrders')})` || element.seriesName === `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.unreceived')})`) && element.value > 0) {
                tooltip += `${element.marker}${element.seriesName}: ${round(element.value)} <br/>`;
              } else if (element.seriesName !== 'transparent' && (element.value > 0 || element.seriesName !== 'Target')) {
                tooltip += `${element.marker}${element.seriesName}: ${round(element.value / 100000000)} <br/>`;
              }
            }
            return tooltip;
          }
          return null;
        },
      },
      legend: {
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        type: 'scroll',
        data: ['Awarded', 'Budget', 'IF', 'Others']
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: t('aipcmc.charts.hundredMillion'),
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => value / 100000000
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
          nameGap: 5,
          splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
        },
        {
          type: 'value',
          position: 'right',
          // name: '%',
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            // formatter: (value) => `${value * 100}`,
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
        },
      ],
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      series: [],
    },
    height: 220,
  });

  /**
   * データまた四半期switchの変化がありましたら、
   * chartとtableは再計算必要があります
   */
  useEffect(() => {
    initData();
  }, [data, fiscalQuarter, kpiComputeRule, mode, selectedProjects, isDxMode]);

  /**
   * 初期化
   */
  const initData = () => {
    initChartData();
    initTableColumns();
    initTableData();
  };

  /**
   * 初期化Chartデータ
   */
  const initChartData = () => {
    if (!data) {
      return;
    }
    const { mart, goal } = data;

    // barのwidthを計算
    const width = (window.innerWidth - 300) / (sizeMode === 'small' ? 4 : 2);
    const preContainer = barWidth || width / (fiscalQuarter?.length || 5); // x count
    sizeMap.big.barWidth = preContainer * 0.35;
    sizeMap.big.targetWidth = sizeMap.big.barWidth * 1.5;
    sizeMap.small.barWidth = preContainer * 0.35;
    sizeMap.small.targetWidth = sizeMap.small.barWidth * 1.5;
    const awardedMarts = mart.filter(d => d.budgetCategoryCustom === 'Awarded');
    const budgetMarts = mart.filter(
      m => !unselectedIds.includes(m.projectId)
        && m.budgetCategoryCustom === 'Budget'
    );
    const ifMarts = mart.filter(
      m => selectedProjectIdsIF.includes(m.projectId)
        && m.budgetCategoryCustom === 'IF'
    );
    const othersMarts = mart.filter(
      m => selectedProjectIdsOthers.includes(m.projectId)
        && m.budgetCategoryCustom === 'Others'
    );

    const dxAwardedMarts = mart.filter(d => d.budgetCategoryCustom === 'DX_Awarded');
    const dxBudgetMarts = mart.filter(
      m => !unselectedIds.includes(m.projectId)
        && m.budgetCategoryCustom === 'DX_Budget'
    );
    const dxIfMarts = mart.filter(
      m => selectedProjectIdsIF.includes(m.projectId)
        && m.budgetCategoryCustom === 'DX_IF'
    );
    const dxOthersMarts = mart.filter(
      m => selectedProjectIdsOthers.includes(m.projectId)
        && m.budgetCategoryCustom === 'DX_Others'
    );

    // 未受注と受注済みのデータを取得
    const { gbDate: gbA, seriesData: seriesDataAwarded } = processingData(awardedMarts, mode);
    const { gbDate: gbB, seriesData: seriesDataBudget } = processingData(budgetMarts, mode);
    const { gbDate: gbI, seriesData: seriesDataIF } = processingData(ifMarts, mode);
    const { gbDate: gbO, seriesData: seriesDataOthers } = processingData(othersMarts, mode);
    const { gbDate: gbDxA, seriesData: seriesDataDXAwarded } = processingData(dxAwardedMarts, mode);
    const { gbDate: gbDxB, seriesData: seriesDataDXBudget } = processingData(dxBudgetMarts, mode);
    const { gbDate: gbDxI, seriesData: seriesDataDXIF } = processingData(dxIfMarts, mode);
    const { gbDate: gbDxO, seriesData: seriesDataDXOthers } = processingData(dxOthersMarts, mode);

    // targetを組み立て
    const greenList = [];
    const yellowList = [];
    const redList = [];
    // yearsを取得
    const dates = Object.keys(gbA).sort((a: any, b: any) => a.localeCompare(b));
    const goalFilterData = goal.filter(item => (fiscalQuarter ? item.fiscalYear : true));
    const goalGbDate = groupBy(goalFilterData, 'fiscalYear');
    for (let j = 0; j < dates.length; j++) {
      const date = dates[j];
      const sumAmountA = kpiComputeRule[kpiCode](gbA[date]);
      const sumAmountB = kpiComputeRule[kpiCode](gbB[date]);
      const sumAmountI = kpiComputeRule[kpiCode](gbI[date]);
      const sumAmountO = kpiComputeRule[kpiCode](gbO[date]);
      let sumAmount = sumAmountA + sumAmountB + sumAmountI + sumAmountO;
      if (isDxMode) {
        const sumAmountDxA = kpiComputeRule[kpiCode](gbDxA[date]);
        const sumAmountDxB = kpiComputeRule[kpiCode](gbDxB[date]);
        const sumAmountDxI = kpiComputeRule[kpiCode](gbDxI[date]);
        const sumAmountDxO = kpiComputeRule[kpiCode](gbDxO[date]);
        sumAmount = sumAmount + sumAmountDxA + sumAmountDxB + sumAmountDxI + sumAmountDxO;
      }
      const sumTarget = kpiComputeRule[kpiCode](goalGbDate[date]);
      greenList.push(sumAmount / sumTarget >= 1 ? sumTarget : 0);
      redList.push(sumAmount / sumTarget <= 0.9 ? sumTarget : 0);
      yellowList.push(
        sumAmount / sumTarget > 0.9 && sumAmount / sumTarget < 1 ? sumTarget : 0
      );
    }

    // chart optionのデータを組み立て
    options.chartOptions.xAxis.data = dates;
    const dxOffSeries = [
      {
        name: 'Awarded',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#093B5E'
        },
        z: 10,
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesDataAwarded,
      },
      {
        name: 'Budget',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#3299D9'
        },
        z: 10,
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesDataBudget,
      },
      {
        name: 'IF',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#9FD9F6'
        },
        z: 10,
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesDataIF,
      },
      {
        name: 'Others',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#B0CEDB'
        },
        z: 10,
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesDataOthers
      }
    ];
    const dxOnSeries = [
      {
        name: 'DX_Awarded',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#093B5E'
        },
        z: 10,
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesDataDXAwarded,
      },
      {
        name: 'DX_Budget',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#195D87'
        },
        z: 10,
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesDataDXBudget,
      },
      {
        name: 'DX_IF',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#507FA2'
        },
        z: 10,
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesDataDXIF,
      },
      {
        name: 'DX_Others',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#619BC5'
        },
        z: 10,
        barGap: '-125%',
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesDataDXOthers
      }
    ];
    const targetSeries = [
      {
        name: 'Target',
        type: 'bar',
        stack: 'goal',
        data: greenList,
        itemStyle: {
          color: '#9bca63',
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
      },
      {
        name: 'Target',
        type: 'bar',
        stack: 'goal',
        data: yellowList,
        itemStyle: {
          color: '#faad14',
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
      },
      {
        name: 'Target',
        type: 'bar',
        stack: 'goal',
        data: redList,
        itemStyle: {
          color: color.errorColor,
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
      }
    ];

    options.chartOptions.series = dxOffSeries;
    if (isDxMode && consolidated) {
      options.chartOptions.legend.data = options.chartOptions.legend.data.concat([
        'DX_Awarded',
        'DX_Budget',
        'DX_IF',
        'DX_Others'
      ]);
      options.chartOptions.legend.width = 340;
      options.chartOptions.series = options.chartOptions.series.concat(dxOnSeries);
    }
    options.chartOptions.series = options.chartOptions.series.concat(targetSeries);
    setOptions(cloneDeep(options));
  };

  const processingData = (mart, modeValue) => {
    const filterData = mart.filter((item) => ((fiscalQuarter || defaultFiscalQuarter).find(
      (per) => per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear)
    )));
    const gbDate = groupBy(
      filterData,
      periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear'
    );
    if (!periodSwitch) {
      const needFillKeys = fiscalQuarter.filter(fy => !Object.keys(gbDate).includes(fy));
      needFillKeys.forEach(k => {
        gbDate[k] = [];
      });
    }
    const seriesData = Object.keys(gbDate).sort().map((date) => kpiComputeRule[kpiCode](gbDate[date]));
    return { gbDate, seriesData };
  };

  /**
   * 初期化table column
   */
  const initTableColumns = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    const map = groupBy(mart.filter(item => ((fiscalQuarter || defaultFiscalQuarter).find(per => (per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear))))), periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
    const dates = Object.keys(map).sort((a: any, b: any) => a.localeCompare(b));
    const baseColumns = [
      {
        title: '',
        dataIndex: 'type',
        key: 'type',
        fixed: 'left',
        width: sizeMode === 'big' ? 50 : 40,
      },
    ];
    const columns: any[] = dates.map((fiscalYear) => ({
      title: <Tooltip title={fiscalYear}>{fiscalYear}</Tooltip>,
      dataIndex: fiscalYear,
      key: fiscalYear,
      className: 'text-right',
      width: sizeMode === 'big' ? 100 : 50,
      render: val => {
        const isNumber = typeof val === 'number';
        if (isNumber) {
          return val.toLocaleString();
        }
        return val;
      }
    }));
    setTableColumns(baseColumns.concat(columns));
  };

  /**
   * 初期化tableデータ
   */
  const initTableData = () => {
    if (!data) {
      return;
    }
    const { mart, goal = [] } = data;
    const filteredData = mart.filter(m => {
      if (m.budgetCategoryCustom.endsWith('Budget')) {
        return !unselectedIds.includes(m.projectId);
      }
      if (m.budgetCategoryCustom.endsWith('IF')) {
        return selectedProjectIdsIF.includes(m.projectId);
      }
      if (m.budgetCategoryCustom.endsWith('Others')) {
        return selectedProjectIdsOthers.includes(m.projectId);
      }
      return true;
    });
    const martMap = groupBy(filteredData, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
    const goalMap = groupBy(goal, 'fiscalYear');

    const getValue = (info: any[]) => round(kpiComputeRule[kpiCode](info) / 100000000);
    const getTarget = (info: any[]) => round(kpiComputeRule[kpiCode](info) / 100000000);
    const getGoal = (amountInfo: any[], goalInfo: any[]) => {
      if (getTarget(goalInfo)) {
        return `${round((getValue(amountInfo) / getTarget(goalInfo)) * 100).toFixed(0)}%`;
      }
      return '-';
    };

    const prepareData = [
      {
        key: 'value',
        type: `${t('aipcmcty.page.value')}(${t('aipcmcty.page.billion')})`,
      },
      {
        key: 'goal',
        type: t('aipcmcty.page.achievementRate'),
      },
    ];
    fiscalQuarter.forEach(per => {
      prepareData[0][per] = getValue(mode ? martMap[per] : martMap[per]);
      prepareData[1][per] = getGoal(
        mode ? martMap[per]?.filter(elem => elem.japanInvolved === 'Toyo-J関与') : martMap[per],
        goalMap[per]
      );
    });
    setTableData(prepareData);
  };

  // Chart reset 事件
  const handleChartEvent = chartEvent => {
    if (
      chartEvent
      && chartEvent.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
      && chartEvent.chartTitle.value.key === (title as any).key
    ) {
      initData();
    }
  };

  return (
    <>
      {ribbonText ? (
        <Ribbon
          text={ribbonText || t('aipcmcty.page.projectQuantity')}
          top={ribbonTop}
          color={ribbonColor || color.primaryColor}
        />
      ) : (
        <></>
      )}
      <TableChart
        showTable={showTable}
        tableColumns={showTable ? tableColumns : []}
        tableData={tableData}
        chartOptions={options.chartOptions}
        title={
          title
            ? {
              value: title,
              styles: {
                fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                paddingLeft: sizeMode === 'big' ? 15 : 0,
              },
            }
            : options.title
        }
        height={height}
        loading={loading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default TurnoverResource;
