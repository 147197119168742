import { useContext, useEffect, useMemo, useState } from "react";
import { AipcmctyContext } from "../contexts/aipcmcty.context";
import dayjs from "dayjs";

const useVersion = (pageInit: boolean) => {
  const {
    versionList: orgAllSnapshots,
    snapshot,
    snapshotVersion,
    compareVersion,
    setCompareVersion,
  } = useContext(AipcmctyContext);

  const [snapshotList, setSnapshotList] = useState([]);
  const [versionList, setVersionList] = useState([]);
  let compSnapshot = compareVersion.snapshot;
  let compVersion = compareVersion.snapshotVersion;

  const versionOptCollection = useMemo(() => {
    const filterVersionCollection = orgAllSnapshots.reduce((acc, cur) => {
      // exclude great than current version
      // exclude current version
      if (cur.snapshot > snapshot || (cur.snapshot === snapshot && cur.snapshotVersion === snapshotVersion)) {
        return acc;
      }
      const list = acc[cur.snapshot];
      if (!list) {
        acc[cur.snapshot] = [];
      }
      acc[cur.snapshot].push({
        label: cur.snapshotVersionName,
        value: cur.snapshotVersion,
      });
      return acc;
    }, {});
    if (Object.keys(filterVersionCollection).length === 0) {
      filterVersionCollection[snapshot] = [
        {
          label: orgAllSnapshots.find((version) => version.snapshotVersion === snapshotVersion)?.snapshotVersionName,
          value: snapshotVersion,
        },
      ];
    }
    return filterVersionCollection;
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    const spList = Object.keys(versionOptCollection).sort((a, b) => (b > a ? 1 : -1));
    const curSnapshotIndex = spList.findIndex((s) => s === snapshot);
    let defaultSnapshot = snapshot;
    if (curSnapshotIndex <= 0) {
      if (spList.length === 1) {
        const [s] = spList;
        defaultSnapshot = s;
      }
      if (spList.length > 1) {
        defaultSnapshot = spList[curSnapshotIndex + 1];
      }
    }
    const defaultCompareVer = {
      snapshot: defaultSnapshot,
      snapshotVersion: 'default',
    };
    const curSnapshotList = spList
      .filter((s) => dayjs(s).isBefore(snapshot) || dayjs(s).isSame(snapshot))
      .map((s) => ({ label: s, value: s }));
    if (
      pageInit
      || (!compareVersion.snapshot && !compareVersion.snapshotVersion)
      || !curSnapshotList.some((s) => s.value === compareVersion.snapshot)
    ) {
      compSnapshot = defaultCompareVer.snapshot;
      compVersion = defaultCompareVer.snapshotVersion;
    }
    setCompareVersion({
      snapshot: compSnapshot,
      snapshotVersion: compVersion,
    });
    setSnapshotList(curSnapshotList);
    setVersionList(versionOptCollection[compSnapshot]);
  }, [snapshot, snapshotVersion]);

  return {
    orgAllSnapshots,
    snapshot,
    snapshotVersion,
    compareVersion,
    snapshotList,
    versionList,
    versionOptCollection,
    setCompareVersion
  };
};

export default useVersion;