/* eslint-disable no-unused-expressions */
import { InputRef, Table, TableProps, Button, Input, Space } from 'antd';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import React, { memo, useEffect, useRef, useState } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';

const VirtualTable: React.FC<any> = (props) => {
  const { columns, scroll } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [childrenColumns, setChildrenColumns] = useState([]);
  const [mergedChildrenColumns, setMergedChildrenColumns] = useState([]);

  useEffect(() => {
    let allColumns = [];
    columns.forEach((column) => {
      if (column.children) {
        allColumns = [...allColumns, ...column.children]
      }
    });
    setChildrenColumns(allColumns)
  }, [columns]);


  useEffect(() => {
    const widthColumnCount = childrenColumns!.filter(
      ({ width }) => !width
    ).length;

    const mergedAllColumns = childrenColumns!.map((column) => {
      if (column.width) {
        return column;
      }
      const computeWith = Math.floor(tableWidth / widthColumnCount);
      return {
        ...column,
        width: computeWith,
      };
    });

    setMergedChildrenColumns(mergedAllColumns)

  }, [childrenColumns]);

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth, mergedChildrenColumns]);

  const renderVirtualList = (
    rawData: object[],
    { scrollbarSize, ref, onScroll }: any
  ) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 49;

    let allColumnWith = 0;
    const widthColumnCount = childrenColumns!.filter(
      ({ width }) => !width
    ).length;
    mergedChildrenColumns.forEach((column) => {
      if (column.width) {
        allColumnWith += column.width;
      } else {
        const computeWith = Math.floor(tableWidth / widthColumnCount);
        allColumnWith += computeWith;
      }
    });

    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        style={{
          scrollbarColor: 'auto'
        }}
        columnCount={mergedChildrenColumns.length}
        columnWidth={(index: number) => {
          const { width } = mergedChildrenColumns[index];
          const finalWidth =
            totalHeight > scroll!.y! &&
            index === mergedChildrenColumns.length - 1
              ? (width as number) - scrollbarSize - 1
              : (width as number);
          let rate = 1
          if (tableWidth > allColumnWith) {
            rate = tableWidth / allColumnWith
          }
          return finalWidth * rate;
        }}
        height={scroll!.y as number}
        rowCount={rawData.length}
        rowHeight={() => 49}
        width={tableWidth}
        onScroll={({ scrollLeft }: { scrollLeft: number }) => {
          onScroll({ scrollLeft });
        }}
      >
        {({
          columnIndex,
          rowIndex,
          style,
        }: {
          columnIndex: number;
          rowIndex: number;
          style: React.CSSProperties;
        }) => {
          const curColumn = (mergedChildrenColumns as any)[columnIndex];
          return (
            <div
              className={classNames(
                `cmcty-table-cell-ellipsis virtual-table-cell ${
                  curColumn.className ? curColumn.className : ''
                }`,
                {
                  'virtual-table-cell-last':
                    columnIndex === mergedChildrenColumns.length - 1,
                }
              )}
              style={{
                ...style,
                padding: '0 12px 0 0',
                lineHeight: '49px',
                borderBottom: '1px solid rgba(0,0,0,.06)',
                borderLeft: 0,
                borderRight: 0,
              }}
            >
              {curColumn.render
                ? curColumn.render(
                    rawData[rowIndex][curColumn.dataIndex],
                    rawData[rowIndex]
                  )
                : (rawData[rowIndex] as any)[
                    (mergedChildrenColumns as any)[columnIndex].dataIndex
                  ]}
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...props}
        className="virtual-table"
        rowKey={(record) => record.id}
        columns={columns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ResizeObserver>
  );
};

export default VirtualTable;
// export default memo(VirtualTable, (prev, next) => prev.columns === next.columns);
