/* eslint-disable max-len */
// chart title: Buffer Suitability Evaluation
import React, {
  useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, groupBy } from 'lodash';
import { Tooltip } from 'antd';
import Ribbon from '../widget/ribbon';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions;
  periodSwitch?: boolean;
  showTable?: boolean;
  chartHandler?: (e: any) => any;
};

const TimeValueChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode, data, height, loading, title, ribbonText, ribbonColor,
    ribbonTop, fiscalQuarter, tableOptions, periodSwitch = false, showTable = true,
    titleLoading, coverLoading, chartHandler
  } = props;

  const {
    defaultFiscalQuarter, color, sizeMode
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 45,
      gridRight: 30,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridBottom: 30,
      splitNumber: 3,
    },
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.grossProfitAndRate'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          if (params && params.length > 0) {
            let tooltip = '<div style="text-align: left;">';
            for (let index = 0; index < params.length; index++) {
              const element = params[index];
              if (element.value > 0 || element.seriesName !== 'Target') {
                if (element.seriesType === 'line') {
                  tooltip += `${element.marker}${element.seriesName}: ${round(element.value * 100)
                  }% <br/>`;
                } else {
                  tooltip += `${element.marker}${element.seriesName}: ${round(element.value / 100000000)} <br/>`;
                }
              }
            }
            tooltip += '</div>';
            return tooltip;
          }
          return null;
        },
      },
      legend: {
        top: 5,
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        data: [],
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: t('aipcmc.charts.hundredMillion'),
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => value / 100000000
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
          nameGap: 5,
          splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
        },
        {
          type: 'value',
          position: 'right',
          name: '%',
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => `${value * 100}`,
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
        }
      ],
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      series: [],
    },
    height: 220,
  });

  /**
   * データまた四半期switchの変化がありましたら、
   * chartとtableは再計算必要があります
   */
  useEffect(() => {
    initData();
  }, [data, fiscalQuarter, kpiComputeRule]);

  /**
   * 初期化
   */
  const initData = () => {
    initChartData();
    initTableColumns();
    initTableData();
  };

  /**
   * 初期化Chartデータ
   */
  const initChartData = () => {
    if (!data) {
      return;
    }
    const { mart } = data;

    // 粗利額未受注と受注済みのデータを取得
    const filterGrossProfitData = mart.filter(item => ((periodSwitch ? defaultFiscalQuarter : (fiscalQuarter || defaultFiscalQuarter)).find(per => (per === item.fiscalYear))));
    const gbDate = groupBy(filterGrossProfitData, 'fiscalYear');
    const grossProfitData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode].grossProfit(gbDate[date]));
    const npvData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode].npv(gbDate[date]));
    const npvRate = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode].npvRate(gbDate[date]));

    // yearsを取得
    const dates = Object.keys(gbDate).sort((a: any, b: any) => a - b);
    options.chartOptions.xAxis.data = dates;

    // chart optionのデータを組み立て
    // bar: 粗利額
    options.chartOptions.series = [];

    options.chartOptions.legend.data = [
      t('aipcmcty.page.grossProfitAmount'),
      t('aipcmcty.page.npvGrossProfit'),
      t('aipcmcty.page.npvRate')
    ];
    options.chartOptions.series.push({
      name: t('aipcmcty.page.npvGrossProfit'),
      type: 'bar',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      z: 10,
      data: npvData,
    });
    options.chartOptions.series.push({
      name: t('aipcmcty.page.grossProfitAmount'),
      type: 'bar',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      z: 10,
      data: grossProfitData,
    });
    options.chartOptions.series.push({
      name: t('aipcmcty.page.npvRate'),
      type: 'line',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        formatter: (params) => `${params.value * 100}%`,
      },
      emphasis: {
        focus: 'series',
      },
      data: npvRate,
      yAxisIndex: 1,
      zlevel: 1,
    });
    setOptions(cloneDeep(options));
  };

  /**
   * 初期化table column
   */
  const initTableColumns = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    const map = groupBy(mart.filter(item => ((periodSwitch ? defaultFiscalQuarter : (fiscalQuarter || defaultFiscalQuarter)).find(per => (per === item.fiscalYear)))), 'fiscalYear');
    const years = Object.keys(map).sort((a: any, b: any) => a - b);
    const baseColumns = [{
      title: '',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      width: sizeMode === 'big' ? 50 : 95
    }];
    const columns: any[] = years.map(fiscalYear => ({
      title: <Tooltip title={fiscalYear}>{fiscalYear}</Tooltip>,
      dataIndex: fiscalYear,
      key: fiscalYear,
      className: 'text-right',
      width: sizeMode === 'big' ? 100 : 50,
    }));
    setTableColumns(baseColumns.concat(columns));
  };

  /**
   * 初期化tableデータ
   */
  const initTableData = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    // const map = groupBy(mart, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
    const map = groupBy(mart, 'fiscalYear');
    const getGrossProfit = (info: any[]) => round(kpiComputeRule[kpiCode].grossProfit(info) / 100000000);
    const getNpv = (info: any[]) => round(kpiComputeRule[kpiCode].npv(info) / 100000000);

    const prepareData = [{
      key: 'grossProfit',
      type: `${t('aipcmcty.page.grossProfitAmount')}（${t('aipcmcty.page.billion')}）`,
    }, {
      key: 'npv',
      type: `${t('aipcmcty.page.npvGrossProfit')}（${t('aipcmcty.page.billion')}）`,
    }];

    Object.keys(map).forEach((per) => {
      prepareData[0][per] = getGrossProfit(map[per]);
      prepareData[1][per] = getNpv(map[per]);
    });
    setTableData(prepareData);
  };

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        initData();
      }
    }
    if (chartHandler) {
      chartHandler(e);
    }
  };

  return (
    <>
      { ribbonText ? <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantityAndQuality')} top={ribbonTop} color={ribbonColor || color.errorColor} /> : <></>}
      <TableChart
        showTable={showTable}
        tableColumns={tableColumns}
        tableData={tableData}
        chartOptions={options.chartOptions}
        title={
          title
            ? {
              value: title,
              styles: {
                fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                paddingLeft: sizeMode === 'big' ? 15 : 0,
              },
            }
            : options.title
        }
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default TimeValueChart;
