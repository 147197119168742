/* eslint-disable prefer-destructuring */
// chart title: Buffer Suitability Evaluation
import React, {
  useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import Ribbon from '../widget/ribbon';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';

type ChartProps = {
  data?: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions
  showTable?: boolean;
  periodSwitch?: boolean;
  layoutOptions?: any;
  chartHandler?: (e: any) => any;
};

const CasesPercentageChart: React.FC<ChartProps> = (props) => {
  const {
    data = [], height, loading, title, ribbonText, ribbonColor, ribbonTop, fiscalQuarter,
    showTable = false, tableOptions, periodSwitch = false, layoutOptions, titleLoading, coverLoading,
    chartHandler
  } = props;

  const { color, sizeMode } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 20,
      gridLeft: 0,
      gridRight: 20,
      gridBottom: 0,
    },
    big: {
      ...sizeMapBase.big,
      gridTop: 20,
      gridLeft: 0,
      gridRight: 20,
      gridBottom: 0,
    },
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.orderAmount'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        show: true,
        formatter: (params) => {
          const dataX = params.data[0];
          const dataY = params.data[1];
          let xLabel = '';
          let yLabel = '';
          let xMidLabel = '';
          const xTitle = layoutOptions.xLabels[0];
          const yTitle = layoutOptions.yLabels[0];
          const xMids = layoutOptions.xLabels[1];
          const xLabels = layoutOptions.xLabels[2];
          const yLabels = layoutOptions.yLabels[1];
          const xTitleLabel = xTitle[0];
          const yTitleLabel = yTitle[0];

          if (dataX < (1 / 4)) {
            xLabel = xLabels[0];
            xMidLabel = xMids[0];
          }
          if (dataX > (1 / 4) && dataX < (2 / 4)) {
            xLabel = xLabels[1];
            xMidLabel = xMids[0];
          }
          if (dataX > (2 / 4) && dataX < (3 / 4)) {
            xLabel = xLabels[2];
            xMidLabel = xMids[1];
          }
          if (dataX > (3 / 4)) {
            xLabel = xLabels[3];
            xMidLabel = xMids[1];
          }
          if (dataY < 1) {
            yLabel = typeof yLabels[0] === 'string' ? yLabels[0] : yLabels[0].label;
          }
          if (dataY > 1 && dataX < 2) {
            yLabel = typeof yLabels[1] === 'string' ? yLabels[1] : yLabels[1].label;
          }
          if (dataY > 2 && dataY < 3) {
            yLabel = typeof yLabels[2] === 'string' ? yLabels[2] : yLabels[2].label;
          }
          if (dataY > 3) {
            yLabel = typeof yLabels[3] === 'string' ? yLabels[3] : yLabels[3].label;
          }
          const str = `${xTitleLabel}(${xMidLabel}): ${xLabel} <br/> ${yTitleLabel}: ${yLabel}`;
          return str;
        },
      },
      legend: {
        top: 5,
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        data: [],
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      yAxis: {
        show: false,
        type: 'value',
        position: 'left',
        name: t('aipcmc.charts.hundredMillion'),
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameGap: 5,
        splitNumber: 4,
      },
      xAxis: {
        show: false,
        type: 'value',
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
        splitNumber: 4,
      },
      series: [{
        symbol: 'circle',
        symbolSize: 10,
        type: 'scatter',
        // label: {
        //   position: 'top',
        //   formatter: (params) => `${params.data[2]}`,
        // },
        data: [],
        zlevel: 1,
      }]
    },
    height: 220,
  });

  useEffect(() => {
    initData();
  }, []);

  const initData = () => {
    initChartData();
  };

  const initChartData = () => {
    // options.chartOptions.series[0].data = [
    //   [0.1, 0.5],
    //   [0.2, 1.5],
    //   [0.3, 2.5],
    //   [0.4, 3.5],
    //   [0.5, 2.5],
    //   [0.6, 1.5],
    //   [0.7, 2.5],
    //   [0.8, 3.5],
    //   [0.9, 2.5],
    // ];
    createLineBlock(0, 0, 1, 4);
    setOptions(cloneDeep(options));
  };

  const createLineBlock = (xMin, yMin, xMax, yMax) => {
    const pX0 = xMin;
    const pY0 = yMin;
    const pX1 = (xMax - xMin) / 4 + xMin;
    const pY1 = (yMax - yMin) / 4 + yMin;
    const pX2 = ((xMax - xMin) / 4) * 2 + xMin;
    const pY2 = ((yMax - yMin) / 4) * 2 + yMin;
    const pX3 = ((xMax - xMin) / 4) * 3 + xMin;
    const pY3 = ((yMax - yMin) / 4) * 3 + yMin;
    const pX4 = xMax;
    const pY4 = yMax;

    const block11 = {
      type: 'line',
      data: [
        [pX0, pY0],
        [pX1, pY0],
        [pX1, pY1],
        [pX0, pY1],
        [pX0, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.green,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.green,
        opacity: 1,
        color: '#cfcfcf'
      },
      animation: false,
    };
    const block12 = {
      type: 'line',
      data: [
        [pX1, pY0],
        [pX2, pY0],
        [pX2, pY1],
        [pX1, pY1],
        [pX1, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.green,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.green,
        opacity: 1,
        color: color.successColor
      },
      animation: false,
    };
    const block13 = {
      type: 'line',
      data: [
        [pX2, pY0],
        [pX3, pY0],
        [pX3, pY1],
        [pX2, pY1],
        [pX2, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.orange,
        opacity: 1,
        color: color.primaryColor,
      },
      animation: false,
    };
    const block14 = {
      type: 'line',
      data: [
        [pX3, pY0],
        [pX4, pY0],
        [pX4, pY1],
        [pX3, pY1],
        [pX3, pY0],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.orange,
        opacity: 1,
        color: color.warningColor
      },
      animation: false,
    };
    const block21 = {
      type: 'line',
      data: [
        [pX0, pY1],
        [pX1, pY1],
        [pX1, pY2],
        [pX0, pY2],
        [pX0, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.green,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.green,
        opacity: 1,
        color: color.successColor
      },
      animation: false,
    };
    const block22 = {
      type: 'line',
      data: [
        [pX1, pY1],
        [pX2, pY1],
        [pX2, pY2],
        [pX1, pY2],
        [pX1, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.orange,
        opacity: 1,
        color: color.successColor
      },
      animation: false,
    };
    const block23 = {
      type: 'line',
      data: [
        [pX2, pY1],
        [pX3, pY1],
        [pX3, pY2],
        [pX2, pY2],
        [pX2, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.red,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.red,
        opacity: 1,
        color: color.primaryColor,
      },
      animation: false,
    };
    const block24 = {
      type: 'line',
      data: [
        [pX3, pY1],
        [pX4, pY1],
        [pX4, pY2],
        [pX3, pY2],
        [pX3, pY1],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.red,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.red,
        opacity: 1,
        color: color.warningColor
      },
      animation: false,
    };
    const block31 = {
      type: 'line',
      data: [
        [pX0, pY2],
        [pX1, pY2],
        [pX1, pY3],
        [pX0, pY3],
        [pX0, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.orange,
        opacity: 1,
        color: color.primaryColor,
      },
      animation: false,
    };
    const block32 = {
      type: 'line',
      data: [
        [pX1, pY2],
        [pX2, pY2],
        [pX2, pY3],
        [pX1, pY3],
        [pX1, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.red,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.red,
        opacity: 1,
        color: color.primaryColor,
      },
      animation: false,
    };
    const block33 = {
      type: 'line',
      data: [
        [pX2, pY2],
        [pX3, pY2],
        [pX3, pY3],
        [pX2, pY3],
        [pX2, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.red,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.red,
        opacity: 1,
        color: color.primaryColor,
      },
      animation: false,
    };
    const block34 = {
      type: 'line',
      data: [
        [pX3, pY2],
        [pX4, pY2],
        [pX4, pY3],
        [pX3, pY3],
        [pX3, pY2],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.red,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.red,
        opacity: 1,
        color: color.warningColor
      },
      animation: false,
    };
    const block41 = {
      type: 'line',
      data: [
        [pX0, pY3],
        [pX1, pY3],
        [pX1, pY4],
        [pX0, pY4],
        [pX0, pY3],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        opacity: 1,
        color: color.warningColor
      },
      animation: false,
    };
    const block42 = {
      type: 'line',
      data: [
        [pX1, pY3],
        [pX2, pY3],
        [pX2, pY4],
        [pX1, pY4],
        [pX1, pY3],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.orange,
        opacity: 1,
        color: color.warningColor
      },
      animation: false,
    };
    const block43 = {
      type: 'line',
      data: [
        [pX2, pY3],
        [pX3, pY3],
        [pX3, pY4],
        [pX2, pY4],
        [pX2, pY3],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.orange,
        opacity: 1,
        color: color.warningColor
      },
      animation: false,
    };
    const block44 = {
      type: 'line',
      data: [
        [pX3, pY3],
        [pX4, pY3],
        [pX4, pY4],
        [pX3, pY4],
        [pX3, pY3],
      ],
      symbol: 'none',
      lineStyle: {
        // color: 'grey',
        // color: colors.orange,
        width: 2,
        opacity: 0,
      },
      emphasis: {
        disabled: true,
      },
      areaStyle: {
        // color: colors.orange,
        opacity: 1,
        color: color.warningColor
      },
      animation: false,
    };
    options.chartOptions.xAxis.min = pX0;
    options.chartOptions.xAxis.max = pX4;
    options.chartOptions.yAxis.min = pY0;
    options.chartOptions.yAxis.max = pY4;
    options.chartOptions.xAxis.axisLabel.formatter = (value) => {
      if (value <= pX1) {
        return t('aipcmcty.page.small');
      }
      if (value <= pX2 && value > pX1) {
        return t('aipcmcty.page.medium');
      }
      return t('aipcmcty.page.large');
    };
    options.chartOptions.yAxis.axisLabel.formatter = (value) => {
      if (value <= pY1) {
        return t('aipcmcty.page.small');
      }
      if (value <= pY2 && value > pY1) {
        return t('aipcmcty.page.medium');
      }
      return t('aipcmcty.page.large');
    };
    const series = [
      options.chartOptions.series[0],
      block11, block12, block13, block14,
      block21, block22, block23, block24,
      block31, block32, block33, block34,
      block41, block42, block43, block44,
    ];
    options.chartOptions.series = series;
  };

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        initData();
      }
    }
    if (chartHandler) {
      chartHandler(e);
    }
  };

  return (
    <>
      {ribbonText ? <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantity')} top={ribbonTop} color={ribbonColor || color.primaryColor} /> : <></>}
      <TableChart
        showTable={showTable}
        chartOptions={options.chartOptions}
        title={title ? {
          value: title,
          styles: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
            paddingLeft: sizeMode === 'big' ? 15 : 0,
          },
        } : options.title}
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        tableOptions={tableOptions}
        layoutOptions={layoutOptions ? {
          ...layoutOptions,
          size: {
            xAxis: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
            yAxis: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          }
        } : undefined}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default CasesPercentageChart;