import { ContentChart, ECHART_EVENT } from '@meteor/frontend-core';
import { AppContext } from '../../contexts/AppContext';
import React, { useContext, useEffect, useState } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { getDayString, getAllDay } from '../../utils/DateUtil';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

type ChartProps = {
  title: string;
  data: any[];
  loading?: boolean;
};

const SupplyValidationGanttChart: React.FC<ChartProps> = (props) => {
  const { t } = useTranslation();
  const { loading, data, title } = props;
  const { selectorTop } = useWindowSize({
    selector: '.gantt-chart',
  });
  const { color } = useContext(AppContext);
  const [orderAmount, setOrderAmount] = useState(0);
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    if (data && data.length > 0) {
      let sum = 0;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        sum += element.orderAmount || 0;
      }
      setOrderAmount(sum);
    }
  }, [data]);

  useEffect(() => {
    const timeline = [];
    const feedList = [];
    const epcList = [];
    for (let index = 0; index < data.length; index++) {
      const item = data[index];
      const feedItem = [];
      const epcItem = [];
      if (item.feedStartDate) {
        timeline.push(new Date(item.feedStartDate).getTime());
        feedItem.push([getDayString(item.feedStartDate), item.projectId]);
      }
      if (item.feedEndDate) {
        timeline.push(new Date(item.feedEndDate).getTime());
        feedItem.push([getDayString(item.feedEndDate), item.projectId]);
      }
      if (item.projectStartDate) {
        timeline.push(new Date(item.projectStartDate).getTime());
        epcItem.push([getDayString(item.projectStartDate), item.projectId]);
      }
      if (item.projectEndDate) {
        timeline.push(new Date(item.projectEndDate).getTime());
        epcItem.push([getDayString(item.projectEndDate), item.projectId]);
      }
      if (feedItem.length > 0) {
        feedList.push({ name: item.projectName, value: feedItem });
      }
      if (epcItem.length > 0) {
        epcList.push({ name: item.projectName, value: epcItem });
      }
    }
    const sorted = timeline.sort((a, b) => a - b);
    const xAxisMinValue = sorted[0];
    const xAxisMaxValue = sorted[sorted.length - 1];

    setOptions({
      title: {
        key: title,
        value: (
          <>
            {title}
            <span style={{ fontSize: 14, paddingLeft: 12 }}>
              <span>{`${t('aipcmcty.page.numProjects')}:`}</span>
              <span>{data.length}</span>
              <span style={{ paddingLeft: 12 }}>{`${t('aipcmcty.page.projectAmount')}: `}</span>
              <span>{orderAmount.toLocaleString()}</span>
            </span>
          </>
        ),
      },
      contentList: [],
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            const projectId = params[0].axisValue;
            const item = data.find((project)=> projectId === project.projectId);
            let tooltips = '<div style="text-align: left;">';
            if (item) {
              tooltips += `${t('aipcmcty.page.projectCaseName')}：${item.projectName} <br/>`;
              tooltips += `${t('aipcmcty.page.projectCaseId')}：${item.projectId} <br/>`;

              if (item.feedStartDate) {
                tooltips += `${t('aipcmcty.page.feedStartDate')}：${getDayString(item.feedStartDate)} <br/>`;
              }
              if (item.feedEndDate) {
                tooltips += `${t('aipcmcty.page.feedEndDate')}：${getDayString(item.feedEndDate)} <br/>`;
              }
              if (item.projectStartDate) {
                tooltips += `${t('aipcmcty.page.epcStartDate')}：${getDayString(item.projectStartDate)} <br/>`;
              }
              if (item.projectEndDate) {
                tooltips += `${t('aipcmcty.page.epcEndDate')}：${getDayString(item.projectEndDate)} <br/>`;
              }
            }
            tooltips += '</div>';
            return tooltips;
          },
          axisPointer: {
            type: 'cross',
            axis: 'y',
            snap: true,
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          show: true,
          data: [
            {
              name: 'FEED',
              icon: 'rect',
              itemStyle: { color: color.secondaryColor },
            },
            {
              name: 'EPC',
              icon: 'rect',
              itemStyle: { color: color.primaryColor },
            },
          ],
        },
        grid: {
          top: 30,
          left: 120,
          right: 40,
          bottom: 40,
        },
        xAxis: {
          type: 'category',
          data: getAllDay(xAxisMinValue, xAxisMaxValue, 5),
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            fontSize: 14,
          },
          data: data.map((item) => item.projectId),
        },
        animation: false,
        series: [
          ...feedList.map((item) => ({
            name: 'FEED',
            type: 'line',
            symbol: 'none',
            // symbol: 'rect',
            // symbolSize: 10,
            // symbolOffset: [-9, 0],
            // showAllSymbol: true,
            lineStyle: {
              width: 20,
              color: color.secondaryColor,
            },
            // itemStyle: {
            //   color: 'black',
            // },
            // markPoint: {
            //   symbol: 'triangle',
            //   symbolSize: 20,
            //   symbolRotate: -90,
            //   symbolOffset: [-9, 0],
            //   itemStyle: {
            //     color: color.secondaryColor,
            //   },
            //   data: [
            //     {
            //       type: 'min', // 设置箭头图标在 line 图表的尾部
            //       label: {
            //         show: false // 隐藏标签
            //       }
            //     }
            //   ]
            // },
            data: item.value,
          })),
          ...epcList.map((item) => ({
            name: 'EPC',
            type: 'line',
            symbol: 'none',
            // symbol: 'rect',
            // symbolSize: 21,
            // symbolOffset: [-20, 0],
            // showAllSymbol: true,
            lineStyle: {
              width: 20,
              color: color.primaryColor,
            },
            // itemStyle: {
            //   color: 'black',
            // },
            // markPoint: {
            //   symbol: 'triangle',
            //   symbolSize: 20,
            //   symbolRotate: -90,
            //   symbolOffset: [10, 0],
            //   itemStyle: {
            //     color: color.primaryColor,
            //   },
            //   data: [
            //     {
            //       type: 'min', // 设置箭头图标在 line 图表的尾部
            //       label: {
            //         show: false // 隐藏标签
            //       }
            //     }
            //   ]
            // },
            data: item.value,
          })),
        ],
      },
      height: data.length * 30 + 80,
    });
  }, [orderAmount]);

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title === chartTitle.key) {
        setOptions(cloneDeep(options));
      }
    }
  };

  return (
    <>
      <div
        className="gantt-chart"
        style={{
          background: '#fff',
          marginTop: 5,
          padding: '6px 20px',
        }}
      >
        {options && !loading ? (
          <ContentChart
            contentList={options.contentList}
            chartOptions={options.chartOptions}
            title={options.title}
            height={options.height}
            showSeriesLabel
            onChartEvent={handleChartEvent}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SupplyValidationGanttChart;
