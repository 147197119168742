/* eslint-disable brace-style */
// chart title: Buffer Suitability Evaluation
import React, {
  ReactNode, useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  cloneDeep, groupBy, orderBy, sumBy
} from 'lodash';
import { Tooltip } from 'antd';
import Ribbon from '../widget/ribbon';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { localeMapping } from '../../consts/locale.mapping';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions
  showTable?: boolean;
  periodSwitch?: boolean;
  barWidth?: number;
  chartHandler?: (e: any) => any;
};

const GrossProfitCompositionChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode, data, height, loading, title, ribbonText, ribbonColor, ribbonTop,
    fiscalQuarter, showTable = true, tableOptions, periodSwitch = false, barWidth, titleLoading = false, coverLoading,
    chartHandler
  } = props;

  const {
    defaultFiscalQuarter, color, sizeMode, locale
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      splitNumber: 3
    },
    big: {
      ...sizeMapBase.big,
      splitNumber: 3
    },
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.orderAmount'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params: any[]) => {
          if (params && params.length > 0) {
            const revertParams = params.reverse();
            if (['KPJ010'].includes(kpiCode)) {
              let tooltips = '<div><table style="width: 280px"><tr>';
              let columnCount = 0;
              const maxColumnCount = 2;
              for (let index = 0; index < revertParams.length; index++) {
                const element = revertParams[index];
                if (columnCount === maxColumnCount) {
                  tooltips += '</tr><tr>';
                  columnCount = 0;
                }
                tooltips += `<td style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis; max-width: 280px">${element.marker}${element.seriesName}:</td> 
                <td style="text-align: right; font-weight:bold; padding-right: 5px">${round(element.value * 100)}%</td>`;
                columnCount++;
              }
              tooltips += '</tr></table></div>';
              return tooltips;
            }
            let tooltip = '<div><table>';
            for (let index = 0; index < revertParams.length; index++) {
              const element = revertParams[index];
              if ((element.value > 0 || element.seriesName !== 'Target') && element.seriesName !== `FY25${t('aipcmcty.page.target')}:25%` && element.seriesName !== `FY30${t('aipcmcty.page.target')}:50%`) {
                tooltip
                    += `<tr><td>${element.marker
                  }${element.seriesName}:</td><td style="text-align: right; font-weight:bold; padding-right: 5px">${round(element.value * 100)}%</td>`;
              }
            }
            tooltip += '</tr></table></div>';
            return tooltip;
          }
          return null;
        },
        appendToBody: true,
        className: 'overflow-all',
        hideDelay: 0,
        position: (point, params, dom, rect, size) => [point[0] - size.contentSize[0] - 10, point[1] - size.contentSize[1] / 4],
      },
      legend: {
        top: 5,
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        data: [],
        needScroll: true,
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: (() => {
          if (['KPJ009', 'KPJ010'].includes(kpiCode)) {
            return 50;
          }
          return getSizeByMap(sizeMode, sizeMap, 'gridTop');
        })(),
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: '%',
          min: 0,
          max: 1,
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => `${value * 100} `,
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
          nameGap: 5,
        },
      ],
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      series: []
    },
    height: 220,
  });

  /**
   * データまた四半期switchの変化がありましたら、
   * chartとtableは再計算必要があります
   */
  useEffect(() => {
    initData();
  }, [data, fiscalQuarter, kpiComputeRule]);

  /**
   * 初期化
   */
  const initData = () => {
    initChartData();
    initTableColumns();
    initTableData();
  };

  /**
   * 初期化Chartデータ
   */
  const initChartData = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    // barのwidthを計算
    const width = (window.innerWidth - 300) / (sizeMode === 'small' ? 4 : 2);
    const preContainer = barWidth || width / (fiscalQuarter?.length || 5); // x count
    sizeMap.big.barWidth = preContainer * 0.45;
    sizeMap.small.barWidth = preContainer * 0.45;

    // 未受注と受注済みのデータを取得
    const filterData = mart.filter(item => ((fiscalQuarter || defaultFiscalQuarter).find(per => (per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear)))));
    const gbDate = groupBy(filterData, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');

    // yearsを取得
    const years = Object.keys(gbDate).sort((a: any, b: any) => a - b);

    // chart optionのデータを組み立て
    options.chartOptions.xAxis.data = years;
    options.chartOptions.series = [];
    const seriesDataList = [];
    // 粗利構成比(非EPC/EPC)
    if (['KPJ005', 'KMT101'].includes(kpiCode)) {
      const notEpcData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.isEpc === '非EPC'), gbDate[date]));
      const isEpcData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.isEpc === 'EPC'), gbDate[date]));
      options.chartOptions.legend.data = [t('aipcmcty.page.nonEPC'), 'EPC', `FY25${t('aipcmcty.page.target')}:25%`, `FY30${t('aipcmcty.page.target')}:50%`];
      seriesDataList.push({
        name: t('aipcmcty.page.nonEPC'),
        seriesData: notEpcData,
        markLine: {
          name: `FY25${t('aipcmcty.page.target')}:25%`,
          type: 'line',
          z: 11,
          symbol: 'none',
          lineStyle: {
            color: color.errorColor,
            width: 2,
            type: 'dashed',
          },
          itemStyle: {
            color: color.errorColor,
          },
          data: new Array(notEpcData.length).fill(0.25),
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
      seriesDataList.push({
        name: 'EPC',
        seriesData: isEpcData,
        markLine: {
          name: `FY30${t('aipcmcty.page.target')}:50%`,
          type: 'line',
          symbol: 'none',
          z: 11,
          lineStyle: {
            color: color.warningColor,
            type: 'dashed',
            width: 2,
          },
          itemStyle: {
            color: color.warningColor,
          },
          data: new Array(isEpcData.length).fill(0.5),
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
    }
    // 粗利構成比(新規/既存)
    if (['KPJ006', 'KMT102'].includes(kpiCode)) {
      const notExistedEpcData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.isExisted === '新規'), gbDate[date]));
      const isExistedData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.isExisted === '既存'), gbDate[date]));
      options.chartOptions.legend.data = [t('aipcmcty.page.new'), t('aipcmcty.page.existing'), `FY25${t('aipcmcty.page.target')}:25%`, `FY30${t('aipcmcty.page.target')}:50%`];
      seriesDataList.push({
        name: t('aipcmcty.page.new'),
        seriesData: notExistedEpcData,
        markLine: {
          name: `FY25${t('aipcmcty.page.target')}:25%`,
          type: 'line',
          z: 11,
          symbol: 'none',
          lineStyle: {
            color: color.errorColor,
            width: 2,
            type: 'dashed',
          },
          itemStyle: {
            color: color.errorColor,
          },
          data: new Array(notExistedEpcData.length).fill(0.25),
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
      seriesDataList.push({
        name: t('aipcmcty.page.existing'),
        seriesData: isExistedData,
        markLine: {
          name: `FY30${t('aipcmcty.page.target')}:50%`,
          type: 'line',
          symbol: 'none',
          z: 11,
          lineStyle: {
            color: color.warningColor,
            type: 'dashed',
            width: 2,
          },
          itemStyle: {
            color: color.warningColor,
          },
          data: new Array(isExistedData.length).fill(0.5),
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
    }
    // 粗利構成比(Japan/主要拠点)
    if (['KPJ007', 'KMT103'].includes(kpiCode)) {
      const notJapanData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.isJapan === 0), gbDate[date]));
      const isJapanData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.isJapan === 1), gbDate[date]));
      options.chartOptions.legend.data = ['Japan', t('aipcmcty.page.mainBase'), `FY25${t('aipcmcty.page.target')}:45%`, `FY30${t('aipcmcty.page.target')}:50%`];
      seriesDataList.push({
        name: t('aipcmcty.page.mainBase'),
        seriesData: notJapanData,
        markLine: {
          name: `FY25${t('aipcmcty.page.target')}:45%`,
          type: 'line',
          z: 11,
          symbol: 'none',
          lineStyle: {
            color: color.errorColor,
            width: 2,
            type: 'dashed',
          },
          itemStyle: {
            color: color.errorColor,
          },
          data: new Array(notJapanData.length).fill(0.45),
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
      seriesDataList.push({
        name: 'Japan',
        seriesData: isJapanData,
        markLine: {
          name: `FY30${t('aipcmcty.page.target')}:50%`,
          type: 'line',
          symbol: 'none',
          z: 11,
          lineStyle: {
            color: color.warningColor,
            type: 'dashed',
            width: 2,
          },
          itemStyle: {
            color: color.warningColor,
          },
          data: new Array(isJapanData.length).fill(0.5),
        },
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
    }
    // 粗利構成比(協業/独自/JV等)
    if (['KPJ008'].includes(kpiCode)) {
      // 協業
      const cooperativeData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.businessType === '協業'), gbDate[date]));
      // 独自
      const oneselfData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.businessType === '独自'), gbDate[date]));
      // JV等
      const jvsData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.businessType === 'JV等'), gbDate[date]));
      options.chartOptions.legend.data = [t('aipcmcty.page.collaboration'), t('aipcmcty.page.own'), t('aipcmcty.page.JV')];
      seriesDataList.push({
        name: t('aipcmcty.page.collaboration'),
        seriesData: cooperativeData,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
      seriesDataList.push({
        name: t('aipcmcty.page.own'),
        seriesData: oneselfData,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
      seriesDataList.push({
        name: t('aipcmcty.page.JV'),
        seriesData: jvsData,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      });
    }
    // 粗利構成比(建設地別)
    if (['KPJ009'].includes(kpiCode)) {
      options.chartOptions.legend.type = 'scroll';

      const legend = [];
      const gbCL = groupBy(filterData.map(item => (locale.locale === 'ja' ? item : { ...item, constructionLocation: (localeMapping[item.constructionLocation] || item.constructionLocation) })), 'constructionLocation');
      Object.keys(gbCL).forEach(cl => {
        const gbCLDate = groupBy(gbCL[cl], periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
        let renameCl = cl;
        if (!cl) {
          renameCl = t('aipcmcty.page.others');
        }
        seriesDataList.push({
          name: renameCl,
          seriesData: Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbCLDate[date], gbDate[date])),
          barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        });
        legend.push(renameCl);
      });
      options.chartOptions.legend.data = legend.sort((a, b) => {
        if (a === t('aipcmcty.page.others')) {
          return 1;
        }
        if (b === t('aipcmcty.page.others')) {
          return -1;
        }
        return a - b;
      });
    }
    // 粗利構成比(商品別)
    if (['KPJ010'].includes(kpiCode)) {
      options.chartOptions.legend.type = 'scroll';
      const legend = [];
      const gbGT = groupBy(filterData, 'goodsType');
      Object.keys(gbGT).forEach(gt => {
        let renameGt = gt;
        if (!renameGt) {
          renameGt = t('aipcmcty.page.others');
        }
        const gbGTDate = groupBy(gbGT[gt], periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
        seriesDataList.push({
          name: renameGt,
          seriesData: Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbGTDate[date], gbDate[date])),
          barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        });
        legend.push(renameGt);
      });
      options.chartOptions.legend.data = legend.sort((a, b) => {
        if (a === t('aipcmcty.page.others')) {
          return 1;
        }
        if (b === t('aipcmcty.page.others')) {
          return -1;
        }
        return a - b;
      });
    }
    seriesDataList.forEach(({ name, seriesData, markLine }) => {
      const seriesInfo: any = {
        name,
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: seriesData,
      };
      if (markLine) {
        // seriesInfo.markLine = markLine;
        options.chartOptions.series.push(markLine);
      }
      // legend
      options.chartOptions.series.push(seriesInfo);
    });

    setOptions(cloneDeep(options));
  };

  /**
   * 初期化table column
   */
  const initTableColumns = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    const map = groupBy(mart.filter(item => ((fiscalQuarter || defaultFiscalQuarter).find(per => (per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear))))), periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
    const years = Object.keys(map).sort((a: any, b: any) => a - b);
    const baseColumns = [{
      title: '',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      width: sizeMode === 'big' ? 50 : (() => {
        if (['KPJ005', 'KPJ006', 'KPJ007', 'KPJ008', 'KMT101', 'KMT102', 'KMT103'].includes(kpiCode)) {
          return 40;
        }
        return 25;
      })()
    }];
    const columns: any[] = years.map(fiscalYear => ({
      title: <Tooltip title={fiscalYear}>{fiscalYear}</Tooltip>,
      dataIndex: fiscalYear,
      key: fiscalYear,
      className: 'text-right',
      width: sizeMode === 'big' ? 100 : 50,
    }));
    setTableColumns(baseColumns.concat(columns));
  };

  /**
   * 初期化tableデータ
   */
  const initTableData = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    const map = groupBy(mart, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');

    const prepareData: any[] = [{
      key: 'value',
      type: t('aipcmcty.page.value'),
    }, {
      key: 'goal',
      type: t('aipcmcty.page.achievementRate'),
    }];

    const setPrepareData = (per) => {
      // 粗利構成比(非EPC/EPC)
      if (['KPJ005', 'KMT101'].includes(kpiCode)) {
        prepareData[0].key = 'notEpc';
        prepareData[0].type = t('aipcmcty.page.nonEPC');
        prepareData[0][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.isEpc === '非EPC'), (map[per] || []))) * 100)}%`;
        prepareData[1].key = 'isEpc';
        prepareData[1].type = 'EPC';
        prepareData[1][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.isEpc === 'EPC'), (map[per] || []))) * 100)}%`;
      }
      // 粗利構成比(新規/既存)
      else if (['KPJ006', 'KMT102'].includes(kpiCode)) {
        prepareData[0].key = 'isExisted';
        prepareData[0].type = t('aipcmcty.page.new');
        prepareData[0][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.isExisted === '新規'), (map[per] || []))) * 100)}%`;
        prepareData[1].key = 'notExisted';
        prepareData[1].type = t('aipcmcty.page.existing');
        prepareData[1][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.isExisted === '既存'), (map[per] || []))) * 100)}%`;
      }
      // 粗利構成比(Japan/主要拠点)
      else if (['KPJ007', 'KMT103'].includes(kpiCode)) {
        prepareData[0].key = 'isJapan';
        prepareData[0].type = 'Japan';
        prepareData[0][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.isJapan === 1), (map[per] || []))) * 100)}%`;
        prepareData[1].key = 'notJapan';
        prepareData[1].type = t('aipcmcty.page.mainBase');
        prepareData[1][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.isJapan === 0), (map[per] || []))) * 100)}%`;
      }
      // 粗利構成比(協業/独自/JV等)
      else if (['KPJ008'].includes(kpiCode)) {
        prepareData[0].key = 'cooperative';
        prepareData[0].type = t('aipcmcty.page.collaboration');
        prepareData[0][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.businessType === '協業'), (map[per] || []))) * 100)}%`;
        prepareData[1].key = 'oneself';
        prepareData[1].type = t('aipcmcty.page.own');
        prepareData[1][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.businessType === '独自'), (map[per] || []))) * 100)}%`;
        if (!prepareData[2]) {
          prepareData[2] = {};
        }
        prepareData[2].key = 'jv';
        prepareData[2].type = t('aipcmcty.page.JV');
        prepareData[2][per] = `${round((kpiComputeRule[kpiCode]((map[per] || []).filter(item => item.businessType === 'JV等'), (map[per] || []))) * 100)}%`;
      }
    };

    if (fiscalQuarter) {
      fiscalQuarter.forEach(per => {
        setPrepareData(per);
      });
    } else {
      Object.keys(map).forEach((per) => {
        setPrepareData(per);
      });
    }
    setTableData(prepareData);
  };

  const hanldeChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        initData();
      }
    }
    if (chartHandler) {
      chartHandler(e);
    }
  };

  return (
    <>
      {ribbonText ? <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantity')} top={ribbonTop} color={ribbonColor || color.primaryColor} /> : <></>}
      <TableChart
        showTable={showTable}
        tableColumns={showTable ? tableColumns : []}
        tableData={tableData}
        chartOptions={options.chartOptions}
        title={title ? {
          value: title,
          styles: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
            paddingLeft: sizeMode === 'big' ? 15 : 0,
          },
        } : options.title}
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={hanldeChartEvent}
      />
    </>
  );
};

export default GrossProfitCompositionChart;
