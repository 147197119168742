import { AutoComplete, DatePicker, Form, Input, Select, Tabs } from 'antd';
import { useContext, useEffect } from 'react';
import CustomerScrollBar from './customer-scrollbar';
import { useTranslation } from 'react-i18next';
import { debounce, uniqBy } from 'lodash';
import { ActionContext } from '../../contexts/action.context';

const ActionFilterContainer: React.FC = () => {
  const { filterCollapsed, filtersData, setFiltersData, tableDataOrg, optData } = useContext(ActionContext);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const handleSiderFilterChange = debounce((changedValue) => {
    setFiltersData((fData) => ({ ...fData, ...changedValue }));
  }, 300);

  // <Tabs.TabPane
  //         style={{ height: '100%' }}
  //         tab={t.tabName}
  //         key={t.key.toString()}
  //       >
  //         {t.content}
  //       </Tabs.TabPane>

  const tabs = [
    {
      key: t('aipcmcty.page.refinement'),
      label: t('aipcmcty.page.refinement'),
      children: (
        <div style={{ height: '100%' }}>
          <CustomerScrollBar style={{ height: 'calc(100% - 16px)', padding: '0 10px' }}>
            <Form {...layout} onValuesChange={handleSiderFilterChange} form={form} initialValues={filtersData}>
              <Form.Item name="search" wrapperCol={{ span: 24 }}>
                <AutoComplete
                  options={tableDataOrg.map((t) => ({
                    label: t.actionName,
                    value: t.actionName,
                  }))}
                >
                  <Input.Search placeholder="アクションID/アクション名" allowClear />
                </AutoComplete>
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.caseId')} className="ellipsis">
                    {t('aipcmcty.page.action.caseId')}
                  </div>
                }
                name="projectId"
              >
                <AutoComplete
                  options={uniqBy<any>(
                    tableDataOrg.filter((t) => t.projectId),
                    'projectId'
                  ).map((t) => ({
                    label: t.projectId,
                    value: t.projectId,
                  }))}
                >
                  <Input.Search allowClear />
                </AutoComplete>
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.priorityLevel')} className="ellipsis">
                    {t('aipcmcty.page.action.priorityLevel')}
                  </div>
                }
                name="priority"
              >
                <Select allowClear mode="multiple" maxTagCount={1} options={optData?.priority} />
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.actionType')} className="ellipsis">
                    {t('aipcmcty.page.action.actionType')}
                  </div>
                }
                name="actionType"
              >
                <Select allowClear mode="multiple" maxTagCount={1} options={optData?.actionType} />
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.status')} className="ellipsis">
                    {t('aipcmcty.page.action.status')}
                  </div>
                }
                name="status"
              >
                <Select allowClear mode="multiple" maxTagCount={1} options={optData?.status} />
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.creator')} className="ellipsis">
                    {t('aipcmcty.page.action.creator')}
                  </div>
                }
                name="createdBy"
              >
                <Select allowClear mode="multiple" maxTagCount={1} options={optData?.createdBy} />
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.assignee')} className="ellipsis">
                    {t('aipcmcty.page.action.assignee')}
                  </div>
                }
                name="assigneeId"
              >
                <Select allowClear mode="multiple" maxTagCount={1} options={optData?.assignee} />
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.startDate')} className="ellipsis">
                    {t('aipcmcty.page.action.startDate')}
                  </div>
                }
                name="startDate"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.dueDate')} className="ellipsis">
                    {t('aipcmcty.page.action.dueDate')}
                  </div>
                }
                name="deadline"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.updateDate')} className="ellipsis">
                    {t('aipcmcty.page.action.updateDate')}
                  </div>
                }
                name="updatedAt"
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label={
                  <div title={t('aipcmcty.page.action.progressRate')} className="ellipsis">
                    {t('aipcmcty.page.action.progressRate')}
                  </div>
                }
                name="progress"
              >
                <Select allowClear mode="multiple" maxTagCount={1} options={optData?.progress} />
              </Form.Item>
            </Form>
          </CustomerScrollBar>
        </div>
      ),
    },
  ];

  useEffect(() => {}, []);

  return (
    <Tabs type="card" size="small" style={{ height: '100%' }} items={!filterCollapsed ? tabs: []} />
  );
};

export default ActionFilterContainer;
