/* eslint-disable brace-style */
// chart title: Buffer Suitability Evaluation
import React, {
  ReactNode, useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  cloneDeep, groupBy, sumBy, sum
} from 'lodash';
import { Tooltip } from 'antd';
import Ribbon from '../widget/ribbon';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions;
  periodSwitch?: boolean;
  showTable?: boolean;
  chartHandler?: (e: any) => any;
};

const HumanResourcesTurnoverChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode, data, height, loading, title, ribbonText, ribbonColor, ribbonTop,
    fiscalQuarter, showTable = true, tableOptions, periodSwitch = false, titleLoading,
    coverLoading, chartHandler
  } = props;

  const { t } = useTranslation();
  const {
    defaultFiscalQuarter, color, sizeMode
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      splitNumber: 3
    },
    big: {
      ...sizeMapBase.big,
      splitNumber: 3
    },
  };

  const valueFormatter = (params) => {
    if (params && params.length > 0) {
      let tooltip = '';
      for (let index = 0; index < params.length; index++) {
        const element = params[index];
        if (element.value > 0) {
          tooltip
            += `${element.marker
            }${element.seriesName}: ${round(element.value / 10000)}${t('aipcmcty.page.tenThousand')} <br/>`;
        }
      }
      return tooltip;
    }
    return null;
  };

  const rateFormatter = (params) => {
    if (params && params.length > 0) {
      let tooltip = '';
      for (let index = 0; index < params.length; index++) {
        const element = params[index];
        if (element.value > 0) {
          if (element.value != null) {
            tooltip
            += `${element.marker
              }${element.seriesName}: ${round(element.value * 100)}% <br/>`;
          }
        }
      }
      return tooltip;
    }
    return null;
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: 'Won %',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: rateFormatter,
      },
      legend: {
        top: 5,
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        data: [],
      },
      grid: {
        left: (() => {
          if (['KRS003'].includes(kpiCode)) {
            return 50;
          }
          return getSizeByMap(sizeMode, sizeMap, 'gridLeft');
        })(),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: '%',
        axisLine: {
          show: true,
        },
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          formatter: (value) => `${round(value * 100)}`
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameGap: 5,
        splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
      },
      series: []
    },
    height: 220,
  });

  /**
   * データまた四半期switchの変化がありましたら、
   * chartとtableは再計算必要があります
   */
  useEffect(() => {
    initData();
  }, [data, fiscalQuarter, kpiComputeRule]);

  /**
   * 初期化
   */
  const initData = () => {
    initChartData();
    initTableColumns();
    initTableData();
  };

  const getSeriesName = () => {
    switch (kpiCode) {
      case 'KRS003':
        return 'MH';
      case 'KRS004':
        return t('aipcmcty.page.jobRate');
      case 'KRS002':
        return t('aipcmcty.page.utilizationRate');
      case 'KRS005':
        return t('aipcmcty.page.turnoverRate');
      default:
        return '';
    }
  };

  /**
   * 初期化Chartデータ
   */
  const initChartData = () => {
    if (!data) {
      return;
    }
    const {
      mart, mh
    } = data;
    // 未受注と受注済みのデータを取得
    const filterData = mart.filter(item => ((fiscalQuarter || defaultFiscalQuarter).find(per => (per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear)))));
    const gbDate = groupBy(filterData, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
    const seriesDataList = [];
    // ESMH稼働率
    if (['KRS002'].includes(kpiCode)) {
      const resourceMhData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date]));
      seriesDataList.push({
        name: getSeriesName(),
        seriesData: resourceMhData
      });
      options.chartOptions.legend.data = [];
    }
    // 需給過不足MH
    else if (['KRS003'].includes(kpiCode)) {
      const mhData = Object.keys(gbDate).map(date => round(kpiComputeRule[kpiCode](gbDate[date])));
      const seriesName = getSeriesName();
      seriesDataList.push({
        name: seriesName,
        seriesData: mhData
      });
      options.chartOptions.legend.data = [];
      options.chartOptions.tooltip.formatter = valueFormatter;
      options.chartOptions.yAxis.axisLabel.formatter = (value) => `${round(value / 10000)}`;
      options.chartOptions.yAxis.name = t('aipcmcty.page.tenThousand');
    }
    // Job率
    else if (['KRS004'].includes(kpiCode)) {
      const jobRateData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date]));
      const seriesName = getSeriesName();
      seriesDataList.push({
        name: seriesName,
        seriesData: jobRateData
      });
      options.chartOptions.legend.data = [];
    }
    // 人財回転率
    else if (['KRS005'].includes(kpiCode)) {
      const filterMhData = mh.filter(item => ((fiscalQuarter || defaultFiscalQuarter).find(per => (per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear)))));
      const gbDateMh = groupBy(filterMhData, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
      const hrTurnoverRateData = Object.keys(gbDateMh).map(date => kpiComputeRule[kpiCode](gbDateMh[date], gbDate[date]));
      const seriesName = getSeriesName();
      seriesDataList.push({
        name: seriesName,
        seriesData: hrTurnoverRateData
      });
      options.chartOptions.legend.data = [];
    }
    // DX Allowance割引率
    else if (['KDX004'].includes(kpiCode)) {
      const orderedData = Object.keys(gbDate).map(date => kpiComputeRule[kpiCode](gbDate[date].filter(item => item.orderState === '受注')));

      const accountingYearDate = groupBy(mart.filter((item)=> item.accountingYear !== 'all'), 'accountingYear');
      const sortedAccountingYear = Object.keys(accountingYearDate).sort((a, b) => a.localeCompare(b));
      const notOrdered = {};

      sortedAccountingYear.forEach((key) => {
        notOrdered[key] = [];
        Object.keys(gbDate).forEach((date) => {
          const notOrderedData = gbDate[date].filter((item) => item.orderState === t('aipcmcty.page.unreceived'));
          const accountingYears = groupBy(notOrderedData, 'accountingYear');
          if (accountingYears[key]) {
            notOrdered[key].push(kpiComputeRule[kpiCode](accountingYears[key]));
          } else {
            notOrdered[key].push(0);
          }
        });
      });

      seriesDataList.push({
        name: t('aipcmcty.page.receivedOrders'),
        seriesData: orderedData
      });
      sortedAccountingYear.forEach((item)=> {
        seriesDataList.push({
          name: `${t('aipcmcty.page.notReceived')}・${item}`,
          seriesData: notOrdered[item],
        });
      });

      options.chartOptions.legend.data = [t('aipcmcty.page.receivedOrders'), ...sortedAccountingYear.filter((item)=> sum(notOrdered[item]) > 0).map((item)=> `未・${item}`)];
    }
    // DX適用比率
    else if (['KDX002'].includes(kpiCode)) {
      const applicationRate = Object.keys(gbDate).map(date => (kpiComputeRule[kpiCode](gbDate[date])));
      seriesDataList.push({
        name: t('aipcmcty.page.receivedOrders'),
        seriesData: applicationRate
      });
      options.chartOptions.legend.data = [];
    } else {
      const notOrderedGrossProfitRateData = Object.keys(gbDate).map(date => sumBy(gbDate[date].filter(item => item.orderState === t('aipcmcty.page.unreceived')), 'rate'));
      const orderedData = Object.keys(gbDate).map(date => sumBy(gbDate[date].filter(item => item.orderState === '受注'), 'rate'));

      seriesDataList.push({
        name: t('aipcmcty.page.receivedOrders'),
        seriesData: orderedData
      });
      seriesDataList.push({
        name: t('aipcmcty.page.unreceived'),
        seriesData: notOrderedGrossProfitRateData,
      });
      options.chartOptions.legend.data = [t('aipcmcty.page.receivedOrders'), t('aipcmcty.page.unreceived')];
    }

    // yearsを取得
    const years = Object.keys(gbDate).sort((a: any, b: any) => a - b);

    options.chartOptions.xAxis.data = years;
    options.chartOptions.series = [];

    seriesDataList.forEach(({ name, seriesData }) => {
      options.chartOptions.series.push({
        name,
        type: 'line',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
          formatter: (params) => `${round(params.value * 100)}%`
        },
        emphasis: {
          focus: 'series',
        },
        data: seriesData,
      });
    });

    setOptions(cloneDeep(options));
  };

  /**
   * 初期化table column
   */
  const initTableColumns = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    const map = groupBy(mart.filter(item => ((fiscalQuarter || defaultFiscalQuarter).find(per => (per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear))))), periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
    const years = Object.keys(map).sort((a: any, b: any) => a - b);
    const baseColumns = [{
      title: '',
      dataIndex: 'type',
      key: 'type',
      fixed: 'left',
      width: sizeMode === 'big' ? 50 : 25
    }];
    const columns: any[] = years.map(fiscalYear => ({
      title: <Tooltip title={fiscalYear}>{fiscalYear}</Tooltip>,
      dataIndex: fiscalYear,
      key: fiscalYear,
      className: 'text-right',
      width: sizeMode === 'big' ? 100 : 50,
    }));
    setTableColumns(baseColumns.concat(columns));
  };

  /**
   * 初期化tableデータ
   */
  const initTableData = () => {
    if (!data) {
      return;
    }
    const {
      mart, mh
    } = data;
    const map = groupBy(mart, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');

    const prepareData = [];
    const setPrepareData = (per) => {
      // ESMH稼働率
      if (['KRS002'].includes(kpiCode)) {
        const resourceMh = kpiComputeRule[kpiCode](map[per]);
        if (!prepareData[0]) {
          prepareData[0] = {};
        }
        prepareData[0].key = 'rate';
        prepareData[0].type = t('aipcmcty.page.rate');
        prepareData[0][per] = `${round(resourceMh * 100)}%`;
      }
      // 需給過不足MH
      else if (['KRS003'].includes(kpiCode)) {
        const resourceMh = kpiComputeRule[kpiCode](map[per]);
        if (!prepareData[0]) {
          prepareData[0] = {};
        }
        prepareData[0].key = 'value';
        prepareData[0].type = t('aipcmcty.page.value');
        prepareData[0][per] = `${round(resourceMh / 10000)}`;
      }
      // Job率
      else if (['KRS004'].includes(kpiCode)) {
        const resourceMh = kpiComputeRule[kpiCode](map[per]);
        if (!prepareData[0]) {
          prepareData[0] = {};
        }
        prepareData[0].key = 'value';
        prepareData[0].type = t('aipcmcty.page.rate');
        prepareData[0][per] = `${round(resourceMh * 100)}%`;
      }
      // 人財回転率
      else if (['KRS005'].includes(kpiCode)) {
        const mhMap = groupBy(mh, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
        const resourceMh = kpiComputeRule[kpiCode](mhMap[per], map[per]);
        if (!prepareData[0]) {
          prepareData[0] = {};
        }
        prepareData[0].key = 'value';
        prepareData[0].type = t('aipcmcty.page.rate');
        prepareData[0][per] = `${round(resourceMh * 100)}%`;
      }
      // DX Allowance割引率
      else if (['KDX004'].includes(kpiCode)) {
        const resourceMh = kpiComputeRule[kpiCode](map[per]);
        if (!prepareData[0]) {
          prepareData[0] = {};
        }
        prepareData[0].key = 'value';
        prepareData[0].type = t('aipcmcty.page.rate');
        prepareData[0][per] = resourceMh != null ? `${round(resourceMh * 100)}%` : '-';
      }
      // DX適用比率
      else if (['KDX002'].includes(kpiCode)) {
        const rate = kpiComputeRule[kpiCode](map[per]);
        if (!prepareData[0]) {
          prepareData[0] = {};
        }
        prepareData[0].key = 'value';
        prepareData[0].type = t('aipcmcty.page.rate');
        prepareData[0][per] = `${round(rate * 100)}%`;
      }
    };
    if (fiscalQuarter) {
      fiscalQuarter.forEach(per => {
        setPrepareData(per);
      });
    } else {
      Object.keys(map).forEach((per) => {
        setPrepareData(per);
      });
    }
    setTableData(prepareData);
  };

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        initData();
      }
    }
    if (chartHandler) {
      chartHandler(e);
    }
  };

  return (
    <>
      { ribbonText ? <Ribbon text={ribbonText || t('aipcmcty.page.projectQuality')} top={ribbonTop} color={ribbonColor || color.warningColor} /> : <></>}
      <TableChart
        showTable={showTable}
        tableColumns={tableColumns}
        tableData={tableData}
        chartOptions={options.chartOptions}
        title={title ? {
          value: title,
          styles: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
            paddingLeft: sizeMode === 'big' ? 15 : 0,
          },
        } : options.title}
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default HumanResourcesTurnoverChart;
