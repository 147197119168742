import {
  FormOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExclamationOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  Divider,
  Form,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { ActionContext } from '../../contexts/action.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ACTION_ROLE, OPERATION } from '../../consts/action-const';

export type ActionTableProp = {
  loading: boolean;
  data: ActionTableData[];
};

export type ActionTableData = {
  actionId: number;
  priority: string;
  actionName: string;
  actionType: string;
  actionDetail: string;
  status: string;
  userId: string;
  userName: string;
  assigneeId: string;
  assigneeName: string;
  deadline: string;
  progress: string;
  memoId: string;
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: ActionTableData;
  index: number;
}

const ActionTable: React.FC<ActionTableProp> = (prop: ActionTableProp) => {
  const { loading, data } = prop;
  // App
  const { setMenuCollapsed, authInfo } = useContext(AppContext);
  // locales
  const { t } = useTranslation();

  const {
    showActionOperationModel,
    setFilterCollapsed,
    setActionCollapsed,
    setActionInfo,
    actionDeleteHandler,
    optData,
    actionUpdate,
    setTableLoading,
  } = useContext(ActionContext);

  const { selectorHeight4Table } = useWindowSize({});

  const [columns, setColumns] = useState([]);

  // table edit
  const [form] = Form.useForm();
  const [editingActionId, setEditingActionId] = useState<number>();
  const isEditing = (record: ActionTableData) =>
    record.actionId === editingActionId;

  const edit = (record: Partial<ActionTableData> & { key: React.Key }) => {
    form.setFieldsValue({ status: record.status, progress: record.progress });
    setEditingActionId(record.actionId);
  };
  const cancel = () => {
    setEditingActionId(null);
  };

  const save = async (record: ActionTableData) => {
    try {
      console.log(form);
      const row = await form.validateFields();
      console.log(row);
      const errors = form.getFieldsError();
      console.log(errors);
      if (errors.some((error) => error.errors.length > 0)) {
        return;
      }
      setTableLoading(true);
      await actionUpdate({
        ...record,
        ...row,
      });
      cancel();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  // role
  const getRole = useCallback((record) => {
    const assigneeId = record.assigneeId;
    const userId = record.userId;
    if (authInfo.user.attributes.sub === userId) {
      return ACTION_ROLE.CREATED_BY;
    }
    if (authInfo.user.attributes.sub === assigneeId) {
      return ACTION_ROLE.ASSIGNEE;
    }
  }, []);

  const render = (text, record, index) =>
    text ? <span title={text}>{text}</span> : '-';

  const renderDate = (text, record, index) => (
    <>
      {text ? (
        <span title={dayjs(text).format('YYYY-MM-DD')}>
          {' '}
          {dayjs(text).format('YYYY-MM-DD')}
        </span>
      ) : (
        '-'
      )}
    </>
  );
  const renderProgress = (text, record, index) => (
    <>
      {!isNil(text) ? (
        <span title={`${text * 100}%`}>{`${text * 100}%`}</span>
      ) : (
        '-'
      )}
    </>
  );

  const renderActionName = (text, record, index) => {
    // return text ? text : '-'
    return (
      <>
        {text ? <span title={text}>{text}</span> : '-'}
        <Popover
          content={
            <span style={{ whiteSpace: 'break-spaces' }}>
              {record.actionDetail}
            </span>
          }
          trigger="hover"
          placement="rightBottom"
        >
          <FileExclamationOutlined style={{ marginLeft: 6 }} />
        </Popover>
      </>
    );
  };

  const renderAction = (text, record, index) => {
    const editable = isEditing(record);

    const DeleteComp = (
      <Tooltip placement="top" title={t('common.delete')} mouseLeaveDelay={0}>
        <Popconfirm
          title="アクションを削除しますか？"
          onConfirm={() => actionDeleteHandler(record.actionId)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      </Tooltip>
    );
    const CommentComp = (
      <Tooltip placement="top" title="コメント">
        <FormOutlined
          onClick={() => {
            setMenuCollapsed(true);
            setFilterCollapsed(true);
            setActionCollapsed(false);
            setActionInfo({
              actionId: record.actionId,
              actionName: record.actionName,
            });
          }}
        />
      </Tooltip>
    );

    if (editable) {
      return (
        <span>
          <CloseOutlined onClick={cancel} style={{ marginRight: 8 }} />
          <Divider type="vertical" />
          <CheckOutlined
            onClick={() => save(record)}
            style={{ marginRight: 8 }}
          />
        </span>
      );
    }
    if (getRole(record) === ACTION_ROLE.CREATED_BY) {
      return (
        <>
          <Tooltip
            placement="top"
            title={t('common.update')}
            mouseLeaveDelay={0}
          >
            <EditOutlined
              onClick={() => {
                showActionOperationModel(OPERATION.MODIFY, record);
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          {DeleteComp}
          <Divider type="vertical" />
          {CommentComp}
        </>
      );
    }
    if (getRole(record) === ACTION_ROLE.ASSIGNEE) {
      return (
        <>
          <Tooltip
            placement="top"
            title={t('common.update')}
            mouseLeaveDelay={0}
          >
            <EditOutlined
              onClick={() => {
                edit(record);
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          {CommentComp}
        </>
      );
    }
    return CommentComp;
  };

  useEffect(() => {
    const columnPre = [
      {
        key: 'priority',
        dataIndex: 'priority',
        title: t('aipcmcty.page.action.priorityLevel'),
        width: 80,
        className: 'align-center',
        render,
        fixed: 'left',
      },
      {
        key: 'actionId',
        dataIndex: 'actionId',
        title: 'ID',
        width: 60,
        render,
        fixed: 'left',
      },
      {
        key: 'projectId',
        dataIndex: 'projectId',
        title: t('aipcmcty.page.action.caseId'),
        width: 120,
        className: 'align-left ellipsis',
        render,
        fixed: 'left',
      },
      {
        key: 'actionName',
        dataIndex: 'actionName',
        title: t('aipcmcty.page.action.actionName'),
        width: 200,
        className: 'align-left ellipsis',
        render: renderActionName,
        fixed: 'left',
      },
      {
        key: 'actionType',
        dataIndex: 'actionType',
        title: t('aipcmcty.page.action.actionType'),
        width: 300,
        className: 'align-left ellipsis',
        render,
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: t('aipcmcty.page.action.status'),
        width: 150,
        className: 'align-left ellipsis',
        render,
      },
      {
        key: 'userName',
        dataIndex: 'userName',
        title: t('aipcmcty.page.action.creator'),
        width: 150,
        className: 'align-left ellipsis',
        render,
      },
      {
        key: 'assigneeName',
        dataIndex: 'assigneeName',
        title: t('aipcmcty.page.action.assignee'),
        width: 150,
        className: 'align-left ellipsis',
        render,
      },
      {
        key: 'startDate',
        dataIndex: 'startDate',
        title: t('aipcmcty.page.action.startDate'),
        width: 120,
        className: 'align-right ellipsis',
        render: renderDate,
      },
      {
        key: 'deadline',
        dataIndex: 'deadline',
        title: t('aipcmcty.page.action.dueDate'),
        width: 120,
        className: 'align-right ellipsis',
        render: renderDate,
      },
      {
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        title: t('aipcmcty.page.action.updateDate'),
        width: 120,
        className: 'align-right ellipsis',
        render: renderDate,
      },
      {
        key: 'progress',
        dataIndex: 'progress',
        title: t('aipcmcty.page.action.progressRate'),
        width: 120,
        className: 'align-right ellipsis',
        render: renderProgress,
      },
      {
        title: 'Action',
        width: 160,
        className: 'flex-center action-cell',
        render: renderAction,
        fixed: 'right',
      },
    ];
    const mergedColumns = columnPre.map((col) => {
      return {
        ...col,
        onCell: (record: ActionTableData) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
    setColumns(mergedColumns);
  }, [editingActionId]);

  const Cell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const renderEditCell = () => {
      if (dataIndex === 'status') {
        return (
          <Form.Item
            name="status"
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select options={optData.status} />
          </Form.Item>
        );
      }
      if (dataIndex === 'progress') {
        return (
          <Form.Item name="progress" style={{ margin: 0 }}>
            <Select options={optData.progress} />
          </Form.Item>
        );
      }
      return children;
    };

    return (
      <td {...restProps}>
        {record && getRole(record) === ACTION_ROLE.ASSIGNEE ? (
          <>{editing ? renderEditCell() : children}</>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <>
      <div
        className="table-container no-validate-message-form"
        style={{ marginTop: 5 }}
      >
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: Cell,
              },
            }}
            loading={loading}
            scroll={{ y: selectorHeight4Table }}
            pagination={false}
            columns={columns}
            dataSource={data}
            rowClassName={(record) => {
              if (dayjs(record.deadline).isBefore(dayjs())) {
                return 'light-red';
              }
            }}
          />
        </Form>
      </div>
    </>
  );
};

export default ActionTable;
