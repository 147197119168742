import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ReactElement, useContext, useEffect, useState } from 'react';
import Ribbon from '../widget/ribbon';
import { AppContext } from '../../contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { cloneDeep, groupBy } from 'lodash';
import { orderMonthRange as monthRange } from '../../pages/simulation/resource-regulation';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  title?: ReactElement;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions;
  periodSwitch?: boolean;
  loading?: boolean;
  selectedProjects: any[];
  unselectBudgets: any[];
  isDxMode: boolean;
  orderYearMonths: string[];
  selectedFiscalYear: number[];
  consolidated: 0 | 1;
  department: string[];
  onOrderYearMonthChange: (yearMonth: string) => void;
  onOrderFYChange: (e: number[]) => void;
};

const TurnoverFlowChart: React.FC<ChartProps> = (props) => {
  const { t } = useTranslation();
  const {
    kpiCode,
    ribbonText,
    ribbonTop,
    ribbonColor,
    title,
    height,
    loading,
    data,
    selectedProjects = [],
    unselectBudgets = [],
    fiscalQuarter,
    periodSwitch,
    onOrderYearMonthChange,
    orderYearMonths,
    selectedFiscalYear,
    onOrderFYChange,
    isDxMode,
    consolidated,
    department,
  } = props;

  const { color, sizeMode, defaultFiscalQuarter } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 50,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };
  const selectedProjectIdsIF = selectedProjects.filter((s) => s.budgetCategoryCustom.endsWith('IF')).map((s) => s.projectId);
  const selectedProjectIdsOthers = selectedProjects.filter((s) => s.budgetCategoryCustom.endsWith('Others')).map((s) => s.projectId);
  const unselectedIds = unselectBudgets.map((u) => u.projectId);

  const fiscalYearOpts = (periodSwitch ? defaultFiscalQuarter : fiscalQuarter).map((f) => ({
    label: f,
    value: Number(`20${f.slice(-2)}`),
    key: f,
  }));

  const [options, setOptions] = useState({
    title: {
      value: t('aipcmcty.page.orderAmount'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        confine: true,
        appendToBody: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          const paramsNeedRender = params.slice(1).reverse();
          let tooltip = '<div style="text-align: left;">';
          for (let index = 0; index < paramsNeedRender.length; index++) {
            const element = paramsNeedRender[index];
            tooltip += `${element.marker}${element.seriesName}: ${round(element.value / 100000000)} <br/>`;
          }
          tooltip += '</div>';
          return tooltip;
        },
      },
      legend: {
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        type: 'scroll',
        left: 'center',
        data: [],
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: t('aipcmc.charts.hundredMillion'),
        axisLine: {
          show: true,
        },
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          formatter: (value) => value / 100000000,
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameGap: 5,
        splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      series: [],
    },
    height: 220,
  });

  const updateChartData = () => {
    if (!data) {
      return;
    }
    const { mart, goal } = data;
    const goalByYear = groupBy(goal.filter(g => !department.length || department.includes(g.tgc)), 'fiscalYear');
    const goals = Object.keys(goalByYear).reduce((prev, cur) => {
      prev[cur] = goalByYear[cur].reduce((pr, c) => pr + c.orderGrossProfit, 0);
      return prev;
    }, {} as any);
    const filteredMart = mart
      .filter(m => !department.length || department.includes(m.department))
      .filter((m) => {
      if (m.budgetCategoryCustom.endsWith('Budget')) {
        return !unselectedIds.includes(m.projectId);
      }
      if (m.budgetCategoryCustom.endsWith('IF')) {
        return selectedProjectIdsIF.includes(m.projectId);
      }
      if (m.budgetCategoryCustom.endsWith('Others')) {
        return selectedProjectIdsOthers.includes(m.projectId);
      }
      if (department.length > 0) {
        return department.includes(m.department);
      }
      return true;
    });
    const martToYearMonth = filteredMart
      .filter((m) => orderYearMonths.includes(`${m.fiscalYear}-${m.month}`))
      .map((m) => ({
        ...m,
        yearMonth: `${m.fiscalYear}-${m.month}`,
      }));
    const martByYM = groupBy(martToYearMonth, 'yearMonth');
    const martCurYear = orderYearMonths.reduce((prev, cur) => {
      const awards = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'Awarded') ?? [];
      const budgets = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'Budget') ?? [];
      const ifs = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'IF') ?? [];
      const others = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'Others') ?? [];
      const dxAwards = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'DX_Awarded') ?? [];
      const dxBudgets = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'DX_Budget') ?? [];
      const dxIfs = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'DX_IF') ?? [];
      const dxOthers = martByYM[cur]?.filter((m) => m.budgetCategoryCustom === 'DX_Others') ?? [];
      prev[cur] = {
        awards: kpiComputeRule[kpiCode](awards ?? 0),
        budgets: kpiComputeRule[kpiCode](budgets ?? 0),
        ifs: kpiComputeRule[kpiCode](ifs ?? 0),
        others: kpiComputeRule[kpiCode](others ?? 0),
        dxAwards: kpiComputeRule[kpiCode](dxAwards ?? 0),
        dxBudgets: kpiComputeRule[kpiCode](dxBudgets ?? 0),
        dxIfs: kpiComputeRule[kpiCode](dxIfs ?? 0),
        dxOthers: kpiComputeRule[kpiCode](dxOthers ?? 0),
        total: kpiComputeRule[kpiCode](martByYM[cur] ?? 0),
      };
      return prev;
    }, {} as any);
    const total = Object.values(martCurYear).reduce(
      (prev: any, cur: any) => {
        prev.awards += cur.awards;
        prev.budgets += cur.budgets;
        prev.ifs += cur.ifs;
        prev.others += cur.others;
        prev.dxAwards += cur.dxAwards;
        prev.dxBudgets += cur.dxBudgets;
        prev.dxIfs += cur.dxIfs;
        prev.dxOthers += cur.dxOthers;
        return prev;
      },
      {
        awards: 0,
        budgets: 0,
        ifs: 0,
        others: 0,
        dxAwards: 0,
        dxBudgets: 0,
        dxIfs: 0,
        dxOthers: 0,
      }
    );
    options.chartOptions.xAxis.data = [...monthRange.map((month) => month), 'Total'];
    let transparentBase = 0;
    const transparentData = [];
    Object.values(martCurYear).forEach((m: any) => {
      transparentData.push(transparentBase);
      transparentBase += m.total;
    });
    const transparentSeries = {
      name: 'transparent',
      type: 'bar',
      stack: 'plan',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      z: 10,
      itemStyle: {
        color: 'transparent',
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: [...transparentData, 0],
    };
    const commonSeries = [
      {
        name: 'Awarded',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#093B5E',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.awards), (total as any).awards],
      },
      {
        name: 'Budget',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#3299D9',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.budgets), (total as any).budgets],
      },
      {
        name: 'IF',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#9FD9F6',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.ifs), (total as any).ifs],
      },
      {
        name: 'Others',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#B0CEDB',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.others), (total as any).others],
      }
    ];
    const dxSeries = [
      {
        name: 'DX_Awarded',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#093B5E',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.dxAwards), (total as any).dxAwards],
      },
      {
        name: 'DX_Budget',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#195D87',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.dxBudgets), (total as any).dxBudgets],
      },
      {
        name: 'DX_IF',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#507FA2',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.dxIfs), (total as any).dxIfs],
      },
      {
        name: 'DX_Others',
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
        },
        emphasis: {
          focus: 'series',
        },
        itemStyle: {
          color: '#619BC5',
        },
        z: 10,
        barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
        data: [...Object.values(martCurYear).map((m: any) => m.dxOthers), (total as any).dxOthers],
      }
    ];
    const goalSeries = {
      name: 'Goal',
      type: 'line',
      symbol: 'none',
      itemStyle: {
        color: '#DD6B66',
      },
      lineStyle: {
        cap: 'square',
      },
      data: Array.from({ length: monthRange.length + 1 }, () => goals[`FY${selectedFiscalYear[0].toString().slice(-2)}`] ?? 0),
    };
    options.chartOptions.series = isDxMode && consolidated
      ? [transparentSeries, ...commonSeries, ...dxSeries, goalSeries]
      : [transparentSeries, ...commonSeries, goalSeries];
    options.chartOptions.legend.data = isDxMode && consolidated
      ? ['Awarded', 'Budget', 'IF', 'Others', 'DX_Awarded', 'DX_Budget', 'DX_IF', 'DX_Others']
      : ['Awarded', 'Budget', 'IF', 'Others'];
    // options.chartOptions.legend.left = isDxMode && consolidated ? 'left' : 'center';
    (options.chartOptions.legend as any).left = 30;
    (options.chartOptions.legend as any).width = 330;
    setOptions(cloneDeep(options));
  };

  useEffect(() => {
    updateChartData();
  }, [data, kpiComputeRule, selectedProjects, selectedFiscalYear[0]]);

  const handleChartEvent = (e) => {
    if (
      !e
      || e.chartTitle.value.key !== title.key
    ) {
      return;
    }
    const {
      eventType, event
    } = e;
    if (
      eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
      || (
        eventType === ECHART_EVENT.CHART_ITEM_SELECTED
        && event.dataIndex > orderYearMonths.length - 1
      )
    ) {
      onOrderYearMonthChange(null);
    }
    if (eventType === ECHART_EVENT.CHART_ITEM_SELECTED) {
      if (event.dataIndex > orderYearMonths.length - 1) return;
      const [year, month] = orderYearMonths[event.dataIndex].replace('FY', '20').split('-');
      onOrderYearMonthChange(`${year}-${Number(month) < 10 ? `0${month}` : month}`);
    }
  };

  return (
    <>
      {ribbonText ? (
        <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantity')} top={ribbonTop} color={ribbonColor || color.primaryColor} />
      ) : (
        <></>
      )}
      <TableChart
        showTable={false}
        tableColumns={[]}
        tableData={[]}
        chartOptions={options.chartOptions}
        title={
          title
            ? {
                value: title,
                styles: {
                  fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                  paddingLeft: sizeMode === 'big' ? 15 : 0,
                },
              }
            : options.title
        }
        height={height}
        loading={loading}
        isBank={!data || data.length === 0}
        tableOptions={null}
        onChartEvent={handleChartEvent}
        select={{
          type: 'cascader',
          value: selectedFiscalYear,
          list: fiscalYearOpts,
          styles: {
            position: 'absolute',
            right: 0,
            zIndex: 1,
          },
          notMinus: true,
          onChange: (value) => {
            onOrderFYChange(value);
          },
        }}
      />
    </>
  );
};

export default TurnoverFlowChart;
