import React, { useContext, useState } from 'react';
import {
  Popover, Radio, RadioChangeEvent, Space, Tooltip
} from 'antd';
import {
  PicLeftOutlined,
  BarChartOutlined,
  InsertRowAboveOutlined,
  TableOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import { AppContext } from '../../../contexts/AppContext';

export const LAYOUT_VIEW_MODE = {
  CHART_TABLE: 'chart-table',
  CHART_ONLY: 'chart-only',
  TABLE_ONLY: 'table-only',
};

export const CHART_SIZE_MODE = {
  SMALL: 'small',
  LARGE: 'big',
};

const LayoutSwitcher: React.FC<any> = (props) => {
  const {
    viewMode, setViewMode
  } = props;

  const {
    color, sizeMode, setSizeMode
  } = useContext(AppContext);

  const [showViewModeSelector, setShowViewModeSelector] = useState(false);
  const [showSizeModeSelector, setShowSizeModeSelector] = useState(false);

  const getViewModeButton = () => {
    if (viewMode === LAYOUT_VIEW_MODE.CHART_TABLE) {
      return (
        <Tooltip title="Both Chart and Table" placement="left">
          <PicLeftOutlined />
        </Tooltip>
      );
    }
    if (viewMode === LAYOUT_VIEW_MODE.CHART_ONLY) {
      return (
        <Tooltip title="Chart Only" placement="left">
          <BarChartOutlined />
        </Tooltip>
      );
    }
    if (viewMode === LAYOUT_VIEW_MODE.TABLE_ONLY) {
      return (
        <Tooltip title="Table Only" placement="left">
          <InsertRowAboveOutlined />
        </Tooltip>
      );
    }
    return <></>;
  };

  const getSizeModeButton = () => {
    if (sizeMode === CHART_SIZE_MODE.SMALL) {
      return (
        <Tooltip title="Small Chart" placement="left">
          <TableOutlined />
        </Tooltip>
      );
    }
    if (sizeMode === CHART_SIZE_MODE.LARGE) {
      return (
        <Tooltip title="Large Chart" placement="left">
          <AppstoreOutlined />
        </Tooltip>
      );
    }
    return <></>;
  };

  const onViewChange = (e: RadioChangeEvent) => {
    setViewMode(e.target.value);
    setShowViewModeSelector(false);
  };

  const onSizeChange = (e: RadioChangeEvent) => {
    setSizeMode(e.target.value);
    setShowSizeModeSelector(false);
  };

  const viewModes = (
    <Radio.Group
      onChange={onViewChange}
      value={viewMode}
      optionType="button"
      buttonStyle="solid"
    >
      <Space direction="vertical">
        <Radio value={LAYOUT_VIEW_MODE.CHART_TABLE}>
          <Tooltip title="Both Chart and Table" placement="left">
            <PicLeftOutlined />
          </Tooltip>
        </Radio>
        <Radio value={LAYOUT_VIEW_MODE.CHART_ONLY}>
          <Tooltip title="Chart Only" placement="left">
            <BarChartOutlined />
          </Tooltip>
        </Radio>
        <Radio value={LAYOUT_VIEW_MODE.TABLE_ONLY}>
          <Tooltip title="Table Only" placement="left">
            <InsertRowAboveOutlined />
          </Tooltip>
        </Radio>
      </Space>
    </Radio.Group>
  );

  const sizeModes = (
    <Radio.Group
      onChange={onSizeChange}
      value={sizeMode}
      optionType="button"
      buttonStyle="solid"
    >
      <Space direction="vertical">
        <Radio value={CHART_SIZE_MODE.SMALL}>
          <Tooltip title="Small Chart" placement="left">
            <TableOutlined />
          </Tooltip>
        </Radio>
        <Radio value={CHART_SIZE_MODE.LARGE}>
          <Tooltip title="Large Chart" placement="left">
            <AppstoreOutlined />
          </Tooltip>
        </Radio>
      </Space>
    </Radio.Group>
  );

  return (
    <div className="mode-container">
      <Popover
        placement="bottom"
        content={viewModes}
        trigger="click"
        open={showViewModeSelector}
        onOpenChange={(visible: boolean) => {
          setShowViewModeSelector(visible);
        }}
      >
        <div
          className="view-mode"
          style={{ background: color.primaryColor, color: '#fff' }}
        >
          {getViewModeButton()}
        </div>
      </Popover>
      <Popover
        placement="bottom"
        content={sizeModes}
        trigger="click"
        open={showSizeModeSelector}
        onOpenChange={(visible: boolean) => {
          setShowSizeModeSelector(visible);
        }}
      >
        <div
          className="view-mode"
          style={{ background: color.primaryColor, color: '#fff' }}
        >
          {getSizeModeButton()}
        </div>
      </Popover>
    </div>
  );
};

export default LayoutSwitcher;
