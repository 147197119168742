import { CompassOutlined, DashboardOutlined, DeploymentUnitOutlined, DiffOutlined, ExperimentOutlined, SettingOutlined } from '@ant-design/icons';
import VersionManager from '../components/version-manager';

const menuRoutes = {
  key: 'aipcmcty',
  title: 'Corporate Management Cockpit',
  label: 'Corporate Management Cockpit',
  path: '/aipcmcty',
  logo: <CompassOutlined />,
  icon: <span>CMC</span>, // todo need a design
  description: 'Achieve quick management decisions and execution by visualizing, monitoring, and simulating management KPIs',
  descI18n: 'aipmc.menu.description',
  menuExt: <VersionManager />,
  routes: [
    {
      key: 'dashboard',
      label: '経営ダッシュボード',
      labeli18n: 'aipcmcty.page.managementDashboard',
      icon: <DashboardOutlined />,
      description: 'Dashboard',
      children: [
        {
          key: 'overall',
          label: '全体KGI/KPI',
          labeli18n: 'aipcmcty.page.overallKGKPI',
          path: '/aipcmcty/dashboard/overall',
          chat: 'true',
        },
        {
          key: 'kpi-tree',
          label: '需給KPIツリー',
          labeli18n: 'aipcmcty.page.demandSupplyKPITree',
          path: '/aipcmcty/dashboard/kpi-tree',
          chat: 'true',
        },
        {
          key: 'typical-kpi',
          label: '需給代表KPI',
          labeli18n: 'aipcmcty.page.demandSupplyRepresentativeKPI',
          path: '/aipcmcty/dashboard/typical-kpi',
          chat: 'true',
        },
      ],
    },
    {
      key: 'simulation',
      label: '需給シミュレーション',
      labeli18n: 'aipcmcty.page.demandSupplySimulation',
      icon: <ExperimentOutlined />,
      description: 'Simulation',
      children: [
        {
          key: 'project-setup',
          label: '案件セットアップ',
          labeli18n: 'aipcmcty.page.projectSetup',
          path: '/aipcmcty/simulation/project-setup',
          chat: 'true',
        },
        {
          key: 'matching-result',
          label: 'リソースセットアップ',
          labeli18n: 'aipcmcty.page.resourceSetup',
          path: '/aipcmcty/simulation/matching-result',
          chat: 'true',
        },
        {
          key: 'resource-regulation',
          label: '案件シミュレーション',
          labeli18n: 'aipcmcty.page.resourceRegulation',
          path: '/aipcmcty/simulation/resource-regulation',
          chat: 'true',
        },
        {
          key: 'supply-validation',
          label: 'ポートフォリオ検証',
          labeli18n: 'aipcmcty.page.portfolioVerification',
          path: '/aipcmcty/simulation/supply-validation',
          chat: 'true',
        },
      ],
    },
    {
      key: 'comparison',
      label: '断面比較',
      labeli18n: 'aipcmcty.page.crossSectionComparison',
      icon: <DiffOutlined />,
      description: 'Comparison',
      children: [
        {
          key: 'trinity-evaluation',
          label: '三位一体評価',
          path: '/aipcmcty/comparison/trinity-evaluation',
          labeli18n: 'aipcmcty.page.trinityEvaluation.menuTitle',
          chat: 'true',
        },
        {
          key: 'portfolio-monitor',
          label: 'ポートフォリオ',
          path: '/aipcmcty/comparison/portfolio-monitor',
          labeli18n: 'aipcmcty.page.portfolio.menuTitle',
          chat: 'true',
        },
        {
          key: 'project',
          label: '案件(期待値なし)',
          labeli18n: 'aipcmcty.page.comparison.projectMenu',
          path: '/aipcmcty/comparison/project',
          chat: 'true',
        },
        {
          key: 'resource',
          label: 'リソース(期待値なし)',
          labeli18n: 'aipcmcty.page.comparison.resourceMenu',
          path: '/aipcmcty/comparison/resource',
          chat: 'true',
        },
      ],
    },
    {
      key: 'management',
      label: '管理関連',
      labeli18n: 'aipcmcty.page.managementRelated',
      icon: <SettingOutlined />,
      description: 'Management',
      children: [
        {
          key: 'action',
          label: 'アクション一覧',
          labeli18n: 'aipcmcty.page.actionList',
          path: '/aipcmcty/management/action',
          chat: 'true',
        },
        {
          key: 'master-setup',
          label: 'マスタセットアップ',
          labeli18n: 'aipcmcty.page.masterSetup',
          path: '/aipcmcty/management/master-setup',
          chat: 'true',
        },
        {
          key: 'record',
          label: '意思入れ履歴',
          labeli18n: 'aipcmcty.page.historyRecord.menu',
          path: '/aipcmcty/management/record',
          chat: 'true',
        },
      ],
    },
    {
      key: 'demo',
      label: 'β版Viz',
      labeli18n: 'aipcmcty.page.betaViz',
      icon: <DeploymentUnitOutlined />,
      description: 'β版Viz',
      children: [
        {
          key: 'investment-return',
          label: '投資回収',
          labeli18n: 'aipcmcty.page.investAndRepay.menuTitle',
          path: '/aipcmcty/demo/investment-return',
          chat: 'true',
        },
        {
          key: 'dx-diff',
          label: 'DX効果分析',
          labeli18n: 'aipcmcty.page.dxDiffAnalyze.menuTitle',
          path: '/aipcmcty/demo/dx-diff',
          chat: 'true',
        },
        {
          key: 'gap-analysis',
          label: 'ギャップ分析',
          labeli18n: 'aipcmcty.page.gapAnalyze.menuTitle',
          path: '/aipcmcty/demo/gap-analysis',
          chat: 'true',
        },
      ],
    },
  ],
};

export default menuRoutes;
