import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import ChartGroup from '@meteor/frontend-core/dist/chart/chart-card/chart-group';
import TurnoverChart from '../../components/charts/turnover.chart';
import GrossProfitCompositionChart from '../../components/charts/gross-profit-composition.chart';
import { useWindowSize } from '../../hooks/useWindowSize';

const OverallPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    fiscalQuarter,
    periodSwitch,
    color,
    locale,
    menuCollapsed,
    scrollCount,
    setScrollCount,
    sizeMode,
  } = useContext(AppContext);
  const { kpiInfos, initKpiData, getKpiData, snapshot, snapshotVersion } = useContext(AipcmctyContext);

  useEffect(() => {
    initKpiData(['KMT001', 'KMT002', 'KMT003', 'KMT101', 'KMT102', 'KMT103']);
  }, [snapshot, snapshotVersion]);

  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );

  const [viewMode, setViewMode] = useState('chart-only');

  const { selectorTop } = useWindowSize({
    selector: '.chart-group-container',
    viewMode,
  });

  return (
    <>
      <ChartGroup
        defaultItemCount={3}
        defaultHeightCount={2}
        loading={false}
        menuCollapsed={menuCollapsed}
        scrollCount={scrollCount}
        setScrollCount={setScrollCount}
        selectorTop={selectorTop}
        sizeMode={sizeMode}
      >
        <ChartGroup.Item>
          <TurnoverChart
            kpiCode="KMT001"
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            data={getKpiData('KMT001').data}
            loading={getKpiData('KMT001').loading}
            title={customChartGroupTitle(t('aipcmcty.page.midtermKgi'), t('aipcmcty.page.consolidatedRevenue'), color.primaryColor)}
            tableOptions={{ rowClassName: 'overall-view', tableHeight: 90 }}
          />
        </ChartGroup.Item>
        <ChartGroup.Item>
          <TurnoverChart
            kpiCode="KMT002"
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            data={getKpiData('KMT002').data}
            loading={getKpiData('KMT002').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.midtermKgi'),
              `${t('aipcmcty.page.consolidatedGrossProfit')}（${t('aipcmcty.page.annualRebound')}）`,
              color.primaryColor
            )}
            tableOptions={{ rowClassName: 'overall-view', tableHeight: 90 }}
          />
        </ChartGroup.Item>
        <ChartGroup.Item>
          <TurnoverChart
            kpiCode="KMT003"
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            data={getKpiData('KMT003').data}
            loading={getKpiData('KMT003').loading}
            title={customChartGroupTitle(t('aipcmcty.page.midtermKgi'), t('aipcmcty.page.consolidatedNetProfit'), color.primaryColor)}
            tableOptions={{ rowClassName: 'overall-view', tableHeight: 90 }}
          />
        </ChartGroup.Item>
      </ChartGroup>
      <ChartGroup
        defaultItemCount={3}
        defaultHeightCount={2}
        loading={false}
        menuCollapsed={menuCollapsed}
        scrollCount={scrollCount}
        setScrollCount={setScrollCount}
        selectorTop={selectorTop}
        sizeMode={sizeMode}
      >
        <ChartGroup.Item>
          <GrossProfitCompositionChart
            kpiCode="KMT101"
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            data={getKpiData('KMT101').data}
            loading={getKpiData('KMT101').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.midtermKpi'),
              locale.locale === 'ja'
                ? `${t('aipcmcty.page.consolidatedGrossProfitComposition')}(${t('aipcmcty.page.nonEPC')}/EPC)`
                : `${t('aipcmcty.page.nonEPC')}/EPC - ${t('aipcmcty.page.consolidatedGrossProfitComposition')}`,
              color.primaryColor
            )}
            tableOptions={{ rowClassName: 'overall-view', tableHeight: 90 }}
          />
        </ChartGroup.Item>
        <ChartGroup.Item>
          <GrossProfitCompositionChart
            kpiCode="KMT102"
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            data={getKpiData('KMT102').data}
            loading={getKpiData('KMT102').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.midtermKpi'),
              locale.locale === 'ja'
                ? `${t('aipcmcty.page.consolidatedGrossProfitComposition')}(${t('aipcmcty.page.newBusiness')}/${t(
                    'aipcmcty.page.existingBusiness'
                  )})`
                : `${t('aipcmcty.page.newBusiness')}/${t('aipcmcty.page.existingBusiness')} - ${t(
                    'aipcmcty.page.consolidatedGrossProfitComposition'
                  )}`,
              color.primaryColor
            )}
            tableOptions={{ rowClassName: 'overall-view', tableHeight: 90 }}
          />
        </ChartGroup.Item>
        <ChartGroup.Item>
          <GrossProfitCompositionChart
            kpiCode="KMT103"
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            data={getKpiData('KMT103').data}
            loading={getKpiData('KMT103').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.midtermKpi'),
              locale.locale === 'ja'
                ? `${t('aipcmcty.page.consolidatedGrossProfitComposition')}(Toyo-J/${t('aipcmcty.page.mainBase')})`
                : `Toyo-J/${t('aipcmcty.page.mainBase')} - ${t('aipcmcty.page.consolidatedGrossProfitComposition')}`,
              color.primaryColor
            )}
            tableOptions={{ rowClassName: 'overall-view', tableHeight: 90 }}
          />
        </ChartGroup.Item>
      </ChartGroup>
    </>
  );
};

export default OverallPage;
