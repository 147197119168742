const ACTION_ACTIVITY_TYPE = {
  // コメント
  COMMENT: 1,
  // ステータス
  STATUS: 2,
  // 進捗率
  PROGRESS: 3,
  // アクション名
  ACTION_NAME: 4,
  // アクションタイプ
  ACTION_TYPE: 5,
  // アクション詳細
  ACTION_DETAIL: 6,
  // 開始日
  CREATED_AT: 7,
  // 優先度
  PRIORITY: 8,
  // 期日
  DEADLINE: 9,
  // 担当者
  ASSIGNEE: 10,
};

const ACTION_PRIORITY = {
  URGENT: '緊急',
  HIGH: '高',
  MIDDLE: '中',
  LOW: '低',
};

const ACTION_ROLE = {
  CREATED_BY: 'createdBy',
  ASSIGNEE: 'assignee',
};

const OPERATION = {
  ADD: 'add',
  MODIFY: 'modify',
  MEMBER: 'member',
};

export { ACTION_ACTIVITY_TYPE, ACTION_PRIORITY, ACTION_ROLE, OPERATION };
