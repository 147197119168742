import React, {
  ReactNode, useContext, useEffect, useState
} from 'react';
import Ribbon from '../widget/ribbon';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { AppContext } from '../../contexts/AppContext';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

type ChartProps = {
  title: any;
  tgcList: string[];
  accountingYears: string[];
  height?: number;
  data: any[];
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
};

const ProjectGrossProfitChart: React.FC<ChartProps> = (props) => {
  const { t } = useTranslation();
  const {
    loading,
    data,
    tgcList,
    accountingYears,
    title,
    height,
    ribbonText,
    ribbonColor,
    ribbonTop,
    titleLoading,
    coverLoading
  } = props;
  const { color, sizeMode } = useContext(AppContext);
  const [orderAmount, setOrderAmount] = useState(0);
  const [options, setOptions] = useState<any>();
  const { sizeMapBase } = useContext(AipcmctyContext);
  const [selectedSort, setSelectedSort] = useState(0);
  const [showData, setShowData] = useState([]);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  useEffect(() => {
    if (data && data.length > 0) {
      let filtered = data.filter((item) => item.budgetCategoryCustom == 'Budget');
      filtered = filtered.filter((item) => tgcList.length === 0 || tgcList.includes(item.tgc));
      filtered = filtered.filter((item) => (
        accountingYears.length === 0
          || accountingYears.includes(item.accountingYear)
      ));

      const sorted = filtered.sort((a, b) => {
        if (selectedSort === 1) {
          return a.grossProfit * a.won - b.grossProfit * b.won;
        }
        return a.grossProfit - b.grossProfit;
      });
      // .slice(0, 10);
      setShowData(sorted);
    }
  }, [data, selectedSort, tgcList, accountingYears]);

  useEffect(() => {
    setOptions({
      title: {
        value: title,
      },
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          appendToBody: true,
          className: 'overflow-all',
          hideDelay: 0,
          formatter: (params) => {
            const projectId = params[0].axisValue;
            const item = showData.find(
              (project) => projectId === project.projectId
            );
            let tooltips = '<div style="text-align: left;">';
            if (item) {
              tooltips += `${t('aipcmcty.page.projectCaseId')}：${item.projectId} <br/>`;
              tooltips += `<span style="display: inline-block; width: 300px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">${t('aipcmcty.page.projectCaseName')}：${item.projectName} </span><br/>`;
            }
            for (let index = 0; index < params.length; index++) {
              const element = params[index];
              tooltips
                += `${element.marker
                }${element.seriesName}: ${round(element.value)}${t('aipcmcty.page.billion')} <br/>`;
            }
            tooltips += '</div>';
            return tooltips;
          },
          // axisPointer: {
          //   type: 'cross',
          //   axis: 'y',
          //   snap: true,
          //   label: {
          //     backgroundColor: '#6a7985',
          //   },
          // },
        },
        legend: {
          show: true,
          textStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
          },
          itemWidth: 14,
          data: [
            {
              name: t('aipcmcty.page.expectedGrossProfit'),
              itemStyle: { color: color.primaryColor },
            },
            {
              name: t('aipcmcty.page.orderGrossProfit'),
              itemStyle: { color: color.secondaryColor },
            },
          ],
        },
        dataZoom: [
          {
            start: 100 - Math.floor(round(9 / showData.length) * 100),
            end: 100,
            yAxisIndex: [0, 1],
            showDataShadow: false,
            width: 10,
          },
          {
            type: 'inside',
            start: 0,
            end: 100,
            yAxisIndex: [0, 1],
            zoomLock: true,
          },
        ],
        grid: {
          top: 30,
          left: 60,
          right: 40,
          bottom: 20,
        },
        xAxis: {
          type: 'value',
          splitNumber: 3,
          label: `${t('aipcmcty.page.value')}（億）`,
          // max: showData[0].grossProfit / 100000000,
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            fontSize: 14,
            // interval: 0,
          },
          data: showData.map((item) => item.projectId),
        },
        series: [
          {
            name: t('aipcmcty.page.expectedGrossProfit'),
            type: 'bar',
            label: {
              show: false,
            },
            data: showData.map((item) => (item.grossProfit * item.won) / 100000000),
            barGap: '-100%',
            itemStyle: { color: color.primaryColor },
            z: 2,
          },
          {
            name: t('aipcmcty.page.orderGrossProfit'),
            type: 'bar',
            label: {
              show: false,
            },
            data: showData.map((item) => item.grossProfit / 100000000),
            itemStyle: { color: color.secondaryColor },
            z: 1,
          },
        ],
      },
      height: 220,
    });
  }, [showData]);

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        setSelectedSort(0);
        setOptions(cloneDeep(options));
      }
    }
  };

  return (
    <>
      {ribbonText ? (
        <Ribbon
          text={ribbonText || t('aipcmcty.page.projectQuantity')}
          top={ribbonTop}
          color={ribbonColor || color.primaryColor}
        />
      ) : (
        <></>
      )}
      {options ? (
        <TableChart
          select={{
            type: 'select',
            label: <span style={{ marginRight: 8 }}>{t('aipcmcty.page.sortOrder')}</span>,
            value: selectedSort,
            width: '160px',
            list: [
              {
                value: 0,
                label: t('aipcmcty.page.grossProfitAmount'),
              },
              {
                value: 1,
                label: t('aipcmcty.page.expectedGrossProfitAmount'),
              },
            ],
            onChange: (value) => {
              setSelectedSort(value);
            },
          }}
          showTable={false}
          tableColumns={[]}
          tableData={[]}
          chartOptions={options.chartOptions}
          title={title ? { value: title } : options.title}
          height={height}
          loading={loading || !data || data.length === 0}
          titleLoading={titleLoading}
          coverLoading={coverLoading}
          mouseWheelZoomLock
          onChartEvent={handleChartEvent}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ProjectGrossProfitChart;
