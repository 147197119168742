import { t } from 'i18next';
import {
  ReactNode, useContext, useEffect, useState
} from 'react';
import { cloneDeep } from 'lodash';
import { DiffResponse } from '../../pages/comparison/project';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';
import { TableChart } from '@meteor/frontend-core';

const DIVIDEND = 100000000;

type DiffChartProps = {
  data: DiffResponse;
  loading: boolean;
  graphLoading: boolean;
  graphHeight?: number;
  title?: string | ReactNode;
  onChartEvent: (e: any) => void;
};

const GrossProfitDiffChart = (props: DiffChartProps) => {
  const {
    data, loading, graphLoading, graphHeight = 340, title, onChartEvent
  } = props;

  const { color, sizeMode } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
      xAxis: 10
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const mapProfitType = (typeStr: string) => {
    switch (typeStr) {
      case '①':
        return `① ${t('aipcmcty.page.comparison.orderP')}`;
      case '②':
        return `② ${t('aipcmcty.page.comparison.disorderP')}`;
      case '③':
        return `③ ${t('aipcmcty.page.comparison.rankIn')}`;
      case '④':
        return `④ ${t('aipcmcty.page.comparison.rankOut')}`;
      case '⑤':
        return `⑤ ${t('aipcmcty.page.comparison.grossProfitUp')}`;
      case '⑥':
        return `⑥ ${t('aipcmcty.page.comparison.grossProfitDown')}`;
      case '⑦':
        return `⑦ ${t('aipcmcty.page.comparison.otherUp')}`;
      case '⑧':
        return `⑧ ${t('aipcmcty.page.comparison.otherDown')}`;
      default:
        return typeStr;
    }
  };

  const [profitGraphOptions, setProfitGraphOptions] = useState({
    title: {
      value: t('aipcmcty.page.comparison.grossProfitGraphTitle'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      title: {
        id: 'GrossProfitGraph'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: [
          t('aipcmcty.page.comparison.total'),
          t('aipcmcty.page.comparison.increase'),
          t('aipcmcty.page.comparison.decrease'),
        ],
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        selectedMode: false
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: [
          t('aipcmcty.page.comparison.targetCyd'),
          '①',
          '②',
          '③',
          '④',
          '⑤',
          '⑥',
          '⑦',
          '⑧',
          t('aipcmcty.page.comparison.currentCyd'),
        ],
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: t('aipcmc.charts.hundredMillion'),
        axisLine: {
          show: true,
        },
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          formatter: (value) => value / DIVIDEND
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameGap: 5,
      },
      series: [
        {
          id: 'placeholder',
          name: 'placeholder',
          type: 'bar',
          stack: 'total',
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          },
          silent: true,
          emphasis: {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          },
          label: {
            show: false
          } as any,
          data: []
        },
        // {
        //   id: 'danger',
        //   name: 'danger',
        //   type: 'bar',
        //   stack: 'goal',
        //   data: [],
        //   barWidth: '70%',
        //   barGap: '-120%',
        //   // silent: true,
        //   emphasis: {
        //     focus: 'series'
        //   }
        // },
        // {
        //   id: 'warning',
        //   name: 'warning',
        //   type: 'bar',
        //   stack: 'goal',
        //   data: [],
        //   barWidth: '70%',
        //   barGap: '-120%',
        //   // silent: true,
        //   emphasis: {
        //     focus: 'series'
        //   }
        // },
        // {
        //   id: 'transcend',
        //   name: 'transcend',
        //   type: 'bar',
        //   stack: 'goal',
        //   data: [],
        //   barWidth: '70%',
        //   barGap: '-120%',
        //   // silent: true,
        //   emphasis: {
        //     focus: 'series'
        //   }
        // },
        {
          id: 'total',
          name: t('aipcmcty.page.comparison.total'),
          type: 'bar',
          stack: 'total',
          itemStyle: {
            borderColor: color.primaryColor,
            color: color.primaryColor
          },
          label: {
            position: 'top',
            color: color.primaryColor
          } as any,
          data: [],
          // barWidth: '50%',
          emphasis: {
            focus: 'series'
          },
        },
        {
          id: 'decrease',
          name: t('aipcmcty.page.comparison.decrease'),
          type: 'bar',
          stack: 'total',
          itemStyle: {
            borderColor: color.errorColor,
            color: color.errorColor
          },
          label: {
            position: 'bottom',
            color: color.errorColor
          } as any,
          data: [],
        },
        {
          id: 'increase',
          name: t('aipcmcty.page.comparison.increase'),
          type: 'bar',
          stack: 'total',
          itemStyle: {
            borderColor: color.successColor,
            color: color.successColor,
          },
          label: {
            position: 'top',
            color: color.successColor
          } as any,
          data: [],
        },
        {
          id: 'goal',
          name: t('aipcmcty.page.comparison.goal'),
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: color.warningColor,
            width: 2,
            type: 'dashed',
          },
        }
      ],
    },
    height: 220,
  });

  const updateProfitGraphOptions = () => {
    const genDiffGraphProfitData = (list, k, graphData) => {
      if (['phaseUp', 'formationChanged', 'consolidated', 'goal', 'consolidatedGoal'].includes(k)) {
        return;
      }
      list.forEach(r => {
        graphData.delta += Number(r.diffTgcDelta ?? 0);
        if (['Budget', 'Awarded'].includes(r.budgetCategoryCurrent)) {
          graphData.current += Number(r.grossProfitTgcCurrent);
        }
        if (['Budget', 'Awarded'].includes(r.budgetCategoryTarget)) {
          graphData.target += Number(r.grossProfitTgcTarget);
        }
        switch (true) {
          case k === 'order' && r.diffTgcDelta >= 0:
            graphData.order.positive += Number(r.diffTgcDelta);
            break;
          case k === 'order' && r.diffTgcDelta < 0:
            graphData.order.negative += Number(r.diffTgcDelta);
            break;
          case k === 'disorder' && r.diffTgcDelta >= 0:
            graphData.disorder.positive += Number(r.diffTgcDelta);
            break;
          case k === 'disorder' && r.diffTgcDelta < 0:
            graphData.disorder.negative += Number(r.diffTgcDelta);
            break;
          case k === 'inBudget' && r.diffTgcDelta >= 0:
            graphData.inBudget.positive += Number(r.diffTgcDelta);
            break;
          case k === 'inBudget' && r.diffTgcDelta < 0:
            graphData.inBudget.negative += Number(r.diffTgcDelta);
            break;
          case k === 'outBudget' && r.diffTgcDelta >= 0:
            graphData.outBudget.positive += Number(r.diffTgcDelta);
            break;
          case k === 'outBudget' && r.diffTgcDelta < 0:
            graphData.outBudget.negative += Number(r.diffTgcDelta);
            break;
          case k === 'grossProfitUp' && r.diffTgcDelta >= 0:
            graphData.grossProfitUp.positive += Number(r.diffTgcDelta);
            break;
          case k === 'grossProfitUp' && r.diffTgcDelta < 0:
            graphData.grossProfitUp.negative += Number(r.diffTgcDelta);
            break;
          case k === 'grossProfitDown' && r.diffTgcDelta >= 0:
            graphData.grossProfitDown.positive += Number(r.diffTgcDelta);
            break;
          case k === 'grossProfitDown' && r.diffTgcDelta < 0:
            graphData.grossProfitDown.negative += Number(r.diffTgcDelta);
            break;
          case k === 'otherUp' && r.diffTgcDelta >= 0:
            graphData.otherUp.positive += Number(r.diffTgcDelta);
            break;
          case k === 'otherUp' && r.diffTgcDelta < 0:
            graphData.otherUp.negative += Number(r.diffTgcDelta);
            break;
          case k === 'otherDown' && r.diffTgcDelta >= 0:
            graphData.otherDown.positive += Number(r.diffTgcDelta);
            break;
          case k === 'otherDown' && r.diffTgcDelta < 0:
            graphData.otherDown.negative += Number(r.diffTgcDelta);
            break;
          default:
            break;
        }
      });
    };

    const genDiffProfitSeries = calcData => {
      const {
        order,
        disorder,
        inBudget,
        outBudget,
        grossProfitUp,
        grossProfitDown,
        otherUp,
        otherDown,
        target,
        current,
      } = calcData;
      const {
        diff, base, positive, negative
      } = Object.keys(calcData).reduce((acc, k, i) => {
        if (['delta', 'target', 'current'].includes(k)) {
          return acc;
        }
        const lastTotal = acc.total[i - 1] ?? target;
        const p = calcData[k].positive;
        const n = calcData[k].negative;
        const d = calcData[k].positive + calcData[k].negative;
        const b = d >= 0 ? lastTotal + calcData[k].negative : lastTotal + d;
        const tt = lastTotal + d;
        const ng = d >= 0 ? Math.abs(n) : Math.abs(d);
        const ps = d >= 0 ? Math.abs(d) : Math.abs(p);
        acc.base.push(b);
        acc.diff.push(d);
        acc.total.push(tt);
        acc.negative.push(ng);
        acc.positive.push(ps);
        return acc;
      }, {
        diff: [],
        base: [],
        total: [],
        negative: [],
        positive: []
      });

      return {
        total: [target, '-', '-', '-', '-', '-', '-', '-', '-', current],
        base: [
          0,
          ...base,
          0
        ],
        negative: [
          '-',
          ...negative,
          '-'
        ],
        positive: [
          '-',
          ...positive,
          '-'
        ],
        diff: [
          '-',
          ...diff,
          '-',
        ],
        positiveR: [
          '-',
          order.positive,
          disorder.positive,
          inBudget.positive,
          outBudget.positive,
          grossProfitUp.positive,
          grossProfitDown.positive,
          otherUp.positive,
          otherDown.positive,
          '-',
        ],
        negativeR: [
          '-',
          order.negative,
          disorder.negative,
          inBudget.negative,
          outBudget.negative,
          grossProfitUp.negative,
          grossProfitDown.negative,
          otherUp.negative,
          otherDown.negative,
          '-',
        ]
      };
    };

    const profitGraphData = {
      order: {
        positive: 0,
        negative: 0
      },
      disorder: {
        positive: 0,
        negative: 0
      },
      inBudget: {
        positive: 0,
        negative: 0
      },
      outBudget: {
        positive: 0,
        negative: 0
      },
      grossProfitUp: {
        positive: 0,
        negative: 0
      },
      grossProfitDown: {
        positive: 0,
        negative: 0
      },
      otherUp: {
        positive: 0,
        negative: 0
      },
      otherDown: {
        positive: 0,
        negative: 0
      },
      delta: 0,
      target: 0,
      current: 0,
    };

    Object.keys(data).forEach(k => {
      genDiffGraphProfitData(data[k], k, profitGraphData);
    });

    const {
      total,
      positive,
      negative,
      base,
      diff: delta,
      positiveR,
      negativeR
    } = genDiffProfitSeries(profitGraphData);

    const mergedProfitOptions = cloneDeep(profitGraphOptions);
    // (mergedProfitOptions.chartOptions.tooltip as any).formatter = ([, d, w, tc, tt, n, p]) => {
    //   const dataIndex = n.dataIndex || p.dataIndex;
    //   const g = [d, w, tc].find(a => a?.value !== '-');
    //   if (tt?.value !== '-' && g) {
    //     return `
    //       ${mapProfitType(tt?.name)}
    //       <br/>
    //       ${g?.seriesName} : ${(g?.value / DIVIDEND).toFixed(2)}${t('aipcmc.charts.hundredMillion')}
    //       <br/>
    //       ${tt?.seriesName} : ${(tt?.value / DIVIDEND).toFixed(2)}${t('aipcmc.charts.hundredMillion')}
    //     `;
    //   }
    //   return `
    //     ${mapProfitType(p?.name || n?.name)}
    //     <br/>
    //     ${p?.seriesName} : ${(positiveR?.[dataIndex] / DIVIDEND).toFixed(2)}${t('aipcmc.charts.hundredMillion')}
    //     <br/>
    //     ${n?.seriesName} : ${(-negativeR?.[dataIndex] / DIVIDEND).toFixed(2)}${t('aipcmc.charts.hundredMillion')}
    //   `;
    // };
    (mergedProfitOptions.chartOptions.tooltip as any).formatter = ([, tt, n, p, g]) => {
      const dataIndex = n.dataIndex || p.dataIndex;
      if (tt?.value !== '-') {
        return `
          ${mapProfitType(tt?.name)}
          <br/>
          ${g?.seriesName} : ${(g?.value / DIVIDEND).toFixed(2)}${t('aipcmc.charts.hundredMillion')}
          <br/>
          ${tt?.seriesName} : ${(tt?.value / DIVIDEND).toFixed(2)}${t('aipcmc.charts.hundredMillion')}
        `;
      }
      return `
        ${mapProfitType(p?.name || n?.name)}
        <br/>
        ${p?.seriesName} : ${(positiveR?.[dataIndex] / DIVIDEND).toFixed(2)}${t('aipcmc.charts.hundredMillion')}
        <br/>
        ${n?.seriesName} : ${(-negativeR?.[dataIndex] / DIVIDEND).toFixed(2)}${t('aipcmc.charts.hundredMillion')}
      `;
    };
    // const tarGoal = data.goal.target;
    const curGoal = data.goal.current;
    // const dangerList = Array.from({ length: 10 }, () => '-') as any[];
    // const warningList = Array.from({ length: 10 }, () => '-') as any[];
    // const transcendList = Array.from({ length: 10 }, () => '-') as any[];
    // const tarRatio = total[0] / tarGoal;
    // const curRatio = total[total.length - 1] / curGoal;

    // if (tarRatio >= 1) {
    //   transcendList[0] = tarGoal;
    // } else if (tarRatio < 0.9) {
    //   dangerList[0] = tarGoal;
    // } else {
    //   warningList[0] = tarGoal;
    // }

    // if (curRatio >= 1) {
    //   transcendList[total.length - 1] = curGoal;
    // } else if (tarRatio < 0.9) {
    //   dangerList[total.length - 1] = curGoal;
    // } else {
    //   warningList[total.length - 1] = curGoal;
    // }

    const seriesConfig = {
      placeholder: {
        label: {
          show: false,
          formatter: () => ''
        },
        data: base
      },
      // danger: {
      //   label: {
      //     show: false,
      //     formatter: () => ''
      //   },
      //   itemStyle: {
      //     borderColor: color.errorColor,
      //     color: color.errorColor,
      //   },
      //   data: dangerList,
      // },
      // warning: {
      //   label: {
      //     show: false,
      //     formatter: () => ''
      //   },
      //   itemStyle: {
      //     borderColor: '#faad14',
      //     color: '#faad14',
      //   },
      //   data: warningList,
      // },
      // transcend: {
      //   label: {
      //     show: false,
      //     formatter: () => ''
      //   },
      //   itemStyle: {
      //     borderColor: '#9bca63',
      //     color: '#9bca63',
      //   },
      //   data: transcendList
      // },
      total: {
        label: {
          show: true,
          formatter: ({ value, name }) => {
            if (!value) {
              return '';
            }
            if (name === t('aipcmcty.page.comparison.currentCyd')) {
              const diff = (profitGraphData.current - profitGraphData.target) / DIVIDEND;
              return `${(value / DIVIDEND).toFixed(2)}\r\n(${diff >= 0 ? '+' : ''}${diff.toFixed(2)})`;
            }
            return `${(value / DIVIDEND).toFixed(2)}`;
          }
        },
        data: total,
      },
      decrease: {
        label: {
          show: true,
          formatter: ({ value, dataIndex }) => {
            const showV = delta[dataIndex] > 0 ? value : -negativeR[dataIndex];
            if (!showV) {
              return '';
            }
            return `-${(showV / DIVIDEND).toFixed(2)}`;
          }
        },
        data: negative
      },
      increase: {
        label: {
          show: true,
          formatter: ({ value, dataIndex }) => {
            const showV = delta[dataIndex] > 0 ? positiveR[dataIndex] : value;
            if (!showV) {
              return '';
            }
            return `+${(showV / DIVIDEND).toFixed(2)}`;
          }
        },
        data: positive
      },
      goal: {
        label: {
          show: false,
          formatter: () => ''
        },
        data: Array.from({ length: 10 }, () => curGoal)
      },
    };
    mergedProfitOptions.chartOptions.series = mergedProfitOptions.chartOptions.series.map(s => {
      const { id } = s;
      return {
        ...s,
        ...seriesConfig[id],
        label: {
          ...s.label,
          ...seriesConfig[id].label
        }

      } as any;
    });
    setProfitGraphOptions(mergedProfitOptions);
  };

  useEffect(() => {
    updateProfitGraphOptions();
  }, [data]);

  return (
    <>
      <TableChart
        showTable={false}
        chartOptions={profitGraphOptions.chartOptions}
        showSeriesLabel
        title={
          title
            ? {
              value: title,
              styles: {
                fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                paddingLeft: sizeMode === 'big' ? 15 : 0,
              },
            } : profitGraphOptions.title
        }
        height={graphHeight}
        loading={loading}
        titleLoading={!loading && graphLoading}
        onChartEvent={onChartEvent}
      />
    </>
  );
};

export default GrossProfitDiffChart;