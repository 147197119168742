// chart title: Buffer Suitability Evaluation
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  cloneDeep, groupBy, sortBy, sumBy, uniq
} from 'lodash';
import { ProjectSetupDetailContext } from '../../contexts/project-setup-detail.context';
import { round } from '../../utils/commonUtil';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';

type ChartProps = {
  data: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
};

const MhPilesChart: React.FC<ChartProps> = (props) => {
  const {
    data, height, loading, titleLoading, coverLoading
  } = props;
  const { t } = useTranslation();
  const { searchDemandForm, feedOrEpcSwitch } = useContext(ProjectSetupDetailContext);

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.mhStack'),
      styles: {
        fontSize: 18,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        position: (point, params, dom, rect, size) => [point[0] - size.contentSize[0] - 10, point[1] - size.contentSize[1] / 3],
        formatter: (params) => {
          if (params && params.length > 0) {
            let tooltip = '<div style="text-align: left;">';
            for (let index = 0; index < params.length; index++) {
              const element = params[index];
              tooltip += `${element.marker}${element.seriesName}: ${round(element.value)} <br/>`;
            }
            tooltip += '</div>';
            return tooltip;
          }
          return null;
        },
      },
      legend: {
        top: 5,
        textStyle: {
          fontSize: 14,
        },
        type: 'scroll',
        padding: [5, 20, 5, 200],
        pageButtonGap: 20,
        itemGap: 5,
        itemWidth: 14,
        data: [],
      },
      grid: {
        left: 80,
        right: 10,
        bottom: 30,
        top: 25,
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: t('aipcmc.charts.hundredMillion'),
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
          },
          nameTextStyle: {
            fontSize: 14,
          },
          nameGap: 5,
          splitNumber: 3,
        },
      ],
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: 14,
          hideOverlap: true
        },
      },
      series: []
    },
    height: 220,
  });

  const initData = () => {
    initChartData();
  };

  const initChartData = () => {
    if (!searchDemandForm) {
      return;
    }
    const {
      division, discipline, category, stage, roles
    } = searchDemandForm;

    const filterMap = {
      division: (item) => division.length > 0 ? division.includes(item.division) : true,
      discipline: (item) => discipline.length > 0 ? discipline.includes(item.discipline) : true,
      category: (item) => category.length > 0 && item.category ? category.includes(item.category) : true,
      stage: (item) => stage.length > 0 && item.stage ? stage.includes(item.stage) : true,
      roles: (item) => roles.length > 0 ? roles.includes(item.role) : true,
      feedOrEpcSwitch: (item) => feedOrEpcSwitch && item.feedOrEpc ? item.feedOrEpc === feedOrEpcSwitch : true
    }
    // disciplineを取得
    const gbDiscipline = groupBy(
      data.filter(item => Object.keys(filterMap).every((key) => filterMap[key](item))),
      'discipline'
    );
    const disciplines = sortBy(Object.keys(gbDiscipline));

    // chart optionのデータを組み立て
    options.chartOptions.legend.data = disciplines;

    const gbDate = groupBy(data, (item) => `${item.year}-${item.month}`);

    const dates = sortBy(Object.keys(gbDate));
    options.chartOptions.xAxis.data = dates;
    const series = [];
    disciplines.forEach((disciplineValue) => {
      const disciplineData = gbDiscipline[disciplineValue];
      series.push({
        name: disciplineValue,
        type: 'bar',
        stack: 'plan',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        z: 10,
        data: dates.map((date) => {
          const findData = disciplineData.filter(item => `${item.year}-${item.month}` === date);
          return sumBy(findData, 'resourceDemandMh') || 0;
        })
      });
    });
    options.chartOptions.series = series;
    setOptions(cloneDeep(options));
  };

  useEffect(() => {
    initData();
  }, [data, searchDemandForm, feedOrEpcSwitch]);

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (options.title.value === chartTitle.value) {
        initData();
      }
    }
  };

  return (
    <>
      <TableChart
        chartOptions={options.chartOptions}
        title={options.title}
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        loadingStyle={{ height: 200 }}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default MhPilesChart;
