// chart title: Buffer Suitability Evaluation
import React, {
  ReactNode, useContext, useEffect, useLayoutEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  cloneDeep, groupBy, sumBy, uniqBy
} from 'lodash';
import { Tooltip } from 'antd';
import Ribbon from '../widget/ribbon';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { AppContext } from '../../contexts/AppContext';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  title?: string | ReactNode;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  tableOptions?: TableOptions;
  showTable?: boolean;
  periodSwitch?: boolean;
  loading?: boolean;
  barWidth?: number
};

const OrderGrossProfit: React.FC<ChartProps> = (props) => {
  const {
    kpiCode,
    data,
    height,
    title,
    ribbonText,
    ribbonColor,
    ribbonTop,
    fiscalQuarter,
    showTable = true,
    tableOptions,
    periodSwitch = false,
    loading,
    barWidth
  } = props;

  const {
    defaultFiscalQuarter, color, sizeMode
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);
  const { t } = useTranslation();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [mode, setMode] = useState(0);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const profitLegend = [t('aipcmcty.page.receivedOrders'), t('aipcmcty.page.unreceived'), `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.receivedOrders')})`, `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.unreceived')})`];

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.orderGrossProfit'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          if (params && params.length > 0) {
            params.reverse();
            let tooltip = '';
            for (let index = 0; index < params.length; index++) {
              const element = params[index];
              if ((element.seriesName === `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.receivedOrders')})` || element.seriesName === `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.unreceived')})`) && element.value > 0) {
                tooltip += `${element.marker}${element.seriesName}: ${round(element.value)} <br/>`;
              } else if (element.seriesName !== 'transparent' && (element.value > 0 || element.seriesName !== 'Target')) {
                tooltip += `${element.marker}${element.seriesName}: ${round(element.value / 100000000)} <br/>`;
              }
            }
            return tooltip;
          }
          return null;
        }
      },
      legend: {
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        data: [],
        type: 'scroll',
        // width: 200,
        // left: 0
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: t('aipcmc.charts.hundredMillion'),
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => value / 100000000
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
          nameGap: 5,
          splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
        },
        {
          type: 'value',
          position: 'right',
          // name: '%',
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            // formatter: (value) => `${value * 100}`,
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
        },
      ],
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          interval: 0,
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
      },
      series: [],
    },
    height: 220,
  });

  /**
   * データまた四半期switchの変化がありましたら、
   * chartとtableは再計算必要があります
   */
  useEffect(() => {
    initData();
  }, [data, fiscalQuarter, kpiComputeRule, mode]);

  /**
   * 初期化
   */
  const initData = () => {
    initChartData();
    initTableColumns();
    initTableData();
  };

  /**
   * 初期化Chartデータ
   */
  const initChartData = () => {
    if (!data) {
      return;
    }
    const { mart, goal = [], count } = data;
    // barのwidthを計算
    const width = (window.innerWidth - 300) / (sizeMode === 'small' ? 4 : 2);
    const preContainer = barWidth || width / (fiscalQuarter?.length || 5); // x count
    sizeMap.big.barWidth = preContainer * 0.35;
    sizeMap.big.targetWidth = sizeMap.big.barWidth * 1.5;
    sizeMap.small.barWidth = preContainer * 0.35;
    sizeMap.small.targetWidth = sizeMap.small.barWidth * 1.5;

    // 未受注と受注済みのデータを取得
    const { gbDate, firstSeries, secondSeries } = processingData(mart, mode);

    // yearsを取得
    const dates = Object.keys(gbDate).sort((a: any, b: any) => a - b);
    // chart optionのデータを組み立て
    options.chartOptions.xAxis.data = dates;
    options.chartOptions.series = [];
    options.chartOptions.legend.data = profitLegend;
    options.chartOptions.series.push({
      name: profitLegend[0],
      type: 'bar',
      stack: 'plan',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      itemStyle: {
        color: '#1A4F99'
      },
      z: 10,
      barGap: '-125%',
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: firstSeries,
    });
    options.chartOptions.series.push({
      name: profitLegend[1],
      type: 'bar',
      stack: 'plan',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      itemStyle: {
        color: '#3299D9'
      },
      z: 10,
      barGap: '-125%',
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: secondSeries,
    });

    // targetを組み立て
    const greenList = [];
    const yellowList = [];
    const redList = [];

    const goalFilterData = goal.filter(item => (fiscalQuarter ? item.fiscalYear : true));
    const goalGbDate = groupBy(goalFilterData, 'fiscalYear');

    for (let j = 0; j < dates.length; j++) {
      const date = dates[j];
      const sumAmount = kpiComputeRule[kpiCode](gbDate[date]);
      const sumTarget = kpiComputeRule[kpiCode](goalGbDate[date]);
      greenList.push(sumAmount / sumTarget >= 1 ? sumTarget : 0);
      redList.push(sumAmount / sumTarget <= 0.9 ? sumTarget : 0);
      yellowList.push(
        sumAmount / sumTarget > 0.9 && sumAmount / sumTarget < 1 ? sumTarget : 0
      );
    }

    options.chartOptions.series.push({
      name: 'Target',
      type: 'bar',
      stack: 'goal',
      data: greenList,
      itemStyle: {
        color: '#9bca63',
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
    });

    options.chartOptions.series.push({
      name: 'Target',
      type: 'bar',
      stack: 'goal',
      data: yellowList,
      itemStyle: {
        color: '#faad14',
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
    });

    options.chartOptions.series.push({
      name: 'Target',
      type: 'bar',
      stack: 'goal',
      data: redList,
      itemStyle: {
        color: color.errorColor,
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'targetWidth'),
    });

    // 未受注と受注済みのデータを取得
    const filterCountData = count.filter((item) => ((fiscalQuarter || defaultFiscalQuarter).find(
      (per) => per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear)
    )));
    const gbCountDDate = groupBy(
      filterCountData,
      periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear'
    );

    const notOrderCountData = Object.keys(gbCountDDate).map((date) => gbCountDDate[date].filter((item) => item.orderState?.includes('未受注')));
    const orderedCountData = Object.keys(gbCountDDate).map((date) => gbCountDDate[date].filter((item) => item.orderState === '受注'));

    options.chartOptions.series.push({
      name: `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.receivedOrders')})`,
      type: 'line',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      lineStyle: {
        color: '#1A4F99',
        shadowColor: '#fff',
        shadowBlur: 2,
      },
      z: 10,
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: orderedCountData.map((item)=> (uniqBy(item, 'projectId')).length),
      yAxisIndex: 1,
    });

    options.chartOptions.series.push({
      name: `${t('aipcmcty.page.projectNum')}(${t('aipcmcty.page.unreceived')})`,
      type: 'line',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      lineStyle: {
        color: '#3299D9',
        shadowColor: '#fff',
        shadowBlur: 2,
      },
      z: 10,
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: notOrderCountData.map((item)=> (uniqBy(item, 'projectId')).length),
      yAxisIndex: 1,
    });

    setOptions(cloneDeep(options));
  };

  const processingData = (mart, modeValue) => {
    const filterData = mart.filter((item) => ((fiscalQuarter || defaultFiscalQuarter).find(
      (per) => per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear)
    )));
    const gbDate = groupBy(
      filterData,
      periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear'
    );
    const orderCondition = modeValue
      ? item => item.japanInvolved?.includes('Toyo-J関与') && item.orderState === '受注'
      : item => item.orderState === '受注';
    const notOrderCondition = mode
      ? item => item.japanInvolved?.includes('Toyo-J関与') && item.orderState === '未受注'
      : item => item.orderState === '未受注';
    const firstSeries = Object.keys(gbDate).map((date) => kpiComputeRule[kpiCode](gbDate[date].filter(orderCondition)));
    const secondSeries = Object.keys(gbDate).map((date) => kpiComputeRule[kpiCode](gbDate[date].filter(notOrderCondition)));
    return { gbDate, firstSeries, secondSeries };
  };

  /**
   * 初期化table column
   */
  const initTableColumns = () => {
    if (!data) {
      return;
    }
    const { mart } = data;
    const map = groupBy(mart.filter(item => ((fiscalQuarter || defaultFiscalQuarter).find(per => (per === (periodSwitch ? `${item.fiscalYear}-${item.fiscalQuarter}` : item.fiscalYear))))), periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
    const dates = Object.keys(map).sort((a: any, b: any) => a - b);
    const baseColumns = [
      {
        title: '',
        dataIndex: 'type',
        key: 'type',
        fixed: 'left',
        width: sizeMode === 'big' ? 50 : 40,
      },
    ];
    const columns: any[] = dates.map((fiscalYear) => ({
      title: <Tooltip title={fiscalYear}>{fiscalYear}</Tooltip>,
      dataIndex: fiscalYear,
      key: fiscalYear,
      className: 'text-right',
      width: sizeMode === 'big' ? 100 : 50,
      render: val => {
        const isNumber = typeof val === 'number';
        if (isNumber) {
          return val.toLocaleString();
        }
        return val;
      }
    }));
    setTableColumns(baseColumns.concat(columns));
  };

  /**
   * 初期化tableデータ
   */
  const initTableData = () => {
    if (!data) {
      return;
    }
    const { mart, goal = [] } = data;
    const martMap = groupBy(mart, periodSwitch ? (item) => `${item.fiscalYear}-${item.fiscalQuarter}` : 'fiscalYear');
    const goalMap = groupBy(goal, 'fiscalYear');

    const getValue = (info: any[]) => round(kpiComputeRule[kpiCode](info) / 100000000);
    const getTarget = (info: any[]) => round(kpiComputeRule[kpiCode](info) / 100000000);
    const getGoal = (amountInfo: any[], goalInfo: any[]) => {
      if (getTarget(goalInfo)) {
        return `${round((getValue(amountInfo) / getTarget(goalInfo)) * 100).toFixed(0)}%`;
      }
      return '-';
    };

    const prepareData = [
      {
        key: 'value',
        type: `${t('aipcmcty.page.value')}(${t('aipcmcty.page.billion')})`,
      },
      {
        key: 'goal',
        type: t('aipcmcty.page.achievementRate'),
      },
    ];
    fiscalQuarter.forEach(per => {
      prepareData[0][per] = getValue(mode ? martMap[per]?.filter(elem => elem.japanInvolved === 'Toyo-J関与') : martMap[per]);
      prepareData[1][per] = getGoal(
        mode ? martMap[per]?.filter(elem => elem.japanInvolved === 'Toyo-J関与') : martMap[per],
        goalMap[per]
      );
    });
    setTableData(prepareData);
  };

  /**
   * 初期化FlowChartデータ
   */
  const initFlowChartData = () => {
    const { mart, goal } = data;
    const gbMonth = groupBy(mart.filter(item => item.fiscalYear === selectedYear), 'month');
    const orderCondition = c => mode
      ? c.japanInvolved?.includes('Toyo-J関与') && c.orderState === '受注'
      : c.orderState === '受注';
    const notOrderCondition = c => mode
      ? c.japanInvolved?.includes('Toyo-J関与') && c.orderState === '未受注'
      : c.orderState === '未受注';
    const test = Object.entries(gbMonth).reduce((prev, [k, v]) => {
      let order = [];
      let notOrder = [];
      v.forEach(c => {
        if (orderCondition(c)) {
          order.push(c);
        }
        if (notOrderCondition(c)) {
          notOrder.push(c);
        }
      });
      prev[k] = { order, notOrder };
      return prev;
    }, {} as any);
    const monthSort = {
      3: 0, 4: 1, 5: 2, 6: 3, 7: 4, 8: 5, 9: 6, 10: 7, 11: 8, 12: 9, 1: 10, 2: 11
    };
    const months = Object.keys(gbMonth).sort((a, b) => monthSort[a] - monthSort[b]);
    const goalByYear = sumBy(goal.filter(item => item.fiscalYear === selectedYear), 'orderGrossProfit');
    const orderProfit = months.map(month => kpiComputeRule[kpiCode](test[month].order));
    const notOrderProfit = months.map(month => kpiComputeRule[kpiCode](test[month].notOrder));
    const transparentData = [];
    let orderFlowData = 0;
    let notOrderFlowData = 0;
    orderProfit.forEach((value, i) => {
      transparentData.push(orderFlowData + notOrderFlowData);
      orderFlowData += value;
      notOrderFlowData += notOrderProfit[i];
    });
    // chart optionのデータを組み立て
    options.chartOptions.xAxis.data = [
      ...months.map(month => `${month}${t('aipcmcty.page.month')}`),
      'Total',
    ];
    options.chartOptions.series = [];
    options.chartOptions.series.push({
      name: 'transparent',
      type: 'bar',
      stack: 'plan',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      z: 10,
      itemStyle: {
        color: 'transparent',
      },
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: [...transparentData, 0],
    });
    options.chartOptions.series.push({
      name: 'Awarded',
      type: 'bar',
      stack: 'plan',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      z: 10,
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: [...orderProfit, orderFlowData]
    });

    options.chartOptions.series.push({
      name: 'Budget',
      type: 'bar',
      stack: 'plan',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      emphasis: {
        focus: 'series',
      },
      z: 10,
      barWidth: getSizeByMap(sizeMode, sizeMap, 'barWidth'),
      data: [...notOrderProfit, notOrderFlowData],
    });

    options.chartOptions.series.push({
      name: 'Goal',
      type: 'line',
      symbol: 'none',
      itemStyle: {
        color: '#DD6B66'
      },
      lineStyle: {
        cap: 'square'
      },
      data: Array.from({ length: months.length + 1 }, () => goalByYear)
    });
    options.chartOptions.legend.data = ['Goal', 'Awarded', 'Budget'];
    setOptions(cloneDeep(options));
  };

  useEffect(() => {
    if (selectedYear) {
      initFlowChartData();
    }
  }, [selectedYear]);

  const handleChartEvent = e => {
    // Chart reset 事件
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
      && e.chartTitle.value.key === t('aipcmcty.page.orderGrossProfit')
    ) {
      initData();
      setSelectedYear(null)
    }
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_ITEM_SELECTED
      && e.chartTitle.value.key === t('aipcmcty.page.orderGrossProfit')
      && !selectedYear
    ) {
      setSelectedYear(e.event.name);
    }
  };

  return (
    <>
      {ribbonText ? (
        <Ribbon
          text={ribbonText || t('aipcmcty.page.projectQuantity')}
          top={ribbonTop}
          color={ribbonColor || color.primaryColor}
        />
      ) : (
        <></>
      )}
      <TableChart
        showTable={showTable && !selectedYear}
        tableColumns={showTable && !selectedYear ? tableColumns : []}
        tableData={tableData}
        chartOptions={options.chartOptions}
        title={
          title
            ? {
              value: title,
              styles: {
                fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                paddingLeft: sizeMode === 'big' ? 15 : 0,
              },
            }
            : options.title
        }
        height={height - (selectedYear ? 20 : 0)}
        loading={loading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default OrderGrossProfit;