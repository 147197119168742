import { Spin } from 'antd';
import { CSSProperties } from 'react';

type LoadingProps = {
  fixed?: boolean;
  style?: CSSProperties;
  hasCover?: boolean;
};

const LoadingPage: React.FC<LoadingProps> = (props: LoadingProps) => {
  const { fixed = true, style = {}, hasCover = false } = props;
  const mergeStyles = (source: CSSProperties, target?: CSSProperties) => {
    return { ...source, ...(target || {}) };
  };
  return (
    <div>
      {hasCover ? <div className="loading-page loading-cover"></div> : <></>}
      <div
        className="loading-page"
        style={mergeStyles(
          { position: fixed ? 'fixed' : 'absolute', zIndex: 300 },
          style
        )}
      >
        <Spin />
      </div>
    </div>
  );
};
export default LoadingPage;
