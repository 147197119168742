/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import { Empty, Tag, Tooltip } from 'antd';
import GrossProfitCompositionChart from '../../components/charts/gross-profit-composition.chart';
import StrategyMatrixChart from '../../components/charts/strategy-matrix.chart';
import SupplyValidationGanttChart from '../../components/charts/supply-validation-gantt.chart';
import LoadingPage from '../../components/common/load-page';
import SupplyValidationHeader from '../../components/supply-validation-header';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { SupplyValidationContext } from '../../contexts/supply-validation.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import APIList from '../../http/ApiList';
import ChartGroup from '@meteor/frontend-core/dist/chart/chart-card/chart-group';
import { useTranslation } from 'react-i18next';
import { localeMapping } from '../../consts/locale.mapping';
import { ChartTableLayout } from '@meteor/frontend-core';

const SupplyValidationPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    fiscalQuarter, periodSwitch, color, sizeMode, locale, menuCollapsed, scrollCount, setScrollCount
  } = useContext(AppContext);
  const {
    initKpiData, getKpiData, snapshot, snapshotVersion
  } = useContext(AipcmctyContext);

  const [viewMode, setViewMode] = useState('chart-table');
  const [projectData, setProjectData] = useState([]);
  const [projectGroupData, setProjectGroupData] = useState(null);
  const [currentKpiOrder, setCurrentKpiOrder] = useState([1, 2, 3, 4]);
  const [ganttLoading, setGanttLoading] = useState(true);

  useEffect(() => {
    initKpiData([
      'KPJ009',
      'KPJ010',
      'KPJ005',
      'KPJ006',
      'KPJ001',
      'KRS001',
      'ValueProposition',
    ]);
    setGanttLoading(true);
    APIList.getProjectCase()
      .get({
        snapshot,
        snapshotVersion,
      })
      .then((data: any[]) => {
        setProjectData(data);
        setGanttLoading(false);
      });
  }, [snapshot, snapshotVersion]);

  const { selectorTop } = useWindowSize({
    selector: '.chart-group-container',
    viewMode,
  });

  let chartListHeight = 500;
  if (typeof window !== 'undefined') {
    chartListHeight = window.innerHeight
      // eslint-disable-next-line no-nested-ternary
      - (viewMode === 'table-only' ? 120 : sizeMode === 'small' ? 342 : 483)
      - selectorTop
      - 20;
  }

  const customChartGroupTitle = useCallback(
    (groupName: string, chartName: string, groupColor: string, cssStyle?: any) => (
      <div style={{ paddingBottom: 4 }} key={chartName}>
        <Tag style={{ height: 22 }} color={groupColor}>
          {groupName}
        </Tag>
        {cssStyle ? <span style={cssStyle}>{chartName}</span> : <>{chartName}</>}
      </div>
    ),
    []
  );

  const kpiMapping = {
    1: (
      <GrossProfitCompositionChart
        periodSwitch={periodSwitch}
        fiscalQuarter={fiscalQuarter}
        showTable={false}
        kpiCode="KPJ009"
        data={getKpiData('KPJ009').data}
        loading={getKpiData('KPJ009').loading}
        title={customChartGroupTitle(
          t('aipcmcty.page.project'),
          `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.byConstructionLocation')})`,
          color.successColor
        )}
        ribbonText={t('aipcmcty.page.amount')}
        ribbonColor={color.primaryColor}
      />
    ),
    2: (
      <GrossProfitCompositionChart
        periodSwitch={periodSwitch}
        fiscalQuarter={fiscalQuarter}
        showTable={false}
        kpiCode="KPJ010"
        data={getKpiData('KPJ010').data}
        loading={getKpiData('KPJ010').loading}
        title={customChartGroupTitle(
          t('aipcmcty.page.project'),
          `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.byProduct')})`,
          color.successColor
        )}
        ribbonText={t('aipcmcty.page.amount')}
        ribbonColor={color.primaryColor}
      />
    ),
    3: (
      <GrossProfitCompositionChart
        periodSwitch={periodSwitch}
        fiscalQuarter={fiscalQuarter}
        showTable={false}
        kpiCode="KPJ005"
        data={getKpiData('KPJ005').data}
        loading={getKpiData('KPJ005').loading}
        title={customChartGroupTitle(
          t('aipcmcty.page.project'),
          locale.locale === 'ja' ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.nonEPC')}/EPC)` : `${t('aipcmcty.page.nonEPC')}/EPC) ${t('aipcmcty.page.grossProfitComposition')}`,
          color.successColor
        )}
        ribbonText={t('aipcmcty.page.amount')}
        ribbonColor={color.primaryColor}
      />
    ),
    4: (
      <GrossProfitCompositionChart
        periodSwitch={periodSwitch}
        fiscalQuarter={fiscalQuarter}
        showTable={false}
        kpiCode="KPJ006"
        data={getKpiData('KPJ006').data}
        loading={getKpiData('KPJ006').loading}
        title={customChartGroupTitle(
          t('aipcmcty.page.project'),
          locale.locale === 'ja' ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')})` : `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existingBusiness')} ${t('aipcmcty.page.grossProfitComposition')}`,
          color.successColor
        )}
        ribbonText={t('aipcmcty.page.amount')}
        ribbonColor={color.primaryColor}
      />
    ),
  };

  const kpiOrder = {
    buildingSite: [1, 2, 3, 4],
    goods: [2, 1, 3, 4],
    epc: [3, 1, 2, 4],
    isNew: [4, 1, 2, 3],
  };

  useEffect(() => {
    if (projectGroupData && projectGroupData.groupedBy) {
      setCurrentKpiOrder(kpiOrder[projectGroupData.groupedBy]);
    }
  }, [projectGroupData]);

  return (
    <>
      <div className="dashboard">
        <SupplyValidationContext.Provider
          value={{
            viewMode,
            setViewMode,
            projectData,
            setProjectData,
            projectGroupData,
            setProjectGroupData,
          }}
        >
          <SupplyValidationHeader>
            <ChartTableLayout viewMode={viewMode}>
              <ChartTableLayout.Chart>
                <ChartGroup
                  loading={false}
                  sizeMode="small"
                  menuCollapsed={menuCollapsed}
                  scrollCount={scrollCount}
                  setScrollCount={setScrollCount}
                  selectorTop={selectorTop}
                >
                  <ChartGroup.Item>
                    <StrategyMatrixChart
                      kpiCode="KPJ001"
                      data={getKpiData('KPJ001').data}
                      loading={getKpiData('KPJ001').loading}
                      layoutOptions={{
                        xLabels: [
                          [t('aipcmcty.page.grossProfitMargin')],
                          [t('aipcmcty.page.lessThan8Percent'), '8%〜12%', t('aipcmcty.page.moreThan12Percent')],
                        ],
                        yLabels: [
                          [t('aipcmcty.page.growth')],
                          [t('aipcmcty.page.new'), t('aipcmcty.page.new'), ''],
                          [`（${t('aipcmcty.page.innovation')}）`, `（${t('aipcmcty.page.adjacentArea')}）`, t('aipcmcty.page.existing')],
                        ],
                        gridTop: 35,
                        xHeight: 65,
                        chartTop: 25
                      }}
                      title={customChartGroupTitle(
                        t('aipcmcty.page.project'),
                        t('aipcmcty.page.valueCreation'),
                        color.successColor
                      )}
                      ribbonText={t('aipcmcty.page.overall')}
                      ribbonColor={color.primaryColor}
                    />
                  </ChartGroup.Item>
                  <ChartGroup.Item>
                    <StrategyMatrixChart
                      kpiCode="KRS001"
                      data={getKpiData('KRS001').data}
                      loading={getKpiData('KRS001').loading}
                      layoutOptions={{
                        xLabels: [
                          [t('aipcmcty.page.profitPerPersonAmount')],
                          [t('aipcmcty.page.lessThan0Yen'), t('aipcmcty.page.0To150MillionYen'), t('aipcmcty.page.over150MillionYen')],
                        ],
                        yLabels: [
                          [t('aipcmcty.page.growth')],
                          [t('aipcmcty.page.new'), t('aipcmcty.page.new'), ''],
                          [`（${t('aipcmcty.page.innovation')}）`, `（${t('aipcmcty.page.adjacentArea')}）`, t('aipcmcty.page.existing')],
                        ],
                        gridTop: 35,
                        xHeight: 65,
                        chartTop: 25
                      }}
                      title={customChartGroupTitle(
                        t('aipcmcty.page.resource'),
                        t('aipcmcty.page.resourceCapability'),
                        color.warningColor, {
                          fontSize: 14
                        }
                      )}
                      ribbonText={t('aipcmcty.page.overall')}
                      ribbonColor={color.primaryColor}
                    />
                  </ChartGroup.Item>
                  <ChartGroup.Item>
                    <StrategyMatrixChart
                      kpiCode="ValueProposition"
                      data={getKpiData('ValueProposition').data}
                      loading={getKpiData('ValueProposition').loading}
                      title={(
                        <span
                          key={t('aipcmcty.page.valueDelivery')}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 3
                          }}
                        >
                          <Tag color={color.successColor} style={{ margin: 0 }}>
                            {t('aipcmcty.page.project')}
                          </Tag>
                          <Tag color={color.warningColor} style={{ margin: 0 }}>
                            {t('aipcmcty.page.resource')}
                          </Tag>
                          <div style={{
                            textAlign: 'center', fontSize: 12, marginLeft: 5
                          }}
                          >
                            {t('aipcmcty.page.valueDelivery')}
                          </div>
                        </span>
                      )}
                      ribbonText={t('aipcmcty.page.overall')}
                      ribbonColor={color.primaryColor}
                    />
                  </ChartGroup.Item>
                  {currentKpiOrder.map((order) => (
                    <ChartGroup.Item key={order}>
                      {kpiMapping[order]}
                    </ChartGroup.Item>
                  ))}
                </ChartGroup>
              </ChartTableLayout.Chart>
              <ChartTableLayout.Table>
                <div
                  style={{
                    height: chartListHeight,
                    overflowY: 'scroll',
                    background: '#FFF',
                    marginTop: 4,
                  }}
                  className="custom-scrollbar"
                >
                  {!ganttLoading && projectGroupData.data ? (
                    projectGroupData.data.length > 0
                      ? projectGroupData.data.map((group) => (
                        <SupplyValidationGanttChart
                          key={group.name}
                          title={locale.locale === 'ja' ? group.name : (localeMapping[group.name] || group.name)}
                          data={group.data.map((item) => (locale.locale === 'ja' ? item : { ...item, constructionLocation: localeMapping[item.constructionLocation] }))}
                        />
                      )) : <div style={{ paddingTop: '5%' }}><Empty /></div>
                  ) : (
                    <LoadingPage fixed={false} />
                  )}
                </div>
              </ChartTableLayout.Table>
            </ChartTableLayout>
          </SupplyValidationHeader>
        </SupplyValidationContext.Provider>
      </div>
    </>
  );
};

export default SupplyValidationPage;