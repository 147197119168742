import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { groupBy, sumBy, union } from 'lodash';

type SupplyDemandMatchingTableData = {
  data: any;
  loading: boolean;
  mhType: string;
  year: string;
  redRange: number;
  tgcs: any;
  filter: any;
};

const SupplyDemandMatchingTable: React.FC<SupplyDemandMatchingTableData> = (props) => {
  const { data, loading, mhType, year, redRange, tgcs, filter } = props;
  const viewMode = 'chart-table';
  const { selectorHeight4Table } = useWindowSize({ viewMode });
  const [prepareData, setPrepareData] = useState(data);

  // 除法
  const divideData = (obj1, obj2) =>
    Object.keys(obj1 || {}).reduce((acc, key) => {
      if (!obj2[key] || obj2[key] === null || obj2[key] === undefined) {
        acc[key] = '-';
      } else {
        acc[key] = `${((obj1[key] / obj2[key]) * 100).toFixed(1)}%`;
      }
      return acc;
    }, {});

  /**
   * set table data
   */
  const initData = () => {
    if (!data) {
      return;
    }

    Object.keys(data).forEach((per) => {
      // level1粒度：TGC
      const jobTypeFilterDataLv1 = tgcs.length === 0 ? data[per] : data[per].filter((item) => tgcs.some((val) => item.tgc === val));

      // level2粒度：Division
      const jobTypeFilterDataLv2 =
        filter[0].length === 0
          ? jobTypeFilterDataLv1
          : jobTypeFilterDataLv1.filter((item) => filter[0].some((val) => item.division === val));

      // level3粒度：Discipline
      const jobTypeFilterDataLv3 =
        filter[1].length === 0
          ? jobTypeFilterDataLv2
          : jobTypeFilterDataLv2.filter((item) => filter[1].some((val) => item.discipline === val));

      // level4粒度：Role
      const jobTypeFilterDataLv4 =
        filter[2].length === 0
          ? jobTypeFilterDataLv3
          : jobTypeFilterDataLv3.filter((item) => filter[2].some((val) => item.role === val));

      const map = groupBy(jobTypeFilterDataLv4, 'date');

      let targetData;
      if (per === 'supplyTotal' || per === 'mhEmployee' || per === 'mhCp') {
        targetData = Object.keys(map).reduce((result, key) => {
          result[key] = Math.ceil(sumBy(map[key], 'supplyMh'));
          return result;
        }, {});
      } else if (per === 'mpEmployee' || per === 'mpCp') {
        targetData = Object.keys(map).reduce((result, key) => {
          result[key] = Math.ceil(sumBy(map[key], 'supplyMh')) || '-';
          return result;
        }, {});
      } else {
        targetData = Object.keys(map).reduce((result, key) => {
          result[key] = Math.ceil(sumBy(map[key], 'demandMh'));
          return result;
        }, {});
      }
      prepareData[per] = targetData;
    });

    if (Object.keys(prepareData.supplyTotal).length === 0) {
      const supplyTotal = [prepareData.mhCp || {}, prepareData.mhEmployee || {}].reduce((acc, cur) => {
        Object.keys(cur).forEach((key) => {
          acc[key] = (acc[key] || 0) + (cur[key] || 0);
        });
        return acc;
      }, {});
      prepareData.supplyTotal = supplyTotal;
    }

    const demandTotal = [prepareData.genMh || {}, prepareData.rdMh || {}, prepareData.propMh || {}, prepareData.jobMh || {}].reduce(
      (acc, cur) => {
        Object.keys(cur).forEach((key) => {
          acc[key] = (acc[key] || 0) + (cur[key] || 0);
        });
        return acc;
      },
      {}
    );
    prepareData.demandTotal = demandTotal;

    const balance = union<string>(Object.keys(prepareData.supplyTotal || {}), Object.keys(prepareData.demandTotal || {})).reduce(
      (acc, cur) => {
        if (
          prepareData.supplyTotal[cur] === null ||
          prepareData.supplyTotal[cur] === undefined ||
          prepareData.demandTotal[cur] === null ||
          prepareData.demandTotal[cur] === undefined
        )
          return '-';
        acc[cur] = (prepareData.supplyTotal[cur] || 0) - (prepareData.demandTotal[cur] || 0);
        return acc;
      },
      {}
    );

    prepareData.balance = balance;

    // const totalRatio = Object.keys(prepareData.demandTotal || 0).reduce((acc, key) => {
    //   if (!prepareData.supplyTotal[key] || 0) return '-';
    //   acc[key] = `${((prepareData.demandTotal[key] / prepareData.supplyTotal[key]) * 100).toFixed(1)}%`;
    //   return acc;
    // }, {});
    // prepareData.totalRatio = totalRatio;
    prepareData.totalRatio = divideData(prepareData.demandTotal, prepareData.supplyTotal);
    prepareData.genRatio = divideData(prepareData.genMh, prepareData.supplyTotal);
    prepareData.rdRatio = divideData(prepareData.rdMh, prepareData.supplyTotal);
    prepareData.propRatio = divideData(prepareData.propMh, prepareData.supplyTotal);
    prepareData.jobRatio = divideData(prepareData.jobMh, prepareData.supplyTotal);

    setPrepareData(prepareData);
  };

  const tableData = [
    {
      title: '保有工数Total',
      key: 1,
      ...prepareData.supplyTotal,
      children: [
        { title: '社員保有工数', ...prepareData.mhEmployee },
        { title: 'CP保有工数', ...prepareData.mhCp },
        { title: '社員数 (MP)', ...prepareData.mpEmployee },
        { title: 'CP数 (MP)', ...prepareData.mpCp },
      ],
    },

    {
      title: '需要工数Total',
      key: 2,
      ...prepareData.demandTotal,
      children: [
        { title: '一般', ...prepareData.genMh },
        { title: 'R&D', ...prepareData.rdMh },
        { title: 'Proposal', ...prepareData.propMh },
        { title: 'JOB', ...prepareData.jobMh },
      ],
    },
    {
      title: 'Balance',
      key: 3,
      ...prepareData.balance,
    },
    {
      title: 'Work Ratio Total',
      key: 4,
      ...prepareData.totalRatio,
      children: [
        { title: '一般比率', ...prepareData.genRatio },
        { title: 'R&D比率', ...prepareData.rdRatio },
        { title: 'Proposal比率', ...prepareData.propRatio },
        { title: 'JOB比率', ...prepareData.jobRatio },
      ],
    },
  ];

  const createColumns = (y: any) => {
    const columns = [
      {
        dataIndex: 'title',
        key: 'title',
        title: 'MTL&ANL.',
        width: 150,
        fixed: true,
        align: 'left',
      },
    ];

    for (let i = 1; i <= 12; i++) {
      const month = i.toString().padStart(2, '0');
      const dataIndex = `${y}-${month}`;
      const column = {
        title: `${y}-${month}`,
        dataIndex: dataIndex,
        key: dataIndex,
        width: 80,
        fixed: false,
        render: (text: any) => {
          let color = '';
          if (text < 0) {
            color = 'red';
          } else if (/%/g.test(text)) {
            color = 'blue';
          }
          return text !== null && text !== undefined ? <span style={{ color: color }}>{text.toLocaleString()}</span> : <span>-</span>;
        },
      };
      columns.push(column as any);
    }
    return columns as any;
  };

  const [columns, setColumns] = useState(createColumns(2024));

  useEffect(() => {
    if (year) {
      setTimeout(() => {
        if (year === '2024') setColumns(createColumns(2024));
        if (year === '2025') setColumns(createColumns(2025));
        if (year === '2026') setColumns(createColumns(2026));
      }, 300);
    }
    initData();
  }, [data, year, redRange, tgcs, filter]);

  return (
    <div className="table-container">
      <Table
        style={{ minHeight: selectorHeight4Table - 25 }}
        scroll={{ y: selectorHeight4Table - 25 }}
        pagination={false}
        columns={columns}
        bordered
        dataSource={tableData}
        size="small"
        rowClassName={(record, index) => (record.key === 3 ? 'table-alert-red' : '')}
        rowKey="title"
        expandable={{
          defaultExpandAllRows: true,
          defaultExpandedRowKeys: ['Work Ratio Total'],
        }}
        loading={loading}
      />
    </div>
  );
};

export default SupplyDemandMatchingTable;
