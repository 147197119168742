export const getDayString = (date: string | number | Date) => {
  const dataObj = new Date(date);
  const add0 = (m: number) => (m < 10 ? `0${m}` : m);
  const year = dataObj.getFullYear();
  const month = dataObj.getMonth() + 1;
  const day = dataObj.getDate();
  return `${year}-${add0(month)}-${add0(day)}`;
};

export const getMonthString = (date) => {
  const dataObj = new Date(date);
  const add0 = (m: number) => (m < 10 ? `0${m}` : m);
  const year = dataObj.getFullYear();
  const month = dataObj.getMonth() + 1;
  const day = dataObj.getDate();
  return `${year}-${add0(month)}`;
};

export const getAllDay = (start: string | number, end: string | number, offset = 0) => {
  const startTime = new Date(start);
  const endTime = new Date(end);

  startTime.setDate(startTime.getDate() - offset);
  endTime.setDate(endTime.getDate() + offset);

  const dateArr: any = [];
  while (endTime.getTime() - startTime.getTime() >= 0) {
    dateArr.push(getDayString(startTime));
    startTime.setDate(startTime.getDate() + 1);
  }
  return dateArr;
};

export const getAllMonth = (start: string | number, end: string | number, offset = 0) => {
  const startTime = new Date(start);
  const endTime = new Date(end);

  startTime.setMonth(startTime.getMonth() - offset);
  endTime.setMonth(endTime.getMonth() + offset);

  const dateArr = [];
  while (endTime.getTime() - startTime.getTime() >= 0) {
    dateArr.push(getMonthString(startTime));
    startTime.setMonth(startTime.getMonth() + 1);
  }
  return dateArr;
};
