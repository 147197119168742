import { useTranslation } from 'react-i18next';
import { DiffExpectedMhDataItem } from '../../pages/comparison/resource';
import { round, minBy, maxBy, groupBy, sumBy, sortBy, cloneDeep } from 'lodash';
import { useContext, useRef, useState, useEffect } from 'react';
import { getAllMonth } from '../../utils/DateUtil';
import dayjs from 'dayjs';
import { AppContext } from '../../contexts/AppContext';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';

type DiffChartProps = {
  data: DiffExpectedMhDataItem[];
  loading: boolean;
  chartLoading: boolean;
  chartMaxMh: number;
  operation: [any, React.Dispatch<any>];
};

const ResourceDemandCompareChart = (props: DiffChartProps) => {
  const { t } = useTranslation();
  const { data, loading, chartLoading, chartMaxMh, operation } = props;
  const [state, dispatch] = operation;
  const { demandChartRef } = state;
  const { color } = useContext(AppContext);
  const months = useRef(getAllMonth('2023-01', '2026-12'));
  const [options, setOptions] = useState({
    title: {
      value: t('aipcmcty.page.comparisonResource.actDemandCompare'),
    },
    chartOptions: {
      dataZoom: [
        {
          type: 'inside',
          startValue: months.current.indexOf(dayjs().format('YYYY-MM')),
        },
        {
          type: 'slider',
          textStyle: {
            fontSize: 11,
          },
          bottom: 10,
          height: 18,
        },
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          const curs = params.filter((item) => item.name === 'current');
          const tars = params.filter((item) => item.name === 'target');
          return `<div>
            <label>${params[0].axisValue}: </label><br/>
            <div style="display: flex">
              <div>
                <label style="font-weight: bold;">${t('aipcmcty.page.comparisonResource.targetCyd')}</label><br/>
                ${tars
                  .map(
                    (tar) =>
                      `<label>${tar.marker} ${tar.seriesName}: <label style="color: ${
                        !tar.data.same ? color.errorColor : 'initial'
                      }">${round(tar.value).toLocaleString()}</label></label><br/>`
                  )
                  .reverse()
                  .join('')}
              </div>
              <div style="margin-left: 10px">
                <label style="font-weight: bold;">${t('aipcmcty.page.comparisonResource.currentCyd')}</label><br/>
                ${curs
                  .map(
                    (cur) =>
                      `<label>${cur.marker} ${cur.seriesName}:<label style="color: ${
                        !cur.data.same ? color.errorColor : 'initial'
                      }"> ${round(cur.value).toLocaleString()}</label></label><br/>`
                  )
                  .reverse()
                  .join('')}
              </div>
            </div>
          </div>`;
        },
      },
      legend: {
        show: true,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '12%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          fontSize: 12,
        },
      },
      yAxis: {
        type: 'value',
        name: 'MH',
        position: 'left',
        axisLine: {
          show: true,
        },
        axisLabel: {
          fontSize: 10,
        },
        max: 0,
      },
      series: [],
    },
    height: 220,
  });

  useEffect(() => {
    initChartData();
  }, [data, chartMaxMh]);

  const initChartData = () => {
    const expectedDemandMh = data;
    if (!expectedDemandMh || expectedDemandMh.length === 0) {
      return;
    }
    const xAxisMin = minBy(expectedDemandMh, (item) => `${item.year}-${item.month}`);
    const xAxisMax = maxBy(expectedDemandMh, (item) => `${item.year}-${item.month}`);
    const xAxisData = getAllMonth(`${xAxisMin.year}-${xAxisMin.month}`, `${xAxisMax.year}-${xAxisMax.month}`);
    const dMGbCJobType = groupBy(expectedDemandMh, 'jobType');
    const series = [];
    Object.keys(dMGbCJobType).forEach((jobType) => {
      const gbJobTypeData = dMGbCJobType[jobType];
      const tarSeries = {
        name: jobType,
        type: 'bar',
        stack: 'target',
        emphasis: {
          focus: 'series',
        },
        label: {
          formatter: (params) => round(params.value),
        },
        data: xAxisData.map((xAxisLabel) => {
          const filterData = gbJobTypeData.filter((item) => `${item.year}-${item.month}` === xAxisLabel);
          let value = 0;
          let compareValue = 0;
          if (filterData && filterData.length > 0) {
            value = sumBy(filterData, 'targetDemandMh');
            compareValue = sumBy(filterData, 'currentDemandMh');
          }
          return {
            name: 'target',
            value,
            same: round(compareValue) === round(value),
            date: xAxisLabel,
          };
        }),
      };
      series.push(tarSeries);
      const curSeries = {
        name: jobType,
        type: 'bar',
        stack: 'current',
        emphasis: {
          focus: 'series',
        },
        label: {
          formatter: (params) => round(params.value),
        },
        itemStyle: {
          opacity: 1,
        },
        data: xAxisData.map((xAxisLabel) => {
          const filterData = gbJobTypeData.filter((item) => `${item.year}-${item.month}` === xAxisLabel);
          let value = 0;
          let compareValue = 0;
          if (filterData && filterData.length > 0) {
            value = sumBy(filterData, 'currentDemandMh');
            compareValue = sumBy(filterData, 'targetDemandMh');
          }
          return {
            name: 'current',
            value,
            same: round(compareValue) === round(value),
            date: xAxisLabel,
          };
        }),
      };
      series.push(curSeries);
    });
    const jobTypeOrder = ['一般', 'R&D', 'Proposal', '保有ジョブ', '受注計画ジョブ'];
    options.chartOptions.xAxis.data = xAxisData;
    options.chartOptions.yAxis.max = chartMaxMh;
    options.chartOptions.series = sortBy(series, (item) => jobTypeOrder.indexOf(item.name));
    setOptions(cloneDeep(options));
  };

  const chartEventHandler = (globalEvent) => {
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED) {
      const { chartTitle } = globalEvent;
      if (chartTitle.value === t('aipcmcty.page.comparisonResource.actDemandCompare')) {
        initChartData();
      }
    }
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_ITEM_SELECTED) {
      const { chartTitle, event } = globalEvent;
      if (chartTitle.value === t('aipcmcty.page.comparisonResource.actDemandCompare')) {
        dispatch({
          type: 'demandAction',
          event,
        });
      }
    }
    if (globalEvent && globalEvent.eventType === ECHART_EVENT.CHART_GLOBAL_OUT) {
      dispatch({
        type: 'clearTip',
      });
    }
  };

  return (
    <div style={{ height: 345 }}>
      <TableChart
        chartRef={demandChartRef}
        showTable={false}
        chartOptions={options.chartOptions}
        title={{
          value: t('aipcmcty.page.comparisonResource.actDemandCompare'),
          styles: {
            fontSize: 16,
          },
        }}
        height={360}
        loading={loading}
        titleLoading={!loading && chartLoading}
        onChartEvent={chartEventHandler}
      />
    </div>
  );
};

export default ResourceDemandCompareChart;
