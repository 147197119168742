import { Button, Card, Checkbox, Col, Empty, Modal, Popover, Row, Select, Space, Spin, Tooltip } from 'antd';
import React, { CSSProperties, ReactNode, memo, useContext, useEffect, useMemo, useState } from 'react';
import { ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined, FilterOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import APIList from '../../http/ApiList';
import GrossProfitDiffChart from '../../components/charts/gross-profit-diff.chart';
import ProfitConsolidatedDiffChart from '../../components/charts/profit-consolidated-diff.chart';
import PhaseMigrationChart from '../../components/charts/phase-migration.chart';
import PhaseDiffChart from '../../components/charts/phase-diff.chart';
import { useAccountPerspective } from '../../hooks/useAccountPerspective';
import { ECHART_EVENT } from '@meteor/frontend-core';
import ProjectInformation from '../../components/project-information';

type DiffDataKey =
  | 'order'
  | 'disorder'
  | 'inBudget'
  | 'outBudget'
  | 'grossProfitUp'
  | 'grossProfitDown'
  | 'otherUp'
  | 'otherDown'
  | 'phaseUp'
  | 'formationChanged'
  | 'exclusion'
  | 'consolidated';

type DiffProfitDataItem = {
  scope: string;
  certainty: string;
  phase: string;
  won: number;
  grossProfitLocal: number;
  grossProfit: number;
  orderAmountLocal: number;
  orderAmount: number;
  accountingYear: string;
  tgc: string;
  budgetCategory: string;
  projectName: string;
  grossProfitCurrent: number;
  grossProfitTarget: number;
  grossProfitLocalCurrent: number;
  grossProfitLocalTarget: number;
  budgetCategoryCurrent: string;
  budgetCategoryTarget: string;
  projectIdCurrent: string;
  projectIdTarget: string;
  alert: string;
  diffDelta: number;
  diffDetail: string;
  diffType: string;
  projectId: string;
  grossProfitDelta: number;
  diffTgcDelta: number;
  grossProfitTgcCurrent: number;
  grossProfitTgcTarget: number;
  budgetCategoryType: number;
};

type DiffPhaseDataItem = {
  alert: string;
  certaintyDiffDetail: string;
  phaseDiffDetail: string;
  scope: string;
  certainty: string;
  phase: string;
  won: number;
  grossProfitLocal: number;
  grossProfit: number;
  orderAmountLocal: number;
  orderAmount: number;
  accountingYear: string;
  tgc: string;
  budgetCategory: string;
  projectName: string;
  certaintyIndexTarget: number;
  certaintyIndexCurrent: number;
  certaintyTarget: string;
  certaintyCurrent: string;
  phaseIndexCurrent: number;
  phaseIndexTarget: number;
  phaseTarget: string;
  phaseCurrent: string;
  budgetCategoryCurrent: string;
  budgetCategoryTarget: string;
  projectIdCurrent: string;
  projectIdTarget: string;
  certaintyDiffType: string;
  phaseDiffType: string;
  projectId: string;
  phaseMigration: string[];
  phaseCur: string;
  phaseTar: string;
  phaseDiffStep: number;
};

type DiffFormationDataItem = {
  alert: string;
  scope: string;
  certainty: string;
  phase: string;
  won: number;
  grossProfitLocal: number;
  grossProfit: number;
  orderAmountLocal: number;
  orderAmount: number;
  accountingYear: string;
  tgc: string;
  budgetCategory: string;
  projectName: string;
  structureCurrent: string;
  structureTarget: string;
  budgetCategoryTarget: string;
  budgetCategoryCurrent: string;
  projectIdTarget: string;
  projectIdCurrent: string;
  diffDetail: string;
  formDiff: boolean;
  projectId: string;
};

type DiffConsolidatedDataItem = {
  fiscalYear: string;
  fiscalQuarter: string;
  grossProfitTarget: number;
  grossProfitCurrent: number;
};

type DiffDataItem<K extends DiffDataKey> = K extends 'phaseUp'
  ? DiffPhaseDataItem
  : K extends 'formationChanged'
  ? DiffFormationDataItem
  : K extends 'consolidated'
  ? DiffConsolidatedDataItem
  : DiffProfitDataItem;

type DiffData = {
  [K in DiffDataKey]: {
    title: string;
    projectList: DiffDataItem<K>[];
  };
};

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export type DiffResponse = {
  [K in DiffDataKey]: DiffDataItem<K>[];
} & {
  goal: {
    current: number;
    target: number;
  };
  consolidatedGoal: {
    current: { [key: `FY${number}`]: any };
    target: { [key: `FY${number}`]: any };
  };
};

type Option = {
  label: ReactNode;
  value: any;
  key?: any;
  [key: string]: any;
};

const isEmpty = (v) => !v && !['boolean', 'number'].includes(typeof v);
const valOrHyphen = (v) => (isEmpty(v) ? '-' : v);

type DiffCardProps = {
  title: string;
  diffList: DiffData[keyof DiffData]['projectList'];
  color: any;
  titleColor: string;
  detailColor: string;
  changeTitle?: boolean;
  loading: boolean;
  titleLoading: boolean;
  highlight?: boolean;
  cardHeight: number;
  filter?: ReactNode;
  titleText?: string;
  setProject: Function;
  setIsModalOpen: Function;
};

const CardTitle = memo(
  ({
    title = '',
    dataLength = 0,
    titleColor = 'white',
    changeTitle = false,
    loading = false,
    text = null,
  }: {
    title: string;
    dataLength: number;
    titleColor: string;
    changeTitle: boolean;
    loading: boolean;
    text?: string;
  }) => (
    <Space className="title-area">
      <span className="card-title">{changeTitle ? title.replace('Up', 'Down') : title}</span>
      <span className="card-diff" style={{ '--diff-color': titleColor } as CSSProperties}>
        {dataLength}
      </span>
      {text}
      <Spin spinning={loading} size="small" />
    </Space>
  )
);

type FilterChangeEvent = {
  current: any[];
  target: any[];
  step: number;
};
const CardFilter = memo(
  (props: { filter: { current: string[]; target: string[] }; filterChange: (e: FilterChangeEvent, init?: boolean) => void }) => {
    const { filter, filterChange } = props;
    let filterContent: ReactNode = null;
    const options = [
      { label: 'Pre-Sales', value: 'preSales' },
      { label: 'Proposal', value: 'proposal' },
      { label: 'Negotiation', value: 'negotiation' },
      { label: 'Awarded', value: 'awarded' },
    ];
    const content = (
      <Space direction="vertical">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '6px',
          }}
        >
          From:
          <Checkbox.Group
            options={options}
            onChange={(e) => filterChange({ ...filter, target: e } as FilterChangeEvent)}
            value={filter.target}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '6px',
          }}
        >
          To:
          <Checkbox.Group
            options={options}
            onChange={(e) => filterChange({ ...filter, current: e } as FilterChangeEvent)}
            value={filter.current}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="primary" style={{ width: '100%' }} onClick={() => filterChange({ current: [], target: [], step: 0 }, true)}>
            リセット: フェーズに変化があった案件を絞り込む
          </Button>
        </div>
      </Space>
    );
    filterContent = (
      <Popover content={content} trigger="click" placement="left">
        <FilterOutlined className="card-title-filter" />
      </Popover>
    );
    return filterContent;
  }
);

const DiffCard = memo(
  ({
    title,
    diffList,
    titleColor = 'white',
    detailColor,
    color,
    changeTitle = false,
    loading,
    titleLoading,
    highlight = false,
    cardHeight,
    filter,
    titleText = null,
    setProject,
    setIsModalOpen,
  }: DiffCardProps) => {
    let cardContent = <></>;
    if (loading) {
      cardContent = <Spin />;
    } else if (!diffList.length) {
      cardContent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    } else {
      cardContent = (
        <ul>
          {diffList.map((p) => {
            let listItemClass = 'list-item';
            if (p.diffDelta >= 0) {
              listItemClass += ' great';
            }
            if (p.diffDelta < 0) {
              listItemClass += ' less';
            }

            const isPhase = !!p.phaseDiffType;
            let phaseIcon = null;
            if (isPhase) {
              if (p.phaseDiffStep > 0) {
                phaseIcon = <ArrowUpOutlined style={{ color: color.successColor }} />;
              } else if (p.phaseDiffStep < 0) {
                phaseIcon = <ArrowDownOutlined style={{ color: color.errorColor }} />;
              } else {
                phaseIcon = <ArrowRightOutlined style={{ color: '#b2b2b2' }} />;
              }
            }
            return (
              <li
                key={p.projectId}
                className={listItemClass}
                style={
                  {
                    '--item-great': color.successColor,
                    '--item-less': color.errorColor,
                  } as CSSProperties
                }
              >
                {p.alert && (
                  <Tooltip title={p.alert} placement="right">
                    <WarningOutlined
                      style={{
                        color: color.errorColor,
                        position: 'absolute',
                        left: 0,
                      }}
                    />
                  </Tooltip>
                )}
                <Popover
                  trigger="hover"
                  content={<ContentPop color={color} popData={p} detailColor={detailColor} />}
                  overlayClassName="comparison-card-pop"
                  placement="left"
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setProject(p);
                      setIsModalOpen(true);
                    }}
                  >
                    {phaseIcon}
                    {p.projectId}
                    &nbsp;&nbsp;
                    {p.projectName}
                  </div>
                </Popover>
              </li>
            );
          })}
        </ul>
      );
    }

    const cardClass = highlight ? 'item-card card-highlight' : 'item-card';

    return (
      <Card
        className={cardClass}
        style={
          {
            '--body-height': `${cardHeight}px`,
            '--card-shadow': '#b2b2b2',
          } as CSSProperties
        }
        title={
          <CardTitle
            title={title}
            dataLength={diffList.length}
            titleColor={titleColor}
            changeTitle={changeTitle}
            loading={!loading && titleLoading}
            text={titleText}
          />
        }
        size="small"
        extra={filter}
      >
        {cardContent}
      </Card>
    );
  }
);

const ContentPop = memo(
  ({
    popData,
    detailColor,
    color,
  }: {
    popData: DiffDataItem<Exclude<DiffDataKey, 'exclusion' | 'consolidated'>>;
    color: any;
    detailColor: string;
  }) => {
    const { t } = useTranslation();
    const {
      projectId,
      projectName,
      budgetCategory,
      tgc,
      accountingYear,
      orderAmount,
      orderAmountLocal,
      grossProfit,
      grossProfitLocal,
      won,
      phase,
      certainty,
      scope,
    } = popData;
    return (
      <div>
        <h4 style={{ fontWeight: 'bold' }}>{t('aipcmcty.page.comparison.projectDetail')}</h4>
        <div className="grid-table">
          <div className="table-name">
            <div>{t('aipcmcty.page.comparison.projectId')}</div>
            <div>{t('aipcmcty.page.comparison.projectName')}</div>
            {Reflect.has(popData as DiffFormationDataItem | DiffProfitDataItem, 'diffDetail') && (
              <div>{t('aipcmcty.page.comparison.diffDetail')}</div>
            )}
            {Reflect.has(popData as DiffProfitDataItem, 'grossProfitDelta') && <div>{t('aipcmcty.page.comparison.grossProfitDelta')}</div>}
            {Reflect.has(popData as DiffPhaseDataItem, 'phaseDiffDetail') && <div>{t('aipcmcty.page.comparison.phaseDiffDetail')}</div>}
            {Reflect.has(popData as DiffPhaseDataItem, 'certaintyDiffDetail') && (
              <div>{t('aipcmcty.page.comparison.certaintyDiffDetail')}</div>
            )}
            <div>{t('aipcmcty.page.comparison.budgetCategory')}</div>
            <div>{t('aipcmcty.page.comparison.tgcTitle')}</div>
            <div>{t('aipcmcty.page.comparison.accountingYear')}</div>
            <div>{t('aipcmcty.page.comparison.orderAmount')}</div>
            <div>{t('aipcmcty.page.comparison.orderAmountLocal')}</div>
            <div>{t('aipcmcty.page.comparison.grossProfit')}</div>
            <div>{t('aipcmcty.page.comparison.grossProfitLocal')}</div>
            <div>{t('aipcmcty.page.comparison.wonPer')}</div>
            <div>{t('aipcmcty.page.comparison.phase')}</div>
            <div>{t('aipcmcty.page.comparison.certainty')}</div>
            <div>{t('aipcmcty.page.comparison.scope')}</div>
          </div>
          <div className="table-value">
            <div>{valOrHyphen(projectId)}</div>
            <Tooltip title={valOrHyphen(projectName)}>
              <div>{valOrHyphen(projectName)}</div>
            </Tooltip>
            {Reflect.has(popData as DiffFormationDataItem | DiffProfitDataItem, 'diffDetail') && (
              <Tooltip title={valOrHyphen((popData as DiffFormationDataItem | DiffProfitDataItem).diffDetail)}>
                <div className="update-item" style={{ '--update-color': detailColor } as CSSProperties}>
                  {valOrHyphen((popData as DiffFormationDataItem | DiffProfitDataItem).diffDetail)}
                </div>
              </Tooltip>
            )}
            {Reflect.has(popData as DiffProfitDataItem, 'grossProfitDelta') && (
              <Tooltip title={valOrHyphen((popData as DiffProfitDataItem).grossProfitDelta)}>
                <div
                  className="update-item"
                  style={
                    {
                      '--update-color': (popData as DiffProfitDataItem).diffDelta >= 0 ? color.successColor : color.errorColor,
                    } as CSSProperties
                  }
                >
                  {valOrHyphen((popData as DiffProfitDataItem).grossProfitDelta)}
                </div>
              </Tooltip>
            )}
            {Reflect.has(popData as DiffPhaseDataItem, 'phaseDiffDetail') && (
              <Tooltip title={valOrHyphen((popData as DiffPhaseDataItem).phaseDiffDetail)}>
                <div className="update-item" style={{ '--update-color': detailColor } as CSSProperties}>
                  {valOrHyphen((popData as DiffPhaseDataItem).phaseDiffDetail)}
                </div>
              </Tooltip>
            )}
            {Reflect.has(popData as DiffPhaseDataItem, 'certaintyDiffDetail') && (
              <Tooltip title={valOrHyphen((popData as DiffPhaseDataItem).certaintyDiffDetail)}>
                <div className="update-item" style={{ '--update-color': detailColor } as CSSProperties}>
                  {valOrHyphen((popData as DiffPhaseDataItem).certaintyDiffDetail)}
                </div>
              </Tooltip>
            )}
            <div>{valOrHyphen(budgetCategory)}</div>
            <div>{valOrHyphen(tgc)}</div>
            <div>{valOrHyphen(accountingYear)}</div>
            <div className="number">{valOrHyphen(orderAmount?.toLocaleString())}</div>
            <div className="number">{valOrHyphen(orderAmountLocal?.toLocaleString())}</div>
            <div className="number">{valOrHyphen(grossProfit?.toLocaleString())}</div>
            <div className="number">{valOrHyphen(grossProfitLocal?.toLocaleString())}</div>
            <div className="number">{typeof won === 'number' ? `${won * 100}%` : valOrHyphen(won)}</div>
            <div>{valOrHyphen(phase)}</div>
            <Tooltip title={valOrHyphen(certainty)}>
              <div>{valOrHyphen(certainty)}</div>
            </Tooltip>
            <Tooltip title={valOrHyphen(scope)}>
              <div>{valOrHyphen(scope)}</div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
);

const ComparisonPage: React.FC = () => {
  const [pageInit, setPageInit] = useState(false);
  const [phaseInit, setPhaseInit] = useState(true);
  // about common
  const { t } = useTranslation();
  const { color } = useContext(AppContext);
  const { versionList, snapshot, snapshotVersion, compareVersion, setCompareVersion } = useContext(AipcmctyContext);

  const { selectorTop } = useWindowSize({
    selector: '.diff-container',
  });

  const diffContainerHeight = window.innerHeight - selectorTop - 30;
  const diffCardHeight = (diffContainerHeight - 6 - 2 * 39) / 2;

  // about filter actions
  const [headerOptions, setHeaderOptions] = useState<{ [k: string]: Option[] }>({
    ayOptions: [],
    tgcOptions: [],
    accountingPerspective: [],
    snapshot: [],
    snapshotVersion: [],
  });

  const [snapshotValue, setSnapshotValue] = useState('');
  const [versionValue, setVersionValue] = useState('');

  const { perspectiveOptions, setAccountPerspective, filterTgcOptions, selectedTgc, filterOptsAndSelected } = useAccountPerspective(
    headerOptions.tgcOptions
  );

  const [dataLoading, setDataLoading] = useState(true);
  const [graphLoading, setGraphLoading] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [project, setProject] = useState<any>();

  const [filterSelect, setFilterSelect] = useState({
    accountingPerspective: null,
    accountingYear: [],
    tgc: [],
    department: []
  });

  const versionOptCollection = useMemo(() => {
    const filterVersionCollection = versionList.reduce((acc, cur) => {
      // exclude great than current version
      // exclude current version
      if (cur.snapshot > snapshot || (cur.snapshot === snapshot && cur.snapshotVersion === snapshotVersion)) {
        return acc;
      }
      const snapshotList = acc[cur.snapshot];
      if (!snapshotList) {
        acc[cur.snapshot] = [];
      }
      acc[cur.snapshot].push({
        label: cur.snapshotVersionName,
        value: cur.snapshotVersion,
      });
      return acc;
    }, {});
    if (Object.keys(filterVersionCollection).length === 0) {
      filterVersionCollection[snapshot] = [
        {
          label: versionList.find((version) => version.snapshotVersion === snapshotVersion)?.snapshotVersionName,
          value: snapshotVersion,
        },
      ];
    }
    return filterVersionCollection;
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    setDataLoading(true);
    Promise.all([
      APIList.getCmcOptions().get({
        category: 'accountingYear',
        snapshot,
        snapshotVersion,
      }) as Promise<Option[]>,
      APIList.getCmcOptions().get({
        category: 'tgc',
        snapshot,
        snapshotVersion,
      }) as Promise<Option[]>,
    ]).then(([ayOptions, tgcOptions]) => {
      const snapshotList = Object.keys(versionOptCollection).sort((a, b) => (b > a ? 1 : -1));
      const [, tgcSelected] = filterOptsAndSelected(perspectiveOptions[1].value, tgcOptions);

      const curSnapshotIndex = snapshotList.findIndex((s) => s === snapshot);
      let defaultSnapshot = snapshot;
      if (curSnapshotIndex <= 0) {
        if (snapshotList.length === 1) {
          const [s] = snapshotList;
          defaultSnapshot = s;
        }
        if (snapshotList.length > 1) {
          defaultSnapshot = snapshotList[curSnapshotIndex + 1];
        }
      }
      const defaultCompareVer = {
        snapshot: defaultSnapshot,
        snapshotVersion: 'default',
      };
      const defaultFilter = {
        ...filterSelect,
        accountingPerspective: perspectiveOptions[1].value,
        accountingYear: ['FY24'],
        tgc: tgcSelected,
      };
      setFilterSelect(defaultFilter);
      let curSnapshotValue = compareVersion.snapshot;
      let curVersionValue = compareVersion.snapshotVersion;
      const curSnapshotList = snapshotList
        .filter((s) => dayjs(s).isBefore(snapshot) || dayjs(s).isSame(snapshot))
        .map((s) => ({ label: s, value: s }));
      if (
        pageInit ||
        (!compareVersion.snapshot && !compareVersion.snapshotVersion) ||
        !curSnapshotList.some((s) => s.value === compareVersion.snapshot)
      ) {
        curSnapshotValue = defaultCompareVer.snapshot;
        curVersionValue = defaultCompareVer.snapshotVersion;
      }
      const curSnapshotVersionList = versionOptCollection[curSnapshotValue];
      setSnapshotValue(curSnapshotValue);
      setVersionValue(curVersionValue);
      setCompareVersion({
        snapshot: curSnapshotValue,
        snapshotVersion: curVersionValue,
      });

      setHeaderOptions({
        accountingPerspective: perspectiveOptions,
        ayOptions,
        tgcOptions,
        snapshot: curSnapshotList,
        snapshotVersion: curSnapshotVersionList,
      });
      setPageInit(true);
    });
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      snapshotVersion: versionOptCollection[compareVersion.snapshot],
    });
  }, [snapshot, snapshotVersion]);

  const handleFilterChange = (key: string, value: any) => {
    setSnapshotValue(compareVersion.snapshot);
    setVersionValue(compareVersion.snapshotVersion);
    const newFilterState = {
      ...filterSelect,
      [key]: value,
    };
    if (
      (key === 'accountingPerspective' && value !== 3)
      || (key === 'tgc' && value.length !== 1 && value[0] !== 'Toyo-J')
    ) {
      newFilterState.department = [];
    }
    setFilterSelect(newFilterState);
    if (key === 'accountingPerspective') {
      setAccountPerspective(value);
      return;
    }
    setGraphLoading(graphLoading + 1);
    getComparison(
      compareVersion.snapshot,
      compareVersion.snapshotVersion,
      newFilterState.accountingPerspective,
      newFilterState.accountingYear.join(','),
      newFilterState.tgc.join(','),
      newFilterState.department
    );
  };

  useEffect(() => {
    if (!selectedTgc.length) {
      return;
    }
    setFilterSelect({
      ...filterSelect,
      tgc: selectedTgc,
    });
    setGraphLoading(graphLoading + 1);
    getComparison(
      compareVersion.snapshot,
      compareVersion.snapshotVersion,
      filterSelect.accountingPerspective,
      filterSelect.accountingYear.join(','),
      selectedTgc.join(','),
      filterSelect.department
    );
  }, [selectedTgc]);

  const handleVersionChange = (key: string, value: any) => {
    // setCompareVersion({
    //   ...compareVersion,
    //   [key]: value,
    // });
    if (key === 'snapshotVersion') {
      setVersionValue(value);
    }
    if (key === 'snapshot') {
      // setCompareVersion((prev) => ({
      //   ...prev,
      //   snapshotVersion: 'default',
      // }));
      setSnapshotValue(value);
      setVersionValue('default');
      setHeaderOptions({
        ...headerOptions,
        snapshotVersion: versionOptCollection[value],
      });
    }
  };

  const handleCompare = () => {
    setGraphLoading(graphLoading + 1);
    setCompareVersion({
      snapshot: snapshotValue,
      snapshotVersion: versionValue,
    });
    getComparison(
      snapshotValue,
      versionValue,
      filterSelect.accountingPerspective,
      filterSelect.accountingYear.join(','),
      filterSelect.tgc.join(','),
      filterSelect.department
    );
  };

  // all data source
  const [diffSource, setDiffSource] = useState<DiffResponse>({
    order: [],
    disorder: [],
    inBudget: [],
    outBudget: [],
    grossProfitUp: [],
    grossProfitDown: [],
    otherUp: [],
    otherDown: [],
    phaseUp: [],
    formationChanged: [],
    exclusion: [],
    consolidated: [],
    goal: {
      current: 0,
      target: 0,
    },
    consolidatedGoal: {
      current: {},
      target: {},
    },
  });

  const [phaseFilter, setPhaseFilter] = useState({
    // current: ['proposal', 'negotiation', 'awarded'],
    // target: ['preSales', 'proposal', 'negotiation'],
    current: [],
    target: [],
    step: 0,
  });
  const handlePhaseMigration = (e, init = false) => {
    setPhaseInit(init);
    setPhaseFilter(e);
  };

  const getComparison = async (
    tarSnapshot,
    tarVersion,
    acPerspective,
    accountingYears,
    tgc,
    departments,
    curSnapshot = snapshot,
    curVersion = snapshotVersion
  ) => {
    const res = (await APIList.getComparison().get({
      tarSnapshot,
      tarVersion,
      curSnapshot,
      curVersion,
      acPerspective,
      accountingYears,
      tgc,
      departments
    })) as DiffResponse;
    setDiffSource(res);
    setDataLoading(false);
    setGraphLoading((prev) => (prev - 1 < 0 ? 0 : prev - 1));
  };

  const mapIndexToTypeGPGraphs = (index) => {
    switch (index) {
      case 1:
        return 'order';
      case 2:
        return 'disorder';
      case 3:
        return 'inBudget';
      case 4:
        return 'outBudget';
      case 5:
        return 'grossProfitUp';
      case 6:
        return 'grossProfitDown';
      case 7:
        return 'otherUp';
      case 8:
        return 'otherDown';
      default:
        return '';
    }
  };

  const cardTitleMap = {
    order: `① ${t('aipcmcty.page.comparison.orderP')}`,
    disorder: `② ${t('aipcmcty.page.comparison.disorderP')}`,
    inBudget: `③ ${t('aipcmcty.page.comparison.rankIn')}`,
    outBudget: `④ ${t('aipcmcty.page.comparison.rankOut')}`,
    grossProfitUp: `⑤ ${t('aipcmcty.page.comparison.grossProfitUp')}`,
    grossProfitDown: `⑥ ${t('aipcmcty.page.comparison.grossProfitDown')}`,
    otherUp: `⑦ ${t('aipcmcty.page.comparison.otherUp')}`,
    otherDown: `⑧ ${t('aipcmcty.page.comparison.otherDown')}`,
    phaseUp: `⑨ ${t('aipcmcty.page.comparison.phaseUp')}`,
    formationChanged: `⑩ ${t('aipcmcty.page.comparison.formationChange')}`,
  };

  const cardRenderData = useMemo(
    () =>
      Object.entries(diffSource).reduce((acc, [k, v]) => {
        if (['goal', 'consolidatedGoal'].includes(k)) {
          return acc;
        }

        let filterMethod;
        if (k === 'phaseUp') {
          const { current, target } = phaseFilter;
          if (current.length && target.length) {
            filterMethod = (p: DiffPhaseDataItem) => current.includes(p.phaseCur) && target.includes(p.phaseTar);
          }
        }
        acc[k] = {
          title: cardTitleMap[k],
          projectList: filterMethod ? (v as any[]).filter(filterMethod) : v,
        };
        return acc;
      }, {}) as DiffData,
    [phaseFilter, diffSource]
  );

  const [chartEvent, setChartEvent] = useState(null);
  const handleChartEvent = (e) => {
    setChartEvent(e);
  };

  return (
    <div className="comparison">
      <Row className="operation-container" justify="space-between">
        <Col style={{ padding: '5px 0' }}>
          <Space>
            {t('aipcmcty.page.comparison.accountingPerspective')}
            :
            <Select
              style={{ width: 180 }}
              options={headerOptions.accountingPerspective}
              value={filterSelect.accountingPerspective}
              onChange={(e) => handleFilterChange('accountingPerspective', e)}
            />
            {t('aipcmcty.page.comparison.tgc')}
            :
            <Select
              allowClear
              style={{ width: 180 }}
              options={filterTgcOptions}
              value={filterSelect.tgc}
              mode="multiple"
              maxTagCount="responsive"
              onChange={(e) => handleFilterChange('tgc', e)}
            />
            {t('aipcmcty.page.comparison.accountingYear')}
            :
            <Select
              style={{ width: 150 }}
              options={headerOptions.ayOptions}
              value={filterSelect.accountingYear}
              mode="multiple"
              maxTagCount="responsive"
              onChange={(e) => handleFilterChange('accountingYear', e)}
            />
            本部:
            <Tooltip title="会計観点: Toyo-J連結+TGC: Toyo-Jのみ指定可">
              <Select
                allowClear
                style={{ width: 200 }}
                value={filterSelect.department}
                mode="multiple"
                maxTagCount="responsive"
                disabled={
                  filterSelect.accountingPerspective !== 3
                  || filterSelect.tgc.length !== 1
                  || filterSelect.tgc[0] !== 'Toyo-J'
                }
                options={[
                  { label: 'CN本部', value: 'CN本部', key: 'CN本部' },
                  { label: '国内営業', value: '国内', key: '国内' },
                  { label: 'OFS本部', value: 'OFS', key: 'OFS' },
                  { label: '海外営業', value: '海外', key: '海外' },
                  { label: 'その他：過去分', value: 'その他: 過去分', key: 'その他: 過去分' },
                ]}
                onChange={(vs) => {
                  handleFilterChange('department', vs);
                }}
              />
            </Tooltip>
          </Space>
        </Col>
        <Col style={{ padding: '5px 0' }}>
          <Space>
            {t('aipcmcty.page.comparison.targetSnapshot')}
            :
            <Select
              style={{ width: 150 }}
              options={headerOptions.snapshot}
              value={snapshotValue}
              onChange={(e) => handleVersionChange('snapshot', e)}
            />
            {t('aipcmcty.page.comparison.targetVersion')}
            :
            <Select
              style={{ width: 150 }}
              options={headerOptions.snapshotVersion}
              value={versionValue}
              onChange={(e) => handleVersionChange('snapshotVersion', e)}
            />
            <Button type="primary" onClick={handleCompare}>
              比較
            </Button>
          </Space>
        </Col>
      </Row>
      <div className="diff-graphs">
        <div className="gross-profit-graph">
          <GrossProfitDiffChart
            data={diffSource}
            loading={dataLoading}
            graphLoading={graphLoading !== 0}
            title={t('aipcmcty.page.comparison.grossProfitGraphTitle')}
            onChartEvent={handleChartEvent}
          />
        </div>
        <div>
          <ProfitConsolidatedDiffChart
            data={diffSource}
            loading={dataLoading}
            graphLoading={graphLoading !== 0}
            title={t('aipcmcty.page.comparison.grossProfitDiffTitle')}
          />
        </div>
        <div>
          <PhaseMigrationChart
            data={diffSource}
            loading={dataLoading}
            graphLoading={graphLoading !== 0}
            title={t('aipcmcty.page.comparison.phaseMigrate')}
            migrationAction={handlePhaseMigration}
          />
        </div>
        <div>
          <PhaseDiffChart
            data={diffSource}
            loading={dataLoading}
            graphLoading={graphLoading !== 0}
            title={t('aipcmcty.page.comparison.salesTop')}
          />
        </div>
      </div>
      <div className="diff-container" style={{ height: diffContainerHeight }}>
        <div className="compare-item">
          <DiffCard
            title={cardRenderData.order.title}
            diffList={cardRenderData.order.projectList}
            detailColor={color.successColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            highlight={chartEvent?.eventType === ECHART_EVENT.CHART_HIGHLIGHT && mapIndexToTypeGPGraphs(chartEvent?.value) === 'order'}
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
          <DiffCard
            title={cardRenderData.disorder.title}
            diffList={cardRenderData.disorder.projectList}
            detailColor={color.errorColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            highlight={chartEvent?.eventType === ECHART_EVENT.CHART_HIGHLIGHT && mapIndexToTypeGPGraphs(chartEvent?.value) === 'disorder'}
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
        <div className="compare-item">
          <DiffCard
            title={cardRenderData.inBudget.title}
            diffList={cardRenderData.inBudget.projectList}
            detailColor={color.successColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            highlight={chartEvent?.eventType === ECHART_EVENT.CHART_HIGHLIGHT && mapIndexToTypeGPGraphs(chartEvent?.value) === 'inBudget'}
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
          <DiffCard
            title={cardRenderData.outBudget.title}
            diffList={cardRenderData.outBudget.projectList}
            detailColor={color.errorColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            highlight={chartEvent?.eventType === ECHART_EVENT.CHART_HIGHLIGHT && mapIndexToTypeGPGraphs(chartEvent?.value) === 'outBudget'}
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
        <div className="compare-item">
          <DiffCard
            title={cardRenderData.grossProfitUp.title}
            diffList={cardRenderData.grossProfitUp.projectList}
            detailColor={color.successColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            highlight={
              chartEvent?.eventType === ECHART_EVENT.CHART_HIGHLIGHT && mapIndexToTypeGPGraphs(chartEvent?.value) === 'grossProfitUp'
            }
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
          <DiffCard
            title={cardRenderData.grossProfitDown.title}
            diffList={cardRenderData.grossProfitDown.projectList}
            detailColor={color.errorColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            highlight={
              chartEvent?.eventType === ECHART_EVENT.CHART_HIGHLIGHT && mapIndexToTypeGPGraphs(chartEvent?.value) === 'grossProfitDown'
            }
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
        <div className="compare-item">
          <DiffCard
            title={cardRenderData.otherUp.title}
            diffList={cardRenderData.otherUp.projectList}
            detailColor={color.successColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            highlight={chartEvent?.eventType === ECHART_EVENT.CHART_HIGHLIGHT && mapIndexToTypeGPGraphs(chartEvent?.value) === 'otherUp'}
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
          <DiffCard
            title={cardRenderData.otherDown.title}
            diffList={cardRenderData.otherDown.projectList}
            detailColor={color.errorColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            highlight={chartEvent?.eventType === ECHART_EVENT.CHART_HIGHLIGHT && mapIndexToTypeGPGraphs(chartEvent?.value) === 'otherDown'}
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
        <div className="compare-item">
          <DiffCard
            title={cardRenderData.phaseUp.title}
            diffList={phaseInit ? cardRenderData.phaseUp.projectList.filter((p) => !!p.phaseDiffStep) : cardRenderData.phaseUp.projectList}
            detailColor={color.primaryColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            changeTitle={phaseFilter.step < 0}
            filter={<CardFilter filter={phaseFilter} filterChange={handlePhaseMigration} />}
            titleText={
              phaseFilter.current.length === 1 && phaseFilter.target.length === 1
                ? `${phaseFilter.target[0].slice(0, 4).toUpperCase()} → ${phaseFilter.current[0].slice(0, 4).toUpperCase()}`
                : null
            }
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
          <DiffCard
            title={cardRenderData.formationChanged.title}
            diffList={cardRenderData.formationChanged.projectList}
            detailColor={color.primaryColor}
            titleColor={color.primaryColor}
            color={color}
            loading={dataLoading}
            titleLoading={graphLoading !== 0}
            cardHeight={diffCardHeight}
            setProject={setProject}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </div>
      <Modal
        className="aipcmc"
        title={`${project?.projectId} ${project?.projectName} ${t('aipcmcty.page.projectDemandSupplyList')}`}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setProject(null);
        }}
        footer={null}
        width="90%"
        styles={{
          body: {
            height: document.body.clientHeight * 0.8,
          },
        }}
        maskClosable={false}
        centered
      >
        <ProjectInformation
          projectId={project?.projectId}
          base=".cmcty-modal-body"
          compSnapshot={compareVersion.snapshot}
          compSnapshotVersion={compareVersion.snapshotVersion}
        />
      </Modal>
    </div>
  );
};

export default ComparisonPage;
