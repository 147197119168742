import { cloneDeep } from 'lodash';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiffResponse } from '../../pages/comparison/project';
import { AppContext } from '../../contexts/AppContext';
import { TableChart } from '@meteor/frontend-core';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';

type DiffChartProps = {
  data: DiffResponse;
  loading: boolean;
  graphLoading: boolean;
  graphHeight?: number;
  title?: string | ReactNode;
};

const DIVIDEND = 100000000;

const ProfitConsolidatedDiffChart = (props: DiffChartProps) => {
  const {
    data, loading, graphLoading, graphHeight = 340, title
  } = props;

  const { t } = useTranslation();

  const {
    sizeMode,
    color
  } = useContext(AppContext);
  const { sizeMapBase } = useContext(AipcmctyContext);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridLeft: 50,
      gridRight: 40,
      splitNumber: 3,
    },
    big: {
      ...sizeMapBase.big,
      gridRight: 40,
      splitNumber: 3,
    },
  };

  const [consolidatedGraphOptions, setConsolidatedGraphOptions] = useState({
    title: {
      value: t('aipcmcty.page.comparison.grossProfitDiffTitle'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      legend: {
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
      },
      xAxis: {
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
        },
        data: []
      },
      yAxis: {
        type: 'value',
        position: 'left',
        name: t('aipcmc.charts.hundredMillion'),
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          formatter: (value) => value / DIVIDEND
        },
        nameTextStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
        },
        nameGap: 5,
        splitNumber: getSizeByMap(sizeMode, sizeMap, 'splitNumber'),
        axisLine: {
          show: true,
        },
      },
      series: []
    },
    height: 220,
  });

  const updateConsolidatedGraphOptions = () => {
    const goals = data.consolidatedGoal.current;
    const showGoal = Object.values(goals).some(g => g.grossProfitQ !== 0);
    const optionsItems = data.consolidated.reduce(
      (acc: any, cur: any) => {
        acc.titles.push(`${cur.fiscalYear}-${cur.fiscalQuarter}`);
        const targetSeries = acc.series.find(s => s.name === t('aipcmcty.page.comparison.targetCyd'));
        const currentSeries = acc.series.find(s => s.name === t('aipcmcty.page.comparison.currentCyd'));
        const goalSeries = acc.series.find(s => s.name === t('aipcmcty.page.comparison.goal'));
        targetSeries.data.push(cur.grossProfitTarget);
        currentSeries.data.push(cur.grossProfitCurrent);
        if (showGoal) {
          goalSeries.data.push(goals?.[cur.fiscalYear]?.grossProfitQ);
        }
        return acc;
      },
      {
        titles: [],
        series: [
          {
            name: t('aipcmcty.page.comparison.targetCyd'),
            type: 'bar',
            data: [],
          },
          {
            name: t('aipcmcty.page.comparison.currentCyd'),
            type: 'bar',
            data: [],
          },
          {
            name: t('aipcmcty.page.comparison.goal'),
            type: 'line',
            step: 'middle',
            data: [],
            symbol: 'none',
            lineStyle: {
              color: color.warningColor,
              width: 2,
              type: 'solid',
            },
            itemStyle: {
              color: color.warningColor,
            }
          }
        ]
      }
    );

    const mergedConsolidatedOptions = cloneDeep(consolidatedGraphOptions);
    mergedConsolidatedOptions.chartOptions.xAxis.data = optionsItems.titles;
    mergedConsolidatedOptions.chartOptions.series = optionsItems.series;
    setConsolidatedGraphOptions(mergedConsolidatedOptions);
  };

  useEffect(() => {
    updateConsolidatedGraphOptions();
  }, [data]);

  return (
    <>
      <TableChart
        showTable={false}
        chartOptions={consolidatedGraphOptions.chartOptions}
        title={
          title
            ? {
              value: title,
              styles: {
                fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
                paddingLeft: sizeMode === 'big' ? 15 : 0,
              },
            }
            : consolidatedGraphOptions.title
        }
        height={graphHeight}
        loading={loading}
        titleLoading={!loading && graphLoading}
      />
    </>
  );
};

export default ProfitConsolidatedDiffChart;
