export const defaultTheme: any = {
  accenture: {
    color: {
      dark: {
        theme: "dark",
        layoutBackground: "#1A4F99",
        primaryColor: "#1A4F99",
        secondaryColor: "#3299D9",
        tertiaryColor: "#9FD9F6",
        errorColor: "#DD6B66",
        warningColor: "#FAD860",
        successColor: "#73A373",
        infoColor: "#6699ff",
      },
      light: {
        theme: "light",
        layoutBackground: "#ffffff",
        primaryColor: "#1A4F99",
        secondaryColor: "#3299D9",
        tertiaryColor: "#9FD9F6",
        errorColor: "#DD6B66",
        warningColor: "#FAD860",
        successColor: "#73A373",
        infoColor: "#6699ff",
      },
    },
    configuration: {
      theme: "dark",
      logoUrl: "/assets/EffiMate.png",
      faviconUrl: "https://www.toyo-eng.com/jp/ja/img/common/logo_sp.png",
      pageTitle: "",
      userAvatar: "",
      footerText: "",
    },
  },
};
