import { Row, Col, Flex, Descriptions, Table, List, Typography } from 'antd';
import { DescriptionsItemType } from 'antd/lib/descriptions';
import { useContext, useEffect, useState } from 'react';
import APIList from '../http/ApiList';
import { AipcmctyContext } from '../contexts/aipcmcty.context';
import { Loading } from '@meteor/frontend-core';
import { round } from 'lodash';
import { getMonthString } from '../utils/DateUtil';

type ProjectInformationProp = {
  base: any;
  offset?: number;
  projectId: string;
  compSnapshot?: string;
  compSnapshotVersion: string;
};

const ProjectInformation: React.FC<any> = (props: ProjectInformationProp) => {
  const { base, offset = 0, projectId, compSnapshot, compSnapshotVersion } = props;

  const { snapshot, snapshotVersion } = useContext(AipcmctyContext);

  const [desc1, setDesc1] = useState([]);
  const [desc2, setDesc2] = useState([]);
  const [desc3, setDesc3] = useState([]);
  const [desc4, setDesc4] = useState([]);
  const [desc5, setDesc5] = useState([]);
  const [desc6, setDesc6] = useState([]);
  const [desc7, setDesc7] = useState([]);

  const [edges] = useState([
    'desc1-bottomPotint->desc2-topPotint',
    'desc2-rightPotint->desc4-leftPotint',
    'desc3-bottomPotint->desc4-topPotint',
    'desc4-bottomPotint->desc5-topPotint',
    'desc5-bottomPotint->desc6-topPotint',
    'desc7-topPotint->desc6-bottomPotint',
  ]);

  const [loading, setLoading] = useState(true);

  const gapX = 20;
  const gapY = 15;
  const arrowSize = 6;

  const BREAK_REG = /(\r\n|\r|\n)/;

  const [descInfo, setDescInfo] = useState({
    layout: { width: 0, height: 0 },
    desc1: { width: 0, height: 0, position: [0, 0], topPotint: [0, 0], rightPotint: [0, 0], bottomPotint: [0, 0], leftPotint: [0, 0] },
    desc2: { width: 0, height: 0, position: [0, 0], topPotint: [0, 0], rightPotint: [0, 0], bottomPotint: [0, 0], leftPotint: [0, 0] },
    desc3: { width: 0, height: 0, position: [0, 0], topPotint: [0, 0], rightPotint: [0, 0], bottomPotint: [0, 0], leftPotint: [0, 0] },
    desc4: { width: 0, height: 0, position: [0, 0], topPotint: [0, 0], rightPotint: [0, 0], bottomPotint: [0, 0], leftPotint: [0, 0] },
    desc5: { width: 0, height: 0, position: [0, 0], topPotint: [0, 0], rightPotint: [0, 0], bottomPotint: [0, 0], leftPotint: [0, 0] },
    desc6: { width: 0, height: 0, position: [0, 0], topPotint: [0, 0], rightPotint: [0, 0], bottomPotint: [0, 0], leftPotint: [0, 0] },
    desc7: { width: 0, height: 0, position: [0, 0], topPotint: [0, 0], rightPotint: [0, 0], bottomPotint: [0, 0], leftPotint: [0, 0] },
    edgePaths: [],
  });

  const computePosition = () => {
    const layoutContainer = document.querySelector(base);
    const layout = {
      width: layoutContainer.clientWidth,
      height: layoutContainer.clientHeight - offset,
    };
    // const layout = {
    //   width: 1488,
    //   height: 671,
    // };
    const getDesc = (positionX, positionY, width, height, right = false) => {
      const spanX = layout.width / 24;
      const spanY = layout.height / 24;
      const x = positionX * spanX;
      const y = positionY * spanY;
      const w = width * spanX - (right ? 0 : gapX);
      const h = height * spanY - gapY;
      return {
        position: [x, y],
        width: w,
        height: h,
        topPotint: [w / 2 + x, 0 + y],
        rightPotint: [w + x, h / 2 + y],
        bottomPotint: [w / 2 + x, h + y],
        leftPotint: [0 + x, h / 2 + y],
      };
    };

    const desc1 = getDesc(0, 0, 7, 12);
    const desc2 = getDesc(0, 12, 7, 12);
    const desc3 = getDesc(7, 0, 17, 3, true);
    const desc4 = getDesc(7, 3, 17, 6, true);
    const desc5 = getDesc(7, 9, 17, 6, true);
    const desc6 = getDesc(7, 15, 17, 6, true);
    const desc7 = getDesc(7, 21, 17, 3, true);

    const descInfo = { layout, desc1, desc2, desc3, desc4, desc5, desc6, desc7, edgePaths: [] };

    const getNodePosition = (desc, nodePosition) => {
      return descInfo[desc][nodePosition];
    };

    const edgePaths = edges.map((edge) => {
      const [before, after] = edge.split('->');
      // d: desc  p: position  b: before  a: after  x: x  y: y
      const [db, pb] = before.split('-');
      const [da, pa] = after.split('-');
      const [xb, yb] = getNodePosition(db, pb);
      const [xa, ya] = getNodePosition(da, pa);
      if (xb === xa) {
        const path = `M ${xb},${yb} L ${xa},${ya - (ya > yb ? arrowSize : -arrowSize)}`;
        return path;
      }
      if (yb === ya) {
        const path = `M ${xb},${yb} L ${xa - (xa > xb ? arrowSize : -arrowSize)},${ya}`;
        return path;
      }
      if (xa > xb || xa < xb) {
        const middleX = (xa - xb) / 2;
        const path = `M ${xb},${yb} L ${xb + middleX},${yb} L ${xb + middleX},${ya} L ${xa - arrowSize},${ya}`;
        return path;
      }
      return `M ${xb},${yb} L ${xa},${ya}`;
    });
    descInfo.edgePaths = edgePaths;

    setDescInfo(descInfo);
  };

  useEffect(() => {
    computePosition();
  }, [base]);

  useEffect(() => {
    if (!projectId) {
      return;
    }
    setLoading(true);

    const condition: any = {
      projectId,
      snapshot,
      snapshotVersion,
    };
    if (compSnapshot && compSnapshotVersion) {
      condition.compSnapshot = compSnapshot;
      condition.compSnapshotVersion = compSnapshotVersion;
    }
    APIList.getProjectUpdatesMsg()
      .get(condition)
      .then((res) => {
        initData(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [projectId, snapshot, snapshotVersion, compSnapshot, compSnapshotVersion]);

  const initData = ({ project, msg, compare, compareSnapshot, compareVersion }) => {
    setDesc1([
      {
        key: '1.Project Outline',
        label: '1.Project Outline',
        children: (
          <Descriptions
            className="no-borderd"
            column={2}
            labelStyle={{
              width: 120,
            }}
            items={[
              {
                key: '1.1',
                label: 'Budget Category',
                children: project?.budgetCategoryCustom,
              },
              {
                key: '1.2',
                label: 'Order Award',
                children: project?.orderScheduledDate,
              },
              {
                key: '1.3',
                label: 'Current status',
                children: project?.phase,
                span: 2,
              },
              {
                key: '1.4',
                label: 'Country',
                children: project?.constructionLocation,
                span: 2,
              },
              {
                key: '1.5',
                label: 'Client',
                children: project?.clientName,
                span: 2,
              },
              {
                key: '1.6',
                label: 'Facility/Capacity',
                children: `${project?.goodsType || ''} / ${project?.capacity || ''}`,
                span: 2,
              },
              {
                key: '1.7',
                label: 'Scope',
                children: project?.scope,
                span: 2,
              },
              {
                key: '1.8',
                label: 'Partner',
                children: project?.structure,
                span: 2,
              },
            ]}
          />
        ),
      },
    ]);
    setDesc2([
      {
        key: '2.Project Performance',
        label: '2.Project Performance',
        children: (
          <Table
            bordered
            size="small"
            pagination={false}
            // scroll={{
            //   y: descInfo.desc1.height - 40 - 60,
            // }}
            columns={[
              {
                key: 'type',
                title: '',
                dataIndex: 'type',
                width: 160,
              },
              {
                key: compareSnapshot,
                title: getMonthString(compareSnapshot),
                dataIndex: 'compare',
                className: 'text-right',
              },
              {
                key: project.snapshot,
                title: getMonthString(project.snapshot),
                dataIndex: 'current',
                className: 'text-right',
              },
              {
                key: 'Difference',
                title: 'Difference',
                dataIndex: 'difference',
                className: 'text-right',
              },
            ]}
            dataSource={[
              {
                type: 'Contract Amount(百万)',
                compare: round((compare?.contractAmount || 0) / 1000000, 2),
                current: round((project.contractAmount || 0) / 1000000, 2),
                difference: round(((project.contractAmount || 0) - (compare?.contractAmount || 0)) / 1000000, 2),
              },
              {
                type: 'Gross Profit(百万)',
                compare: round((compare?.grossProfit || 0) / 1000000, 2),
                current: round((project.grossProfit || 0) / 1000000, 2),
                difference: round(((project.grossProfit || 0) - (compare?.grossProfit || 0)) / 1000000, 2),
              },
              {
                type: 'Gross Profit Ratio',
                compare: `${round((compare?.grossMargin || 0) * 100, 2)}%`,
                current: `${round((project.grossMargin || 0) * 100, 2)}%`,
                difference: `${round(((project.grossMargin || 0) - (compare?.grossMargin || 0)) * 100, 2)}%`,
              },
              {
                type: 'MD Unit Rate',
                compare: '',
                current: '',
                difference: '',
              },
              {
                type: 'Gross Profit for this FY',
                compare: '',
                current: '',
                difference: '',
              },
            ]}
          />
        ),
      },
    ]);
    setDesc3([
      {
        key: '3.Update until last meeting',
        label: '3.Update until last meeting',
        children: (
          <List
            split={false}
            size="small"
            dataSource={msg?.previousUpdate?.split(BREAK_REG)}
            renderItem={(item: string) => <List.Item style={{ padding: '0 16px', fontSize: 12, lineHeight: 1.25 }}>{item}</List.Item>}
            locale={{
              emptyText: 'データがありません',
            }}
          />
        ),
      },
    ]);
    setDesc4([
      {
        key: '4.New Update',
        label: '4.New Update',
        children: (
          <List
            split={false}
            size="small"
            dataSource={msg?.newUpdate?.split(BREAK_REG)}
            renderItem={(item: string) => <List.Item style={{ padding: '0 16px', fontSize: 12, lineHeight: 1.25 }}>{item}</List.Item>}
            locale={{
              emptyText: 'データがありません',
            }}
          />
        ),
      },
    ]);
    setDesc5([
      {
        key: '5.Issue to Award',
        label: '5.Issue to Award',
        children: (
          <List
            split={false}
            size="small"
            dataSource={msg?.awardIssue?.split(BREAK_REG)}
            renderItem={(item: string) => <List.Item style={{ padding: '0 16px', fontSize: 12, lineHeight: 1.25 }}>{item}</List.Item>}
            locale={{
              emptyText: 'データがありません',
            }}
          />
        ),
      },
    ]);
    setDesc6([
      {
        key: '6.Action',
        label: '6.Action',
        children: (
          <List
            split={false}
            size="small"
            dataSource={msg?.action?.split(BREAK_REG)}
            renderItem={(item: string) => <List.Item style={{ padding: '0 16px', fontSize: 12, lineHeight: 1.25 }}>{item}</List.Item>}
            locale={{
              emptyText: 'データがありません',
            }}
          />
        ),
      },
    ]);
    setDesc7([
      {
        key: '7.Orders/Directions by EC & RMC',
        label: '7.Orders/Directions by EC & RMC',
        children: <span style={{ margin: '0 16px', fontSize: 12, lineHeight: 1.25 }}>{msg?.remarks}</span>,
      },
    ]);
  };

  return (
    <>
      <div className="project-information" style={{ position: 'relative' }}>
        {!loading ? (
          <>
            <Descriptions
              className="deep-border"
              style={{
                position: 'absolute',
                left: descInfo.desc1.position[0],
                top: descInfo.desc1.position[1],
                width: descInfo.desc1.width,
                height: descInfo.desc1.height,
              }}
              size="small"
              labelStyle={{
                fontWeight: 'bolder',
                height: 40,
              }}
              contentStyle={{
                // header: 40 padding: 16
                display: 'block',
                height: descInfo.desc1.height - 40 - 16,
                padding: 0,
              }}
              colon={false}
              layout="vertical"
              bordered
              items={desc1}
            />
            <Descriptions
              className="deep-border"
              style={{
                position: 'absolute',
                left: descInfo.desc2.position[0],
                top: descInfo.desc2.position[1],
                width: descInfo.desc2.width,
                height: descInfo.desc2.height,
              }}
              size="small"
              labelStyle={{
                fontWeight: 'bolder',
                height: 40,
              }}
              contentStyle={{
                // header: 40 padding: 16
                display: 'block',
                height: descInfo.desc1.height - 40 - 16,
                padding: 0,
              }}
              colon={false}
              layout="vertical"
              bordered
              items={desc2}
            />
            <Descriptions
              className="deep-border"
              style={{
                position: 'absolute',
                left: descInfo.desc3.position[0],
                top: descInfo.desc3.position[1],
                width: descInfo.desc3.width,
                height: descInfo.desc3.height,
              }}
              size="small"
              labelStyle={{
                fontWeight: 'bolder',
                width: descInfo.desc3.width * 0.12,
              }}
              contentStyle={{
                height: descInfo.desc3.height,
                overflowY: 'auto',
                display: 'block',
                padding: 0,
              }}
              colon={false}
              bordered
              items={desc3}
            />
            <Descriptions
              className="deep-border"
              style={{
                position: 'absolute',
                left: descInfo.desc4.position[0],
                top: descInfo.desc4.position[1],
                width: descInfo.desc4.width,
                height: descInfo.desc4.height,
              }}
              size="small"
              labelStyle={{
                fontWeight: 'bolder',
                width: descInfo.desc4.width * 0.12,
              }}
              contentStyle={{
                height: descInfo.desc4.height,
                overflowY: 'auto',
                display: 'block',
                padding: 0,
              }}
              colon={false}
              bordered
              items={desc4}
            />
            <Descriptions
              className="deep-border"
              style={{
                position: 'absolute',
                left: descInfo.desc5.position[0],
                top: descInfo.desc5.position[1],
                width: descInfo.desc5.width,
                height: descInfo.desc5.height,
              }}
              size="small"
              labelStyle={{
                fontWeight: 'bolder',
                width: descInfo.desc5.width * 0.12,
              }}
              contentStyle={{
                height: descInfo.desc5.height,
                overflowY: 'auto',
                display: 'block',
                padding: 0,
              }}
              colon={false}
              bordered
              items={desc5}
            />
            <Descriptions
              className="deep-border"
              style={{
                position: 'absolute',
                left: descInfo.desc6.position[0],
                top: descInfo.desc6.position[1],
                width: descInfo.desc6.width,
                height: descInfo.desc6.height,
              }}
              size="small"
              labelStyle={{
                fontWeight: 'bolder',
                width: descInfo.desc6.width * 0.12,
              }}
              contentStyle={{
                height: descInfo.desc6.height,
                overflowY: 'auto',
                display: 'block',
                padding: 0,
              }}
              colon={false}
              bordered
              items={desc6}
            />
            <Descriptions
              className="deep-border descriptions-content-padding-0-16"
              style={{
                position: 'absolute',
                left: descInfo.desc7.position[0],
                top: descInfo.desc7.position[1],
                width: descInfo.desc7.width,
                height: descInfo.desc7.height,
              }}
              size="small"
              labelStyle={{
                fontWeight: 'bolder',
                height: 40,
              }}
              contentStyle={{
                // header: 40 padding: 16
                display: 'block',
                height: descInfo.desc7.height - 40,
                overflowY: 'auto',
                padding: 0,
              }}
              colon={false}
              layout="vertical"
              bordered
              items={desc7}
            />
            <svg width={descInfo.layout.width} height={descInfo.layout.height} xmlns="http://wwww.w3.org/2000/svg">
              <defs>
                <marker id="triangle" markerUnits="strokeWidth" markerWidth="3" markerHeight="8" refX="0" refY="4" orient="auto">
                  <path d="M 0 0 L 3 4 L 0 8 M 0 4 L 3 4" fill="none" stroke="#aaa" />
                </marker>
              </defs>
              {descInfo.edgePaths.map((path) => (
                <path d={path} stroke="#aaa" strokeWidth="2" fill="none" style={{ markerEnd: 'url(#triangle)' }} />
              ))}
            </svg>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default ProjectInformation;
