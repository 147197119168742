import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Card, Col, Radio, Row, Select, Space, Table } from 'antd';
import { groupBy, upperFirst } from 'lodash';
import APIList from '../../http/ApiList';
import useVersion from '../../hooks/useVersion';
import { useTranslation } from 'react-i18next';

type Option = {
  label: ReactNode;
  value: any;
  key?: any;
  [key: string]: any;
};

type GapAnalysisItem = {
  snapshot: string;
  snapshotVersion: string;
  fiscalYear: string;
  /** Scope Type */
  scope: string;
  /** DX効果 */
  dxInfluence: number;
  /** DX Allowance */
  dxAllowance: number;
  /** DX適用した受注金額 */
  dxAllowOrderAmount: number;
  /** 年度受注金額 */
  sbOrder: number;
  /** 案件への適用成功率 */
  dxApplyRate: number;
  /** 案件に対する粗利効果 */
  dxNetVsOdRate: number;
  /** 積込実行率 */
  dxLatestVsPreSalesRatio: number;
  /** DX課金 */
  dxCharge: number;
  /** DX課金した受注金額 */
  dxChargeOrderAmount: number;
  /** DX課金料率 */
  dxChargeRate: number;
  /** 投資額 */
  dxInvestment: number;
};

const baseDivider = 1000000;

const GapAnalysisPage: React.FC = () => {
  const { t } = useTranslation();
  const [pageInit, setPageInit] = useState(false);
  const {
    snapshot,
    snapshotVersion,
    compareVersion,
    setCompareVersion,
    versionOptCollection,
    snapshotList
  } = useVersion(pageInit);

  const [tableLoading, setTableLoading] = useState(false);
  const [filterOpts, setFilterOpts] = useState({
    fyOpts: [],
    tgcOpts: [],
  });
  const [filter, setFilter] = useState({
    fiscalYear: 'FY24',
    tarSnapshot: compareVersion.snapshot,
    tarVersion: compareVersion.snapshotVersion,
    tgc: [],
  });
  const [orgData, setOrgData] = useState<GapAnalysisItem[]>([]);
  const [tableType, setTableType] = useState(TABLE_CATEGORY.TREE);

  useEffect(() => {
    setFilter(prev => ({
      ...prev,
      tarSnapshot: compareVersion.snapshot,
      tarVersion: compareVersion.snapshotVersion,
    }));
  }, [compareVersion]);

  useEffect(() => {
    if (!pageInit && snapshot && snapshotVersion && compareVersion.snapshot && compareVersion.snapshotVersion) {
      initialize();
    }
  }, [compareVersion]);

  const getFiscalYearsOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'accountingYear',
      snapshot: snapshot,
      snapshotVersion: snapshotVersion,
    })) as Option[];
    const fyOpts = res.map((r) => ({
      key: r.value,
      label: r.value,
      value: r.value,
    }));
    setFilterOpts((prev) => ({
      ...prev,
      fyOpts,
    }));
    const selectedFy = fyOpts.find((f) => f.value === 'FY24');
    setFilter((prev) => ({ ...prev, fiscalYear: selectedFy.value }));
    return selectedFy;
  };

  const getTgcOptions = async () => {
    const res = (await APIList.getCmcOptions().get({
      category: 'tgc',
      snapshot,
      snapshotVersion,
    })) as Option[];
    const tgcOpts = res
      .filter((r) => r.attribute1 === 'subsidiary')
      .map((r) => ({
        key: r.value,
        label: r.value,
        value: r.value,
      }));
    setFilterOpts((prev) => ({
      ...prev,
      tgcOpts,
    }));
    const selectedTgc = tgcOpts.map((r) => r.value);
    setFilter((prev) => ({ ...prev, tgc: selectedTgc }));
    return selectedTgc;
  };

  const getTableData = async (
    tgc: string[],
    curSnapshot: string,
    curVersion: string,
    tarSnapshot: string,
    tarVersion: string,
    fiscalYear: string
  ) => {
    const data = (await APIList.getGapAnalysis().get({
      tgc,
      curSnapshot,
      curVersion,
      tarSnapshot,
      tarVersion,
      fiscalYear
    })) as GapAnalysisItem[];
    setOrgData(data);
  };

  const initialize = async () => {
    setTableLoading(true);
    const [, selectedTgc] = await Promise.all([getFiscalYearsOptions(), getTgcOptions()]);
    await getTableData(
      selectedTgc,
      snapshot,
      snapshotVersion,
      compareVersion.snapshot,
      compareVersion.snapshotVersion,
      filter.fiscalYear
    );
    setTableLoading(false);
    setPageInit(true);
  };

  const handleRadioChange = (radioType: TABLE_CATEGORY) => {
    setTableType(radioType);
  };

  const handleFilterChange = (e: string, key: string) => {
    setFilter(prev => ({
      ...prev,
      [key]: e
    }));
  };

  const handleCompareClick = async () => {
    setCompareVersion({
      snapshot: filter.tarSnapshot,
      snapshotVersion: filter.tarVersion,
    });
    setTableLoading(true);
    await getTableData(
      filter.tgc,
      snapshot,
      snapshotVersion,
      filter.tarSnapshot,
      filter.tarVersion,
      filter.fiscalYear
    );
    setTableLoading(false);
  };

  const keyMaps = {
    dxInfluence: t('aipcmcty.page.gapAnalyze.dxInfluence'),
    dxAllowance: t('aipcmcty.page.gapAnalyze.dxAllowance'),
    dxAllowOrderAmount: t('aipcmcty.page.gapAnalyze.dxAllowOrderAmount'),
    sbOrder: t('aipcmcty.page.gapAnalyze.sbOrder'),
    dxApplyRate: t('aipcmcty.page.gapAnalyze.dxApplyRate'),
    dxNetVsOdRate: t('aipcmcty.page.gapAnalyze.dxNetVsOdRate'),
    dxLatestVsPreSalesRatio: t('aipcmcty.page.gapAnalyze.dxLatestVsPreSalesRatio'),
    dxCharge: t('aipcmcty.page.gapAnalyze.dxCharge'),
    dxChargeOrderAmount: t('aipcmcty.page.gapAnalyze.dxChargeOrderAmount'),
    dxChargeRate: t('aipcmcty.page.gapAnalyze.dxChargeRate'),
    dxInvestment: t('aipcmcty.page.gapAnalyze.dxInvestment'),
  };

  const swapAxisToObject = (data: any[], baseKey: string) => {
    if (!data.length) {
      console.log('Data is Empty!');
      return [];
    }
    const transToNumberKeys = [
      'dxInfluence',
      'dxAllowance',
      'dxAllowOrderAmount',
      'sbOrder',
      'dxApplyRate',
      'dxNetVsOdRate',
      'dxLatestVsPreSalesRatio',
      'dxCharge',
      'dxChargeOrderAmount',
      'dxChargeRate',
      'dxInvestment',
    ];
    const transformKeys = Object.keys(data[0]).filter((k) => k !== baseKey);
    const transformedData = transformKeys.reduce((prev, k) => {
      prev[k] = {
        key: k,
        title: keyMaps[k],
      };
      return prev;
    }, {} as any);
    data.forEach((d) => {
      transformKeys.forEach((k) => {
        transformedData[k][d[baseKey]] = transToNumberKeys.includes(k) ? Number(d[k]) : d[k];
      });
    });
    return transformedData;
  };

  const genTreeDataAndCols = (data: GapAnalysisItem[]) => {
    const roiItems = data.filter((o) => o.scope === 'total');
    if (!roiItems.length) {
      return { data: [], cols: [] };
    }
    const treeData = swapAxisToObject(roiItems, 'snapshot');
    const dataSource = [
      {
        ...treeData.dxInfluence,
        children: [
          {
            ...treeData.dxAllowance,
            children: [
              {
                ...treeData.dxAllowOrderAmount,
                children: [
                  {
                    ...treeData.sbOrder,
                  },
                  {
                    ...treeData.dxApplyRate,
                  },
                ],
              },
              {
                ...treeData.dxNetVsOdRate,
              },
            ],
          },
          {
            ...treeData.dxLatestVsPreSalesRatio,
          },
          {
            ...treeData.dxCharge,
            children: [
              {
                ...treeData.dxChargeOrderAmount,
              },
              {
                ...treeData.dxChargeRate,
              },
            ],
          },
        ],
      },
      {
        ...treeData.dxInvestment,
      },
    ];
    const cols = [
      {
        title: t('aipcmcty.page.gapAnalyze.logicTree'),
        key: 'title',
        dataIndex: 'title',
        className: 'text-left',
        fixed: 'left',
      },
      {
        title: filter.tarSnapshot,
        key: filter.tarSnapshot,
        dataIndex: filter.tarSnapshot,
        className: 'text-right',
        render: (val, item) => {
          const isRate = ['dxApplyRate', 'dxNetVsOdRate', 'dxLatestVsPreSalesRatio', 'dxChargeRate'].includes(item.key);
          if (isRate) {
            return ![undefined, null].includes(val) ? `${(val * 100).toFixed(1)}%` : '-';
          }
          return ![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-';
        },
      },
      {
        title: snapshot,
        key: snapshot,
        dataIndex: snapshot,
        className: 'text-right',
        render: (val, item) => {
          const isRate = ['dxApplyRate', 'dxNetVsOdRate', 'dxLatestVsPreSalesRatio', 'dxChargeRate'].includes(item.key);
          if (isRate) {
            return ![undefined, null].includes(val) ? `${(val * 100).toFixed(1)}%` : '-';
          }
          return ![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-';
        },
      },
    ];
    return { data: dataSource, cols };
  };

  const genTableDataAndCols = (data: GapAnalysisItem[]) => {
    if (!data.length) {
      return { data: [], cols: [] };
    }
    const gListItems = groupBy(data, 'scope');
    const dataSource = Object.keys(gListItems).reduce((prev, cur) => {
      const tItem = gListItems[cur].find((g) => g.snapshot === filter.tarSnapshot);
      const cItem = gListItems[cur].find((g) => g.snapshot === snapshot);
      const item = {
        scope: cur,
        tarDxAllowance: tItem?.dxAllowance,
        curDxAllowance: cItem?.dxAllowance,
        tarDxAOrderAmount: tItem?.dxAllowOrderAmount,
        curDxAOrderAmount: cItem?.dxAllowOrderAmount,
        tarDxNetVsOdRate: tItem?.dxNetVsOdRate,
        curDxNetVsOdRate: cItem?.dxNetVsOdRate,
        tar: filter.tarSnapshot,
        cur: snapshot,
      };
      prev.push(item);
      return prev;
    }, []);
    const cols = [
      {
        title: '',
        key: 'scope',
        dataIndex: 'scope',
        className: 'text-right',
        fixed: 'left',
        render: (val) => upperFirst(val),
      },
      {
        title: t('aipcmcty.page.gapAnalyze.dxAllowance'),
        dataIndex: 'dxAllowance',
        className: 'text-center',
        children: [
          {
            title: filter.tarSnapshot,
            dataIndex: 'tarDxAllowance',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-'),
          },
          {
            title: snapshot,
            dataIndex: 'curDxAllowance',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-'),
          },
        ],
      },
      {
        title: t('aipcmcty.page.gapAnalyze.dxAllowOrderAmount'),
        dataIndex: 'dxAOrderAmount',
        className: 'text-right',
        children: [
          {
            title: filter.tarSnapshot,
            dataIndex: 'tarDxAOrderAmount',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-'),
          },
          {
            title: snapshot,
            dataIndex: 'curDxAOrderAmount',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? Number((val / baseDivider).toFixed(0)).toLocaleString() : '-'),
          },
        ],
      },
      {
        title: t('aipcmcty.page.gapAnalyze.dxNetVsOdRateDetail'),
        dataIndex: 'dxNetVsOdRate',
        className: 'text-right',
        children: [
          {
            title: filter.tarSnapshot,
            dataIndex: 'tarDxNetVsOdRate',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? `${Number(val * 100).toFixed(1)}%` : '-'),
          },
          {
            title: snapshot,
            dataIndex: 'curDxNetVsOdRate',
            className: 'text-right',
            render: (val) => (![undefined, null].includes(val) ? `${Number(val * 100).toFixed(1)}%` : '-'),
          },
        ],
      },
    ];
    return { data: dataSource, cols };
  };

  const render = (data: GapAnalysisItem[], tableType: TABLE_CATEGORY) => {
    let dataSource = [];
    let cols = [];
    if (tableType === TABLE_CATEGORY.TREE) {
      const res = genTreeDataAndCols(data);
      dataSource = res.data;
      cols = res.cols;
    }

    if (tableType === TABLE_CATEGORY.TABLE) {
      const res = genTableDataAndCols(data);
      dataSource = res.data;
      cols = res.cols;
    }

    return (
      <Table
        expandable={{
          defaultExpandedRowKeys: ['dxInfluence', 'dxAllowance', 'dxAllowOrderAmount', 'dxCharge'],
        }}
        style={{ background: '#FFF' }}
        scroll={{ y: window.innerHeight - 315 }}
        pagination={false}
        columns={cols}
        dataSource={dataSource}
        loading={tableLoading}
        className="scroll-table"
        size={'small'}
        bordered
        rowKey="key"
      />
    );
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row
          justify="space-between"
          className="operation-container"
          style={{ backgroundColor: 'white', padding: '0 16px', marginBottom: 2 }}
        >
          <Col style={{ padding: '5px 0' }}>
            <Space>
              {t('aipcmcty.page.gapAnalyze.fiscalYear')}:
              <Select
                style={{ width: 120 }}
                options={filterOpts.fyOpts}
                value={filter.fiscalYear}
                onChange={e => handleFilterChange(e, 'fiscalYear')}
              />
              {t('aipcmcty.page.gapAnalyze.compareSnapshot')}:
              <Select
                style={{ width: 180 }}
                options={snapshotList}
                value={filter.tarSnapshot}
                onChange={e => handleFilterChange(e, 'tarSnapshot')}
              />
              {t('aipcmcty.page.gapAnalyze.compareVersion')}:
              <Select
                style={{ width: 180 }}
                options={versionOptCollection[filter.tarSnapshot]}
                value={filter.tarVersion}
                onChange={e => handleFilterChange(e, 'tarVersion')}
              />
              <Button
                type="primary"
                onClick={handleCompareClick}
              >
                {t('aipcmcty.page.gapAnalyze.compare')}
              </Button>
            </Space>
          </Col>
        </Row>
        <Card>
          <div style={{ paddingBottom: 30, marginBottom: 20 }}>
            <div style={{ marginRight: 20, float: 'right' }}>
            <span style={{ marginRight: 15 }}>{t('aipcmcty.page.gapAnalyze.unit')}</span>
              <Radio.Group buttonStyle="solid" onChange={(e) => handleRadioChange(e.target.value)} value={tableType}>
                <Radio.Button value={TABLE_CATEGORY.TREE}>DX-ROI</Radio.Button>
                <Radio.Button value={TABLE_CATEGORY.TABLE}>DX-DETAIL</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          {render(orgData, tableType)}
        </Card>
      </div>
    </>
  );
};

enum TABLE_CATEGORY {
  TREE,
  TABLE,
}

export default GapAnalysisPage;
