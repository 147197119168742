import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Checkbox, Popover, Tag, Tooltip } from 'antd';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import StrategyMatrixChart from '../../components/charts/strategy-matrix.chart';
import OrderAmountChart from '../../components/charts/order-amount.chart';
import TurnoverChart from '../../components/charts/turnover.chart';
import GrossProfitCompositionChart from '../../components/charts/gross-profit-composition.chart';
import GrossProfitMargin from '../../components/charts/gross-profit-margin.chart';
import GrossProfitPerMhChart from '../../components/charts/gross-profit-per-mh.chart';
import HumanResourcesTurnoverChart from '../../components/charts/human-resources-turnover.chart';
import WonPercentChart from '../../components/charts/won-percent.chart';
import MatchingRateByYear from '../../components/charts/matching-rate-by-year.chart';
import LoadingPage from '../../components/common/load-page';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import CompareChart from '../../components/charts/compare.chart';

const KpiTreePage: React.FC = () => {
  const { t } = useTranslation();
  const pathRef = useRef(null);
  const { fiscalQuarter, periodSwitch, color, locale } = useContext(AppContext);
  const { kpiInfos, initKpiData, getKpiData, snapshot, snapshotVersion } = useContext(AipcmctyContext);
  const [pathSize, setPathSize] = useState({ width: 0, height: 0 });
  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(false);
  const [disableDrag, setDisableDrag] = useState(true);
  // const [chartHeight, setChartHeight] = useState(0);

  const startPos = 30;
  const startTop = 50;
  const chartHeightOffset = 60;
  const chartWidthOffset = 10;
  const bigChartHeight = 400;
  const bigChartWidth = 500;
  const chartHeight = 240;
  const chartWidth = 280;
  const minChartHeight = 25;

  const levelY = {
    0: startTop,
    1: startTop + chartHeightOffset + bigChartHeight / 2,
    2: startTop + chartHeightOffset + bigChartHeight + chartHeightOffset + minChartHeight / 2,
    3: startTop + chartHeightOffset + bigChartHeight + chartHeightOffset + minChartHeight + chartHeightOffset + chartHeight / 2,
  };

  const [nodeData, setNodeData] = useState<any>(null);

  const customChartGroupTitle = (
    groupName: string,
    chartName: any,
    groupColor: string,
    ribbonWidth = {
      en: 135,
      ja: 120,
    }
  ) => (
    <div
      style={{
        width: '100%',
        display: 'flex',
      }}
      key={chartName}
    >
      <Tag color={groupColor} style={{ height: 22 }}>
        {groupName}
      </Tag>
      <div style={{ fontSize: 14, width: `calc(100% - ${ribbonWidth[locale.locale]}px)` }}>
        {chartName}
      </div>
    </div>
  );

  const isAllChildrenCollapsed = (node) => {
    let collapsed = true;
    nodeData.edges.forEach((edge) => {
      if (edge.source === node.id) {
        const i = nodeData.nodes.findIndex((n) => edge.target === n.id);
        if (i > -1 && !nodeData.nodes[i].collapsed) {
          collapsed = false;
        }
      }
    });
    return collapsed;
  };

  const findChildren = (node, data) => {
    const children = [];
    data.edges.forEach((edge) => {
      if (edge.source === node.id) {
        const i = data.nodes.findIndex((n) => edge.target === n.id);
        if (i > -1) {
          children.push(data.nodes[i]);
        }
      }
    });
    return children;
  };

  const findParent = (node, data) => {
    let parent = null;
    data.edges.forEach((edge) => {
      if (edge.target === node.id) {
        const i = data.nodes.findIndex((n) => edge.source === n.id);
        if (i > -1) {
          parent = data.nodes[i];
        }
      }
    });
    return parent;
  };

  const setChildrenParentCollapsed = (item: any, collapsed: boolean) => {
    const children = findChildren(item, nodeData);
    if (children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const index = nodeData.nodes.findIndex((node) => child.id === node.id);
        if (index > -1) {
          nodeData.nodes[index].parentCollapsed = collapsed;
          // setChildrenParentCollapsed(child, collapsed);
        }
      }
    }
  };

  const setNodePosition = (data) => {
    for (let i = 0; i < data.nodes.length; i++) {
      let position = 0;
      switch (data.nodes[i].level) {
        case 0:
          {
            const level01 = data.nodes.filter((node) => node.level === 1 && !node.collapsed && !node.parentCollapsed);
            const level02 = data.nodes.filter((node) => node.level === 2 && !node.parentCollapsed);
            const level03 = data.nodes.filter((node) => node.level === 3 && !node.collapsed && !node.parentCollapsed);
            let max0 = Math.max(level01.length, level02.length, level03.length);
            // 占位 最少2个
            if (max0 < 2) {
              max0 = 2;
            }
            if (max0 % 2 === 0) {
              position = startPos + (chartWidth + chartWidthOffset) * (max0 / 2) + chartWidthOffset / 2;
            } else {
              position = startPos + (chartWidth + chartWidthOffset) * (Math.ceil(max0 / 2) - 1) + chartWidth / 2;
            }
          }
          break;
        case 1:
          {
            let index1 = 0;
            // 找出所有同层的节点
            const allLevel1 = data.nodes.filter((node) => node.level === 1 && !node.collapsed && !node.parentCollapsed);
            // 找出该节点在同层中的位置
            const index11 = allLevel1.findIndex((item) => item.id === data.nodes[i].id);
            // 计算同层中，该节点之前位置节点的子层中节点的个数 并得出偏移位置
            for (let k = 0; k < index11; k++) {
              const level23 = findChildren(allLevel1[k], data).filter((node) => !node.parentCollapsed);
              // 占位最少2个
              if (level23.length === 0) {
                index1 += 2;
              } else {
                let index2 = 0;
                let level13 = [];
                // 找出所有子节点的子节点
                for (let index = 0; index < level23.length; index++) {
                  const element = level23[index];
                  const item = findChildren(element, data).filter((node) => !node.collapsed && !node.parentCollapsed);
                  level13 = level13.concat(item);
                }
                if (level13.length > 2) {
                  index2 += level13.length;
                } else {
                  index2 += 2;
                }
                index1 += index2;
              }
            }
            // 找出该节点的所有子节点
            const level12 = findChildren(data.nodes[i], data).filter((node) => !node.collapsed && !node.parentCollapsed);
            let level13 = [];
            // 找出所有子节点的子节点
            for (let index = 0; index < level12.length; index++) {
              const element = level12[index];
              const item = findChildren(element, data).filter((node) => !node.collapsed && !node.parentCollapsed);
              level13 = level13.concat(item);
            }
            let max1 = 0;

            if (level12.length > level13.length) {
              max1 = level12.length;
            } else {
              max1 = level13.length;
            }

            if (max1 < 2) {
              max1 = 2;
            }

            if (max1 % 2 === 0) {
              position = startPos + (chartWidth + chartWidthOffset) * (max1 / 2 + index1) + chartWidthOffset / 2;
            } else {
              position = startPos + (chartWidth + chartWidthOffset) * (Math.ceil(max1 / 2) + index1 - 1) + chartWidth / 2;
            }
          }
          break;
        case 2:
          {
            let index2 = 0;

            // 找出该节点在同层中的位置
            const allLevel22 = data.nodes.filter((node) => node.level === 2 && !node.parentCollapsed);
            const index22 = allLevel22.findIndex((item) => item.id === data.nodes[i].id);
            // 计算同层中，该节点之前位置节点的子层中节点的个数 并得出偏移位置
            for (let k = 0; k < index22; k++) {
              const level23 = findChildren(allLevel22[k], data).filter((node) => !node.collapsed && !node.parentCollapsed);
              if (level23.length === 0) {
                index2 += 1;
              } else {
                index2 += level23.length;
              }
            }

            // 查询该节点下子节点个数
            const level23 = findChildren(data.nodes[i], data).filter((node) => !node.collapsed && !node.parentCollapsed);

            // 没有打开的子层时要占位
            const max2 = level23.length === 0 ? 1 : level23.length;

            if (max2 % 2 === 0) {
              position = startPos + (chartWidth + chartWidthOffset) * (max2 / 2 + index2) + chartWidthOffset / 2;
            } else {
              position = startPos + (chartWidth + chartWidthOffset) * (Math.ceil(max2 / 2) + index2 - 1) + chartWidth / 2;
            }
          }
          break;
        case 3:
          {
            let index3 = 0;
            // 找到父节点
            const parent = findParent(data.nodes[i], data);
            // 找出父节点在同层中的位置
            const allLevel32 = data.nodes.filter((node) => node.level === 2 && !node.parentCollapsed);
            const index32 = allLevel32.findIndex((item) => item.id === parent.id);
            // 计算同层中，父节点之前位置节点的子层中节点的个数 并得出偏移位置
            for (let k = 0; k < index32; k++) {
              const level33 = findChildren(allLevel32[k], data).filter((node) => !node.collapsed && !node.parentCollapsed);
              // 没有打开的子层时要占位
              if (level33.length === 0) {
                index3 += 1;
              } else {
                index3 += level33.length;
              }
            }

            // 查询父节点下子节点
            const level33 = findChildren(parent, data).filter((node) => !node.collapsed && !node.parentCollapsed);
            const index33 = level33.findIndex((item) => item.id === data.nodes[i].id);

            position = startPos + (chartWidth + chartWidthOffset) * (index3 + index33) + chartWidth / 2;
          }
          break;
        default:
          break;
      }
      data.nodes[i].x = position;
    }
    return data;
  };

  const content = (list) => (
    <>
      {list && list.length > 0 ? (
        list.map((item) => (
          <div key={item.id}>
            <Checkbox
              style={{ marginRight: 6 }}
              checked={!item.collapsed}
              onChange={() => {
                const index = nodeData.nodes.findIndex((node) => item.id === node.id);
                const collapsed = !item.collapsed;
                if (index) {
                  nodeData.nodes[index].collapsed = collapsed;
                  setChildrenParentCollapsed(item, collapsed);
                }
                const data = setNodePosition(nodeData);
                // setScale(pathSize.width / (data.nodes[0].x * 2));
                setNodeData({ ...data });
              }}
            >
              {item.label}
            </Checkbox>
          </div>
        ))
      ) : (
        <></>
      )}
    </>
  );

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      if (pathRef && pathRef.current) {
        setPathSize({
          width: pathRef.current.clientWidth,
          height: document.body.clientHeight - 100,
        });
      }
    });
  }

  useEffect(() => {
    if (pathRef && pathRef.current) {
      setPathSize({
        width: pathRef.current.clientWidth,
        height: document.body.clientHeight - 100,
      });
    }
  }, []);

  useEffect(() => {
    const nodes: any[] = [
      {
        id: '1',
        label: t('aipcmcty.page.highProductivityPortfolio'),
        y: levelY[0],
        width: chartWidth,
        height: minChartHeight,
        level: 0,
        collapsed: false,
      },
      {
        id: '11',
        label: t('aipcmcty.page.valueCreation'),
        y: levelY[1],
        width: bigChartWidth,
        height: bigChartHeight,
        level: 1,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          <StrategyMatrixChart
            kpiCode="KPJ001"
            data={getKpiData('KPJ001').data}
            loading={getKpiData('KPJ001').loading}
            layoutOptions={{
              xLabels: [
                [t('aipcmcty.page.grossProfitMargin')],
                [t('aipcmcty.page.lessThan8Percent'), '8%〜12%', t('aipcmcty.page.moreThan12Percent')],
              ],
              yLabels: [
                [{ vertical: true, label: t('aipcmcty.page.growth'), symmetry: locale.locale !== 'ja', initial: true }],
                [
                  { vertical: true, label: t('aipcmcty.page.new'), symmetry: locale.locale !== 'ja', initial: true },
                  { vertical: true, label: t('aipcmcty.page.new'), symmetry: locale.locale !== 'ja', initial: true },
                  { vertical: true, label: '', symmetry: locale.locale !== 'ja', initial: true },
                ],
                [
                  { vertical: true, label: `（${t('aipcmcty.page.innovation')}）`, symmetry: locale.locale !== 'ja', initial: true },
                  { vertical: true, label: `（${t('aipcmcty.page.adjacentArea')}）`, symmetry: locale.locale !== 'ja', initial: true },
                  { vertical: true, label: t('aipcmcty.page.existing'), symmetry: locale.locale !== 'ja', initial: true },
                ],
              ],
              gridTop: 35,
              xHeight: 35,
              chartTop: 25,
            }}
            title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.valueCreation'), color.successColor)}
            ribbonText={t('aipcmcty.page.overall')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={bigChartHeight - 15}
          />
        ),
      },
      {
        id: '12',
        label: t('aipcmcty.page.resourceCapability'),
        y: levelY[1],
        width: bigChartWidth,
        height: bigChartHeight,
        level: 1,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          <StrategyMatrixChart
            kpiCode="KRS001"
            data={getKpiData('KRS001').data}
            loading={getKpiData('KRS001').loading}
            layoutOptions={{
              xLabels: [
                [t('aipcmcty.page.profitPerPersonAmount')],
                [t('aipcmcty.page.lessThan0Yen'), t('aipcmcty.page.0To150MillionYen'), t('aipcmcty.page.over150MillionYen')],
              ],
              yLabels: [
                [{ vertical: true, label: t('aipcmcty.page.growth'), symmetry: locale.locale !== 'ja', initial: true }],
                [
                  { vertical: true, label: t('aipcmcty.page.new'), symmetry: locale.locale !== 'ja', initial: true },
                  { vertical: true, label: t('aipcmcty.page.new'), symmetry: locale.locale !== 'ja', initial: true },
                  { vertical: true, label: '', symmetry: true, initial: true },
                ],
                [
                  { vertical: true, label: `（${t('aipcmcty.page.innovation')}）`, symmetry: locale.locale !== 'ja', initial: true },
                  { vertical: true, label: `（${t('aipcmcty.page.adjacentArea')}）`, symmetry: locale.locale !== 'ja', initial: true },
                  { vertical: true, label: t('aipcmcty.page.existing'), symmetry: locale.locale !== 'ja', initial: true },
                ],
              ],
              gridTop: 35,
              xHeight: 35,
              chartTop: 25,
            }}
            title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.resourceCapability'), color.warningColor, {
              ja: 135,
              en: 135,
            })}
            ribbonText={t('aipcmcty.page.overall')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={bigChartHeight - 15}
          />
        ),
      },
      // {
      //   id: '13',
      //   label: '戦略マトリックス(DX適用)',
      //   y: levelY[1],
      //   width: bigChartWidth,
      //   height: bigChartHeight,
      //   level: 1,
      //   collapsed: false,
      //   parentCollapsed: false,
      //   dom: (
      //     <StrategyMatrixChart
      //       kpiCode="KDX001"
      //       data={getKpiData('KDX001').data}
      //       loading={getKpiData('KDX001').loading}
      //       layoutOptions={{
      //         xLabels: [
      //           ['DX Allowance割引率'],
      //           ['50%以上', '50%未満〜0%以上', '0%'],
      //         ],
      //         yLabels: [
      //           ['成長性'],
      //           ['新規', '新規', ''],
      //           ['（革新）', '（隣接・周辺）', '既存'],
      //         ],
      //         gridTop: 20,
      //         xHeight: 65,
      //       }}
      //       title={customChartGroupTitle(
      //         'DX適用',
      //         '戦略マトリックス(DX適用)',
      //         color.errorColor
      //       )}
      //       ribbonText="全体"
      //       ribbonColor={color.primaryColor}
      //       ribbonTop={0}
      //       height={bigChartHeight - 15}
      //     />
      //   ),
      // },
      {
        id: '13',
        label: t('aipcmcty.page.valueDelivery'),
        y: levelY[1],
        width: bigChartWidth,
        height: bigChartHeight,
        level: 1,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          <StrategyMatrixChart
            kpiCode="ValueProposition"
            data={getKpiData('ValueProposition').data}
            loading={getKpiData('ValueProposition').loading}
            // layoutOptions={{
            //   xLabels: [
            //     ['1人あたり受注額'],
            //     ['46百万円未満', '46百万円〜76百万円', '76百万円以上'],
            //   ],
            //   yLabels: [
            //     ['粗利率（収益性）'],
            //     ['', '', ''],
            //     [{
            //       label: <>
            //         <div style={{ display: 'flex', flexDirection: 'column', writingMode: 'horizontal-tb' }}>
            //           <span>12%</span>
            //           <span>以上</span>
            //         </div>
            //              </>
            //     }, {
            //       label: <>
            //         <div style={{ display: 'flex', flexDirection: 'column', writingMode: 'horizontal-tb' }}>
            //           <span>8%</span>
            //           <span>〜</span>
            //           <span>12%</span>
            //         </div>
            //       </>,
            //     }, {
            //       label: <>
            //         <div style={{ display: 'flex', flexDirection: 'column', writingMode: 'horizontal-tb' }}>
            //           <span>8%</span>
            //           <span>未満</span>
            //         </div>
            //       </>
            //     }],
            //   ],
            //   gridTop: 25,
            //   xHeight: 35,
            //   chartTop: 25,
            // }}
            title={
              <span
                key={t('aipcmcty.page.valueDelivery')}
                style={{
                  position: 'relative',
                  width: '100%',
                  display: 'inline-block',
                  top: -5,
                }}
              >
                <Tag color={color.successColor} style={{ left: 0, top: 0, position: 'absolute' }}>
                  {t('aipcmcty.page.project')}
                </Tag>
                <Tag color={color.warningColor} style={{ left: locale.locale === 'ja' ? 40 : 37, top: 0, position: 'absolute' }}>
                  {t('aipcmcty.page.resource')}
                </Tag>
                <div style={{ textAlign: 'center', fontSize: 14 }}>{t('aipcmcty.page.valueDelivery')}</div>
              </span>
            }
            ribbonText={t('aipcmcty.page.overall')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={bigChartHeight - 15}
          />
        ),
      },
      {
        id: '111',
        label: t('aipcmcty.page.projectQuantity'),
        y: levelY[2],
        width: chartWidth,
        height: minChartHeight,
        level: 2,
        collapsed: false,
        parentCollapsed: false,
      },
      {
        id: '112',
        label: t('aipcmcty.page.projectQuality'),
        y: levelY[2],
        width: chartWidth,
        height: minChartHeight,
        level: 2,
        collapsed: false,
        parentCollapsed: false,
      },
      {
        id: '121',
        label: t('aipcmcty.page.resourceQuantity'),
        y: levelY[2],
        width: chartWidth,
        height: minChartHeight,
        level: 2,
        collapsed: false,
        parentCollapsed: false,
      },
      {
        id: '122',
        label: t('aipcmcty.page.resourceQuality'),
        y: levelY[2],
        width: chartWidth,
        height: minChartHeight,
        level: 2,
        collapsed: false,
        parentCollapsed: false,
      },
      {
        id: '131',
        label: t('aipcmcty.page.DXapplicationQuantity'),
        y: levelY[2],
        width: chartWidth,
        height: minChartHeight,
        level: 2,
        collapsed: false,
        parentCollapsed: false,
      },
      {
        id: '132',
        label: t('aipcmcty.page.dxApplicableQuality'),
        y: levelY[2],
        width: chartWidth,
        height: minChartHeight,
        level: 2,
        collapsed: false,
        parentCollapsed: false,
      },
      {
        id: '1110',
        label: t('aipcmcty.page.newBusinessMaturity'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          <OrderAmountChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ002"
            loading={getKpiData('KPJ002').loading}
            data={null}
            title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.newBusinessMaturity'), color.successColor)}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1111',
        label: t('aipcmcty.page.orderAmount'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <OrderAmountChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ002"
            data={getKpiData('KPJ002').data}
            loading={getKpiData('KPJ002').loading}
            title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.orderAmount'), color.successColor)}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={60}
          />
        ),
      },
      {
        id: '1112',
        label: t('aipcmcty.page.sales'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <TurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ003"
            data={getKpiData('KPJ003').data}
            loading={getKpiData('KPJ003').loading}
            title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.sales'), color.successColor)}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={60}
          />
        ),
      },
      // {
      //   id: '1113',
      //   label: '粗利額(期待値)',
      //   y: levelY[3],
      //   width: chartWidth,
      //   height: chartHeight,
      //   level: 3,
      //   collapsed: true,
      //   parentCollapsed: false,
      //   dom: (
      //      <TurnoverChart periodSwitch={periodSwitch}
      // fiscalQuarter={fiscalQuarter}
      //       showTable={false}
      //       kpiCode="KPJ004"
      //       data={getKpiData('KPJ004').data} loading={getKpiData('KPJ004').loading}
      //       title={customChartGroupTitle(
      //         t('aipcmcty.page.project'),
      //         '粗利額(期待値)',
      //         color.successColor
      //       )}
      //       ribbonText={t('aipcmcty.page.amount')}
      //       ribbonColor={color.primaryColor}
      //       ribbonTop={0}
      //       height={chartHeight}
      //     />
      //   ),
      // },
      {
        id: '1114',
        label:
          locale.locale === 'ja'
            ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.nonEPC')}/EPC)`
            : `${t('aipcmcty.page.nonEPC')}/EPC - ${t('aipcmcty.page.grossProfitComposition')}`,
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <GrossProfitCompositionChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ005"
            data={getKpiData('KPJ005').data}
            loading={getKpiData('KPJ005').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.project'),
              locale.locale === 'ja'
                ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.nonEPC')}/EPC)`
                : `${t('aipcmcty.page.nonEPC')}/EPC - ${t('aipcmcty.page.grossProfitComposition')}`,
              color.successColor
            )}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={60}
          />
        ),
      },
      {
        id: '1115',
        label:
          locale.locale === 'ja'
            ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')})`
            : `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existingBusiness')} - ${t('aipcmcty.page.grossProfitComposition')}`,
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <GrossProfitCompositionChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ006"
            data={getKpiData('KPJ006').data}
            loading={getKpiData('KPJ006').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.project'),
              locale.locale === 'ja'
                ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.new')}/${t('aipcmcty.page.existing')})`
                : `${t('aipcmcty.page.new')}/${t('aipcmcty.page.existingBusiness')} - ${t('aipcmcty.page.grossProfitComposition')}`,
              color.successColor
            )}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={60}
          />
        ),
      },
      {
        id: '1116',
        label:
          locale.locale === 'ja'
            ? `${t('aipcmcty.page.grossProfitComposition')}(Japan/${t('aipcmcty.page.others')})`
            : `Japan/${t('aipcmcty.page.others')} - ${t('aipcmcty.page.grossProfitComposition')}`,
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <GrossProfitCompositionChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ007"
            data={getKpiData('KPJ007').data}
            loading={getKpiData('KPJ007').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.project'),
              locale.locale === 'ja'
                ? `${t('aipcmcty.page.grossProfitComposition')}(Japan/${t('aipcmcty.page.others')})`
                : `Japan/${t('aipcmcty.page.others')} - ${t('aipcmcty.page.grossProfitComposition')}`,
              color.successColor
            )}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={60}
          />
        ),
      },
      {
        id: '1117',
        label:
          locale.locale === 'ja'
            ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.collaboration')}/${t('aipcmcty.page.own')}/${t(
                'aipcmcty.page.JV'
              )})`
            : `${t('aipcmcty.page.collaboration')}/${t('aipcmcty.page.own')}/${t('aipcmcty.page.JV')} - ${t(
                'aipcmcty.page.grossProfitComposition'
              )}`,
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <GrossProfitCompositionChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ008"
            data={getKpiData('KPJ008').data}
            loading={getKpiData('KPJ008').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.project'),
              locale.locale === 'ja'
                ? `${t('aipcmcty.page.grossProfitComposition')}(${t('aipcmcty.page.collaboration')}/${t('aipcmcty.page.own')}/${t(
                    'aipcmcty.page.JV'
                  )})`
                : `${t('aipcmcty.page.collaboration')}/${t('aipcmcty.page.own')}/${t('aipcmcty.page.JV')} - ${t(
                    'aipcmcty.page.grossProfitComposition'
                  )}`,
              color.successColor
            )}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={60}
          />
        ),
      },
      {
        id: '1121',
        label: `${t('aipcmcty.page.consolidatedGrossProfit')}(${t('aipcmcty.page.annualRebound')})`,
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          <TurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KMT002"
            data={getKpiData('KMT002').data}
            loading={getKpiData('KMT002').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.midtermKgi'),
              <span style={{ fontSize: 12 }}>{`${t('aipcmcty.page.consolidatedGrossProfit')}(${t('aipcmcty.page.annualRebound')})`}</span>,
              color.primaryColor,
              { en: 155, ja: 120 }
            )}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            barWidth={60}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1122',
        label: t('aipcmcty.page.grossProfitAndRate'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <GrossProfitMargin
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ011"
            data={getKpiData('KPJ011').data}
            loading={getKpiData('KPJ011').loading}
            title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.grossProfitAndRate'), color.successColor)}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            barWidth={80}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1123',
        label: t('aipcmcty.page.grossProfitPerMH'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <GrossProfitPerMhChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KPJ012"
            data={getKpiData('KPJ012').data}
            loading={getKpiData('KPJ012').loading}
            title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.grossProfitPerMH'), color.successColor)}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1124',
        label: t('aipcmcty.page.NPVgrossProfitRate'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <HumanResourcesTurnoverChart
            showTable={false}
            kpiCode="KPJ013"
            data={getKpiData('KPJ013').data}
            loading={getKpiData('KPJ013').loading}
            title={customChartGroupTitle(t('aipcmcty.page.project'), t('aipcmcty.page.NPVgrossProfitRate'), color.successColor)}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1125',
        label: 'Won％',
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <WonPercentChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            kpiCode="KPJ014"
            data={getKpiData('KPJ014').data}
            loading={getKpiData('KPJ014').loading}
            title={customChartGroupTitle(t('aipcmcty.page.project'), 'Won%', color.successColor)}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight + 1}
          />
        ),
      },
      // {
      //   id: '1125',
      //   label: '戦略案件比率',
      //   y: levelY[3],
      //   width: chartWidth,
      //   height: chartHeight,
      //   level: 3,
      //   collapsed: true,
      //   parentCollapsed: false,
      //   dom: (
      //     <div style={{ paddingBottom: 20 }}>
      //       <CasesPercentageChart
      //         layoutOptions={{
      //           xLabels: [['戦略性'], ['継続性あり', '将来展開'], ['既存', '既存', '既存', '新規/将来性']],
      //           yLabels: [['顾客関係'], [{ label: '推荐', vertical: false }, { label: '良好', vertical: false }, { label: '普通', vertical: false }, { label: '一般', vertical: false }]],
      //         }}
      //         title={customChartGroupTitle(t('aipcmcty.page.project'), '戦略案件比率', color.successColor)}
      //         ribbonText={t('aipcmcty.page.quality')}
      //         ribbonColor={color.primaryColor}
      //         ribbonTop={0}
      //         height={chartHeight - 19}
      //       />
      //     </div>
      //   ),
      // },
      {
        id: '1211',
        label: t('aipcmcty.page.ESMHutilizationRate'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <HumanResourcesTurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KRS002"
            data={getKpiData('KRS002').data}
            loading={getKpiData('KRS002').loading}
            title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.ESMHutilizationRate'), color.warningColor, {
              ja: 135,
              en: 135,
            })}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1212',
        label: t('aipcmcty.page.resourceMatchingRate'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          <MatchingRateByYear
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="MatchingRateByYear"
            data={getKpiData('MatchingRateByYear').data}
            loading={getKpiData('MatchingRateByYear').loading}
            title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.resourceMatchingRate'), color.warningColor, {
              ja: 135,
              en: 135,
            })}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={80}
          />
        ),
      },
      {
        id: '12121',
        label: t('aipcmcty.page.demandSupplyGapMH'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <HumanResourcesTurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KRS003"
            data={getKpiData('KRS003').data}
            loading={getKpiData('KRS003').loading}
            title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.demandSupplyGapMH'), color.warningColor, {
              ja: 135,
              en: 135,
            })}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1221',
        label: t('aipcmcty.page.profitPerPersonAmount'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          <TurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="OperatingIncomePerCapita"
            data={getKpiData('OperatingIncomePerCapita').data}
            loading={getKpiData('OperatingIncomePerCapita').loading}
            title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.profitPerPersonAmount'), color.warningColor, {
              ja: 135,
              en: 135,
            })}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={60}
          />
        ),
      },
      {
        id: '12211',
        label: t('aipcmcty.page.jobRate'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <HumanResourcesTurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KRS004"
            data={getKpiData('KRS004').data}
            loading={getKpiData('KRS004').loading}
            title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.jobRate'), color.warningColor, { ja: 135, en: 135 })}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1222',
        label: t('aipcmcty.page.talentTurnoverRate'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <HumanResourcesTurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KRS005"
            data={getKpiData('KRS005').data}
            loading={getKpiData('KRS005').loading}
            title={customChartGroupTitle(t('aipcmcty.page.resource'), t('aipcmcty.page.talentTurnoverRate'), color.warningColor, {
              ja: 135,
              en: 135,
            })}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1321',
        label: t('aipcmcty.page.DXapplicationRatio'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <HumanResourcesTurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KDX002"
            data={getKpiData('KDX002').data}
            loading={getKpiData('KDX002').loading}
            title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.DXapplicationRatio'), color.errorColor)}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
          />
        ),
      },
      {
        id: '1311',
        label: t('aipcmcty.page.dxAllowanceFullPot'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          // <TurnoverChart
          //   periodSwitch={periodSwitch}
          //   fiscalQuarter={fiscalQuarter}
          //   showTable={false}
          //   kpiCode="KDX003"
          //   data={getKpiData('KDX003').data}
          //   loading={getKpiData('KDX003').loading}
          //   title={customChartGroupTitle(
          //     'DX適用',
          //     'DX Allowanceフルポテ',
          //     color.errorColor
          //   )}
          //   ribbonText={t('aipcmcty.page.amount')}
          //   ribbonColor={color.primaryColor}
          //   ribbonTop={0}
          //   height={chartHeight}
          //   barWidth={80}
          // />
          <CompareChart
            // periodSwitch={periodSwitch}
            // fiscalQuarter={fiscalQuarter}
            showTable={false}
            // kpiCode="KDX003"
            data={{
              legends: [t('aipcmcty.page.trackRecord'), `${t('aipcmcty.page.forecast')}(${t('aipcmcty.page.fullPotato')})`],
              reality: [0, 27000000, 0, 0, 0],
              prediction: [0, 0, 1186975365, 7053316667, 5976145262],
            }}
            loading={getKpiData('KDX003').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.dxApplicable'),
              <>
                {t('aipcmcty.page.dxAllowanceFullPot')}
                <br />
                <div className="ellipsis" style={{ textAlign: 'center', width: 'calc(100% - 30px)' }}>{`(${t(
                  'aipcmcty.page.annualRebound'
                )})`}</div>
              </>,
              color.errorColor
            )}
            ribbonText={t('aipcmcty.page.amount')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            splitNumber={10}
          />
        ),
      },
      {
        id: '1322',
        label: t('aipcmcty.page.DXallowanceDiscountRate'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: false,
        parentCollapsed: false,
        dom: (
          // <HumanResourcesTurnoverChart
          //   periodSwitch={periodSwitch}
          //   fiscalQuarter={fiscalQuarter}
          //   showTable={false}
          //   kpiCode="KDX004"
          //   data={getKpiData('KDX004').data}
          //   loading={getKpiData('KDX004').loading}
          //   title={customChartGroupTitle(
          //     'DX適用',
          //     'DX Allowance割引率',
          //     color.errorColor
          //   )}
          //   ribbonText={t('aipcmcty.page.quality')}
          //   ribbonColor={color.primaryColor}
          //   ribbonTop={0}
          //   height={chartHeight}
          // />
          <CompareChart
            // periodSwitch={periodSwitch}
            // fiscalQuarter={fiscalQuarter}
            showTable={false}
            // kpiCode="KDX003"
            data={{
              legends: [t('aipcmcty.page.trackRecord'), t('aipcmcty.page.forecast')],
              reality: [0, 15400000, 0, 0, 0],
              prediction: [0, 0, 200546180, 1147667909, 1079355940],
            }}
            loading={getKpiData('KDX003').loading}
            title={customChartGroupTitle(
              t('aipcmcty.page.dxApplicable'),
              `${t('aipcmcty.page.dxBilling')}(${t('aipcmcty.page.annualRebound')})`,
              color.errorColor
            )}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            splitNumber={6}
          />
        ),
      },
      {
        id: '1323',
        label: t('aipcmcty.page.dxAllowanceAmount'),
        y: levelY[3],
        width: chartWidth,
        height: chartHeight,
        level: 3,
        collapsed: true,
        parentCollapsed: false,
        dom: (
          <TurnoverChart
            periodSwitch={periodSwitch}
            fiscalQuarter={fiscalQuarter}
            showTable={false}
            kpiCode="KDX005"
            data={getKpiData('KDX005').data}
            loading={getKpiData('KDX005').loading}
            title={customChartGroupTitle(t('aipcmcty.page.dxApplicable'), t('aipcmcty.page.dxAllowanceAmount'), color.errorColor)}
            ribbonText={t('aipcmcty.page.quality')}
            ribbonColor={color.primaryColor}
            ribbonTop={0}
            height={chartHeight}
            barWidth={60}
          />
        ),
      },
    ];
    const edges = [
      {
        id: '1',
        source: '1',
        target: '11',
      },
      {
        id: '2',
        source: '1',
        target: '12',
      },
      {
        id: '3',
        source: '1',
        target: '13',
      },
      {
        id: '4',
        source: '11',
        target: '111',
      },
      {
        id: '5',
        source: '11',
        target: '112',
      },
      {
        id: '6',
        source: '12',
        target: '121',
      },
      {
        id: '7',
        source: '12',
        target: '122',
      },
      {
        id: '8',
        source: '13',
        target: '131',
      },
      {
        id: '9',
        source: '13',
        target: '132',
      },
      {
        id: '101',
        source: '111',
        target: '1110',
      },
      {
        id: '10',
        source: '111',
        target: '1111',
      },
      {
        id: '11',
        source: '111',
        target: '1112',
      },
      {
        id: '12',
        source: '111',
        target: '1113',
      },
      {
        id: '13',
        source: '111',
        target: '1114',
      },
      {
        id: '14',
        source: '111',
        target: '1115',
      },
      {
        id: '15',
        source: '111',
        target: '1116',
      },
      {
        id: '16',
        source: '111',
        target: '1117',
      },
      {
        id: '17',
        source: '112',
        target: '1121',
      },
      {
        id: '18',
        source: '112',
        target: '1122',
      },
      {
        id: '19',
        source: '112',
        target: '1123',
      },
      {
        id: '20',
        source: '112',
        target: '1124',
      },
      {
        id: '21',
        source: '112',
        target: '1125',
      },
      {
        id: '22',
        source: '121',
        target: '1211',
      },
      {
        id: '23',
        source: '121',
        target: '1212',
      },
      {
        id: '233',
        source: '121',
        target: '12121',
      },
      {
        id: '24',
        source: '122',
        target: '1221',
      },
      {
        id: '241',
        source: '122',
        target: '12211',
      },
      {
        id: '25',
        source: '122',
        target: '1222',
      },
      {
        id: '26',
        source: '131',
        target: '1311',
      },
      {
        id: '27',
        source: '132',
        target: '1321',
      },
      {
        id: '28',
        source: '132',
        target: '1322',
      },
      {
        id: '29',
        source: '132',
        target: '1323',
      },
    ];
    const data = setNodePosition({ nodes, edges });
    setScale(pathSize.width / (nodes[0].x * 2));
    setNodeData(data);
  }, [pathSize, kpiInfos, fiscalQuarter, periodSwitch]);

  useEffect(() => {
    initKpiData([
      [
        'KPJ002',
        'KPJ011',
        'KPJ003',
        'KPJ004',
        'KPJ005',
        'KPJ006',
        'KPJ007',
        'KPJ008',
        'KPJ012',
        'KPJ013',
        'KPJ014',
        'KPJ001',
        'KRS001',
        'ValueProposition',
      ],
      ['KRS002', 'KRS003', 'KRS004', 'KRS005', 'OperatingIncomePerCapita', 'MatchingRateByYear'],
      ['KDX002', 'KDX003', 'KDX004', 'KDX005', 'KMT002'],
    ]);
  }, [snapshot, snapshotVersion]);

  const renderNodes = () => (
    <div
      style={{
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        height: document.body.clientHeight - 100,
        zIndex: 2,
      }}
    >
      {nodeData ? (
        nodeData.nodes.map((node) => (
          <div
            style={{
              position: 'absolute',
              top: `${node.y - node.height / 2}px`,
              left: `${node.x - node.width / 2}px`,
              width: `${node.width}px`,
              height: `${node.height}px`,
              textAlign: 'center',
              display: node.collapsed || node.parentCollapsed ? 'none' : 'block',
            }}
            key={node.id}
          >
            <Draggable
              position={{
                x: 0,
                y: 0,
              }}
              onStop={(e, data) => {
                node.x += data.lastX;
                node.y += data.lastY;
                setNodeData({ ...nodeData });
              }}
              disabled={disableDrag}
              handle=".node-title"
            >
              <div
                className="node-title"
                style={{
                  height: node.height,
                  border: '2px solid #aaa',
                }}
              >
                {/* {Math.random() > 0.5 ? (
                    <RiseOutlined
                      style={{
                        padding: 4,
                        position: 'absolute',
                        display: node.dom ? 'block' : 'none',
                        right: 0,
                        top: 0,
                        color: color.successColor,
                        fontWeight: 'bolder',
                      }}
                    />
                  ) : (
                    <FallOutlined
                      style={{
                        padding: 4,
                        position: 'absolute',
                        display: node.dom ? 'block' : 'none',
                        right: 0,
                        top: 0,
                        color: color.errorColor,
                        fontWeight: 'bolder',
                      }}
                    />
                  )} */}
                {!node.dom ? <span style={{ fontWeight: 'bolder' }}>{node.label}</span> : <span>{node.dom}</span>}
                <Popover placement="rightTop" title="Sub Trees" content={content(findChildren(node, nodeData))} trigger="click">
                  <span
                    style={{
                      position: 'absolute',
                      top: `${node.height - 2}px`,
                      left: `${node.width / 2 - 11}px`,
                      fontSize: 20,
                      display: nodeData.edges.find((edge) => edge.source === node.id) ? 'block' : 'none',
                    }}
                  >
                    {isAllChildrenCollapsed(node) ? (
                      <MinusCircleOutlined
                        style={{
                          position: 'absolute',
                          background: 'white',
                          borderRadius: 10,
                          color: '#aaa',
                        }}
                      />
                    ) : (
                      <PlusCircleOutlined
                        style={{
                          position: 'absolute',
                          background: 'white',
                          borderRadius: 10,
                          color: '#aaa',
                        }}
                      />
                    )}
                  </span>
                </Popover>
              </div>
            </Draggable>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );

  const renderEdges = () => (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: document.body.clientHeight - 100,
        zIndex: 1,
      }}
    >
      <svg
        width={nodeData ? nodeData.nodes[0].x * 2 : document.body.clientWidth}
        height={startTop + chartHeightOffset * 3 + bigChartHeight + chartHeight / 2 + 50 + minChartHeight * 2}
        xmlns="http://wwww.w3.org/2000/svg"
      >
        <defs>
          <marker id="triangle" markerUnits="strokeWidth" markerWidth="6" markerHeight="6" refX="0" refY="3" orient="auto">
            <path d="M 0 0 L 6 3 L 0 6 M 0 3 L 6 3" fill="none" stroke="#aaa" />
          </marker>
        </defs>
        {nodeData ? (
          nodeData.edges.map((edge) => {
            const source = nodeData.nodes.find((node) => node.id === edge.source);
            const target = nodeData.nodes.find((node) => node.id === edge.target);
            if (!target || target.collapsed || target.parentCollapsed) {
              return <Fragment key={edge.id}></Fragment>;
            }

            const middle = {
              x: (target.x + target.width / 2 - (source.x - source.width / 2)) / 2 + target.x + target.width / 2,
              y: (target.y - target.height / 2 - (source.y + source.height / 2)) / 2 + source.y + source.height / 2,
            };

            const path = `M ${source.x},${source.y + source.height / 2} L ${source.x},${middle.y} L ${target.x},${middle.y} L ${target.x},${
              target.y - target.height / 2 - 12
            }`;

            return <path key={edge.id} d={path} stroke="#aaa" strokeWidth="2" fill="none" style={{ markerEnd: 'url(#triangle)' }} />;
          })
        ) : (
          <></>
        )}
      </svg>
    </div>
  );

  return (
    <div
      className="custom-scrollbar"
      onWheel={(e) => {
        if (scale === null || !nodeData || !nodeData.nodes) {
          return;
        }
        const newScale = scale - e.deltaY / 1000;
        if (newScale > pathSize.width / (nodeData.nodes[0].x * 2) && newScale < 1.5) {
          setScale(newScale);
        }
        e.preventDefault();
      }}
      style={{ position: 'relative', overflow: 'scroll', background: '#FFF' }}
    >
      {/* <Switch
          style={{ position: 'absolute', zIndex: 10 }}
          checkedChildren="Draggable Off "
          unCheckedChildren="Draggable On"
          checked={disableDrag}
          onChange={() => {
            setDisableDrag(!disableDrag);
          }}
        /> */}
      <div
        ref={pathRef}
        style={{
          width: '100%',
          height: document.body.clientHeight - 90,
        }}
      >
        {!loading ? (
          <div
            style={{
              transform: `scale(${scale}) translateX(${((scale - 1) / 2) * (pathSize.width / scale)}px) translateY(${
                ((scale - 1) / 2) * (pathSize.height / scale)
              }px)`,
            }}
          >
            {renderNodes()}
            {renderEdges()}
          </div>
        ) : (
          <LoadingPage fixed={false} />
        )}
      </div>
    </div>
  );
};

export default KpiTreePage;
