// chart title: Buffer Suitability Evaluation
import React, {
  useContext, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import Ribbon from '../widget/ribbon';
import { ContentChart, ECHART_EVENT } from '@meteor/frontend-core';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap, round } from '../../utils/commonUtil';
import { AppContext } from '../../contexts/AppContext';
import dayjs from 'dayjs';

type ChartProps = {
  kpiCode?: string;
  data: any;
  height?: number;
  loading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  ribbonTop?: number;
  fiscalQuarter?: string[];
  periodSwitch?: boolean;
  chartHandler?: (e: any) => any;
};

const WonPercentChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode, data, height, loading, title, ribbonText, ribbonColor, ribbonTop, fiscalQuarter, periodSwitch, chartHandler
  } = props;

  const { t } = useTranslation();
  const {
    defaultFiscalQuarter, color, sizeMode, certainty
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule } = useContext(AipcmctyContext);
  const offset = 10;

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      splitNumber: 3
    },
    big: {
      ...sizeMapBase.big,
      splitNumber: 3
    },
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: 'Won %',
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        appendToBody: true,
        className: 'overflow-all',
        hideDelay: 0,
        position: (point, params, dom, rect, size) => [point[0] - size.contentSize[0] - 10, point[1] + 10],
        formatter: (params) => {
          const {
            value, projectId, projectName, projectOrderAmount
          } = params.data;
          if (projectId) {
            return `${t('aipcmcty.page.projectID')}: ${projectId}<br>${t('aipcmcty.page.projectName')}: ${projectName}<br>${t('aipcmcty.page.orderScheduledYearMonth')}: ${value[0]}<br>${t('aipcmcty.page.orderAmount')}: ${round(projectOrderAmount / 100000000)} ${t('aipcmcty.page.billion')} <br> Won%: ${round(value[1] * 100)}%<br>`;
          }
          return null;
        }
      },
      legend: {
        top: 5,
        textStyle: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
        },
        itemGap: 5,
        itemWidth: 14,
        data: [],
      },
      grid: {
        left: getSizeByMap(sizeMode, sizeMap, 'gridLeft'),
        right: getSizeByMap(sizeMode, sizeMap, 'gridRight'),
        bottom: getSizeByMap(sizeMode, sizeMap, 'gridBottom'),
        top: getSizeByMap(sizeMode, sizeMap, 'gridTop'),
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          fontSize: getSizeByMap(sizeMode, sizeMap, 'xAxis'),
          align: 'left',
          // padding: [0, 0, 0, 15],
        },

      },
      yAxis: [
        {
          type: 'value',
          position: 'left',
          name: '%',
          axisLine: {
            show: true,
          },
          axisLabel: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
            formatter: (value) => `${value * 100}`
          },
          nameTextStyle: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'yAxis'),
          },
          nameGap: 5,
        },
      ],
      series: []
    },
    height: 220,
  });

  const initData = () => {
    initChartData();
  };

  const initChartData = () => {
    if (!data) {
      return;
    }

    const { mart } = data;
    const filterData = mart;

    const wonData = filterData.map((item: any) => ({
      date: item.orderScheduledDate,
      won: item.won,
      projectOrderAmount: item.projectOrderAmount,
      projectOrderAmountWon: item.projectOrderAmountWon,
      projectId: item.projectId,
      projectName: item.projectName
    }));

    options.chartOptions.legend.data = [];
    const seriesDataList = [];
    wonData.forEach(({
      date, won, projectOrderAmount, projectOrderAmountWon, projectId, projectName
    }) => {
      seriesDataList.push({
        value: [date, won],
        symbolSize: (() => {
          const size = (certainty ? projectOrderAmountWon : projectOrderAmount) / 5000000000;
          if (size < 3) {
            return 3;
          }
          return size;
        })(),
        projectId,
        projectName,
        projectOrderAmount
      });
    });
    options.chartOptions.series = [{
      type: 'scatter',
      data: seriesDataList,
      markLine: {
        symbol: ['none', 'none'],
        label: {
          show: true,
          formatter: 'Today',
          color: color.errorColor,
          distance: 0,
        },
        lineStyle: {
          color: color.errorColor,
          width: 2,
        },
        data: [{
          xAxis: dayjs().format('YYYY-MM')
        }],
        animation: false,
      },
    }];
    options.chartOptions.xAxis.splitNumber = periodSwitch ? defaultFiscalQuarter.length : (fiscalQuarter || defaultFiscalQuarter).length;
    setOptions(cloneDeep(options));
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    initData();
  }, [data, kpiComputeRule]);

  useEffect(() => {
    initChartData();
  }, [fiscalQuarter]);

  const getHeight = () => (sizeMode === 'big' ? height - 87 : height - 40);

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        initData();
      }
    }
    if (chartHandler) {
      chartHandler(e);
    }
  };

  return (
    <>
      { ribbonText ? <Ribbon text={ribbonText || t('aipcmcty.page.projectQuality')} top={ribbonTop} color={ribbonColor || color.warningColor} /> : <></>}
      <ContentChart
        title={title ? {
          value: title,
          styles: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
            paddingLeft: sizeMode === 'big' ? 15 : 0,
          },
        } : options.title}
        contentList={options.contentList}
        chartOptions={options.chartOptions}
        showSeriesLabel={options.showSeriesLabel}
        mouseWheelZoomLock={options.mouseWheelZoomLock}
        height={getHeight()}
        tooltips={options.tooltips}
        loading={loading}
        isBank={!data || data.length === 0}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default WonPercentChart;
