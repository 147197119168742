import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// ja
import jaCommon from "./ja/common/label.json";
import jaConfig from "./ja/config/label.json";
import jaAipcmcty from "./ja/label.json";
// en
import enCommon from "./en/common/label.json";
import enConfig from "./en/config/label.json";
import enAipcmcty from "./en/label.json";

const resources = {
  ja: {
    translation: {
      ...jaCommon,
      ...jaConfig,
      ...jaAipcmcty
    },
  },
  en: {
    translation: {
      ...enCommon,
      ...enConfig,
      ...enAipcmcty
    },
  },
};

i18n.use(initReactI18next).init(
  {
    lng: "ja",
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false,
    },

    // react: {
    //   wait: true,
    // },
    resources: resources,
  },
  (err: any) => console.log("something went wrong loading", err)
);

export default i18n;
