import React, { ReactNode } from 'react';

const CustomerScrollBar: React.FC<{ children: ReactNode, style: React.CSSProperties }> = (props) => {
  const { children, style } = props;
  return (
    <div
      className="custom-scrollbar"
      style={{ ...style, overflowX: 'hidden', overflowY: 'scroll' }}
    >
      {children}
    </div>
  );
};

export default CustomerScrollBar;