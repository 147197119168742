// chart title: Buffer Suitability Evaluation
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  cloneDeep, groupBy, sumBy
} from 'lodash';
import Ribbon from '../widget/ribbon';
import { TableOptions } from '@meteor/frontend-core/dist/chart/chart-widgets/chart-table';
import { ECHART_EVENT, TableChart } from '@meteor/frontend-core';
import { AppContext } from '../../contexts/AppContext';
import { ACTION_EVENT } from '../../consts/action-event';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { getSizeByMap } from '../../utils/commonUtil';

type ChartProps = {
  kpiCode?: string;
  data: any;
  projectData?: any;
  height?: number;
  loading?: boolean;
  titleLoading?: boolean;
  coverLoading?: boolean;
  title?: any;
  ribbonText?: string;
  ribbonColor?: string;
  tableOptions?: TableOptions
  showTable?: boolean;
  fiscalQuarter?: string[];
  periodSwitch?: boolean;
  filter?: any[];
  showProjects?: boolean;
};

const SupplyDemandMatchingChart: React.FC<ChartProps> = (props) => {
  const {
    kpiCode, data, height, loading, title, ribbonText, ribbonColor, showTable = true, tableOptions, fiscalQuarter, periodSwitch = false,
    titleLoading = false, coverLoading, filter, showProjects, projectData
  } = props;

  const {
    color, sizeMode
  } = useContext(AppContext);
  const { sizeMapBase, kpiComputeRule, defaultFiscalQuarter } = useContext(AipcmctyContext);
  const { t } = useTranslation();
  const offset = 10;
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  const current = `${date.getFullYear()}\n${currentMonth < 10 ? `0${currentMonth}` : currentMonth}`;
  const months: string[] = [];
  for (let year = 2022; year <= 2026; year++) {
    for (let month = 1; month <= 12; month++) {
      months.push(`${year}\n${month < 10 ? `0${month}` : month}`);
    }
  }

  const chartRef = useRef(null);
  const modalChartRef = useRef(null);

  const [otherProjectSupplyMh, setOtherProjectSupplyMh] = useState([]);

  const sizeMap = {
    small: {
      ...sizeMapBase.small,
      gridTop: 80,
      gridBottom: 10,
    },
    big: {
      ...sizeMapBase.big,
    },
  };

  const [options, setOptions] = useState<any>({
    title: {
      value: t('aipcmcty.page.orderAmount'),
      styles: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
        paddingLeft: sizeMode === 'big' ? 15 : 0,
      },
    },
    chartOptions: {
      tooltip: {
        appendToBody: true,
        className: 'overflow-all',
        hideDelay: 0,
        trigger: 'axis',
        position: (point, params, dom, rect, size) => [point[0] - size.contentSize[0] - offset, point[1] + offset],
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        },
        formatter: (params) => {
          const displayDate = params[0].axisValue.replace(/\n/g, '/');
          let tooltips = `<div><table style="width: 180px"><tr><td>${displayDate}</td></tr>`;
          params.reverse();

          params.forEach((element, index) => {
            const projectId = element.seriesName;
            const item = projectData.find(project => projectId === project.projectId);
            const tooltipBody = item
              ? `<td style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis; max-width: 180px">${element.marker}${item.projectName}:</td> <td style="text-align: right; font-weight:bold; padding-right: 5px">${element.value.toLocaleString()}</td>`
              : `<td>${element.marker}${element.seriesName}:</td> <td style="text-align: right; font-weight:bold; padding-right: 5px">${element.value.toLocaleString()}</td>`;
            tooltips += `<tr>${tooltipBody}</tr>`;
          });

          tooltips += '</table></div>';
          return tooltips;
        },
      },
      legend: {
        type: 'scroll',
        textStyle: {
          fontSize: getSizeByMap('small', sizeMap, 'legend'),
        },
        data: [],
        itemGap: 5,
        selected: {},
        top: 10
      },
      grid: {
        left: Number(getSizeByMap(sizeMode, sizeMap, 'gridLeft')),
        right: Number(getSizeByMap(sizeMode, sizeMap, 'gridRight')),
        bottom: sizeMode === 'big' ? Number(getSizeByMap(sizeMode, sizeMap, 'gridBottom'))
          : Number(getSizeByMap(sizeMode, sizeMap, 'gridBottom')) + 40,
        top: Number(getSizeByMap(sizeMode, sizeMap, 'gridTop')),
        containLabel: true
      },
      toolbox: {
        feature: {
          // dataZoom: {
          //   yAxisIndex: 'none'
          // },
          // restore: {},
          // saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          fontSize: getSizeByMap('small', sizeMap, 'xAxis'),
        },
      },
      yAxis: {
        type: 'value',
        min: 0,
        axisLabel: {
          fontSize: getSizeByMap('small', sizeMap, 'yAxis'),
        },
        nameTextStyle: {
          fontSize: getSizeByMap('small', sizeMap, 'yAxis'),
        },
      },
      dataZoom: [
        // {
        //   type: 'inside',
        // },
        {
          textStyle: {
            fontSize: 11,
          },
          bottom: 30,
          height: 18,
          startValue: months.indexOf(current),

        },
      ],
      series: []
    },
    height: 240,
  });

  useEffect(() => {
    setTimeout(() => {
      if (projectData?.length > 0) {
        setOptions({
          ...options,
          chartOptions: {
            ...options.chartOptions,
            tooltip: {
              appendToBody: true,
              className: 'overflow-all',
              hideDelay: 0,
              position: (point, params, dom, rect, size) => [point[0] - size.contentSize[0] - offset, point[1] + offset],
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              },
              formatter: (params) => {
                const displayDate = params[0].axisValue.replace(/\n/g, '/');
                let tooltips = `<div><table style="width: 180px"><tr><td>${displayDate}</td></tr>`;
                params.reverse();

                params.forEach((element, index) => {
                  const projectId = element.seriesName;
                  const item = projectData.find(project => projectId === project.projectId);
                  const tooltipBody = item
                    ? `<td style="white-space: nowrap; overflow: hidden;text-overflow: ellipsis; max-width: 180px">${element.marker}${item.projectName}:</td> <td style="text-align: right; font-weight:bold; padding-right: 5px">${element.value.toLocaleString()}</td>`
                    : `<td>${element.marker}${element.seriesName}:</td> <td style="text-align: right; font-weight:bold; padding-right: 5px">${element.value.toLocaleString()}</td>`;
                  tooltips += `<tr>${tooltipBody}</tr>`;
                });

                tooltips += '</table></div>';
                return tooltips;
              },
            },
          },
        });
      }
    }, 800);
    initData();
  }, [data, filter, fiscalQuarter, kpiComputeRule, showProjects, projectData]);

  const initData = () => {
    initChartData();
  };

  const initChartData = () => {
    if (!data) {
      return;
    }
    const { jobTypeData, supplyData } = data;
    // level2粒度：Division
    const jobTypeFilterDataLv2 = filter[0].length === 0 ? jobTypeData
      : jobTypeData.filter(item => filter[0].some(val => item.division === val));
    const supplyFilterDataLv2 = filter[0].length === 0 ? supplyData
      : supplyData?.filter(item => filter[0].some(val => item.division === val));
    const projectFilterDataLv2 = filter[0].length === 0 ? projectData
      : projectData?.filter(item => filter[0].some(val => item.division === val));
    // level3粒度：Discipline
    const jobTypeFilterDataLv3 = filter[1].length === 0 ? jobTypeFilterDataLv2
      : jobTypeFilterDataLv2.filter(item => filter[1].some(val => item.discipline === val));
    const supplyFilterDataLv3 = filter[1].length === 0 ? supplyFilterDataLv2
      : supplyFilterDataLv2?.filter(item => filter[1].some(val => item.discipline === val));
    const projectFilterDataLv3 = filter[1].length === 0 ? projectFilterDataLv2
      : projectFilterDataLv2?.filter(item => filter[1].some(val => item.discipline === val));
    // level4粒度：Role
    const jobTypeFilterDataLv4 = filter[2].length === 0 ? jobTypeFilterDataLv3
      : jobTypeFilterDataLv3.filter(item => filter[2].some(val => item.role === val));
    const supplyFilterDataLv4 = filter[2].length === 0 ? supplyFilterDataLv3
      : supplyFilterDataLv3?.filter(item => filter[2].some(val => item.role === val));
    const projectFilterDataLv4 = filter[2].length === 0 ? projectFilterDataLv3
      : projectFilterDataLv3?.filter(item => filter[2].some(val => item.role === val));

    if (!jobTypeFilterDataLv4.length) {
      return;
    }
    const gbDateSupply = groupBy(supplyFilterDataLv4, (item) => `${item.year}\n${item.month}`);

    // chart optionのデータを組み立て
    options.chartOptions.xAxis.data = months;
    const series = [];
    const legend = [];
    const gbJT = groupBy(jobTypeFilterDataLv4, 'jobType');
    const gbPJT = groupBy(projectFilterDataLv4, 'projectId');

    const budgetData = groupBy(gbJT['受注計画案件'], (item) => `${item.year}\n${item.month}`);
    const totalDemand = months.map(key => Math.ceil(sumBy(budgetData[key], 'demandMh')));
    const totalSupply = months.map(key => Math.ceil(sumBy(budgetData[key], 'supplyMh')));

    Object.keys(gbJT).forEach((jobType) => {
      const gbJTDate = groupBy(gbJT[jobType], (item) => `${item.year}\n${item.month}`);
      if (jobType !== '受注計画案件') {
        series.push({
          name: jobType,
          type: 'line',
          stack: 'Total',
          showSymbol: false,
          areaStyle: {},
          label: {
            show: true,
            fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
          },
          // emphasis: {
          //   focus: 'series',
          // },
          data: months.map(key => Math.ceil(kpiComputeRule[kpiCode](gbJTDate[key] || []))),
        });
        legend.push(jobType);
      } else if (showProjects) {
        let sumDemand: number[] = Array.from({ length: 60 }, () => 0);
        let sumSupply: number[] = Array.from({ length: 60 }, () => 0);
        Object.keys(gbPJT).forEach((project) => {
          const gbPJTDate = groupBy(gbPJT[project], (item) => `${item.year}\n${item.month}`);
          const d = months.map(key => Math.ceil(kpiComputeRule[kpiCode](gbPJTDate[key] || [])));
          const su = months.map(key => Math.ceil(sumBy(gbPJTDate[key] || [], 'supplyMh')));
          series.push({
            name: project,
            type: 'line',
            stack: 'Total',
            areaStyle: {},
            showSymbol: false,
            label: {
              show: true,
              fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
            },
            // emphasis: {
            //   focus: 'series',
            // },
            data: d,
          });
          legend.push(project);
          sumDemand = sumDemand.map((s, i) => s + d[i]);
          sumSupply = sumSupply.map((s, i) => s + su[i]);
        });
        series.push({
          name: t('aipcmcty.page.otherProjects'),
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          showSymbol: false,
          label: {
            show: true,
            fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
          },
          // emphasis: {
          //   focus: 'series',
          // },
          data: totalDemand.map((d, i) => d - sumDemand[i]),
        });
        legend.push(t('aipcmcty.page.otherProjects'));
        setOtherProjectSupplyMh(totalSupply.map((d, i) => d - sumSupply[i]));
      } else {
        series.push({
          name: jobType,
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          showSymbol: false,
          label: {
            show: true,
            fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
          },
          // emphasis: {
          //   focus: 'series',
          // },
          data: months.map(key => Math.ceil(kpiComputeRule[kpiCode](gbJTDate[key] || []))),
        });
        legend.push(jobType);
      }
    });

    series.push({
      name: 'Total MH',
      type: 'line',
      lineStyle: {
        color: color.errorColor,
        width: 2,
      },
      showSymbol: false,
      step: 'middle',
      label: {
        show: true,
        fontSize: getSizeByMap(sizeMode, sizeMap, 'series'),
      },
      itemStyle: {
        color: color.errorColor,
      },
      // emphasis: {
      //   focus: 'series',
      // },
      data: Object.keys(gbDateSupply).map(key => Math.ceil(kpiComputeRule.ResourceSupplyMh(gbDateSupply[key])))
    });
    legend.push('Total MH');

    const selected: any = {};
    legend.forEach((type) => {
      selected[type] = true;
    });
    options.chartOptions.legend = {
      textStyle: {
        fontSize: getSizeByMap(sizeMode, sizeMap, 'legend'),
      },
      data: legend,
      selected: selected,
      top: 10,
      itemGap: 5,
      type: legend.length > 6 ? 'scroll' : 'plain',
    };
    options.chartOptions.series = series;
    setOptions(cloneDeep(options));
  };

  const handleChartEvent = e => {
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_CLEAR_SELECTED
    ) {
      const { chartTitle } = e;
      if (title.key === chartTitle.value.key) {
        initData();
      }
    }
    if (
      e
      && e.eventType === ECHART_EVENT.CHART_LEGEND_SELECTED
    ) {
      const { event, chartTitle, chartInstance } = e;
      if (title.key === chartTitle.value.key) {
        const { jobTypeData, supplyData } = data;

        // level2粒度：Division
        const jobTypeFilterDataLv2 = filter[0].length === 0 ? jobTypeData
          : jobTypeData.filter(item => filter[0].some(val => item.division === val));
        const supplyFilterDataLv2 = filter[0].length === 0 ? supplyData
          : supplyData?.filter(item => filter[0].some(val => item.division === val));
        const projectFilterDataLv2 = filter[0].length === 0 ? projectData
          : projectData?.filter(item => filter[0].some(val => item.division === val));

        // level3粒度：Discipline
        const jobTypeFilterDataLv3 = filter[1].length === 0 ? jobTypeFilterDataLv2
          : jobTypeFilterDataLv2.filter(item => filter[1].some(val => item.discipline === val));
        const supplyFilterDataLv3 = filter[1].length === 0 ? supplyFilterDataLv2
          : supplyFilterDataLv2?.filter(item => filter[1].some(val => item.discipline === val));
        const projectFilterDataLv3 = filter[1].length === 0 ? projectFilterDataLv2
          : projectFilterDataLv2?.filter(item => filter[1].some(val => item.discipline === val));

        // level4粒度：Role
        const jobTypeFilterDataLv4 = filter[2].length === 0 ? jobTypeFilterDataLv3
          : jobTypeFilterDataLv3.filter(item => filter[2].some(val => item.role === val));
        const supplyFilterDataLv4 = filter[2].length === 0 ? supplyFilterDataLv3
          : supplyFilterDataLv3?.filter(item => filter[2].some(val => item.role === val));
        const projectFilterDataLv4 = filter[2].length === 0 ? projectFilterDataLv3
          : projectFilterDataLv3?.filter(item => filter[2].some(val => item.role === val));

        if (!jobTypeFilterDataLv4.length) {
          return;
        }

        let gbDate;
        let pjtDate;
        if (showProjects) {
          // common select filter
          const filteredGbDate = jobTypeFilterDataLv4.filter(item => item.jobType !== '受注計画案件');
          const temp1 = filteredGbDate.filter(item => !event.selected[item.jobType]);
          gbDate = groupBy(temp1, (item) => `${item.year}\n${item.month}`);
          // project select filter
          const temp2 = projectFilterDataLv4.filter(item => !event.selected[item.projectId]);
          pjtDate = groupBy(temp2, (item) => `${item.year}\n${item.month}`);
        } else {
          gbDate = groupBy(jobTypeFilterDataLv4.filter(item => !event.selected[item.jobType]), (item) => `${item.year}\n${item.month}`);
        }

        // const gbDate = groupBy(jobTypeFilterDataLv3.filter(item => !event.selected[item.jobType]), (item) => `${item.year}\n${item.month}`);
        // const pJTDate = groupBy(projectFilterDataLv3.filter(item => !event.selected[item.projectId]), (item) => `${item.year}\n${item.month}`);

        const totalGbDateSupply = groupBy(supplyFilterDataLv4, (item) => `${item.year}\n${item.month}`);

        const instance = chartInstance.current.getEchartsInstance();
        const option = instance.getOption();
        const lineSeries = option.series.find(item => item.name === 'Total MH');
        const targetData = Object.keys(totalGbDateSupply).map(key => (Math.ceil(sumBy(totalGbDateSupply[key], 'resourceSupplyMh') || 0)));

        if (showProjects) {
          if (Object.keys(gbDate).length > 0 || Object.keys(pjtDate).length > 0 || !event.selected[t('aipcmcty.page.otherProjects')]) {
            lineSeries.data = months.map((key, index) => (targetData[index] || 0) - Math.ceil(sumBy(gbDate[key], 'supplyMh')) - Math.ceil(sumBy(pjtDate[key], 'supplyMh')));
            if (!event.selected[t('aipcmcty.page.otherProjects')]) {
              lineSeries.data = lineSeries.data.map((d, i) => d - otherProjectSupplyMh[i]);
            }
          } else {
            lineSeries.data = targetData;
          }
        } else if (Object.keys(gbDate).length > 0) {
          lineSeries.data = months.map((key, index) => (targetData[index] || 0) - Math.ceil(sumBy(gbDate[key], 'supplyMh')));
        } else {
          lineSeries.data = targetData;
        }

        setOptions(cloneDeep({
          ...options,
          chartOptions: option
        }));
      }
    }
  };

  return (
    <>
      {ribbonText ? <Ribbon text={ribbonText || t('aipcmcty.page.projectQuantity')} color={ribbonColor || color.primaryColor} /> : <></>}
      <TableChart
        showTable={showTable}
        chartRef={chartRef}
        modalChartRef={modalChartRef}
        chartOptions={options.chartOptions}
        title={title ? {
          value: title,
          styles: {
            fontSize: getSizeByMap(sizeMode, sizeMap, 'title'),
            paddingLeft: sizeMode === 'big' ? 15 : 0,
          },
        } : options.title}
        height={height}
        loading={loading}
        titleLoading={titleLoading}
        coverLoading={coverLoading}
        isBank={!data || data.length === 0}
        tableOptions={tableOptions}
        onChartEvent={handleChartEvent}
      />
    </>
  );
};

export default SupplyDemandMatchingChart;
