import { CSSProperties, ReactNode } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { DiffResponse } from '../../pages/comparison/project';
import LoadingPage from '../common/load-page';

type PhaseType = 'preSales' | 'proposal' | 'negotiation' | 'awarded';

type DiffChartProps = {
  data: DiffResponse;
  loading: boolean;
  graphLoading: boolean;
  graphHeight?: number;
  title?: string | ReactNode;
  migrationAction: (filter: { current: PhaseType; target: PhaseType }) => any;
};

const PhaseMigrationChart = (props: any) => {
  const { data, loading, graphLoading, title, migrationAction } = props;

  const { t } = useTranslation();

  const tarPhase = {
    preSales: [],
    proposal: [],
    negotiation: [],
    awarded: [],
  };

  const curPhase = {
    preSales: [],
    proposal: [],
    negotiation: [],
    awarded: [],
  };

  data.phaseUp.forEach((p) => {
    tarPhase[p.phaseTar].push(p);
    curPhase[p.phaseCur].push(p);
  });

  const awardedP = curPhase.preSales.filter((p) => p.phaseTar === 'awarded');
  const negotiationP = curPhase.preSales.filter((p) => p.phaseTar === 'negotiation');
  const proposalP = curPhase.preSales.filter((p) => p.phaseTar === 'proposal');
  const preSalesP = curPhase.preSales.filter((p) => p.phaseTar === 'preSales');

  const awardedPro = curPhase.proposal.filter((p) => p.phaseTar === 'awarded');
  const negotiationPro = curPhase.proposal.filter((p) => p.phaseTar === 'negotiation');
  const proposalPro = curPhase.proposal.filter((p) => p.phaseTar === 'proposal');
  const preSalesPro = curPhase.proposal.filter((p) => p.phaseTar === 'preSales');

  const awardedN = curPhase.negotiation.filter((p) => p.phaseTar === 'awarded');
  const negotiationN = curPhase.negotiation.filter((p) => p.phaseTar === 'negotiation');
  const proposalN = curPhase.negotiation.filter((p) => p.phaseTar === 'proposal');
  const preSalesN = curPhase.negotiation.filter((p) => p.phaseTar === 'preSales');

  const awardedA = curPhase.awarded.filter((p) => p.phaseTar === 'awarded');
  const negotiationA = curPhase.awarded.filter((p) => p.phaseTar === 'negotiation');
  const proposalA = curPhase.awarded.filter((p) => p.phaseTar === 'proposal');
  const preSalesA = curPhase.awarded.filter((p) => p.phaseTar === 'preSales');

  return (
    <div className="migrate-graph">
      <h4 className="list-card-title">
        {title}
        <Spin spinning={!loading && graphLoading} size="small" style={{ marginLeft: 8 }} />
      </h4>
      {loading ? (
        <LoadingPage fixed={false} />
      ) : (
        <>
          <div
            className="phase-migrate"
            style={
              {
                '--backward-color': '#faa29d',
                '--forward-color': '#98cb98',
                '--title-color': '#e2e2e2',
                '--retain-color': '#b9b9b9',
              } as CSSProperties
            }
          >
            {/* line1 */}
            <div className="phase-item title angle">
              <span className="current">{t('aipcmcty.page.comparison.currentCyd')}</span>
              <span className="target">{t('aipcmcty.page.comparison.targetCyd')}</span>
            </div>
            <div className="phase-item title">
              <div>Pre-Sales</div>
              <div>({curPhase.preSales.length})</div>
            </div>
            <div className="phase-item title">
              <div>Proposal</div>
              <div>({curPhase.proposal.length})</div>
            </div>
            <div className="phase-item title">
              <div>Negotiation</div>
              <div>({curPhase.negotiation.length})</div>
            </div>
            <div className="phase-item title">
              <div>Awarded</div>
              <div>({curPhase.awarded.length})</div>
            </div>
            {/* line2 */}
            <div className="phase-item title">
              <div>Pre-Sales</div>
              <div>({tarPhase.preSales.length})</div>
            </div>
            <div
              className="phase-item retain filter"
              onClick={() => migrationAction({ current: ['preSales'], target: ['preSales'], step: 0 })}
            >
              {preSalesP.length}
            </div>
            <div
              className="phase-item forward filter"
              onClick={() => migrationAction({ current: ['proposal'], target: ['preSales'], step: 1 })}
            >
              {preSalesPro.length}
            </div>
            <div
              className="phase-item forward filter"
              onClick={() => migrationAction({ current: ['negotiation'], target: ['preSales'], step: 1 })}
            >
              {preSalesN.length}
            </div>
            <div
              className="phase-item forward filter"
              onClick={() => migrationAction({ current: ['awarded'], target: ['preSales'], step: 1 })}
            >
              {preSalesA.length}
            </div>
            {/* line3 */}
            <div className="phase-item title">
              <div>Proposal</div>
              <div>({tarPhase.proposal.length})</div>
            </div>
            <div
              className="phase-item backward filter"
              onClick={() => migrationAction({ current: ['preSales'], target: ['proposal'], step: -1 })}
            >
              {proposalP.length}
            </div>
            <div
              className="phase-item retain filter"
              onClick={() => migrationAction({ current: ['proposal'], target: ['proposal'], step: 0 })}
            >
              {proposalPro.length}
            </div>
            <div
              className="phase-item forward filter"
              onClick={() => migrationAction({ current: ['negotiation'], target: ['proposal'], step: 1 })}
            >
              {proposalN.length}
            </div>
            <div
              className="phase-item forward filter"
              onClick={() => migrationAction({ current: ['awarded'], target: ['proposal'], step: 1 })}
            >
              {proposalA.length}
            </div>
            {/* line4 */}
            <div className="phase-item title">
              <div>Negotiation</div>
              <div>({tarPhase.negotiation.length})</div>
            </div>
            <div
              className="phase-item backward filter"
              onClick={() => migrationAction({ current: ['preSales'], target: ['negotiation'], step: -1 })}
            >
              {negotiationP.length}
            </div>
            <div
              className="phase-item backward filter"
              onClick={() => migrationAction({ current: ['proposal'], target: ['negotiation'], step: -1 })}
            >
              {negotiationPro.length}
            </div>
            <div
              className="phase-item retain filter"
              onClick={() => migrationAction({ current: ['negotiation'], target: ['negotiation'], step: 0 })}
            >
              {negotiationN.length}
            </div>
            <div
              className="phase-item forward filter"
              onClick={() => migrationAction({ current: ['awarded'], target: ['negotiation'], step: 1 })}
            >
              {negotiationA.length}
            </div>
            {/* line5 */}
            <div className="phase-item title">
              <div>Awarded</div>
              <div>({tarPhase.awarded.length})</div>
            </div>
            <div
              className="phase-item backward filter"
              onClick={() => migrationAction({ current: ['preSales'], target: ['awarded'], step: -1 })}
            >
              {awardedP.length}
            </div>
            <div
              className="phase-item backward filter"
              onClick={() => migrationAction({ current: ['proposal'], target: ['awarded'], step: -1 })}
            >
              {awardedPro.length}
            </div>
            <div
              className="phase-item backward filter"
              onClick={() => migrationAction({ current: ['negotiation'], target: ['awarded'], step: -1 })}
            >
              {awardedN.length}
            </div>
            <div
              className="phase-item retain filter"
              onClick={() => migrationAction({ current: ['awarded'], target: ['awarded'], step: 0 })}
            >
              {awardedA.length}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PhaseMigrationChart;
