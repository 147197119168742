import { Button, Card, Col, Empty, Popover, Row, Select, Space, Spin, Tabs, Tooltip, Tree } from 'antd';
import React, { CSSProperties, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { CheckSquareOutlined, CloseSquareOutlined, WarningOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ceil, debounce, groupBy, map, max, maxBy, round, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contexts/AppContext';
import { AipcmctyContext } from '../../contexts/aipcmcty.context';
import { useWindowSize } from '../../hooks/useWindowSize';
import APIList from '../../http/ApiList';
import { disciplineOps, divisionOps, roleOps } from '../../consts/master-options';
import ResourceDemandCompareChart from '../../components/charts/resource-demand-compare.chart';
import ResourceSupplyCompareChart from '../../components/charts/resource-supply-compare.chart';
import { getAllMonth } from '../../utils/DateUtil';
import { useAccountPerspective } from '../../hooks/useAccountPerspective';

type DiffDataKey = 'expectedDemandMh' | 'expectedSupplyMh' | 'diffDemandPjt' | 'diffSupplyTgc';

export type DiffExpectedMhDataItem = {
  year: string;
  month: string;
  jobType: string;
  currentDemandMh: number;
  targetDemandMh: number;
  currentSupplyMh: number;
  targetSupplyMh: number;
};

type DiffPjtDataItem = {
  projectId: string;
  projectName: string;
  existDiffType: string;
  existDiffDetail: string;
  durationDiffType: string;
  durationDiffDetail: string;
  formDiff: string;
  formDiffDetail: string;
  alert: string;
  tgc: string;
  division: string;
  discipline: string;
  role: string;
  inputDemandMhDiff: number;
  sfdcDemandMhDiff: number;
  supplyMhDiff: number;
  budgetCategoryTarget: string;
  budgetCategoryCurrent: string;
  durationTarget: number;
  durationCurrent: number;
  projectStartDateTarget: string;
  projectStartDateCurrent: string;
  projectIdTarget: string;
  projectIdCurrent: string;
  inputDemandMhCurrent: number;
  inputDemandMhTarget: number;
  sfdcDemandMhCurrent: number;
  sfdcDemandMhTarget: number;
  currentSupplyMh: number;
  targetSupplyMh: number;
  diffDetail: string;
  children: DiffPjtDataItem[];
};

type DiffDataItem<K extends DiffDataKey> = K extends 'diffDemandPjt' | 'diffSupplyTgc' ? DiffPjtDataItem : DiffExpectedMhDataItem;

type DiffData = {
  title: string;
  projectsEntity: DiffDataItem<'diffDemandPjt'>[];
};

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
export type DiffResponse = {
  [K in DiffDataKey]: DiffDataItem<K>[];
};

const ComparisonResourcePage: React.FC = () => {
  const DEBOUNCE_TIME = 2000;
  const [pageInit, setPageInit] = useState(false);
  const { t } = useTranslation();
  // about common
  const { color } = useContext(AppContext);
  const { versionList, snapshot, snapshotVersion, compareVersion, setCompareVersion } = useContext(AipcmctyContext);
  const { selectorTop } = useWindowSize({
    selector: '.cmcty-card-body',
  });

  const demandChartRef = useRef<any>();
  const supplyChartRef = useRef<any>();
  const [state, dispatch] = useReducer(
    (state, action) => {
      const { demandChartRef, supplyChartRef } = state;
      const { type, event, tabKey } = action;
      const demandInstance = demandChartRef.current.getEchartsInstance();
      const supplyInstance = supplyChartRef.current.getEchartsInstance();
      switch (type) {
        case 'demandAction':
          supplyInstance.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: event.dataIndex,
          });
          return {
            ...state,
            tabActiveKey: event.data.date,
          };
        case 'supplyAction':
          demandInstance.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: event.dataIndex,
          });
          return {
            ...state,
            tabActiveKey: event.data.date,
          };
        case 'setTabActiveKey':
          return {
            ...state,
            tabActiveKey: tabKey,
          };
        case 'clearTip':
          supplyInstance.dispatchAction({
            type: 'hideTip',
          });
          demandInstance.dispatchAction({
            type: 'hideTip',
          });
          return state;
      }
    },
    {
      tabActiveKey: dayjs().format('YYYY-MM'),
      demandChartRef,
      supplyChartRef,
    }
  );

  // about filter actions
  const [headerOptions, setHeaderOptions] = useState({
    tgcOptions: [],
    divisionOptions: [],
    disciplineOptions: [],
    roleOptions: [],
    snapshot: [],
    snapshotVersion: [],
  });

  const { perspectiveOptions, setAccountPerspective, accountingPerspective, filterTgcOptions, selectedTgc } = useAccountPerspective(
    headerOptions.tgcOptions
  );

  // all data source
  const [diffSource, setDiffSource] = useState<DiffResponse>({
    expectedDemandMh: [],
    expectedSupplyMh: [],
    diffDemandPjt: [],
    diffSupplyTgc: [],
  });
  const [expectedDemandMh, setExpectedDemandMh] = useState([]);
  const [expectedSupplyMh, setExpectedSupplyMh] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [chartLoading, setChartLoading] = useState(0);
  const [cardLoading, setCardLoading] = useState(0);

  const [filterSelect, setFilterSelect] = useState({
    tgc: [],
    division: [],
    discipline: [],
    role: [],
  });

  const [snapshotValue, setSnapshotValue] = useState('');
  const [versionValue, setVersionValue] = useState('');

  const [cardRenderData, setCardRenderData] = useState({
    pjtUpd: [],
    formationUpd: [],
    pjtDurDateUpd: [],
    supplyPlanUpd: [],
  });

  const [chartMaxMh, setMaxChartMh] = useState(0);

  const versionOptCollection = useMemo(() => {
    const filterVersionCollection = versionList.reduce((acc, cur) => {
      // exclude great than current version
      // exclude current version
      if (cur.snapshot > snapshot || (cur.snapshot === snapshot && cur.snapshotVersion === snapshotVersion)) {
        return acc;
      }
      const snapshotList = acc[cur.snapshot];
      if (!snapshotList) {
        acc[cur.snapshot] = [];
      }
      acc[cur.snapshot].push({
        label: cur.snapshotVersionName,
        value: cur.snapshotVersion,
      });
      return acc;
    }, {});
    if (Object.keys(filterVersionCollection).length === 0) {
      filterVersionCollection[snapshot] = [
        {
          label: versionList.find((version) => version.snapshotVersion === snapshotVersion)?.snapshotVersionName,
          value: snapshotVersion,
        },
      ];
    }
    return filterVersionCollection;
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    if (!snapshot || !snapshotVersion) {
      return;
    }
    setDataLoading(true);

    APIList.getCmcOptions()
      .get({
        category: 'tgc',
        snapshot,
        snapshotVersion,
      })
      .then((tgcs) => {
        const snapshotList = Object.keys(versionOptCollection).sort((a, b) => (b > a ? 1 : -1));
        const curSnapshotIndex = snapshotList.findIndex((s) => s === snapshot);
        let defaultSnapshot = snapshot;
        if (curSnapshotIndex <= 0) {
          if (snapshotList.length === 1) {
            const [s] = snapshotList;
            defaultSnapshot = s;
          }
          if (snapshotList.length > 1) {
            defaultSnapshot = snapshotList[curSnapshotIndex + 1];
          }
        }
        const defaultCompareVer = {
          snapshot: defaultSnapshot,
          snapshotVersion: 'default',
        };
        const defaultFilter = {
          tgc: selectedTgc,
          division: [],
          discipline: [],
          role: [],
        };
        setFilterSelect(defaultFilter);
        const curSnapshotList = snapshotList
          .filter((s) => dayjs(s).isBefore(snapshot) || dayjs(s).isSame(snapshot))
          .map((s) => ({ label: s, value: s }));
        let curSnapshotValue = compareVersion.snapshot;
        let curVersionValue = compareVersion.snapshotVersion;
        if (
          pageInit ||
          (!compareVersion.snapshot && !compareVersion.snapshotVersion) ||
          !curSnapshotList.some((s) => s.value === compareVersion.snapshot)
        ) {
          curSnapshotValue = defaultCompareVer.snapshot;
          curVersionValue = defaultCompareVer.snapshotVersion;
        }
        const curSnapshotVersionList = versionOptCollection[curSnapshotValue];
        setSnapshotValue(curSnapshotValue);
        setVersionValue(curVersionValue);
        setCompareVersion({
          snapshot: curSnapshotValue,
          snapshotVersion: curVersionValue,
        });
        setHeaderOptions({
          tgcOptions: tgcs,
          divisionOptions: divisionOps,
          disciplineOptions: disciplineOps,
          roleOptions: roleOps,
          snapshot: curSnapshotList,
          snapshotVersion: curSnapshotVersionList,
        });
        setPageInit(true);
      });
  }, [snapshot, snapshotVersion]);

  useEffect(() => {
    if (compareVersion.snapshot && compareVersion.snapshotVersion && pageInit) {
      handleCompare();
    }
  }, [state.tabActiveKey]);

  useEffect(() => {
    const demandData = diffSource.diffDemandPjt.reduce(
      (acc, cur) => {
        if (['99'].some((code) => !cur.existDiffType.startsWith(code))) {
          acc.pjtUpd.push(cur);
        }
        if (['99'].some((code) => !cur.formDiff.startsWith(code))) {
          acc.formationUpd.push(cur);
        }
        if (['99'].some((code) => !cur.durationDiffType.startsWith(code))) {
          acc.pjtDurDateUpd.push(cur);
        }
        return acc;
      },
      {
        pjtUpd: [],
        formationUpd: [],
        pjtDurDateUpd: [],
      }
    );

    const supplyData = {
      supplyPlanUpd: diffSource.diffSupplyTgc,
    };

    const getDemandTreeData = (key) => {
      const data = demandData[key].reduce((acc, cur) => {
        const existed = acc.find((item) => item.projectId === cur.projectId);
        if (existed) {
          existed.children.push({ ...cur, isChild: true });
        } else {
          acc.push({
            ...cur,
            isChild: false,
            children: [cur],
          });
        }
        return acc;
      }, []);
      Object.keys(data).forEach((key) => {
        const pjtData = data[key];
        pjtData.sfdcDemandMhTarget = round(sumBy(pjtData.children, 'sfdcDemandMhTarget'));
        pjtData.sfdcDemandMhCurrent = round(sumBy(pjtData.children, 'sfdcDemandMhCurrent'));
        pjtData.sfdcDemandMhDiff = round(pjtData.sfdcDemandMhCurrent - pjtData.sfdcDemandMhTarget);
        const { children } = pjtData;
        delete pjtData.children;
        const gbDivision = groupBy(children, 'division');
        pjtData.children = Object.keys(gbDivision)
          .map((key) => ({
            ...pjtData,
            division: key,
            isChild: true,
            sfdcDemandMhDiff: sumBy(gbDivision[key], 'sfdcDemandMhDiff'),
          }))
          .filter(({ sfdcDemandMhDiff }) => round(sfdcDemandMhDiff));
      });
      return data;
    };

    const getSupplyTreeData = (key) => {
      const data = supplyData[key].reduce((acc, cur) => {
        const existed = acc.find((item) => item.tgc === cur.tgc);
        if (existed) {
          existed.children.push({ ...cur, isChild: true });
        } else {
          acc.push({
            ...cur,
            isChild: false,
            children: [cur],
          });
        }
        return acc;
      }, []);
      Object.keys(data).forEach((key) => {
        const tgcData = data[key];
        tgcData.targetSupplyMh = round(sumBy(tgcData.children, 'targetSupplyMh'));
        tgcData.currentSupplyMh = round(sumBy(tgcData.children, 'currentSupplyMh'));
        tgcData.supplyMhDiff = round(tgcData.currentSupplyMh - tgcData.targetSupplyMh);
        const { children } = tgcData;
        delete tgcData.children;
        const gbDivision = groupBy(children, 'division');
        tgcData.children = Object.keys(gbDivision)
          .map((key) => ({
            ...tgcData,
            division: key,
            isChild: true,
            supplyMhDiff: sumBy(gbDivision[key], 'supplyMhDiff'),
          }))
          .filter(({ supplyMhDiff }) => round(supplyMhDiff));
      });
      return data;
    };

    setCardRenderData({
      pjtUpd: getDemandTreeData('pjtUpd'),
      formationUpd: getDemandTreeData('formationUpd'),
      pjtDurDateUpd: getDemandTreeData('pjtDurDateUpd'),
      supplyPlanUpd: getSupplyTreeData('supplyPlanUpd'),
    });
  }, [diffSource]);

  const handleFilterChange = (key: string, value: any) => {
    setSnapshotValue(compareVersion.snapshot);
    setVersionValue(compareVersion.snapshotVersion);
    const newFilterState = {
      ...filterSelect,
      [key]: value,
    };
    setFilterSelect(newFilterState);
    filterChangeHandler({
      tarSnapshot: compareVersion.snapshot,
      tarVersion: compareVersion.snapshotVersion,
      tgc: newFilterState.tgc.join(','),
      division: newFilterState.division.join(','),
      discipline: newFilterState.discipline.join(','),
      role: newFilterState.role.join(','),
      escape: false,
    });
  };

  const handleVersionChange = (key: string, value: any) => {
    if (key === 'snapshot') {
      setSnapshotValue(value);
      setVersionValue('default');
      setHeaderOptions({
        ...headerOptions,
        snapshotVersion: versionOptCollection[value],
      });
    } else {
      setVersionValue(value);
    }
  };

  const handleCompare = (filter?) => {
    if (filter) {
      setCompareVersion({
        snapshot: filter.tarSnapshot,
        snapshotVersion: filter.tarVersion,
      });
    } else {
      setSnapshotValue(compareVersion.snapshot);
      setVersionValue(compareVersion.snapshotVersion);
    }

    const mergedState = {
      tarSnapshot: compareVersion.snapshot,
      tarVersion: compareVersion.snapshotVersion,
      tgc: filterSelect.tgc,
      division: filterSelect.division,
      discipline: filterSelect.discipline,
      role: filterSelect.role,
      ...(filter || {}),
    };
    const { tarSnapshot, tarVersion, tgc, division, discipline, role } = mergedState;
    getComparison({
      tarSnapshot,
      tarVersion,
      tgc: tgc.join(','),
      division: division.join(','),
      discipline: discipline.join(','),
      role: role.join(','),
      escape: !filter,
    });
  };

  const getComparison = async ({
    tarSnapshot,
    tarVersion,
    tgc,
    division,
    discipline,
    role,
    curSnapshot = snapshot,
    curVersion = snapshotVersion,
    escape,
  }) => {
    const [year, month] = state.tabActiveKey.split('-');
    if (!escape) {
      setChartLoading(chartLoading + 1);
    }
    setCardLoading(cardLoading + 1);
    const res = (await APIList.getComparisonResource().get({
      tarSnapshot,
      tarVersion,
      curSnapshot,
      curVersion,
      division,
      discipline,
      role,
      tgc,
      year,
      month,
      escape,
    })) as DiffResponse;
    setDiffSource(res);
    if (!escape) {
      setMaxChartMh(getChartMaxMh(res));
      setExpectedDemandMh(res.expectedDemandMh);
      setExpectedSupplyMh(res.expectedSupplyMh);
      setChartLoading((prev) => (prev - 1 < 0 ? 0 : prev - 1));
    }
    setDataLoading(false);
    setCardLoading((prev) => (prev - 1 < 0 ? 0 : prev - 1));
  };

  const filterChangeHandler = useCallback(debounce(getComparison, DEBOUNCE_TIME), []);

  const getChartMaxMh = (res: DiffResponse) => {
    const { expectedDemandMh, expectedSupplyMh } = res;
    const expectedDemandMhGroup = groupBy(expectedDemandMh, (item) => `${item.year}-${item.month}`);
    const expectedSupplyMhGroup = groupBy(expectedSupplyMh, (item) => `${item.year}-${item.month}`);
    const targetDemandMhMax = max(Object.values(expectedDemandMhGroup).map((arr) => sumBy(arr, 'targetDemandMh')));
    const currentDemandMhMax = max(Object.values(expectedDemandMhGroup).map((arr) => sumBy(arr, 'currentDemandMh')));
    const targetSupplyMhMax = max(Object.values(expectedSupplyMhGroup).map((arr) => sumBy(arr, 'targetSupplyMh')));
    const currentSupplyMhMax = max(Object.values(expectedSupplyMhGroup).map((arr) => sumBy(arr, 'currentSupplyMh')));
    const maxMh = max<number>([targetDemandMhMax, currentDemandMhMax, targetSupplyMhMax, currentSupplyMhMax]);
    const finalMaxMH = ceil(maxMh * 1.1, -4);
    return finalMaxMH;
  };

  // filter card render data
  const CardTitle = (title: string, projectIds: string[], c = 'white') => {
    const { length } = projectIds;
    return (
      <Space>
        <span className="card-title">{title}</span>
        {title !== cardTitleMap.supplyPlanUpd && (
          <span className="card-diff" style={{ '--diff-color': color.primaryColor } as CSSProperties}>
            {length}
          </span>
        )}
        <Spin spinning={!dataLoading && cardLoading > 0} size="small" />{' '}
      </Space>
    );
  };

  const DiffCard = ({ diffD, colorStr = 'white' }: { diffD: DiffData; colorStr?: string }) => {
    const { title, projectsEntity } = diffD;
    let cardContent = <></>;
    if (dataLoading) {
      cardContent = <Spin />;
    } else if (!projectsEntity.length) {
      cardContent = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    } else {
      cardContent = (
        <ul>
          {projectsEntity.map((p) => (
            <li
              key={p.projectId || p.tgc}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {p.alert && (
                <Tooltip title={p.alert} placement="right">
                  <WarningOutlined
                    style={{
                      color: color.errorColor,
                      position: 'absolute',
                      left: 0,
                    }}
                  />
                </Tooltip>
              )}
              <Popover
                trigger="click"
                content={
                  <ContentPop
                    {...p}
                    title={title}
                    diffDetail={(() => {
                      if (title === cardTitleMap.pjtUpd) {
                        return p.existDiffDetail;
                      }
                      if (title === cardTitleMap.formationUpd) {
                        return p.formDiffDetail;
                      }
                      if (title === cardTitleMap.pjtDurDateUpd) {
                        return p.durationDiffDetail;
                      }
                      return null;
                    })()}
                    colorStr={colorStr}
                  />
                }
                overlayClassName="comparison-card-pop"
                placement="left"
              >
                <div
                  className="ellipsis"
                  style={{
                    maxWidth: `calc((100vw - 500px) / 4 - ${title === cardTitleMap.pjtUpd ? 70 : 40}px)`,
                    cursor: 'pointer',
                  }}
                  title={title === cardTitleMap.supplyPlanUpd ? `${p.tgc}` : `${p.projectId} ${p.projectName}`}
                >
                  {title === cardTitleMap.pjtUpd ? (
                    <>
                      {p.existDiffType.startsWith('01') && <CheckSquareOutlined style={{ marginRight: 5, color: color.successColor }} />}
                      {p.existDiffType.startsWith('02') && <CloseSquareOutlined style={{ marginRight: 5, color: color.errorColor }} />}
                    </>
                  ) : (
                    <></>
                  )}
                  {title === cardTitleMap.supplyPlanUpd ? (
                    <>{p.tgc}</>
                  ) : (
                    <>
                      {p.projectId}
                      &nbsp;&nbsp;
                      {p.projectName}
                    </>
                  )}
                </div>
              </Popover>
            </li>
          ))}
        </ul>
      );
    }

    return (
      <Card
        className="item-card"
        title={CardTitle(title, Object.keys(projectsEntity), colorStr)}
        size="small"
        styles={{
          body: {
            height: `calc(100vh - ${selectorTop + 40}px)`,
          },
        }}
      >
        {cardContent}
      </Card>
    );
  };

  const getNumberDiff = (diff: number) => {
    if (diff > 0) {
      return `+${diff.toLocaleString()}`;
    }
    return diff.toLocaleString();
  };

  const ContentPop = (
    props: DiffDataItem<'diffDemandPjt'> & {
      colorStr: string;
      diffDetail: string;
      title: string;
    }
  ) => {
    const {
      projectId,
      projectName,
      diffDetail,
      budgetCategoryCurrent,
      tgc,
      sfdcDemandMhTarget,
      sfdcDemandMhCurrent,
      currentSupplyMh,
      targetSupplyMh,
      colorStr,
      title,
      children,
      sfdcDemandMhDiff,
      supplyMhDiff,
    } = props;
    return (
      <div>
        <h4 style={{ fontWeight: 'bold' }}>{t('aipcmcty.page.comparisonResource.changeContent')}</h4>
        <div className="grid-table">
          <div className="table-name">
            {title !== cardTitleMap.supplyPlanUpd ? (
              <>
                <div>{t('aipcmcty.page.comparisonResource.projectId')}</div>
                <div>{t('aipcmcty.page.comparisonResource.projectName')}</div>
              </>
            ) : (
              <></>
            )}
            <div>{t('aipcmcty.page.comparisonResource.workDiff')}</div>
            {children &&
              children.map((child) => (
                <div style={{ marginLeft: 15 }} key={`${child.projectId}-${child.division}`}>
                  {child.division}
                </div>
              ))}
            {diffDetail && <div>{t('aipcmcty.page.comparisonResource.diffDetail')}</div>}
            {title !== cardTitleMap.supplyPlanUpd ? <div>{t('aipcmcty.page.comparisonResource.budgetCategory')}</div> : <></>}
            <div>TGC</div>
            {/* <div>工数明細(今回 - 前回)</div> */}
            {title !== cardTitleMap.supplyPlanUpd ? (
              <>
                {' '}
                <div>{t('aipcmcty.page.comparisonResource.preDemandMH')}</div>
                <div>{t('aipcmcty.page.comparisonResource.curDemandMH')}</div>
              </>
            ) : (
              <>
                <div>{t('aipcmcty.page.comparisonResource.preSupplyMH')}</div>
                <div>{t('aipcmcty.page.comparisonResource.curSupplyMH')}</div>
              </>
            )}
          </div>
          <div className="table-value">
            {title !== cardTitleMap.supplyPlanUpd ? (
              <>
                <div>{projectId ?? '-'}</div>
                <div>{projectName ?? '-'}</div>
              </>
            ) : (
              <></>
            )}
            {title === cardTitleMap.supplyPlanUpd ? (
              <div className="number"> {getNumberDiff(supplyMhDiff)}</div>
            ) : (
              <div className="number"> {getNumberDiff(sfdcDemandMhDiff)}</div>
            )}
            {children &&
              children.map((child) =>
                title === cardTitleMap.supplyPlanUpd ? (
                  <div className="number" key={`${child.projectId}-${child.supplyMhDiff}`}>
                    {getNumberDiff(child.supplyMhDiff)}
                  </div>
                ) : (
                  <div className="number" key={`${child.projectId}-${child.sfdcDemandMhDiff}`}>
                    {getNumberDiff(child.sfdcDemandMhDiff)}
                  </div>
                )
              )}
            {diffDetail && (
              <Tooltip title={diffDetail ?? '-'}>
                <div className="update-item" style={{ '--update-color': colorStr } as CSSProperties}>
                  {diffDetail ?? '-'}
                </div>
              </Tooltip>
            )}
            {title !== cardTitleMap.supplyPlanUpd ? <div>{budgetCategoryCurrent ?? '-'}</div> : <></>}
            <div>{tgc ?? '-'}</div>
            {/* <div className="number">{`${
              round(
                title === cardTitleMap.supplyPlanUpd
                  ? currentSupplyMh
                  : sfdcDemandMhCurrent
              ).toLocaleString() ?? '-'
            } - ${
              round(
                title === cardTitleMap.supplyPlanUpd
                  ? targetSupplyMh
                  : sfdcDemandMhTarget
              ).toLocaleString() ?? '-'
            }`}</div> */}
            <div className="number" style={{ marginLeft: 15 }}>
              {round(title === cardTitleMap.supplyPlanUpd ? targetSupplyMh : sfdcDemandMhTarget).toLocaleString() ?? '-'}
            </div>
            <div className="number" style={{ marginLeft: 15 }}>
              {round(title === cardTitleMap.supplyPlanUpd ? currentSupplyMh : sfdcDemandMhCurrent).toLocaleString() ?? '-'}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const cardTitleMap = {
    pjtUpd: t('aipcmcty.page.comparisonResource.projectDelAdd'),
    formationUpd: t('aipcmcty.page.comparisonResource.formationChange'),
    pjtDurDateUpd: t('aipcmcty.page.comparisonResource.projectDateChange'),
    supplyPlanUpd: t('aipcmcty.page.comparisonResource.supplyPlanUpd'),
  };

  useEffect(() => {
    if (!selectedTgc.length) {
      return;
    }
    const newState = {
      ...filterSelect,
      tgc: selectedTgc,
    };
    setFilterSelect(newState);
    getComparison({
      tarSnapshot: compareVersion.snapshot,
      tarVersion: compareVersion.snapshotVersion,
      tgc: newState.tgc.join(','),
      division: newState.division.join(','),
      discipline: newState.discipline.join(','),
      role: newState.role.join(','),
      escape: false,
    });
  }, [selectedTgc]);

  const tabs = getAllMonth('2023-01', '2026-12').map((yearMonth) => ({
    key: yearMonth,
    label: yearMonth,
    children: (
      <div className="diff-container">
        <div style={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <div className="compare-item-resource-label">{t('aipcmcty.page.comparisonResource.demand')}</div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="compare-item-resource">
              <DiffCard
                diffD={{
                  title: cardTitleMap.pjtUpd,
                  projectsEntity: cardRenderData.pjtUpd,
                }}
                colorStr={color.errorColor}
                key={cardTitleMap.pjtUpd}
              />
            </div>
            <div className="compare-item-resource">
              <DiffCard
                diffD={{
                  title: cardTitleMap.formationUpd,
                  projectsEntity: cardRenderData.formationUpd,
                }}
                colorStr={color.errorColor}
                key={cardTitleMap.formationUpd}
              />
            </div>
            <div className="compare-item-resource">
              <DiffCard
                diffD={{
                  title: cardTitleMap.pjtDurDateUpd,
                  projectsEntity: cardRenderData.pjtDurDateUpd,
                }}
                colorStr={color.errorColor}
                key={cardTitleMap.pjtDurDateUpd}
              />
            </div>
          </div>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <div className="compare-item-resource-label">{t('aipcmcty.page.comparisonResource.supply')}</div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className="compare-item-resource">
              <DiffCard
                diffD={{
                  title: cardTitleMap.supplyPlanUpd,
                  projectsEntity: cardRenderData.supplyPlanUpd,
                }}
                colorStr={color.errorColor}
                key={cardTitleMap.supplyPlanUpd}
              />
            </div>
          </div>
        </div>
      </div>
    ),
  }));

  return (
    <div className="comparison">
      <Row className="operation-container" justify="space-between">
        <Col style={{ padding: '5px 0' }}>
          <Space>
            {t('aipcmcty.page.comparison.accountingPerspective')}
            :
            <Select
              style={{ width: 120 }}
              options={perspectiveOptions}
              value={accountingPerspective}
              onChange={(e) => setAccountPerspective(e)}
            />
            TGC:
            <Select
              allowClear
              showSearch
              style={{ width: 180 }}
              options={filterTgcOptions}
              value={filterSelect.tgc}
              mode="multiple"
              maxTagCount="responsive"
              onChange={(e) => handleFilterChange('tgc', e)}
            />
            Division:
            <Select
              allowClear
              showSearch
              style={{ width: 130 }}
              options={headerOptions.divisionOptions}
              value={filterSelect.division}
              mode="multiple"
              maxTagCount="responsive"
              onChange={(e) => handleFilterChange('division', e)}
              filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
            />
            Discipline:
            <Select
              allowClear
              showSearch
              style={{ width: 130 }}
              options={headerOptions.disciplineOptions}
              value={filterSelect.discipline}
              mode="multiple"
              maxTagCount="responsive"
              onChange={(e) => handleFilterChange('discipline', e)}
              filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
            />
            Role:
            <Select
              allowClear
              showSearch
              style={{ width: 130 }}
              options={headerOptions.roleOptions}
              value={filterSelect.role}
              mode="multiple"
              maxTagCount="responsive"
              onChange={(e) => handleFilterChange('role', e)}
              filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
            />
          </Space>
        </Col>
        <Col style={{ padding: '5px 0' }}>
          <Space>
            {t('aipcmcty.page.comparisonResource.targetSnapshot')}
            :
            <Select
              style={{ width: 150 }}
              options={headerOptions.snapshot}
              value={snapshotValue}
              onChange={(e) => handleVersionChange('snapshot', e)}
            />
            {t('aipcmcty.page.comparisonResource.targetVersion')}
            :
            <Select
              style={{ width: 150 }}
              options={headerOptions.snapshotVersion}
              value={versionValue}
              onChange={(e) => handleVersionChange('snapshotVersion', e)}
            />
            <Button
              type="primary"
              onClick={() => {
                handleCompare({
                  tarSnapshot: snapshotValue,
                  tarVersion: versionValue,
                });
              }}
            >
              {t('aipcmcty.page.comparisonResource.compare')}
            </Button>
          </Space>
        </Col>
      </Row>
      <div className="diff-graphs">
        <div style={{ flex: 1 }}>
          <ResourceDemandCompareChart
            operation={[state, dispatch]}
            chartMaxMh={chartMaxMh}
            data={expectedDemandMh}
            loading={dataLoading}
            chartLoading={chartLoading > 0}
          />
        </div>
        <div style={{ flex: 1 }}>
          <ResourceSupplyCompareChart
            operation={[state, dispatch]}
            chartMaxMh={chartMaxMh}
            data={expectedSupplyMh}
            loading={dataLoading}
            chartLoading={chartLoading > 0}
          />
        </div>
      </div>
      <Tabs
        onChange={(activeKey) => {
          dispatch({
            type: 'setTabActiveKey',
            tabKey: activeKey,
          });
        }}
        type="card"
        style={{ marginTop: 10 }}
        size="small"
        activeKey={state.tabActiveKey}
        items={tabs}
      />
    </div>
  );
};

export default ComparisonResourcePage;
