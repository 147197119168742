import { CloseOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { Layout } from 'antd';

const { Sider } = Layout;

/* @noflow */
export type DrawerProperties = {
  maxWidth: number;
  minWidth: number;
  collapsed: boolean;
  style?: React.CSSProperties;
  children: ReactNode;
  closeBtnFunc?: () => void;
};

const DrawerContainer: React.FC<DrawerProperties> = (props) => {
  const defaultStyle = {
    position: 'absolute',
    top: 0,
    right: -4,
    height: '100%',
    zIndex: 99,
    backgroundColor: 'white',
  };
  const {
    maxWidth,
    minWidth = 0,
    collapsed = true,
    style,
    children,
    closeBtnFunc,
  } = props;

  const mergedStyle = {
    ...defaultStyle,
    ...style
  };

  return (
    <Sider
      className="drawer-sider"
      style={mergedStyle as React.CSSProperties}
      width={maxWidth}
      collapsible
      collapsedWidth={minWidth}
      collapsed={collapsed}
      trigger={null}
    >
      {children}
      {closeBtnFunc && !collapsed && (
        <CloseOutlined
          onClick={() => closeBtnFunc()} style={{
            position: 'absolute', right: 18, top: 21, zIndex: 10
          }}
        />
      )}
    </Sider>
  );
};

export default DrawerContainer;